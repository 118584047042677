import {
  FjdButtonSelectGroup,
  FjdHeading,
  FjdMarkdown,
  FjdNewSelect,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useStorageState } from 'react-storage-hooks';

import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { PostboxesCards } from './PostboxesCards/PostboxesCards';
import { PostboxesTable } from './PostboxesTable/PostboxesTable';
import { FjdSelectOption } from 'fjd-react-components/build/components/Selects/Select.types';
import { PersonType } from '../../models/Person';
import { useUserRoles } from '../../hooks/useUserRoles';

export const postboxesListViewModeLocalStorageKey =
  'pdbk-postboxes-list-view-mode';

export function Postboxes() {
  const { t } = useTranslation();

  const [viewMode, setViewMode] = useStorageState<string | undefined>(
    localStorage,
    postboxesListViewModeLocalStorageKey
  );
  const { isApplicationAdmin } = useUserRoles();

  const ownerTypeSelectOptions: FjdSelectOption[] = [
    { label: 'Alle', value: '', valueObject: null },
    {
      label: 'Öffentliche Stellen',
      value: PersonType.PUBLIC_ORGANISATION,
      valueObject: PersonType.PUBLIC_ORGANISATION
    },
    {
      label: 'Nichtöffentliche Stellen',
      value: PersonType.PRIVATE_LEGAL_ENTITY,
      valueObject: PersonType.PRIVATE_LEGAL_ENTITY
    },
    {
      label: 'Natürliche Personen',
      value: PersonType.NATURAL_PERSON,
      valueObject: PersonType.NATURAL_PERSON
    }
  ];

  const [ownerTypeSelectOption, setOwnerTypeSelectOption] =
    useState<FjdSelectOption>(ownerTypeSelectOptions[0]);

  return (
    <>
      <Helmet>
        <title>
          {t('Postfächer')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack spacing="5xl">
        <FjdStack spacing="xs">
          <FjdSectionHeader
            align="center"
            heading={<FjdHeading level={1} text={t('Postfächer')} />}
          />
          <FjdMarkdown
            source={t(
              `Bitte wählen Sie zunächst das Postfach aus, mit dem Sie arbeiten möchten.`
            )}
          />
        </FjdStack>

        <FjdStack spacing="m">
          <FjdStack spacing="l" orientation={'horizontal'}>
            {isApplicationAdmin && (
              <FjdNewSelect
                id={'postfaecher-select'}
                size={'s'}
                disableSearch={true}
                options={ownerTypeSelectOptions}
                selectedItems={[ownerTypeSelectOption]}
                onChange={(selectedItems) =>
                  setOwnerTypeSelectOption(selectedItems[0])
                }
              />
            )}
            <FjdButtonSelectGroup
              id="view-mode"
              onChange={(event) => setViewMode(event.target.value)}
              options={[
                {
                  icon: 'grid',
                  selected: viewMode === 'cards' || !viewMode,
                  text: t('Kartenansicht'),
                  value: 'cards'
                },
                {
                  icon: 'table-split',
                  selected: viewMode === 'table',
                  text: t('Tabellenansicht'),
                  value: 'table'
                }
              ]}
              size="s"
            />
          </FjdStack>
          <Suspense
            fallback={<LoadingSpinner label={t('Lade Postfächer …')} />}
          >
            {(!viewMode || viewMode === 'cards') && (
              <PostboxesCards ownerType={ownerTypeSelectOption.valueObject} />
            )}
            {viewMode === 'table' && (
              <PostboxesTable
                pageSize={10}
                ownerType={ownerTypeSelectOption.valueObject}
              />
            )}
          </Suspense>
        </FjdStack>
      </FjdStack>
    </>
  );
}

export { Postboxes as default } from './Postboxes';
