import { useMemo } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';

import { RouteKey, routes } from '../utils/router';

export function useParentPostboxId() {
  const postboxSubPageMatch = useMatch(`${routes[RouteKey.POSTBOX].path}/*`);

  const [searchParams] = useSearchParams();

  const parentPostboxId = useMemo(
    () => searchParams.get('parentPostboxId'),
    [searchParams]
  );

  return parentPostboxId || (postboxSubPageMatch?.params.postboxId as string);
}
