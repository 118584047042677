import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreateLeadingPartyRequest,
  LeadingParty,
  UpdateLeadingPartyRequest
} from '../models/LeadingParty';
import { mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useLeadingParty(postboxId: string) {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createLeadingParty = useCallback(
    async (
      caseId: string,
      data: CreateLeadingPartyRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const leadingPartyPath = `/postboxes/${postboxId}/cases/${caseId}/leading-party`;

        await makeRequestWithFullResponse<LeadingParty>(
          leadingPartyPath,
          'POST',
          data
        );

        await mutate(leadingPartyPath);

        if (!silent) {
          alert(
            'success',
            t('Verfahrensführende Stelle erfolgreich erstellt.')
          );
        }

        setLoading(false);

        return makeRequest<LeadingParty>(leadingPartyPath, 'GET');
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, postboxId, t]
  );

  const updateLeadingParty = useCallback(
    async (
      caseId: string,
      data: UpdateLeadingPartyRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const updatedLeadingParty = await makeRequest<LeadingParty>(
          `/postboxes/${postboxId}/cases/${caseId}/leading-party`,
          'PUT',
          data
        );

        await mutate(`/postboxes/${postboxId}/cases/${caseId}/leading-party`);

        if (!silent) {
          alert('success', t('Änderungen gespeichert.'));
        }

        setLoading(false);

        return updatedLeadingParty;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  return {
    createLeadingParty,
    loading,
    updateLeadingParty
  };
}
