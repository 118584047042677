import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useTable } from '../../../../hooks/useTable';
import { Case } from '../../../../models/Case';
import { CasesTableCaseRow } from '../CasesTableCaseRow/CasesTableCaseRow';

interface CasesTableCaseRowsProps {
  cases: Case[];
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  postboxId: string;
  table: ReturnType<typeof useTable>;
}

export function CasesTableCaseRows({
  cases,
  onOpenPublicServiceType,
  table
}: CasesTableCaseRowsProps) {
  const { t } = useTranslation();

  return cases ? (
    <>
      {cases.length === 0 && (
        <FjdVirtualizedTableRow>
          <FjdVirtualizedTableCell level={2}>
            {t('Keine Fälle gefunden.')}
          </FjdVirtualizedTableCell>
        </FjdVirtualizedTableRow>
      )}

      {cases.map((c, index) => (
        <Fragment key={c?.id}>
          <CasesTableCaseRow
            case={c}
            first={index === 0}
            key={c.id}
            last={index === cases.length - 1}
            onOpenPublicServiceType={onOpenPublicServiceType}
            table={table}
          />
        </Fragment>
      ))}
    </>
  ) : null;
}
