import {
  FjdFlexSpacer,
  FjdFlyout,
  FjdNavigationBar,
  FjdNavigationBarItem,
  FjdSpinner
} from 'fjd-react-components';
import { MouseEventHandler, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';
import useSWR from 'swr';

import useElementByLocation from '../hooks/useElementByLocation';
import { PersonType } from '../models/Person';
import { Postbox } from '../models/Postbox';
import { RouteKey, routes } from '../utils/router';
import { truncate } from '../utils/string';
import { PostboxMenu } from './PostboxMenu';
import { RouterLink } from './RouterLink';

interface MainNavigationProps {
  onCreateCaseClick?: MouseEventHandler;
}

export function MainNavigation({ onCreateCaseClick }: MainNavigationProps) {
  const { t } = useTranslation();
  const casesPageMatch = useMatch(routes[RouteKey.CASES].path);
  const postboxPageMatch = useMatch(routes[RouteKey.POSTBOX].path);
  const postboxesSubPageMatch = useMatch(`${routes[RouteKey.POSTBOX].path}/*`);
  const locationElement = useElementByLocation();
  const postboxSettingsPageMatch = useMatch(
    routes[RouteKey.POSTBOX_SETTINGS].path
  );

  const postboxId = useMemo(() => {
    return postboxesSubPageMatch?.params.postboxId;
  }, [postboxesSubPageMatch]);

  const { data: postbox } = useSWR<Postbox>(
    postboxId ? `/postboxes/${postboxId}` : null,
    { suspense: false }
  );

  return (
    <FjdNavigationBar>
      <FjdFlyout
        flyout={
          <Suspense fallback={<FjdSpinner size="s" />}>
            <PostboxMenu />
          </Suspense>
        }
        offset={[16, 12]}
      >
        {postbox && (
          <FjdNavigationBarItem
            highlighted
            iconRight="caret-down"
            label={t(`Postfach: {{postboxName}}`, {
              postboxName: truncate(postbox?.name, 26)
            })}
          />
        )}
      </FjdFlyout>

      <RouterLink
        component={({ href, onClick }) => (
          <FjdNavigationBarItem
            active={!!postboxPageMatch}
            href={href}
            icon="table-split"
            label={t('Fallübersicht')}
            onClick={onClick}
          />
        )}
        to={`/postboxes/${postboxId}`}
      />

      <RouterLink
        component={({ href, onClick }) => (
          <FjdNavigationBarItem
            active={!!locationElement || !!casesPageMatch}
            href={href}
            icon="edit"
            label={t('Schreibtisch')}
            onClick={onClick}
          />
        )}
        to={`/postboxes/${postboxId}/cases`}
      />

      <RouterLink
        component={({ href, onClick }) => (
          <FjdNavigationBarItem
            active={!!postboxSettingsPageMatch}
            href={href}
            icon="settings"
            label={t('Postfacheinstellungen')}
            onClick={onClick}
          />
        )}
        to={`/postboxes/${postboxId}/settings`}
      />

      <FjdFlexSpacer />

      {postbox?.owner.type === PersonType.PUBLIC_ORGANISATION && (
        <FjdNavigationBarItem
          highlighted
          icon="add-outline"
          label={t('Neuen Fall anlegen')}
          onClick={onCreateCaseClick}
        />
      )}
    </FjdNavigationBar>
  );
}
