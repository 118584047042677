import { FjdTreeItem } from 'fjd-react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useElementByLocation from '../../../../hooks/useElementByLocation';
import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import useTabs from '../../../../hooks/useTabs';
import { useTreeExpansion } from '../../../../hooks/useTreeExpansion';
import { instanceOfMessage } from '../../../../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../../../../models/Participation';
import { ParticipationTreeItem } from '../ParticipationTreeItem/ParticipationTreeItem';

interface ParticipationsTreeProps {
  caseId: string;
  postboxId: string;
}

export function ParticipationsTree({
  caseId,
  postboxId
}: ParticipationsTreeProps) {
  const { expandNode } = useTreeExpansion('sidebar-tree');

  const { t } = useTranslation();
  const { activeTab } = useTabs(postboxId);
  const locationElement = useElementByLocation();

  const { data: participations } = usePaginatedSwr<Participation>({
    elementLabel: t('Mitwirkungen'),
    filter: [{ key: 'caseId', value: caseId }],
    key: `/postboxes/${postboxId}/participation-results`,
    pageSize: 9999
  });

  useEffect(() => {
    if (locationElement) {
      if (instanceOfMessage(locationElement)) {
        expandNode(locationElement.caseId);
        expandNode(locationElement.participationId);
      }

      if (instanceOfParticipation(locationElement)) {
        expandNode(locationElement.id);
      }
    }
  }, [expandNode, locationElement]);

  return participations ? (
    <>
      {participations.length === 0 && (
        <FjdTreeItem label={t('Keine Mitwirkungen')} />
      )}

      {participations.map((participation) => (
        <ParticipationTreeItem
          activeTab={activeTab}
          key={participation.id}
          participation={participation}
          postboxId={postboxId}
        />
      ))}
    </>
  ) : null;
}
