import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateInvolvedPartyRequest } from '../models/InvolvedParty';
import {
  CreateParticipationRequest,
  Participation,
  UpdateParticipationRequest
} from '../models/Participation';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';
import { useInvolvedParty } from './useInvolvedParty';

export function useParticipation(postboxId: string) {
  const { alert } = useAlerts();
  const { createInvolvedParty } = useInvolvedParty(postboxId);
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createParticipation = useCallback(
    async (
      data: CreateParticipationRequest,
      involvedParty: CreateInvolvedPartyRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const newParticipationResponse =
          await makeRequestWithFullResponse<Participation>(
            `/postboxes/${postboxId}/participations`,
            'POST',
            data
          );

        const newParticipation = await makeRequest<Participation>(
          newParticipationResponse.headers.location,
          'GET'
        );

        await createInvolvedParty(newParticipation.id, involvedParty, true);

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participations.*$`)
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participation-results.*$`)
        );

        if (!silent) {
          alert('success', t('Mitwirkung erfolgreich erstellt.'));
        }

        setLoading(false);

        return newParticipation;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [
      alert,
      createInvolvedParty,
      makeRequest,
      makeRequestWithFullResponse,
      postboxId,
      t
    ]
  );

  const deleteParticipation = useCallback(
    async (participationId: string) => {
      setLoading(true);

      try {
        await makeRequest(
          `/postboxes/${postboxId}/participations/${participationId}`,
          'DELETE'
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participations.*$`)
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participation-results.*$`)
        );

        alert('success', t('Mitwirkung gelöscht.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  const updateParticipation = useCallback(
    async (
      participationId: string,
      data: UpdateParticipationRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const updatedParticipation = await makeRequest<Participation>(
          `/postboxes/${postboxId}/participations/${participationId}`,
          'PUT',
          data
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participations.*$`)
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/participation-results.*$`)
        );

        await mutate(
          `/postboxes/${postboxId}/participations/${participationId}`
        );

        if (!silent) {
          alert('success', t('Änderungen gespeichert.'));
        }

        setLoading(false);

        return updatedParticipation;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  return {
    createParticipation,
    deleteParticipation,
    loading,
    updateParticipation
  };
}
