import {
  FjdButton,
  FjdFlyout,
  FjdMenu,
  FjdMenuItem
} from 'fjd-react-components';
import { FjdIconGlyph } from 'fjd-react-components/build/components/Icon/Icon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function LanguageSwitch() {
  const { i18n } = useTranslation();

  const languages = useMemo<
    {
      code: string;
      disabled?: boolean;
      icon: FjdIconGlyph;
      label: string;
    }[]
  >(
    () => [
      { code: 'de', icon: 'flag-de', label: 'Deutsch' },
      { code: 'en', disabled: true, icon: 'flag-uk', label: 'English' }
    ],
    []
  );

  const currentLanguage = useMemo(
    () =>
      languages.find((lang) => lang.code === i18n.language) ||
      languages.find((lang) => lang.code === 'en'),
    [i18n.language, languages]
  );

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  return (
    <FjdFlyout
      flyout={
        <FjdMenu>
          {languages.map((lang) => (
            <FjdMenuItem
              appearance="light"
              disabled={lang.disabled}
              icon={lang.icon}
              key={lang.code}
              label={lang.label}
              onClick={() => changeLanguage(lang.code)}
              size="s"
            />
          ))}
        </FjdMenu>
      }
    >
      <FjdButton
        appearance="link"
        iconLeft={currentLanguage?.icon}
        label={currentLanguage?.label || ''}
        size="s"
      />
    </FjdFlyout>
  );
}
