import { TFunction } from 'react-i18next';

import { AddressType } from '../models/Address';

// https://www.xrepository.de/details/urn:de:bund:destatis:bevoelkerungsstatistik:schluessel:staat
export const countryOptions = [
  {
    label: 'Afghanistan',
    value: '423'
  },
  {
    label: 'Ägypten',
    value: '287'
  },
  {
    label: 'Albanien',
    value: '121'
  },
  {
    label: 'Algerien',
    value: '221'
  },
  {
    label: 'Andorra',
    value: '123'
  },
  {
    label: 'Angola',
    value: '223'
  },
  {
    label: 'Antigua und Barbuda',
    value: '320'
  },
  {
    label: 'Äquatorialguinea',
    value: '274'
  },
  {
    label: 'Arabische Republik Syrien',
    value: '475'
  },
  {
    label: 'Argentinien',
    value: '323'
  },
  {
    label: 'Armenien',
    value: '422'
  },
  {
    label: 'Aserbaidschan',
    value: '425'
  },
  {
    label: 'Äthiopien',
    value: '225'
  },
  {
    label: 'Australien',
    value: '523'
  },
  {
    label: 'Bahamas',
    value: '324'
  },
  {
    label: 'Bahrain',
    value: '424'
  },
  {
    label: 'Bangladesch',
    value: '460'
  },
  {
    label: 'Barbados',
    value: '322'
  },
  {
    label: 'Belgien',
    value: '124'
  },
  {
    label: 'Belize',
    value: '330'
  },
  {
    label: 'Benin',
    value: '229'
  },
  {
    label: 'Bhutan',
    value: '426'
  },
  {
    label: 'Bolivarische Republik Venezuela',
    value: '367'
  },
  {
    label: 'Bosnien und Herzegowina',
    value: '122'
  },
  {
    label: 'Botsuana',
    value: '227'
  },
  {
    label: 'Brasilien',
    value: '327'
  },
  {
    label: 'Brunei Darussalam',
    value: '429'
  },
  {
    label: 'Bulgarien',
    value: '125'
  },
  {
    label: 'Burkina Faso',
    value: '258'
  },
  {
    label: 'Burundi',
    value: '291'
  },
  {
    label: 'Cabo Verde',
    value: '242'
  },
  {
    label: 'Chile',
    value: '332'
  },
  {
    label: 'China',
    value: '479'
  },
  {
    label: 'Cookinseln',
    value: '527'
  },
  {
    label: 'Costa Rica',
    value: '334'
  },
  {
    label: 'Côte d’Ivoire',
    value: '231'
  },
  {
    label: 'Dänemark',
    value: '126'
  },
  {
    label: 'Demokratische Republik Kongo',
    value: '246'
  },
  {
    label: 'Demokratische Volksrepublik Korea',
    value: '434'
  },
  {
    label: 'Demokratische Volksrepublik Laos',
    value: '449'
  },
  {
    label: 'Deutschland',
    value: '000'
  },
  {
    label: 'Dominica',
    value: '333'
  },
  {
    label: 'Dominikanische Republik',
    value: '335'
  },
  {
    label: 'Dschibuti',
    value: '230'
  },
  {
    label: 'Ecuador',
    value: '336'
  },
  {
    label: 'El Salvador',
    value: '337'
  },
  {
    label: 'Eritrea',
    value: '224'
  },
  {
    label: 'Estland',
    value: '127'
  },
  {
    label: 'Eswatini',
    value: '281'
  },
  {
    label: 'Fidschi',
    value: '526'
  },
  {
    label: 'Finnland',
    value: '128'
  },
  {
    label: 'Föderierte Staaten von Mikronesien',
    value: '545'
  },
  {
    label: 'Frankreich',
    value: '129'
  },
  {
    label: 'Gabun',
    value: '236'
  },
  {
    label: 'Gambia',
    value: '237'
  },
  {
    label: 'Georgien',
    value: '430'
  },
  {
    label: 'Ghana',
    value: '238'
  },
  {
    label: 'Grenada',
    value: '340'
  },
  {
    label: 'Griechenland',
    value: '134'
  },
  {
    label: 'Guatemala',
    value: '345'
  },
  {
    label: 'Guinea',
    value: '261'
  },
  {
    label: 'Guinea-Bissau',
    value: '259'
  },
  {
    label: 'Guyana',
    value: '328'
  },
  {
    label: 'Haiti',
    value: '346'
  },
  {
    label: 'Honduras',
    value: '347'
  },
  {
    label: 'Indien',
    value: '436'
  },
  {
    label: 'Indonesien',
    value: '437'
  },
  {
    label: 'Irak',
    value: '438'
  },
  {
    label: 'Irland',
    value: '135'
  },
  {
    label: 'Islamische Republik Iran',
    value: '439'
  },
  {
    label: 'Island',
    value: '136'
  },
  {
    label: 'Israel',
    value: '441'
  },
  {
    label: 'Italien',
    value: '137'
  },
  {
    label: 'Jamaika',
    value: '355'
  },
  {
    label: 'Japan',
    value: '442'
  },
  {
    label: 'Jemen',
    value: '421'
  },
  {
    label: 'Jordanien',
    value: '445'
  },
  {
    label: 'Kambodscha',
    value: '446'
  },
  {
    label: 'Kamerun',
    value: '262'
  },
  {
    label: 'Kanada',
    value: '348'
  },
  {
    label: 'Kasachstan',
    value: '444'
  },
  {
    label: 'Katar',
    value: '447'
  },
  {
    label: 'Kenia',
    value: '243'
  },
  {
    label: 'Kirgisistan',
    value: '450'
  },
  {
    label: 'Kiribati',
    value: '530'
  },
  {
    label: 'Kolumbien',
    value: '349'
  },
  {
    label: 'Komoren',
    value: '244'
  },
  {
    label: 'Kongo',
    value: '245'
  },
  {
    label: 'Kosovo',
    value: '150'
  },
  {
    label: 'Kroatien',
    value: '130'
  },
  {
    label: 'Kuba',
    value: '351'
  },
  {
    label: 'Kuwait',
    value: '448'
  },
  {
    label: 'Lesotho',
    value: '226'
  },
  {
    label: 'Lettland',
    value: '139'
  },
  {
    label: 'Libanon',
    value: '451'
  },
  {
    label: 'Liberia',
    value: '247'
  },
  {
    label: 'Libyen',
    value: '248'
  },
  {
    label: 'Liechtenstein',
    value: '141'
  },
  {
    label: 'Litauen',
    value: '142'
  },
  {
    label: 'Luxemburg',
    value: '143'
  },
  {
    label: 'Madagaskar',
    value: '249'
  },
  {
    label: 'Malawi',
    value: '256'
  },
  {
    label: 'Malaysia',
    value: '482'
  },
  {
    label: 'Malediven',
    value: '454'
  },
  {
    label: 'Mali',
    value: '251'
  },
  {
    label: 'Malta',
    value: '145'
  },
  {
    label: 'Marokko',
    value: '252'
  },
  {
    label: 'Marshallinseln',
    value: '544'
  },
  {
    label: 'Mauretanien',
    value: '239'
  },
  {
    label: 'Mauritius',
    value: '253'
  },
  {
    label: 'Mexiko',
    value: '353'
  },
  {
    label: 'Monaco',
    value: '147'
  },
  {
    label: 'Mongolei',
    value: '457'
  },
  {
    label: 'Montenegro',
    value: '140'
  },
  {
    label: 'Mosambik',
    value: '254'
  },
  {
    label: 'Myanmar',
    value: '427'
  },
  {
    label: 'Namibia',
    value: '267'
  },
  {
    label: 'Nauru',
    value: '531'
  },
  {
    label: 'Nepal',
    value: '458'
  },
  {
    label: 'Neuseeland',
    value: '536'
  },
  {
    label: 'Nicaragua',
    value: '354'
  },
  {
    label: 'Niederlande',
    value: '148'
  },
  {
    label: 'Niger',
    value: '255'
  },
  {
    label: 'Nigeria',
    value: '232'
  },
  {
    label: 'Niue',
    value: '533'
  },
  {
    label: 'Nordmazedonien',
    value: '144'
  },
  {
    label: 'Norwegen',
    value: '149'
  },
  {
    label: 'Oman',
    value: '456'
  },
  {
    label: 'Österreich',
    value: '151'
  },
  {
    label: 'Pakistan',
    value: '461'
  },
  {
    label: 'Palau',
    value: '537'
  },
  {
    label: 'Panama',
    value: '357'
  },
  {
    label: 'Papua-Neuguinea',
    value: '538'
  },
  {
    label: 'Paraguay',
    value: '359'
  },
  {
    label: 'Peru',
    value: '361'
  },
  {
    label: 'Philippinen',
    value: '462'
  },
  {
    label: 'Plurinationaler Staat Bolivien',
    value: '326'
  },
  {
    label: 'Polen',
    value: '152'
  },
  {
    label: 'Portugal',
    value: '153'
  },
  {
    label: 'Republik Korea',
    value: '467'
  },
  {
    label: 'Republik Moldau',
    value: '146'
  },
  {
    label: 'Ruanda',
    value: '265'
  },
  {
    label: 'Rumänien',
    value: '154'
  },
  {
    label: 'Russische Föderation',
    value: '160'
  },
  {
    label: 'Salomonen',
    value: '524'
  },
  {
    label: 'Sambia',
    value: '257'
  },
  {
    label: 'Samoa',
    value: '543'
  },
  {
    label: 'San Marino',
    value: '156'
  },
  {
    label: 'São Tomé und Príncipe',
    value: '268'
  },
  {
    label: 'Saudi-Arabien',
    value: '472'
  },
  {
    label: 'Schweden',
    value: '157'
  },
  {
    label: 'Schweiz',
    value: '158'
  },
  {
    label: 'Senegal',
    value: '269'
  },
  {
    label: 'Serbien',
    value: '170'
  },
  {
    label: 'Seychellen',
    value: '271'
  },
  {
    label: 'Sierra Leone',
    value: '272'
  },
  {
    label: 'Simbabwe',
    value: '233'
  },
  {
    label: 'Singapur',
    value: '474'
  },
  {
    label: 'Slowakei',
    value: '155'
  },
  {
    label: 'Slowenien',
    value: '131'
  },
  {
    label: 'Somalia',
    value: '273'
  },
  {
    label: 'Spanien',
    value: '161'
  },
  {
    label: 'Sri Lanka',
    value: '431'
  },
  {
    label: 'St. Kitts und Nevis',
    value: '370'
  },
  {
    label: 'St. Lucia',
    value: '366'
  },
  {
    label: 'St. Vincent und die Grenadinen',
    value: '369'
  },
  {
    label: 'Südafrika',
    value: '263'
  },
  {
    label: 'Sudan',
    value: '277'
  },
  {
    label: 'Südsudan',
    value: '278'
  },
  {
    label: 'Suriname',
    value: '364'
  },
  {
    label: 'Tadschikistan',
    value: '470'
  },
  {
    label: 'Thailand',
    value: '476'
  },
  {
    label: 'Timor-Leste',
    value: '483'
  },
  {
    label: 'Togo',
    value: '283'
  },
  {
    label: 'Tonga',
    value: '541'
  },
  {
    label: 'Trinidad und Tobago',
    value: '371'
  },
  {
    label: 'Tschad',
    value: '284'
  },
  {
    label: 'Tschechien',
    value: '164'
  },
  {
    label: 'Tunesien',
    value: '285'
  },
  {
    label: 'Türkei',
    value: '163'
  },
  {
    label: 'Turkmenistan',
    value: '471'
  },
  {
    label: 'Tuvalu',
    value: '540'
  },
  {
    label: 'Uganda',
    value: '286'
  },
  {
    label: 'Ukraine',
    value: '166'
  },
  {
    label: 'Ungarn',
    value: '165'
  },
  {
    label: 'Uruguay',
    value: '365'
  },
  {
    label: 'Usbekistan',
    value: '477'
  },
  {
    label: 'Vanuatu',
    value: '532'
  },
  {
    label: 'Vatikanstadt',
    value: '167'
  },
  {
    label: 'Vereinigte Arabische Emirate',
    value: '469'
  },
  {
    label: 'Vereinigte Republik Tansania',
    value: '282'
  },
  {
    label: 'Vereinigte Staaten',
    value: '368'
  },
  {
    label: 'Vereinigtes Königreich',
    value: '168'
  },
  {
    label: 'Vietnam',
    value: '432'
  },
  {
    label: 'Weißrussland',
    value: '169'
  },
  {
    label: 'Zentralafrikanische Republik',
    value: '289'
  },
  {
    label: 'Zypern',
    value: '181'
  }
];

export function getAddressTypeLabel(
  addressType: AddressType,
  t: TFunction
): string {
  if (!addressType) {
    return '';
  }

  switch (addressType) {
    case AddressType.INTERNATIONAL:
      return t('International');
    case AddressType.NATIONAL:
      return t('National');
    case AddressType.POSTBOX:
      return t('Postfach');
    default:
      return '';
  }
}
