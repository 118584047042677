// @ts-nocheck
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SWRConfig } from 'swr';
import { Configuration } from 'swr/dist/types';

import useAlerts from '../hooks/useAlerts';
import { useApi } from '../hooks/useApi';
import { cache } from '../swr';

interface AppContainerProps {
  children: ReactNode;
}

export function AppContainer({ children }: AppContainerProps) {
  const { makeRequest } = useApi();
  const { alert, renderAlertContainer } = useAlerts();
  const { t } = useTranslation();

  const swrConfig = useMemo<Partial<Configuration>>(
    () => ({
      cache,
      errorRetryCount: 0,
      fetcher: (url) => makeRequest(`${url}`, 'GET'),
      onError: (error) => {
        if (
          error &&
          (String(error.status).startsWith('4') ||
            String(error.status).startsWith('5'))
        ) {
          alert('error', t('Da ist leider etwas schiefgelaufen.'));
          throw new Error(error);
        }
      },
      revalidateOnFocus: false,
      suspense: true
    }),
    [alert, makeRequest, t]
  );

  return (
    //TODO typescript issues
    <SWRConfig value={swrConfig}>
      {children}
      {renderAlertContainer()}
    </SWRConfig>
  );
}
