import Joi, { Schema } from 'joi';

export type InvolvedParty = {
  contactPersonId?: string;
  fileReference?: string;
  postboxId: string;
  processReference?: string;
  responsibleOrganisationalUnit?: string;
};

export type CreateInvolvedPartyRequest = InvolvedParty;

export type UpdateInvolvedPartyRequest = InvolvedParty;

export const CreateInvolvedPartyRequestSchema: Schema = Joi.object({
  contactPersonId: Joi.string().optional().allow(''),
  fileReference: Joi.string().optional().allow(''),
  postboxId: Joi.string().required(),
  processReference: Joi.string().optional().allow(''),
  responsibleOrganisationalUnit: Joi.string().optional().allow('')
});

export const UpdateInvolvedPartyRequestSchema =
  CreateInvolvedPartyRequestSchema;
