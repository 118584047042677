import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreateInvolvedPartyRequest,
  InvolvedParty,
  UpdateInvolvedPartyRequest
} from '../models/InvolvedParty';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useInvolvedParty(postboxId: string) {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createInvolvedParty = useCallback(
    async (
      participationId: string,
      data: CreateInvolvedPartyRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const involvedPartyPath = `/postboxes/${postboxId}/participations/${participationId}/involved-party`;

        await makeRequestWithFullResponse<InvolvedParty>(
          involvedPartyPath,
          'POST',
          data
        );

        await mutate(involvedPartyPath);

        if (!silent) {
          alert('success', t('Kommunikationspartner erfolgreich erstellt.'));
        }

        setLoading(false);

        return makeRequest<InvolvedParty>(involvedPartyPath, 'GET');
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, postboxId, t]
  );

  const deleteInvolvedParty = useCallback(
    async (participationId: string) => {
      setLoading(true);

      try {
        await makeRequest(
          `/postboxes/${postboxId}/participations/${participationId}/involved-party`,
          'DELETE'
        );

        await mutate(
          `/postboxes/${postboxId}/participations/${participationId}/involved-party`
        );

        alert('success', t('Kommunikationspartner entfernt.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  const updateInvolvedParty = useCallback(
    async (
      participationId: string,
      data: UpdateInvolvedPartyRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const updatedInvolvedParty = await makeRequest<InvolvedParty>(
          `/postboxes/${postboxId}/participations/${participationId}/involved-party`,
          'PUT',
          data
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/.+/participations/${participationId}/involved-party$`
          )
        );

        if (!silent) {
          alert('success', t('Änderungen gespeichert.'));
        }

        setLoading(false);

        return updatedInvolvedParty;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  return {
    createInvolvedParty,
    deleteInvolvedParty,
    loading,
    updateInvolvedParty
  };
}
