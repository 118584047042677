import {
  FjdButton,
  FjdFlexSpacer,
  FjdStack,
  FjdTooltip,
  FjdTruncatedText,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../../hooks/usePaginatedSwr';
import { useTreeExpansion } from '../../../../../hooks/useTreeExpansion';
import { Message } from '../../../../../models/Message';
import { Participation } from '../../../../../models/Participation';

interface ParticipationSelectionTableRowProps {
  onDownloadProtocol: (element: Message | Participation) => void;
  onSelect: (element: Message | Participation) => void;
  participation: Participation;
}

export function ParticipationSelectionTableRow({
  onDownloadProtocol,
  onSelect,
  participation
}: ParticipationSelectionTableRowProps) {
  const { t } = useTranslation();

  const { toggleNode, treeExpansion } = useTreeExpansion(
    'participation-logging'
  );

  const { data: messages } = usePaginatedSwr<Message>({
    elementLabel: t('Nachrichten'),
    key: `/postboxes/${participation.postboxId}/participations/${participation.id}/message-results`,
    pageSize: 999999
  });

  return (
    <>
      <FjdVirtualizedTableRow>
        <FjdVirtualizedTableCell>
          <FjdStack orientation="horizontal" spacing="s">
            <FjdButton
              appearance="primary-link"
              disabled={messages?.length === 0}
              hideLabel
              iconLeft={
                treeExpansion[participation.id] ? 'caret-up' : 'caret-down'
              }
              label={t('Zeile ein-/ausklappen')}
              onClick={() => toggleNode(participation.id)}
              size="s"
            />
            <FjdTruncatedText text={participation.title} />
            <span>({messages.length})</span>
          </FjdStack>
        </FjdVirtualizedTableCell>
        <FjdVirtualizedTableCell>
          <FjdTruncatedText text={participation.id} />
        </FjdVirtualizedTableCell>
        <FjdVirtualizedTableCell>
          <FjdTruncatedText allowHTML text={participation.description || '-'} />
        </FjdVirtualizedTableCell>
        <FjdVirtualizedTableCell maxWidth="6.5rem">
          <FjdStack orientation="horizontal" spacing="s">
            <FjdFlexSpacer />
            <FjdTooltip
              focusable={false}
              placement="top"
              tooltip={t('Protokolleinträge abrufen')}
            >
              <FjdButton
                appearance="primary-link"
                hideLabel
                iconLeft="view"
                label={t('Protokolleinträge abrufen')}
                onClick={() => onSelect(participation)}
                size="s"
              />
            </FjdTooltip>
            <FjdTooltip
              focusable={false}
              placement="top"
              tooltip={t('Protokolleinträge herunterladen')}
            >
              <FjdButton
                appearance="primary-link"
                hideLabel
                iconLeft="download"
                label={t('Protokolleinträge herunterladen')}
                onClick={() => onDownloadProtocol(participation)}
                size="s"
              />
            </FjdTooltip>
          </FjdStack>
        </FjdVirtualizedTableCell>
      </FjdVirtualizedTableRow>

      {treeExpansion[participation.id] &&
        messages.map((message) => (
          <FjdVirtualizedTableRow key={message.id}>
            <FjdVirtualizedTableCell level={1}>
              <FjdStack orientation="horizontal" spacing="s">
                <span style={{ visibility: 'hidden' }}>
                  <FjdButton
                    appearance="primary-link"
                    disabled
                    hideLabel
                    iconLeft="caret-down"
                    label={t('Zeile ein-/ausklappen')}
                    size="s"
                  />
                </span>
                <FjdTruncatedText text={message.subject} />
              </FjdStack>
            </FjdVirtualizedTableCell>
            <FjdVirtualizedTableCell>
              <FjdTruncatedText text={message.id} />
            </FjdVirtualizedTableCell>
            <FjdVirtualizedTableCell>
              <FjdTruncatedText allowHTML text={message.note || '-'} />
            </FjdVirtualizedTableCell>
            <FjdVirtualizedTableCell maxWidth="6.5rem">
              <FjdStack orientation="horizontal" spacing="s">
                <FjdFlexSpacer />
                <FjdTooltip
                  focusable={false}
                  placement="top"
                  tooltip={t('Protokolleinträge abrufen')}
                >
                  <FjdButton
                    appearance="primary-link"
                    hideLabel
                    iconLeft="view"
                    label={t('Protokolleinträge abrufen')}
                    onClick={() => onSelect(message)}
                    size="s"
                  />
                </FjdTooltip>
                <FjdTooltip
                  focusable={false}
                  placement="top"
                  tooltip={t('Protokolleinträge herunterladen')}
                >
                  <FjdButton
                    appearance="primary-link"
                    hideLabel
                    iconLeft="download"
                    label={t('Protokolleinträge herunterladen')}
                    onClick={() => onDownloadProtocol(message)}
                    size="s"
                  />
                </FjdTooltip>
              </FjdStack>
            </FjdVirtualizedTableCell>
          </FjdVirtualizedTableRow>
        ))}
    </>
  );
}
