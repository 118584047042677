import { FjdButton, FjdPagination, FjdStack } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import { Except } from 'type-fest';

import { PaginationStyle, usePagination } from '../hooks/usePagination';

interface PaginationProps {
  elementLabel?: string;
  pagination: Except<ReturnType<typeof usePagination>, 'PaginationComponent'>;
  paginationStyle?: PaginationStyle;
}

export function Pagination({
  elementLabel = 'Elemente',
  pagination,
  paginationStyle = 'default'
}: PaginationProps) {
  const { t } = useTranslation();

  return paginationStyle === 'default' ? (
    <FjdPagination
      currentPage={pagination.currentPage}
      labels={{
        currentPage: t('Aktuelle Seite'),
        nextPage: t('Nächste Seite'),
        of: t('von'),
        page: t('Seite'),
        pageSize: t('{{elementLabel}} pro Seite', { elementLabel }),
        previousPage: t('Vorherige Seite'),
        rows: t('{{elementLabel}}', { elementLabel })
      }}
      onNextPage={pagination.nextPage}
      onPrevPage={pagination.prevPage}
      onSetPage={pagination.setPage}
      pageSize={pagination.pageSize}
      rowCount={pagination.rowCount}
    />
  ) : (
    <FjdStack orientation="horizontal" spacing="s">
      <FjdButton
        appearance="secondary"
        disabled={pagination.currentPage <= 0}
        hideLabel
        iconLeft="arrow-left"
        label={t('Vorherige Seite')}
        onClick={pagination.prevPage}
        size="s"
      />
      <FjdButton
        appearance="secondary"
        disabled={pagination.currentPage + 1 >= pagination.pages}
        hideLabel
        iconLeft="arrow-right"
        label={t('Nächste Seite')}
        onClick={pagination.nextPage}
        size="s"
      />
    </FjdStack>
  );
}
