import {
  FjdButton,
  FjdFormControl,
  FjdSelect,
  FjdStack
} from 'fjd-react-components';
import { FjdSelectOption } from 'fjd-react-components/build/components/Select/Select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../hooks/usePaginatedSwr';
import { useUserRoles } from '../hooks/useUserRoles';
import { Postbox } from '../models/Postbox';
import { RouterLink } from './RouterLink';

export interface PostboxSelectProps {
  onChange?: (selection: Postbox | null) => void;
  selectedPostbox?: Postbox | null;
  showEditButton?: boolean;
}

export function PostboxSelect({
  onChange,
  selectedPostbox,
  showEditButton = true
}: PostboxSelectProps) {
  const { t } = useTranslation();
  const { isApplicationAdmin } = useUserRoles();

  const { data: postboxes } = usePaginatedSwr<Postbox>({
    elementLabel: t('Postfächer'),
    key: isApplicationAdmin ? '/postbox-results' : '/users/postboxes',
    filter:
      showEditButton && !isApplicationAdmin
        ? [{ key: 'admin', value: showEditButton + '' }]
        : [],
    pageSize: 9999
  });

  const postboxOptions = useMemo<FjdSelectOption[]>(
    () => [
      { placeholder: true, text: t('- Bitte wählen -'), value: '' },
      ...postboxes.map((postbox) => ({ text: postbox.name, value: postbox.id }))
    ],
    [postboxes, t]
  );

  return (
    <FjdFormControl label={t('Ausgewähltes Postfach')}>
      <div>
        <FjdStack orientation="horizontal" spacing="m">
          <FjdSelect
            ariaLabel={t('Postfachauswahl')}
            disabled={postboxOptions.length <= 1}
            id="postbox"
            minWidth="18rem"
            name="postbox"
            onChange={(postboxId) => {
              if (typeof onChange === 'function') {
                onChange(
                  postboxId !== ''
                    ? (postboxes.find(
                        (postbox) => postbox.id === postboxId
                      ) as Postbox)
                    : null
                );
              }
            }}
            options={postboxOptions}
            size="s"
            value={selectedPostbox?.id || ''}
          />

          {selectedPostbox && showEditButton && (
            <RouterLink
              component={({ href, onClick }) => (
                <FjdButton
                  appearance="primary-link"
                  href={href}
                  iconLeft="edit"
                  label={t('Postfach bearbeiten')}
                  onClick={onClick}
                  size="s"
                />
              )}
              to={`/postboxes/${selectedPostbox.id}/settings`}
            />
          )}
        </FjdStack>
      </div>
    </FjdFormControl>
  );
}
