import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import { FileHash } from './File';

export interface Document {
  authorization?: string;
  documentDate?: string;
  filename?: string;
  formatNote?: string;
  hash?: FileHash;
  id: string;
  mimeType: string;
  note?: string;
  receivedOn?: string;
  relevance?: string;
  sentOn: string;
  title: string;
  version?: string;
  reference: ExternalDocumentReference | FileReference;
}

export interface ExternalDocumentReference {
  authorization?: any;
  accessMode: string;
  type: 'EXTERNAL';
  uri: string;
}

export interface FileReference {
  fileId: string;
  type: 'FILE';
}

export type CreateDocumentRequest = Except<Document, 'reference' | 'sentOn'> & {
  reference: { type: 'EXTERNAL' | 'FILE' };
};

export const CreateDocRequestSchema: Schema = Joi.object({
  authorization: Joi.string().optional().allow(''),
  documentDate: Joi.string().optional().allow(''),
  filename: Joi.string().optional().allow(''),
  formatNote: Joi.string().optional().allow(''),
  hash: Joi.object().optional(),
  id: Joi.string().optional().allow(''),
  mimeType: Joi.string().required(),
  note: Joi.string().optional().allow(''),
  reference: Joi.object({
    accessMode: Joi.string().optional().allow(''),
    fileId: Joi.string().optional(),
    type: Joi.string().valid('EXTERNAL', 'FILE').required(),
    uri: Joi.string().optional().allow('')
  }).required(),
  relevance: Joi.string().optional().allow(''),
  sentOn: Joi.string().optional().allow(''),
  title: Joi.string().required(),
  version: Joi.string().optional().allow('')
});

export function instanceOfExternalDocumentReference(
  object: any
): object is ExternalDocumentReference {
  return object.type === 'EXTERNAL';
}

export function instanceOfFileReference(object: any): object is FileReference {
  return object.type === 'FILE';
}
