import {
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdHeading,
  FjdStack
} from 'fjd-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import PostboxesSettings from './PostboxesSettings/PostboxesSettings';
import Protocols from './Protocols/Protocols';
import PublicServiceTypes from './PublicServiceTypes/PublicServiceTypes';

enum ContentTab {
  PST = 'public-service-types',
  POSTBOXES = 'postboxes',
  PROTOCOL = 'protocol'
}

export function Settings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const [activeContentTab, setActiveContentTab] = useState<ContentTab>(
    ContentTab.PST
  );

  useEffect(() => {
    const tabFromUrl = searchParams.get('tab');

    if (tabFromUrl && tabFromUrl !== activeContentTab) {
      setActiveContentTab(tabFromUrl as ContentTab);
    }
  }, [activeContentTab, searchParams]);

  return (
    <>
      <Helmet>
        <title>
          {t('Einstellungen')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack spacing="xl">
        <FjdHeading level={1} text={t('Einstellungen')} />

        <FjdStack spacing="5xl">
          <FjdContentTabBar>
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.PST}
              label={t('Verwaltungsleistungen')}
              onClick={() => {
                setActiveContentTab(ContentTab.PST);
                setSearchParams({ tab: ContentTab.PST });
              }}
            />
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.PROTOCOL}
              label={t('Protokolle & Statistik')}
              onClick={() => {
                setActiveContentTab(ContentTab.PROTOCOL);
                setSearchParams({ tab: ContentTab.PROTOCOL });
              }}
            />
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.POSTBOXES}
              label={t('Postfachverwaltung')}
              onClick={() => {
                setActiveContentTab(ContentTab.POSTBOXES);
                setSearchParams({ tab: ContentTab.POSTBOXES });
              }}
            />
          </FjdContentTabBar>

          {activeContentTab === ContentTab.PST && <PublicServiceTypes />}
          {activeContentTab === ContentTab.POSTBOXES && <PostboxesSettings />}
          {activeContentTab === ContentTab.PROTOCOL && <Protocols />}
        </FjdStack>
      </FjdStack>
    </>
  );
}

export { Settings as default } from './Settings';
