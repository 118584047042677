import {
  FjdCard,
  FjdFlexSpacer,
  FjdHeading,
  FjdIcon,
  FjdLoadingOverlay,
  FjdStack,
  FjdTruncatedText
} from 'fjd-react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Case } from '../../../models/Case';
import { Postbox } from '../../../models/Postbox';
import { RouterLink } from '../../../shared/RouterLink';
import { getPostboxPath } from '../../../utils/router';
import { PersonType } from '../../../models/Person';

interface PostboxCardProps {
  postboxId: string;
}

export function PostboxCard({ postboxId }: PostboxCardProps) {
  const { t } = useTranslation();

  const { data: postbox, isValidating: postboxIsValidating } = useSWR<Postbox>(
    `/postboxes/${postboxId}`
  );

  const { data: cases, isValidating: casesIsValidating } = useSWR<Case[]>(
    `/postboxes/${postboxId}/cases`
  );

  const loading = useMemo(
    () => postboxIsValidating || casesIsValidating,
    [casesIsValidating, postboxIsValidating]
  );

  const postboxFooterName = (pb: Postbox) => {
    if (pb.owner.type === PersonType.NATURAL_PERSON) {
      return pb.owner.name.firstName + ' ' + pb.owner.name.lastName;
    }
    return pb.owner.organizationName;
  };

  return postbox ? (
    <FjdLoadingOverlay loading={loading}>
      <RouterLink
        component={({ href, onClick }) => (
          <FjdCard
            footer={
              <FjdStack orientation="horizontal" spacing="l">
                <FjdStack orientation="horizontal" spacing="s">
                  <FjdIcon glyph="user-avatar" size="s" />
                  <span>{postboxFooterName(postbox)}</span>
                </FjdStack>
                <FjdFlexSpacer />
                <span>
                  {t('{{count}} Fälle', { count: cases?.length || 0 })}
                </span>
              </FjdStack>
            }
            href={href}
            minHeight="12.5rem"
            onClick={onClick}
            padding="xl"
          >
            <FjdStack spacing="m">
              <FjdHeading level={2} maxLines={2} text={postbox.name} />
              <FjdTruncatedText maxLines={2} text={postbox.note || ''} />
            </FjdStack>
          </FjdCard>
        )}
        to={getPostboxPath(postbox)}
      />
    </FjdLoadingOverlay>
  ) : null;
}
