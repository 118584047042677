import {
  FjdFormControl,
  FjdMarkdown,
  FjdModal,
  FjdStack
} from 'fjd-react-components';
import Joi from 'joi';
import { Suspense, useCallback, useRef } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { usePublicServiceTypeLead } from '../../../../hooks/usePublicServiceTypeLead';
import { PublicServiceType } from '../../../../models/PublicServiceType';
import { LoadingSpinner } from '../../../../shared/LoadingSpinner';
import { submitForm } from '../../../../utils/form';
import { PostboxesTable } from '../../../Postboxes/PostboxesTable/PostboxesTable';

interface RegisterPostboxesModalProps {
  onClose: () => void;
  onRegister?: () => void;
  publicServiceTypeToRegister?: PublicServiceType;
}

type FormControls = { postboxIds: string[] };

export function RegisterPostboxesModal({
  onClose,
  onRegister,
  publicServiceTypeToRegister
}: RegisterPostboxesModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const { createPublicServiceTypeLead, loading } = usePublicServiceTypeLead();

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm<FormControls>({
    defaultValues: {
      postboxIds: []
    },
    resolver: joiResolver(
      Joi.object({
        postboxIds: Joi.array().items(Joi.string().optional()).required()
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onRegisterPublicServiceType = useCallback(
    async (data: FormControls) => {
      if (publicServiceTypeToRegister) {
        await createPublicServiceTypeLead(data.postboxIds, {
          publicServiceTypeId: publicServiceTypeToRegister.id
        });

        if (typeof onRegister === 'function') {
          onRegister();
        }
      }
    },
    [createPublicServiceTypeLead, onRegister, publicServiceTypeToRegister]
  );

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Verwaltungsleistung registrieren')}
      id="link-postbox-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => {
        submitForm(form.current);
      }}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Änderungen speichern')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <form onSubmit={handleSubmit(onRegisterPublicServiceType)} ref={form}>
        <FjdStack spacing="xl">
          <FjdStack spacing="m">
            <FjdMarkdown
              source={t(
                `Bitte wählen Sie die Postfächer aus, die Sie für die Verwaltungsleistung **„{{publicServiceTypeName}}“** registrieren möchten.`,
                { publicServiceTypeName: publicServiceTypeToRegister?.name }
              )}
            />
          </FjdStack>

          <FjdFormControl
            inputId="postboxIds"
            label={t('Postfächer')}
            validationMessage={
              (errors.postboxIds as DeepMap<any, FieldError>)?.errors
            }
          >
            <Controller
              name="postboxIds"
              control={control}
              render={({ field }) => {
                return (
                  <Suspense
                    fallback={<LoadingSpinner label={t('Lade Postfächer …')} />}
                  >
                    <PostboxesTable
                      defaultSelection={field.value}
                      linked={false}
                      onSelectionChange={(selection) => {
                        field.onChange(selection);
                        trigger('postboxIds');
                      }}
                      pageSize={10}
                      selectionType="multi"
                    />
                  </Suspense>
                );
              }}
            />
          </FjdFormControl>
        </FjdStack>

        <input type="submit" hidden />
      </form>
    </FjdModal>
  );
}
