import { useKeycloak } from '@react-keycloak/web';

import { Role } from '../models/Role';

export function useUserRoles() {
  const { keycloak } = useKeycloak();

  const roles = keycloak.tokenParsed?.resource_access?.pdbk?.roles;
  const isApplicationAdmin = roles?.includes(Role.ADMIN);
  const isExtParticipant = roles?.includes(Role.EXT_PARTICIPANT);

  return { roles, isApplicationAdmin, isExtParticipant };
}
