import { TableColumn, useTable } from '../../../../hooks/useTable';

export const casesTableDefaultColumns: TableColumn[] = [
  {
    active: true,
    fixed: true,
    header: '',
    id: 'selection',
    minWidth: '2.75rem',
    sortable: false,
    width: '2.75rem'
  },
  {
    active: true,
    fixed: true,
    header: '',
    id: 'type',
    minWidth: '3.125rem',
    sortable: false,
    width: '3.125rem'
  },
  {
    active: true,
    fixed: true,
    header: 'Bezeichnung / Betreff',
    id: 'name',
    resizable: true,
    sortable: true,
    sortKey: 'TITLE',
    width: '24rem'
  },
  {
    active: true,
    header: 'Verwaltungsleistung',
    id: 'publicServiceType',
    resizable: true,
    sortable: true,
    sortKey: 'PUBLIC_SERVICE_TYPE_ID',
    width: '16rem'
  },
  {
    active: true,
    header: 'Stelle',
    id: 'authority',
    resizable: true,
    sortable: false,
    width: '16rem'
  },
  {
    active: true,
    header: 'Geschäftszeichen VF.S',
    id: 'leadProcessReference',
    minWidth: '6rem',
    resizable: true,
    sortable: false
  },
  {
    active: true,
    header: 'Geschäftszeichen VB.S',
    id: 'participantProcessReference',
    minWidth: '6rem',
    resizable: true,
    sortable: false
  },
  {
    active: true,
    format: 'number',
    header: 'Ausgelöst/gesendet',
    id: 'elementDate',
    minWidth: '6rem',
    resizable: true,
    sortable: false
  },
  {
    active: true,
    format: 'number',
    header: 'Empfangen',
    id: 'messageReceivedOn',
    minWidth: '6rem',
    resizable: true,
    sortable: false
  },
  {
    active: true,
    format: 'number',
    header: '# Mitwirkungen',
    id: 'amountParticipations',
    resizable: true,
    sortable: false,
    width: '9rem'
  },
  {
    active: true,
    format: 'number',
    header: '# Nachrichten',
    id: 'amountMessages',
    resizable: true,
    sortable: false,
    width: '8rem'
  },
  {
    active: true,
    format: 'number',
    header: '# Dokumente',
    id: 'amountDocuments',
    resizable: true,
    sortable: false,
    width: '8rem'
  },
  {
    active: true,
    fixed: true,
    header: '',
    id: 'config',
    minWidth: '4.5rem',
    sortable: false,
    width: '4.5rem'
  }
];

interface CasesTableHeaderProps {
  table: ReturnType<typeof useTable>;
}

export function CasesTableHeader({ table }: CasesTableHeaderProps) {
  return table.tableHeader;
}
