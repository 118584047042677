import {
  FjdButton,
  FjdFlyout,
  FjdHeader,
  FjdMenu,
  FjdMenuItem,
  FjdStack
} from 'fjd-react-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

import { useProfile } from '../hooks/useProfile';
import { RouteKey, routes } from '../utils/router';
import { RouterLink } from './RouterLink';
import Config from '../config.json';
import UiTemplateLogo from './UiTemplate';

export function Header() {
  const { keycloak } = useKeycloak();
  const { profile } = useProfile();
  const { t } = useTranslation();
  const keycloakUrl =
    process.env.REACT_APP_KEYCLOAK_URL || Config.REACT_APP_KEYCLOAK_URL;
  const keycloakRealm =
    process.env.REACT_APP_KEYCLOAK_REALM || Config.REACT_APP_KEYCLOAK_REALM;

  const postboxesPageMatch = useMatch(routes[RouteKey.POSTBOXES].path);
  const settingsPageMatch = useMatch(routes[RouteKey.SETTINGS].path);

  const logout = useCallback(() => {
    keycloak?.logout({
      redirectUri:
        process.env.REACT_APP_KEYCLOAK_REDIRECT ||
        Config.REACT_APP_KEYCLOAK_REDIRECT
    });
  }, [keycloak]);

  return (
    <FjdHeader
      logo={<UiTemplateLogo />}
      menu={
        <FjdMenu>
          <RouterLink
            component={({ href, onClick }) => (
              <FjdMenuItem
                appearance="light"
                href={href}
                icon="home"
                label={t('Startseite')}
                onClick={onClick}
              />
            )}
            to="/"
          />

          <RouterLink
            component={({ href, onClick }) => (
              <FjdMenuItem
                appearance="light"
                href={href}
                icon="settings"
                label={t('Einstellungen')}
                onClick={onClick}
              />
            )}
            to="/settings"
          />

          <FjdMenuItem
            appearance="light"
            href={process.env.REACT_APP_DOCS || Config.REACT_APP_DOCS}
            icon="help-outline"
            label={t('Hilfe')}
          />

          {keycloak.authenticated && (
            <FjdMenuItem
              appearance="light"
              icon="user-avatar"
              label={`${profile?.firstName} ${profile?.lastName}`}
              onClick={logout}
            />
          )}
        </FjdMenu>
      }
      navigation={
        <FjdStack orientation="horizontal">
          <RouterLink
            component={({ href, onClick }) => (
              <FjdButton
                appearance={postboxesPageMatch ? 'link' : 'primary-link'}
                iconLeft="home"
                label={t('Startseite')}
                href={href}
                onClick={onClick}
              />
            )}
            to="/"
          />

          <RouterLink
            component={({ href, onClick }) => (
              <FjdButton
                appearance={settingsPageMatch ? 'link' : 'primary-link'}
                iconLeft="settings"
                label={t('Einstellungen')}
                href={href}
                onClick={onClick}
              />
            )}
            to="/settings"
          />

          <FjdButton
            appearance="primary-link"
            href={process.env.REACT_APP_DOCS || Config.REACT_APP_DOCS}
            iconLeft="help-outline"
            label={t('Hilfe')}
          />

          {keycloak.authenticated && (
            <FjdFlyout
              flyout={
                <FjdMenu>
                  <FjdMenuItem
                    appearance="light"
                    icon="user-avatar-filled-alt"
                    label={t('Kontoverwaltung')}
                    onClick={() => {
                      window.open(
                        `${keycloakUrl}/realms/${keycloakRealm}/account`,
                        '_blank'
                      );
                    }}
                  />
                  <FjdMenuItem
                    appearance="light"
                    icon="logout"
                    label={t('Abmelden')}
                    onClick={logout}
                  />
                </FjdMenu>
              }
              offset={6}
              placement="bottom-end"
            >
              <FjdButton
                appearance="primary-link"
                data-testid="user-button"
                iconLeft="user-avatar"
                label={`${profile?.firstName} ${profile?.lastName}`}
              />
            </FjdFlyout>
          )}
        </FjdStack>
      }
    />
  );
}
