import {
  FjdCard,
  FjdColumns,
  FjdFieldsetControl,
  FjdFormControl,
  FjdModal,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useCallback, useEffect, useRef } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { usePostbox } from '../../../../hooks/usePostbox';
import {
  FormOfAddress,
  NaturalPerson,
  NotPublicPersonType,
  PersonType,
  PublicOrganization
} from '../../../../models/Person';
import {
  CreatePostboxRequest,
  CreatePostboxRequestSchema
} from '../../../../models/Postbox';
import { submitForm } from '../../../../utils/form';
import { getPersonTypeLabel } from '../../../../utils/postbox';
import { useUserRoles } from '../../../../hooks/useUserRoles';

interface CreatePostboxModalProps {
  onClose: () => void;
}

type FormControls = CreatePostboxRequest;

export function CreatePostboxModal({ onClose }: CreatePostboxModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();
  const { isApplicationAdmin } = useUserRoles();

  const form = useRef<HTMLFormElement>(null);

  const { createPostbox, loading } = usePostbox();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      notifications: [],
      owner: {
        type: isApplicationAdmin
          ? PersonType.PUBLIC_ORGANISATION
          : PersonType.PRIVATE_LEGAL_ENTITY
      }
    },
    resolver: joiResolver(
      CreatePostboxRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onCreate = useCallback(
    async (data: FormControls) => {
      await createPostbox(data);

      onClose();
    },
    [createPostbox, onClose]
  );

  const ownerType = watch('owner.type');

  useEffect(() => {
    register('notifications', { value: [] });
  }, [register]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Neues Postfach anlegen')}
      id="create-postbox-modal"
      loading={loading}
      onClose={() => !loading && onClose()}
      onPrimaryButtonClick={() => submitForm(form.current)}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Postfach anlegen')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <form onSubmit={handleSubmit(onCreate)} ref={form}>
        <FjdStack spacing="xl">
          <FjdFieldsetControl label={t('Inhaberdaten')}>
            <FjdCard>
              <FjdStack spacing="xl">
                <FjdFormControl
                  inputId="ownerType"
                  label={t('Art des Inhabers')}
                  validationMessage={
                    (errors.owner as DeepMap<PublicOrganization, FieldError>)
                      ?.type?.message
                  }
                >
                  <Controller
                    name="owner.type"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdSelect
                          ariaLabel={t('Art des Inhabers')}
                          disableSearch
                          id="ownerType"
                          name={field.name}
                          onChange={field.onChange}
                          options={Object.values(
                            isApplicationAdmin
                              ? PersonType
                              : NotPublicPersonType
                          ).map((type) => ({
                            selected: field.value === type,
                            text: getPersonTypeLabel(type, t),
                            value: type
                          }))}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                {(ownerType === PersonType.PUBLIC_ORGANISATION ||
                  ownerType === PersonType.PRIVATE_LEGAL_ENTITY) && (
                  <FjdColumns spacing="xl">
                    <FjdFormControl
                      inputId="owner.organizationName"
                      label={t('Organisationsname')}
                      validationMessage={
                        (
                          errors.owner as DeepMap<
                            PublicOrganization,
                            FieldError
                          >
                        )?.organizationName?.message
                      }
                    >
                      <Controller
                        name="owner.organizationName"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id="owner.organizationName"
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </FjdFormControl>

                    <FjdFormControl
                      inputId="owner.organisationalUnit"
                      label={t('Organisationseinheit')}
                      optional
                      validationMessage={
                        (
                          errors.owner as DeepMap<
                            PublicOrganization,
                            FieldError
                          >
                        )?.organisationalUnit?.message
                      }
                    >
                      <Controller
                        name="owner.organisationalUnit"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id="owner.organisationalUnit"
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                    </FjdFormControl>
                  </FjdColumns>
                )}

                {ownerType === PersonType.NATURAL_PERSON && (
                  <>
                    <FjdColumns>
                      <FjdFormControl
                        inputId="owner.name.doctoralDegrees"
                        label={t('Titel')}
                        optional
                        validationMessage={
                          (errors.owner as DeepMap<NaturalPerson, FieldError>)
                            ?.name?.doctoralDegrees?.message
                        }
                      >
                        <Controller
                          name="owner.name.doctoralDegrees"
                          control={control}
                          render={({ field }) => {
                            return (
                              <FjdTextInput
                                defaultValue={field.value}
                                id="owner.name.doctoralDegrees"
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </FjdFormControl>

                      <FjdFormControl
                        inputId="owner.name.formOfAddress"
                        label={t('Anrede')}
                        validationMessage={
                          (errors.owner as DeepMap<NaturalPerson, FieldError>)
                            ?.name?.formOfAddress?.message
                        }
                      >
                        <Controller
                          name="owner.name.formOfAddress"
                          control={control}
                          render={({ field }) => {
                            return (
                              <FjdSelect
                                disableSearch
                                id="name.formOfAddress"
                                name={field.name}
                                onChange={field.onChange}
                                options={Object.values(FormOfAddress).map(
                                  (formOfAddress) => ({
                                    selected: field.value === formOfAddress,
                                    text: formOfAddress,
                                    value: formOfAddress,
                                    id: formOfAddress.toLowerCase()
                                  })
                                )}
                                value={field.value}
                              />
                            );
                          }}
                        />
                      </FjdFormControl>
                    </FjdColumns>

                    <FjdColumns>
                      <FjdFormControl
                        inputId="owner.name.lastName"
                        label={t('Name')}
                        validationMessage={
                          (errors.owner as DeepMap<NaturalPerson, FieldError>)
                            ?.name?.lastName?.message
                        }
                      >
                        <Controller
                          name="owner.name.lastName"
                          control={control}
                          render={({ field }) => {
                            return (
                              <FjdTextInput
                                defaultValue={field.value}
                                id="owner.name.lastName"
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </FjdFormControl>

                      <FjdFormControl
                        inputId="owner.name.firstName"
                        label={t('Vorname')}
                        validationMessage={
                          (errors.owner as DeepMap<NaturalPerson, FieldError>)
                            ?.name?.firstName?.message
                        }
                      >
                        <Controller
                          name="owner.name.firstName"
                          control={control}
                          render={({ field }) => {
                            return (
                              <FjdTextInput
                                defaultValue={field.value}
                                id="owner.name.firstName"
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </FjdFormControl>
                    </FjdColumns>
                  </>
                )}
              </FjdStack>
            </FjdCard>
          </FjdFieldsetControl>

          <FjdFieldsetControl label={t('Postfachdaten')}>
            <FjdCard>
              <FjdStack spacing="xl">
                <FjdFormControl
                  inputId="name"
                  label={t('Postfachname')}
                  validationMessage={errors.name?.message}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="name"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                <FjdFormControl
                  inputId="note"
                  label={t('Bemerkung zum Postfach')}
                  optional
                  validationMessage={errors.note?.message}
                >
                  <Controller
                    name="note"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="note"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                          rows={3}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </FjdStack>
            </FjdCard>
          </FjdFieldsetControl>
        </FjdStack>

        <input type="submit" hidden />
      </form>
    </FjdModal>
  );
}
