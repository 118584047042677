import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import { Privileges } from './Privileges';

export interface Case {
  caseStatus?: CaseStatus;
  description?: string;
  id: string;
  postboxId: string;
  publicServiceTypeId: string;
  privileges?: Privileges[];
  startDate?: string;
  title: string;
}

export enum CaseStatus {
  CLOSED = 'CLOSED',
  OPENED = 'OPENED'
}

export type CreateCaseRequest = Except<
  Case,
  'id' | 'postboxId' | 'privileges' | 'startDate'
>;

export type UpdateCaseRequest = CreateCaseRequest;

export const CreateCaseRequestSchema: Schema = Joi.object({
  description: Joi.string().optional().allow(''),
  publicServiceTypeId: Joi.string().required(),
  title: Joi.string().required()
});

export const UpdateCaseRequestSchema = CreateCaseRequestSchema;
export function instanceOfCase(object?: any): object is Case {
  if (!object) {
    return false;
  }

  return (
    'postboxId' in object &&
    'publicServiceTypeId' in object &&
    'title' in object
  );
}
