import { FjdStack } from 'fjd-react-components';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { ContactPersonTable } from './ContactPersonTable/ContactPersonTable';

export function PostboxContactPersons() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('Kontaktpersonen')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense
        fallback={<LoadingSpinner label={t('Lade Kontaktpersonen …')} />}
      >
        <FjdStack spacing="5xl">
          <ContactPersonTable />
        </FjdStack>
      </Suspense>
    </>
  );
}

export { PostboxContactPersons as default } from './PostboxContactPersons';
