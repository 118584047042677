export function submitForm(form?: HTMLFormElement | null) {
  if (!form) {
    return;
  }

  if (typeof form.requestSubmit === 'function') {
    form.requestSubmit();
  } else {
    form.dispatchEvent(
      new Event('submit', { bubbles: true, cancelable: true })
    );
  }
}
