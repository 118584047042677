import { CreateMessageRequest } from '../../../../models/Message';
import {
  CreateMessageForm,
  Uploads
} from '../../../Participation/CreateMessageModal/CreateMessageForm/CreateMessageForm';

interface CreateParticipationFormStep3Props {
  caseId: string;
  initialData?: Partial<CreateMessageRequest>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  onToggleDocs?: (showDocs: boolean) => void;
  onUploadChange?: (uploads: Uploads) => void;
  onUploading?: (uploading: boolean) => void;
  postboxId: string;
}

type FormControls = CreateMessageRequest;

export function CreateParticipationFormStep3({
  caseId,
  initialData,
  onChange,
  onInit,
  onNext,
  onToggleDocs,
  onUploadChange,
  onUploading,
  postboxId
}: CreateParticipationFormStep3Props) {
  return (
    <CreateMessageForm
      caseId={caseId}
      initialData={initialData}
      isParticipationStartMessage
      onInit={onInit}
      onChange={onChange}
      onCreate={onNext}
      onToggleDocs={onToggleDocs}
      onUploadChange={onUploadChange}
      onUploading={onUploading}
      postboxId={postboxId}
    />
  );
}
