import { FjdTreeItem } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { useParentPostboxId } from '../../../../hooks/useParentPostboxId';
import useTabs from '../../../../hooks/useTabs';
import { Message } from '../../../../models/Message';
import { MessageTreeItem } from '../MessageTreeItem/MessageTreeItem';

interface MessagesTreeProps {
  participationId: string;
  postboxId: string;
}

export function MessagesTree({
  participationId,
  postboxId
}: MessagesTreeProps) {
  const { t } = useTranslation();
  const { activeTab } = useTabs(postboxId);
  const parentPostboxId = useParentPostboxId();

  const { data: messages } = usePaginatedSwr<Message>({
    key: `/postboxes/${parentPostboxId}/participations/${participationId}/message-results`,
    pageSize: 9999,
    sortByOrder: 'DESC',
    sortByProperty: 'RECEIVED_ON'
  });

  return messages ? (
    <>
      {messages.length === 0 && <FjdTreeItem label={t('Keine Nachrichten')} />}

      {messages.map((message) => (
        <MessageTreeItem
          activeTab={activeTab}
          key={message.id}
          message={message}
          postboxId={postboxId}
        />
      ))}
    </>
  ) : null;
}
