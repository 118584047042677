import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';

import { PdfExportData } from '../../../models/PdfExportData';
import { PublicOrganization } from '../../../models/Person';
import { getMessageTypeLabel } from '../../../utils/message';
import { getParticipationObligationLabel } from '../../../utils/participation';
import { truncate } from '../../../utils/string';

Font.register({
  family: 'Roboto',
  src: 'Roboto-Regular.ttf'
});

Font.register({
  family: 'Roboto',
  fontWeight: 'bold',
  src: 'Roboto-Bold.ttf'
});

export const exportPdfStyles = StyleSheet.create({
  body: {
    flexGrow: 1
  },
  footer: {
    bottom: '1.69cm',
    left: '2.41cm',
    position: 'absolute',
    right: '1.69cm'
  },
  footerContent: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 11,
    justifyContent: 'space-between',
    lineHeight: 1
  },
  footerText: {
    flexGrow: 1,
    fontSize: 10
  },
  header: {
    top: '1.69cm',
    left: '2.41cm',
    position: 'absolute',
    right: '1.69cm'
  },
  heading1: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.3,
    marginBottom: 18
  },
  heading2: {
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 6
  },
  heading3: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6
  },
  heading4: {
    fontSize: 11,
    fontWeight: 'bold',
    marginBottom: 6
  },
  logo: {
    alignSelf: 'flex-end',
    width: '3cm'
  },
  page: {
    backgroundColor: '#fff',
    fontFamily: 'Roboto',
    fontSize: 11,
    paddingBottom: '3.69cm',
    paddingLeft: '2.41cm',
    paddingRight: '1.69cm',
    paddingTop: '4.69cm'
  },
  pageNumber: {
    fontSize: 10
  },
  section: {
    lineHeight: 1.5,
    marginBottom: '1cm'
  },
  subHeading: {
    fontSize: 12
  },
  table: {
    marginBottom: 6
  },
  tableCell: {
    border: '1pt solid #ddd',
    borderLeft: 'none',
    flexBasis: '64%',
    flexGrow: 1,
    paddingBottom: 4,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5
  },
  tableHeaderCell: {
    backgroundColor: '#f4f4f4',
    border: '1pt solid #ddd',
    flexBasis: '36%',
    flexShrink: 0,
    paddingBottom: 4,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5
  },
  tableRow: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'row',
    fontSize: 10,
    marginTop: -1
  }
});

export interface ExportPdfProps {
  data: PdfExportData;
}

export function ExportPdf({ data }: ExportPdfProps) {
  const { t } = useTranslation();

  const caseTable: { label: string; value?: string }[] = [
    {
      label: 'Verfahrensführende Stelle',
      value: data.leadingParty.postbox?.name
    },
    {
      label: 'Zuständige Organisationseinheit',
      value: (data.leadingParty.postbox?.owner as PublicOrganization)
        .organisationalUnit
    },
    {
      label: 'Verwaltungsleistung',
      value: data.publicServiceType?.name
    },
    {
      label: 'Bezeichnung des Falls',
      value: data?.title
    },
    {
      label: 'Aktenzeichen der verfahrensführenden Stelle',
      value: data.leadingParty?.fileReference
    },
    {
      label: 'Geschäftszeichen der verfahrensführenden Stelle',
      value: data.leadingParty?.processReference
    },
    {
      label: 'Auslösung des Falls in der verfahrensführenden Stelle',
      value: data?.startDate ? new Date(data?.startDate).toLocaleString() : ''
    },
    {
      label: 'Beschreibung des Falls',
      value: data?.description
    }
  ];

  const renderParticipationTable = useCallback(
    (participation: PdfExportData['participations'][0]) => [
      {
        label: 'Verfahrensbeteiligte Stelle',
        value: participation.involvedParty.postbox?.name
      },
      {
        label: 'Bezeichnung der Mitwirkung',
        value: participation.title
      },
      {
        label: 'Mitwirkungsaufgabe',
        value: participation.task
      },
      {
        label: 'Rechtsgrundlage der Mitwirkung',
        value: participation.legalBasis
      },
      {
        label: 'Verbindlichkeit der Mitwirkung',
        value: getParticipationObligationLabel(participation.obligation, t)
      },
      {
        label: 'Auslösung der Mitwirkung durch die verfahrensführende Stelle',
        value: participation.startDate
          ? new Date(participation.startDate).toLocaleString()
          : ''
      },
      {
        label: 'Mitwirkungsfrist',
        value: participation.deadline
          ? new Date(participation.deadline).toLocaleString()
          : ''
      },
      {
        label: 'Beschreibung der Mitwirkung',
        value: participation.description
      },
      {
        label: 'Aktenzeichen der verfahrensbeteiligten Stelle',
        value: participation.involvedParty.fileReference
      },
      {
        label: 'Geschäftszeichen der verfahrensbeteiligten Stelle',
        value: participation.involvedParty.processReference
      },
      {
        label:
          'Zuständige Organisationseinheit der verfahrensbeteiligten Stelle',
        value: participation.involvedParty.responsibleOrganisationalUnit
      }
    ],
    [t]
  );

  const renderMessageTable = useCallback(
    (
      participation: PdfExportData['participations'][0],
      message: PdfExportData['participations'][0]['messages'][0]
    ) => [
      {
        label: 'Absender',
        value: message.fromLeader
          ? data.leadingParty.postbox.name
          : participation.involvedParty.postbox.name
      },
      {
        label: 'Empfänger',
        value: !message.fromLeader
          ? data.leadingParty.postbox.name
          : participation.involvedParty.postbox.name
      },
      {
        label: 'Betreff',
        value: message.subject
      },
      {
        label: 'Art der Nachricht',
        value: getMessageTypeLabel(message.type, t)
      },
      {
        label: 'Versendet',
        value: message.sentOn ? new Date(message.sentOn).toLocaleString() : ''
      },
      {
        label: 'Empfangen',
        value: message.receivedOn
          ? new Date(message.receivedOn).toLocaleString()
          : ''
      },
      {
        label: 'Nachrichtentext',
        value: message.text
      }
    ],
    [data.leadingParty.postbox.name, t]
  );

  const renderDocumentsTable = useCallback(
    (message: PdfExportData['participations'][0]['messages'][0]) =>
      message.docs?.map((doc, index) => ({
        label: `Angehängtes Dokument ${index + 1}`,
        value: `Bezeichnung: ${doc.title}
          Dateiname: ${doc.filename || '-'}
          Dateityp: ${doc.mimeType || '-'}
          Version: ${doc.version || '-'}
          Autorisierungshinweis: ${doc.authorization || '-'}
          Datum: ${doc.documentDate || '-'}
          Bemerkung: ${doc.note || '-'}`
      })),
    []
  );

  return (
    <Document>
      <Page size="A4" style={exportPdfStyles.page} wrap>
        <View fixed style={exportPdfStyles.header}>
          <Image
            src="digitale-services-logo.png"
            style={exportPdfStyles.logo}
          />
        </View>

        <View style={exportPdfStyles.body}>
          <View style={exportPdfStyles.section}>
            <Text style={exportPdfStyles.subHeading}>
              Plattform Digitale Behördenkommunikation (PDBK)
            </Text>
            <Text style={exportPdfStyles.heading1}>
              Dokumentation des Falls „{data?.title}“
            </Text>
            <Text>
              Erstellt von: {data.profile?.firstName} {data.profile?.lastName}
            </Text>
            <Text>
              Erstellt am:{' '}
              {new Date().toLocaleDateString(undefined, { dateStyle: 'long' })}
            </Text>
          </View>

          <View style={exportPdfStyles.section}>
            <Text style={exportPdfStyles.heading2}>
              <span>1</span> Fall | {data.leadingParty.postbox?.name}{' '}
              {data.leadingParty?.fileReference
                ? `| ${data.leadingParty?.fileReference}`
                : ''}
            </Text>

            <View style={exportPdfStyles.table}>
              {caseTable.map((row) => (
                <View
                  key={row.label}
                  style={exportPdfStyles.tableRow}
                  wrap={false}
                >
                  <View style={exportPdfStyles.tableHeaderCell}>
                    <Text>{row.label}</Text>
                  </View>
                  <View style={exportPdfStyles.tableCell}>
                    <Text>{row.value || '-'}</Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          {data.participations?.map((participation, participationIndex) => (
            <View key={participation.id}>
              <View style={exportPdfStyles.section}>
                <Text style={exportPdfStyles.heading3}>
                  <span>1.{participationIndex + 1}</span> Mitwirkung |{' '}
                  {participation?.involvedParty.postbox.name}
                </Text>

                <View style={exportPdfStyles.table}>
                  {renderParticipationTable(participation).map((row) => (
                    <View
                      key={row.label}
                      style={exportPdfStyles.tableRow}
                      wrap={false}
                    >
                      <View style={exportPdfStyles.tableHeaderCell}>
                        <Text>{row.label}</Text>
                      </View>
                      <View style={exportPdfStyles.tableCell}>
                        <Text>{row.value || '-'}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              </View>

              {participation.messages.map((message, messageIndex) => (
                <View key={message.id} style={exportPdfStyles.section}>
                  <Text style={exportPdfStyles.heading4}>
                    <span>
                      1.{participationIndex + 1}.{messageIndex + 1}
                    </span>{' '}
                    Nachricht | {message.subject}
                  </Text>

                  <View style={exportPdfStyles.table}>
                    {renderMessageTable(participation, message).map((row) => (
                      <View
                        key={row.label}
                        style={exportPdfStyles.tableRow}
                        wrap={false}
                      >
                        <View style={exportPdfStyles.tableHeaderCell}>
                          <Text>{row.label}</Text>
                        </View>
                        <View style={exportPdfStyles.tableCell}>
                          <Text>{row.value || '-'}</Text>
                        </View>
                      </View>
                    ))}
                  </View>

                  {message.docs && message.docs.length > 0 && (
                    <View style={exportPdfStyles.table}>
                      {renderDocumentsTable(message)?.map((row) => (
                        <View
                          key={row.label}
                          style={exportPdfStyles.tableRow}
                          wrap={false}
                        >
                          <View style={exportPdfStyles.tableHeaderCell}>
                            <Text>{row.label}</Text>
                          </View>
                          <View style={exportPdfStyles.tableCell}>
                            <Text>{row.value || '-'}</Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  )}
                </View>
              ))}
            </View>
          ))}
        </View>

        <View
          fixed
          render={({ pageNumber }) => (
            <View style={exportPdfStyles.footerContent}>
              <Text style={exportPdfStyles.footerText}>
                PDBK | Dokumentation Fall „{truncate(data?.title, 30)}“ |{' '}
                {new Date().toLocaleDateString(undefined, {
                  dateStyle: 'long'
                })}
              </Text>
              <Text style={exportPdfStyles.pageNumber}>Seite {pageNumber}</Text>
            </View>
          )}
          style={exportPdfStyles.footer}
        />
      </Page>
    </Document>
  );
}
