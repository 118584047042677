import Config from '../config.json';

enum UiTemplateName {
  THUERINGEN = 'thueringen',
  NIEDERSACHSEN = 'niedersachsen'
}

const uiTemplate: string =
  process.env.REACT_APP_UI_TEMPLATE || Config.REACT_APP_UI_TEMPLATE;

export default function UiTemplateLogo() {
  switch (uiTemplate) {
    case UiTemplateName.THUERINGEN:
      return (
        <img
          alt={'Freistaat Thüringen Logo'}
          height="60"
          src="/freistaat-thueringen-logo.svg"
          width="122"
        />
      );
    case UiTemplateName.NIEDERSACHSEN:
      return (
        <img
          alt={'Niedersachsen Logo'}
          height="60"
          src="/niedersachsen-logo.svg"
          width="122"
        />
      );
    default:
      return <div>Unbekanntes UI-Template</div>;
  }
}

export function getUiTemplateFaviconString(): string {
  switch (uiTemplate) {
    case UiTemplateName.THUERINGEN:
      return './favicon_thueringen.ico';
    case UiTemplateName.NIEDERSACHSEN:
      return './favicon_niedersachsen.ico';
    default:
      return 'data:,';
  }
}
