import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import useSWR from 'swr';

import { RouteKey, routes } from '../utils/router';
import { useParentPostboxId } from './useParentPostboxId';
import { User } from '../models/User';

function useUserByLocation() {
  const parentPostboxId = useParentPostboxId();
  const postboxSettingsPageMatch = useMatch(
    `${routes[RouteKey.POSTBOX].path}/settings`
  );

  const elementUrl = useMemo(() => {
    if (postboxSettingsPageMatch) {
      return `/users/postboxes/${parentPostboxId}`;
    }

    return null;
  }, [parentPostboxId, postboxSettingsPageMatch]);

  const element = useSWR<User>(elementUrl, {
    suspense: false
  });

  return elementUrl ? element.data : null;
}

export default useUserByLocation;
