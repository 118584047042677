import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay,
  FjdModal,
  FjdSpinner
} from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { Case } from '../../../models/Case';
import { InvolvedParty } from '../../../models/InvolvedParty';
import { LeadingParty } from '../../../models/LeadingParty';
import { Participation } from '../../../models/Participation';
import { PublicServiceType } from '../../../models/PublicServiceType';
import { getParticipationObligationLabel } from '../../../utils/participation';
import { defaultDateFormatOptions } from '../../../utils/string';
import { PublicServiceTypeModal } from '../../PublicServiceType/PublicServiceTypeModal/PublicServiceTypeModal';

interface ParticipationDetailsProps {
  participationId: string;
  postboxId: string;
}

export function ParticipationDetails({
  participationId,
  postboxId
}: ParticipationDetailsProps) {
  const { t } = useTranslation();

  const [publicServiceTypeDetail, setPublicServiceTypeDetail] =
    useState<string>();

  const { data: participation, isValidating } = useSWR<Participation>(
    `/postboxes/${postboxId}/participations/${participationId}`
  );

  const { data: caseData } = useSWR<Case>(
    participation
      ? `/postboxes/${postboxId}/cases/${participation?.caseId}`
      : null,
    { suspense: false }
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    participation
      ? `/postboxes/${postboxId}/cases/${participation?.caseId}/leading-party`
      : null
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${postboxId}/participations/${participation?.id}/involved-party`
  );

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    caseData?.publicServiceTypeId
      ? `/public-service-types/${caseData.publicServiceTypeId}`
      : null,
    { suspense: false }
  );

  return participation ? (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdDescriptionList>
          <FjdDescriptionListItem
            description={
              <a
                href={`/public-service-types/${publicServiceType?.id}`}
                onClick={(event) => {
                  event.preventDefault();
                  setPublicServiceTypeDetail(publicServiceType?.id);
                }}
              >
                {publicServiceType?.name}
              </a>
            }
            term={t('Verwaltungsleistung')}
          />
          <FjdDescriptionListItem
            description={
              <Link to={`/postboxes/${postboxId}/cases/${caseData?.id}`}>
                {caseData?.title}
              </Link>
            }
            term={t('Fall')}
          />
          {participation.description && (
            <FjdDescriptionListItem
              allowHTML
              description={participation.description}
              term={t('Beschreibung')}
            />
          )}
          <FjdDescriptionListItem
            description={participation.task}
            term={t('Mitwirkungsaufgabe')}
          />
          <FjdDescriptionListItem
            description={getParticipationObligationLabel(
              participation.obligation,
              t
            )}
            term={t('Verbindlichkeit der Mitwirkung')}
          />
          {participation.legalBasis && (
            <FjdDescriptionListItem
              allowHTML
              description={participation.legalBasis}
              term={t('Rechtsgrundlage')}
            />
          )}
          {participation.startDate && (
            <FjdDescriptionListItem
              description={new Date(
                participation.startDate
              ).toLocaleDateString()}
              term={t('Datum der Auslösung')}
            />
          )}
          {participation.deadline && (
            <FjdDescriptionListItem
              description={new Date(participation.deadline).toLocaleString(
                [],
                defaultDateFormatOptions
              )}
              term={t('Mitwirkungsfrist')}
            />
          )}

          {involvedParty?.fileReference && (
            <FjdDescriptionListItem
              description={leadingParty?.fileReference}
              term={t('Aktenzeichen')}
            />
          )}

          {involvedParty?.processReference && (
            <FjdDescriptionListItem
              description={leadingParty?.processReference}
              term={t('Geschäftszeichen')}
            />
          )}
        </FjdDescriptionList>
      </FjdLoadingOverlay>

      {publicServiceTypeDetail && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              heading={t('Lade …')}
              id="public-service-type-details-modal"
              open
            >
              <FjdSpinner />
            </FjdModal>
          }
        >
          <PublicServiceTypeModal
            onClose={() => setPublicServiceTypeDetail(undefined)}
            publicServiceTypeId={publicServiceTypeDetail}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
