import {
  FjdCard,
  FjdFormControl,
  FjdHeading,
  FjdListInput,
  FjdLoadingOverlay,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';
import Joi from 'joi';
import { useCallback, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';
import { useDebounceCallback } from '@react-hook/debounce';

import { usePostbox } from '../../../../hooks/usePostbox';
import {
  NotificationDuration,
  Postbox,
  PostboxNotification
} from '../../../../models/Postbox';
import { submitForm } from '../../../../utils/form';
import { getNotificationDurationLabel } from '../../../../utils/postbox';

interface EscalationLevelProps {
  level: number;
  notifications: PostboxNotification[];
  postbox: Postbox;
}

type FormControls = {
  emailAddresses: string[];
};

export function EscalationLevel({
  level,
  notifications,
  postbox
}: EscalationLevelProps) {
  const { t } = useTranslation();

  const form = useRef<HTMLFormElement>(null);

  const { loading, updatePostbox } = usePostbox();

  const {
    clearErrors,
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: {
      emailAddresses: notifications.map((notification) => notification.email)
    },
    resolver: joiResolver(
      Joi.object({
        emailAddresses: Joi.array()
          .unique()
          .items(
            Joi.string()
              .email({ tlds: { allow: false } })
              .allow('')
          )
          .optional()
      }).messages({
        'array.unique': t('Bitte entfernen Sie doppelte E-Mail-Adressen.'),
        'string.email': t(
          'Bitte überprüfen Sie die Eingabe auf ungültige E-Mail-Adressen.'
        )
      })
    ) as Resolver<FormControls, object>,
    reValidateMode: 'onSubmit'
  });

  const onSave = useCallback(
    (data: FormControls) => {
      const newNotifications: PostboxNotification[] = [
        ...postbox.notifications.filter(
          (notification) => notification.escalationLevel !== level
        ),
        ...data.emailAddresses
          .filter((email) => !!email)
          .map((email) => ({
            email,
            escalationLevel: level
          }))
      ];

      if (
        JSON.stringify(newNotifications) !==
        JSON.stringify(postbox.notifications)
      ) {
        updatePostbox(
          postbox.id,
          {
            ...postbox,
            notifications: newNotifications
          },
          true
        );
      }
    },
    [level, postbox, updatePostbox]
  );

  const debouncedSubmit = useDebounceCallback(
    () => submitForm(form.current),
    1000,
    false
  );

  return (
    <FjdCard minHeight="100%">
      <form onSubmit={handleSubmit(onSave)} ref={form}>
        <FjdLoadingOverlay loading={loading}>
          <FjdStack spacing="l">
            <FjdSectionHeader
              align="center"
              heading={
                <FjdHeading
                  level={2}
                  text={t('Eskalationsstufe {{level}}', { level })}
                />
              }
            />

            <FjdStack spacing="xl">
              <FjdFormControl
                inputId={`escalation-level-${level}-duration`}
                label={t('Verzögerung')}
              >
                {getNotificationDurationLabel(
                  Object.values(NotificationDuration)[level],
                  t
                )}
              </FjdFormControl>

              <FjdFormControl
                inputId="email-list"
                label={t('Empfänger')}
                validationMessage={
                  errors.emailAddresses && errors.emailAddresses.length > 0
                    ? errors.emailAddresses?.filter((error) => !!error)[0]
                        ?.message
                    : undefined
                }
              >
                <div>
                  <FjdStack spacing="s">
                    <Controller
                      name="emailAddresses"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdListInput
                            defaultValue={field.value}
                            id="email-list"
                            label={t('Empfänger')}
                            labels={{
                              addItemButton: t('Empfänger hinzufügen'),
                              deleteItemButton: t('Empfänger entfernen')
                            }}
                            onChange={(value) => {
                              clearErrors();
                              field.onChange(value);
                              debouncedSubmit();
                            }}
                          />
                        );
                      }}
                    />
                  </FjdStack>
                </div>
              </FjdFormControl>
            </FjdStack>
          </FjdStack>
        </FjdLoadingOverlay>
        <input type="submit" hidden />
      </form>
    </FjdCard>
  );
}

export { EscalationLevel as default } from './EscalationLevel';
