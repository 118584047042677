import {
  FjdButton,
  FjdLoadingOverlay,
  FjdModal,
  FjdSpinner,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { useTable } from '../../../hooks/useTable';
import { Case } from '../../../models/Case';
import { PublicServiceTypeModal } from '../../PublicServiceType/PublicServiceTypeModal/PublicServiceTypeModal';
import { CasesTableCaseRows } from './CasesTableCaseRows/CasesTableCaseRows';
import {
  casesTableDefaultColumns,
  CasesTableHeader
} from './CasesTableHeader/CasesTableHeader';

interface CasesTableProps {
  postboxId: string;
}

export function CasesTable({ postboxId }: CasesTableProps) {
  const { t } = useTranslation();

  const [publicServiceTypeDetail, setPublicServiceTypeDetail] =
    useState<string>();

  const table = useTable(
    'poxtbox-cases-table',
    postboxId,
    casesTableDefaultColumns
  );

  const {
    data: cases,
    isValidating,
    pagination
  } = usePaginatedSwr<Case>({
    elementLabel: t('Fälle'),
    key: `/postboxes/${postboxId}/case-results`,
    pageSize: 10,
    sortByOrder: table.sortByOrder,
    sortByProperty: table.sortByProperty
  });

  return (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdStack spacing="s">
          <FjdVirtualizedTable
            disableVirtualization
            overflow={true}
            header={<CasesTableHeader table={table} />}
          >
            <CasesTableCaseRows
              cases={cases}
              onOpenPublicServiceType={setPublicServiceTypeDetail}
              postboxId={postboxId}
              table={table}
            />

            {table.selection.length > 0 && (
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCell>
                  <FjdStack orientation="horizontal" spacing="s">
                    <FjdButton
                      appearance="primary-link"
                      iconLeft="new-tab"
                      label={t('Auswahl auf Schreibtisch öffnen ({{count}})', {
                        count: table.selection.length
                      })}
                      onClick={table.openSelection}
                      size="s"
                    />
                    <FjdButton
                      appearance="primary-link"
                      iconLeft="reset"
                      label={t('Auswahl aufheben')}
                      onClick={table.resetSelection}
                      size="s"
                    />
                  </FjdStack>
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            )}

            {cases && cases.length > 0 && (
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCell>
                  {pagination.PaginationComponent}
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            )}
          </FjdVirtualizedTable>
        </FjdStack>
      </FjdLoadingOverlay>

      {publicServiceTypeDetail && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              heading={t('Lade …')}
              id="public-service-type-details-modal"
              open
            >
              <FjdSpinner />
            </FjdModal>
          }
        >
          <PublicServiceTypeModal
            onClose={() => setPublicServiceTypeDetail(undefined)}
            publicServiceTypeId={publicServiceTypeDetail}
          />
        </Suspense>
      )}
    </>
  );
}
