export function truncate(string?: string, maxLength = 20, middle = true) {
  if (!string) {
    return '';
  }

  const partLength = Math.round(maxLength / 2);

  if (string.length > maxLength) {
    if (middle) {
      return (
        string.substr(0, partLength) +
        '…' +
        string.substr(string.length - partLength, string.length)
      );
    } else {
      return string.substr(0, maxLength) + '…';
    }
  }

  return string;
}

export const defaultDateFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  month: '2-digit',
  year: '2-digit'
};
