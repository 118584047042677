import { FjdMarkdown, FjdModal } from 'fjd-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAlerts from '../../../hooks/useAlerts';
import { useCase } from '../../../hooks/useCase';
import { useElementNavigation } from '../../../hooks/useElementNavigation';
import { useLeadingParty } from '../../../hooks/useLeadingParty';
import {
  Case,
  CreateCaseRequest,
  CreateCaseRequestSchema
} from '../../../models/Case';

interface DuplicateCaseModalProps {
  caseToDuplicate?: Case;
  onClose: () => void;
  postboxId: string;
}

export function DuplicateCaseModal({
  caseToDuplicate,
  onClose,
  postboxId
}: DuplicateCaseModalProps) {
  const [loading, setLoading] = useState(false);
  const { createCase } = useCase(postboxId);
  const { createLeadingParty } = useLeadingParty(postboxId);
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();
  const { alert } = useAlerts();

  const onCreate = async () => {
    const caseCopy: CreateCaseRequest = {
      description: caseToDuplicate?.description,
      publicServiceTypeId: caseToDuplicate!.publicServiceTypeId,
      title: caseToDuplicate?.title + ' ' + t('(Kopie)')
    };

    if (!CreateCaseRequestSchema.validate(caseCopy).error) {
      setLoading(true);

      const newCase = await createCase(caseCopy, true);

      if (newCase !== undefined) {
        await createLeadingParty(newCase.id, { postboxId }, true);

        alert('success', t('Fall erfolgreich dupliziert.'));
        navigateToElement(newCase);
        onClose();
      }

      setLoading(false);
    }
  };

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Fall duplizieren')}
      id="duplicate-case-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={onCreate}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Duplizieren')}
      secondaryButtonLabel={t('Schließen')}
    >
      <FjdMarkdown
        source={t(
          'Möchten Sie diesen Fall wirklich duplizieren? Im Original enthaltene Mitwirkungen und Nachrichten werden dabei nicht dupliziert.'
        )}
      />
    </FjdModal>
  );
}
