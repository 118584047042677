import { TFunction } from 'i18next';

import { MessageType } from '../models/Message';

export function getMessageTypeLabel(
  messageType: MessageType,
  t: TFunction
): string {
  if (!messageType) {
    return '';
  }

  switch (messageType) {
    case MessageType.CASE_RESULT:
      return t('Übermittlung des Verfahrensergebnisses');
    case MessageType.CASE_STATUS:
      return t('Übermittlung des Fallstatus');
    case MessageType.CLOSE:
      return t('Abschließen der Mitwirkung');
    case MessageType.COMMUNICATION:
      return t('Kommunikation während der Mitwirkung');
    case MessageType.DOWNSTREAM:
      return t('Nachgelagerte Kommunikation im Fall');
    case MessageType.META_INFORMATION:
      return t('Metainformationen');
    case MessageType.PARTICIPATION_RESULT:
      return t('Übermittlung des Mitwirkungsergebnisses');
    case MessageType.START:
      return t('Auslösen der Mitwirkung im Fall');
    default:
      return '';
  }
}
