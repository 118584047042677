import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Case, CreateCaseRequest, UpdateCaseRequest } from '../models/Case';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useCase(postboxId: string) {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createCase = useCallback(
    async (data: CreateCaseRequest, silent?: boolean) => {
      setLoading(true);

      try {
        const newCaseResponse = await makeRequestWithFullResponse<Case>(
          `/postboxes/${postboxId}/cases`,
          'POST',
          data
        );

        await matchMutate(new RegExp(`^/postboxes/${postboxId}/cases.*$`));

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/case-results.*$`)
        );

        if (!silent) {
          alert('success', t('Fall erfolgreich erstellt.'));
        }
        setLoading(false);
        return makeRequest<Case>(newCaseResponse.headers.location, 'GET');
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, postboxId, t]
  );

  const deleteCase = useCallback(
    async (caseId: string) => {
      setLoading(true);

      try {
        await makeRequest(`/postboxes/${postboxId}/cases/${caseId}`, 'DELETE');

        await matchMutate(new RegExp(`^/postboxes/${postboxId}/cases.*$`));

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/case-results.*$`)
        );

        alert('success', t('Fall gelöscht.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  const updateCase = useCallback(
    async (caseId: string, data: UpdateCaseRequest, silent?: boolean) => {
      setLoading(true);

      try {
        const updatedCase = await makeRequest<Case>(
          `/postboxes/${postboxId}/cases/${caseId}`,
          'PUT',
          data
        );

        await matchMutate(new RegExp(`^/postboxes/${postboxId}/cases.*$`));

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/case-results.*$`)
        );

        await mutate(`/postboxes/${postboxId}/cases/${caseId}`);

        if (!silent) {
          alert('success', t('Änderungen gespeichert.'));
        }

        setLoading(false);

        return updatedCase;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  return { createCase, deleteCase, loading, updateCase };
}
