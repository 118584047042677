import { format, formatISO } from 'date-fns';
import {
  FjdColumns,
  FjdFilePreview,
  FjdFormControl,
  FjdRichtextEditor,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useMemo } from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CreateDocumentRequest,
  ExternalDocumentReference
} from '../../../../models/Document';
import { CreateMessageRequest } from '../../../../models/Message';

interface DocumentPreviewProps {
  control: Control<CreateMessageRequest>;
  document: CreateDocumentRequest;
  errors: DeepMap<CreateMessageRequest, FieldError>;
  expanded: boolean;
  index: number;
  onChange?: (document: CreateDocumentRequest) => void;
  onDelete?: () => void;
  onToggle?: () => void;
  progress?: number;
}

export function DocumentPreview({
  control,
  document,
  errors,
  expanded,
  index,
  onChange,
  onDelete,
  onToggle,
  progress
}: DocumentPreviewProps) {
  const { t } = useTranslation();

  const documentErrors = useMemo(
    () => errors.docs?.[index],
    [errors.docs, index]
  );

  return (
    <FjdFilePreview
      expanded={expanded}
      icon="document"
      invalid={!!documentErrors}
      onDelete={onDelete}
      onToggle={onToggle}
      progress={progress}
      title={
        document.reference.type === 'EXTERNAL'
          ? (document.reference as ExternalDocumentReference).uri
          : progress !== undefined
          ? `${document.filename} (${progress}%)`
          : document.filename || ''
      }
    >
      <FjdStack spacing="l">
        <FjdFormControl
          inputId={`docs.${index}.title`}
          label={t('Dokumententitel')}
          validationMessage={documentErrors?.title?.message}
        >
          <Controller
            control={control}
            name={`docs.${index}.title`}
            render={({ field }) => {
              return (
                <FjdTextInput
                  autoFocus
                  defaultValue={field.value}
                  id={`docs.${index}.title`}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(event) => {
                    field.onChange(event);

                    if (typeof onChange === 'function') {
                      onChange({
                        ...document,
                        title: event.target.value
                      });
                    }
                  }}
                />
              );
            }}
          />
        </FjdFormControl>

        <FjdColumns>
          <FjdFormControl
            inputId={`docs.${index}.documentDate`}
            label={t('Datum des Dokuments')}
            optional
            validationMessage={documentErrors?.documentDate?.message}
          >
            <Controller
              control={control}
              name={`docs.${index}.documentDate`}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={
                      field.value
                        ? format(new Date(field.value), 'yyyy-MM-dd')
                        : undefined
                    }
                    id={`docs.${index}.documentDate`}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      const value = formatISO(new Date(event.target.value));

                      field.onChange(value);

                      if (typeof onChange === 'function') {
                        onChange({
                          ...document,
                          documentDate: value
                        });
                      }
                    }}
                    type="date"
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId={`docs.${index}.version`}
            label={t('Version des Dokuments')}
            optional
            validationMessage={documentErrors?.version?.message}
          >
            <Controller
              control={control}
              name={`docs.${index}.version`}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id={`docs.${index}.version`}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);

                      if (typeof onChange === 'function') {
                        onChange({
                          ...document,
                          version: event.target.value
                        });
                      }
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdColumns>

        <FjdFormControl
          inputId={`docs.${index}.authorization`}
          label={t('Autorisierungshinweis')}
          optional
          validationMessage={documentErrors?.authorization?.message}
        >
          <Controller
            control={control}
            name={`docs.${index}.authorization`}
            render={({ field }) => {
              return (
                <FjdTextInput
                  defaultValue={field.value}
                  id={`docs.${index}.authorization`}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(event) => {
                    field.onChange(event);

                    if (typeof onChange === 'function') {
                      onChange({
                        ...document,
                        authorization: event.target.value
                      });
                    }
                  }}
                />
              );
            }}
          />
        </FjdFormControl>

        <FjdFormControl
          inputId={`docs.${index}.note`}
          label={t('Bemerkungen zum Dokument')}
          optional
          validationMessage={documentErrors?.note?.message}
        >
          <Controller
            control={control}
            name={`docs.${index}.note`}
            render={({ field }) => {
              return (
                <FjdRichtextEditor
                  id={`docs.${index}.note`}
                  initialValue={field.value}
                  name={field.name}
                  onChange={(value) => {
                    field.onChange(value);

                    if (typeof onChange === 'function') {
                      onChange({
                        ...document,
                        note: value
                      });
                    }
                  }}
                  output="html"
                  rows={3}
                />
              );
            }}
          />
        </FjdFormControl>
      </FjdStack>
    </FjdFilePreview>
  );
}
