import {
  FjdButton,
  FjdFlexSpacer,
  FjdLoadingOverlay,
  FjdStack,
  FjdTooltip,
  FjdTruncatedText,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { useProtocol } from '../../../../hooks/useProtocol';
import { Case } from '../../../../models/Case';

interface CaseSelectionTableProps {
  onSelect: (caseData: Case) => void;
  postboxId: string;
}

export function CaseSelectionTable({
  onSelect,
  postboxId
}: CaseSelectionTableProps) {
  const { t } = useTranslation();

  const {
    data: cases,
    isValidating,
    pagination
  } = usePaginatedSwr<Case>({
    elementLabel: t('Fälle'),
    key: `/postboxes/${postboxId}/case-results`,
    pageSize: 10
  });

  const { downloadProtocol, loading } = useProtocol();

  return cases ? (
    <FjdLoadingOverlay loading={loading || isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol>
                {t('Name des Falls')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('ID')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>
                {t('Beschreibung')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="6.5rem"></FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {cases.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Fälle gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {cases.map((caseData, index) => (
            <FjdVirtualizedTableRow even={index % 2 === 1} key={caseData.id}>
              <FjdVirtualizedTableCell>
                <FjdTruncatedText text={caseData.title} />
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                <FjdTruncatedText text={caseData.id} />
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                <FjdTruncatedText
                  allowHTML
                  text={caseData.description || '-'}
                />
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell maxWidth="6.5rem">
                <FjdStack orientation="horizontal" spacing="s">
                  <FjdFlexSpacer />
                  <FjdTooltip
                    focusable={false}
                    placement="top"
                    tooltip={t('Protokolleinträge abrufen')}
                  >
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      iconLeft="view"
                      label={t('Protokolleinträge abrufen')}
                      onClick={() => onSelect(caseData)}
                      size="s"
                    />
                  </FjdTooltip>
                  <FjdTooltip
                    focusable={false}
                    placement="top"
                    tooltip={t('Protokolleinträge herunterladen')}
                  >
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      iconLeft="download"
                      label={t('Protokolleinträge herunterladen')}
                      onClick={() => downloadProtocol(caseData)}
                      size="s"
                    />
                  </FjdTooltip>
                </FjdStack>
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          ))}
        </FjdVirtualizedTable>

        {pagination.PaginationComponent}
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
