import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import {
  CreateDocRequestSchema,
  CreateDocumentRequest,
  Document
} from './Document';
import { LogEntry } from './LogEntry';
import { Privileges } from './Privileges';

export interface Message {
  caseId: string;
  docs?: Document[];
  fromLeader: boolean;
  id: string;
  logs?: LogEntry[];
  note?: string;
  participationId: string;
  postboxId: string;
  privileges?: Privileges[];
  received?: boolean;
  receivedOn?: string;
  sentOn: string;
  subject: string;
  text?: string;
  type: MessageType;
}

export enum MessageType {
  CASE_RESULT = 'CASE_RESULT',
  CASE_STATUS = 'CASE_STATUS',
  CLOSE = 'CLOSE',
  COMMUNICATION = 'COMMUNICATION',
  DOWNSTREAM = 'DOWNSTREAM',
  META_INFORMATION = 'META_INFORMATION',
  PARTICIPATION_RESULT = 'PARTICIPATION_RESULT',
  START = 'START'
}

export type CreateMessageRequest = Except<
  Message,
  | 'caseId'
  | 'docs'
  | 'id'
  | 'participationId'
  | 'postboxId'
  | 'privileges'
  | 'receivedOn'
> & { docs: CreateDocumentRequest[] };

export const CreateMessageRequestSchema: Schema = Joi.object({
  docs: Joi.array().items(CreateDocRequestSchema),
  fromLeader: Joi.boolean().required(),
  note: Joi.string().optional().allow(''),
  sentOn: Joi.string().required(),
  subject: Joi.string().required(),
  text: Joi.string().optional().allow(''),
  type: Joi.string()
    .valid(...Object.values(MessageType))
    .required()
});

export function instanceOfMessage(object?: any): object is Message {
  if (!object || typeof object !== 'object') {
    return false;
  }

  return 'fromLeader' in object && 'sentOn' in object;
}
