import { format, formatISO } from 'date-fns';
import {
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdModal,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import useAlerts from '../../../hooks/useAlerts';
import { useLeadingParty } from '../../../hooks/useLeadingParty';
import { useParticipation } from '../../../hooks/useParticipation';
import {
  InvolvedParty,
  UpdateInvolvedPartyRequestSchema
} from '../../../models/InvolvedParty';
import {
  LeadingParty,
  UpdateLeadingPartyRequest
} from '../../../models/LeadingParty';
import {
  Participation,
  UpdateParticipationRequest,
  UpdateParticipationRequestSchema
} from '../../../models/Participation';
import { submitForm } from '../../../utils/form';
import { UpdateParticipationFormStep1 } from './UpdateParticipationFormStep1/UpdateParticipationFormStep1';
import { UpdateParticipationFormStep2 } from './UpdateParticipationFormStep2/UpdateParticipationFormStep2';

interface UpdateParticipationModalProps {
  caseId: string;
  onClose: () => void;
  participationId: string;
  postboxId: string;
}

type FormData = {
  leadingParty: Partial<UpdateLeadingPartyRequest>;
  participation: Partial<UpdateParticipationRequest>;
};

export function UpdateParticipationModal({
  caseId,
  onClose,
  participationId,
  postboxId
}: UpdateParticipationModalProps) {
  const { alert } = useAlerts();
  const { t } = useTranslation();

  const [activeForm, setActiveForm] = useState<HTMLFormElement>();

  const { data: participation } = useSWR<Participation>(
    `/postboxes/${postboxId}/participations/${participationId}`
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${postboxId}/participations/${participationId}/involved-party`
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${postboxId}/cases/${caseId}/leading-party`
  );

  const [formData, setFormData] = useState<FormData>({
    leadingParty: {
      contactPersonId: leadingParty?.contactPersonId,
      fileReference: leadingParty?.fileReference,
      postboxId: leadingParty?.postboxId,
      processReference: leadingParty?.processReference,
      responsibleOrganisationalUnit: leadingParty?.responsibleOrganisationalUnit
    },
    participation: {
      caseId: participation?.caseId,
      deadline: participation?.deadline
        ? format(new Date(participation.deadline), 'yyyy-MM-dd')
        : undefined,
      description: participation?.description,
      legalBasis: participation?.legalBasis,
      obligation: participation?.obligation,
      task: participation?.task,
      title: participation?.title
    }
  });

  const [step, setStep] = useState<number>(0);

  const { updateParticipation } = useParticipation(postboxId);
  const { updateLeadingParty } = useLeadingParty(postboxId);

  const [loading, setLoading] = useState(false);

  const primaryButtonLabel = useMemo(() => {
    return step === 0 ? t('Weiter') : t('Änderungen speichern');
  }, [step, t]);

  const secondaryButtonLabel = useMemo(() => {
    return step === 0 ? t('Schließen') : t('Zurück');
  }, [step, t]);

  const onUpdate = useCallback(async () => {
    if (
      !UpdateParticipationRequestSchema.validate(formData.participation)
        .error &&
      !UpdateInvolvedPartyRequestSchema.validate(formData.leadingParty).error
    ) {
      setLoading(true);

      const updatedParticipation = await updateParticipation(
        participationId,
        {
          ...(formData.participation as UpdateParticipationRequest),
          deadline: formData.participation.deadline
            ? formatISO(new Date(formData.participation.deadline as string))
            : undefined
        },
        true
      );

      if (updatedParticipation !== undefined) {
        await updateLeadingParty(
          caseId,
          formData.leadingParty as UpdateLeadingPartyRequest,
          true
        );

        setLoading(false);

        alert('success', t('Änderungen gespeichert.'));

        onClose();
      }
    }
  }, [
    alert,
    caseId,
    formData.leadingParty,
    formData.participation,
    onClose,
    participationId,
    t,
    updateLeadingParty,
    updateParticipation
  ]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Mitwirkung bearbeiten')}
      id="update-participation-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => submitForm(activeForm)}
      onSecondaryButtonClick={() =>
        step === 0 ? onClose() : setStep((step) => step - 1)
      }
      open
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonLabel={secondaryButtonLabel}
    >
      <FjdStack spacing="xl">
        {!loading && step < 3 && (
          <FjdContentTabBar>
            <FjdContentTabBarItem
              active={step === 0}
              label={t('1. Allgemeine Informationen')}
              onClick={() => setStep(0)}
            />
            <FjdContentTabBarItem
              active={step === 1}
              label={t('2. Informationen zur mitwirkenden Stelle')}
              onClick={() => {
                if (step === 0) {
                  submitForm(activeForm);
                } else {
                  setStep(1);
                }
              }}
            />
          </FjdContentTabBar>
        )}

        {step === 0 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <UpdateParticipationFormStep1
              caseId={caseId}
              initialData={formData.participation}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  participation: { ...formData.participation, ...data }
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={() => setStep(1)}
              postboxId={postboxId}
            />
          </Suspense>
        )}

        {step === 1 && involvedParty && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <UpdateParticipationFormStep2
              initialData={formData.leadingParty}
              involvedParty={involvedParty}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  leadingParty: { ...formData.leadingParty, ...data }
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={onUpdate}
              postboxId={postboxId}
            />
          </Suspense>
        )}
      </FjdStack>
    </FjdModal>
  );
}
