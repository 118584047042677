import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Case, instanceOfCase } from '../models/Case';
import { instanceOfMessage, Message } from '../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../models/Participation';
import {
  getCasePath,
  getMessagePath,
  getParticipationPath
} from '../utils/router';

export function useElementNavigation() {
  const navigate = useNavigate();

  const navigateToElement = useCallback(
    (
      element: Case | Message | Participation,
      currentPostboxId?: string,
      replace?: boolean,
      state?: any
    ) => {
      if (instanceOfCase(element)) {
        navigate(getCasePath(element, currentPostboxId), { replace, state });
      } else if (instanceOfParticipation(element)) {
        navigate(getParticipationPath(element, currentPostboxId), {
          replace,
          state
        });
      } else if (instanceOfMessage(element)) {
        navigate(getMessagePath(element, currentPostboxId), { replace, state });
      }
    },
    [navigate]
  );

  return { navigateToElement };
}
