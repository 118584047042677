import {
  FjdCard,
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdModal,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAlerts from '../../../hooks/useAlerts';
import { useCase } from '../../../hooks/useCase';
import { useLeadingParty } from '../../../hooks/useLeadingParty';
import {
  Case,
  CreateCaseRequest,
  CreateCaseRequestSchema,
  instanceOfCase,
  UpdateCaseRequest,
  UpdateCaseRequestSchema
} from '../../../models/Case';
import {
  CreateLeadingPartyRequest,
  CreateLeadingPartyRequestSchema,
  LeadingParty,
  UpdateLeadingPartyRequest,
  UpdateLeadingPartyRequestSchema
} from '../../../models/LeadingParty';
import { submitForm } from '../../../utils/form';
import { UpsertCaseFormStep1 } from './UpsertCaseFormStep1/UpsertCaseFormStep1';
import { UpsertCaseFormStep2 } from './UpsertCaseFormStep2/UpsertCaseFormStep2';
import { UpsertCaseFormStep3 } from './UpsertCaseFormStep3/UpsertCaseFormStep3';

interface UpsertCaseModalProps {
  caseToUpdate?: Case;
  defaultPublicServiceTypeId?: string;
  initialStep?: 0 | 1 | 2;
  leadingPartyToUpdate?: LeadingParty;
  onClose: () => void;
  postboxId: string;
}

export interface UpserCaseFormData {
  case: Partial<CreateCaseRequest | UpdateCaseRequest>;
  leadingParty: Partial<CreateLeadingPartyRequest | UpdateLeadingPartyRequest>;
}

export function UpsertCaseModal({
  caseToUpdate,
  defaultPublicServiceTypeId,
  initialStep,
  leadingPartyToUpdate,
  onClose,
  postboxId
}: UpsertCaseModalProps) {
  const { alert } = useAlerts();
  const { t } = useTranslation();

  const updateMode = useMemo(
    () => instanceOfCase(caseToUpdate),
    [caseToUpdate]
  );

  const [activeForm, setActiveForm] = useState<HTMLFormElement>();
  const [createdCase, setCreatedCase] = useState<Case>();

  const [formData, setFormData] = useState<UpserCaseFormData>(
    updateMode
      ? {
          case: {
            description: caseToUpdate?.description,
            publicServiceTypeId: caseToUpdate?.publicServiceTypeId,
            title: caseToUpdate?.title
          },
          leadingParty: {
            contactPersonId: leadingPartyToUpdate?.contactPersonId,
            fileReference: leadingPartyToUpdate?.fileReference,
            postboxId: leadingPartyToUpdate?.postboxId,
            processReference: leadingPartyToUpdate?.processReference,
            responsibleOrganisationalUnit:
              leadingPartyToUpdate?.responsibleOrganisationalUnit
          }
        }
      : {
          case: {
            publicServiceTypeId: defaultPublicServiceTypeId
          },
          leadingParty: {
            postboxId
          }
        }
  );

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<0 | 1 | 2>(
    updateMode ? 1 : initialStep !== undefined ? initialStep : 0
  );

  const { createCase, updateCase } = useCase(postboxId);
  const { createLeadingParty, updateLeadingParty } = useLeadingParty(postboxId);

  const heading = useMemo(() => {
    if (updateMode) {
      return t('Fallinformationen bearbeiten');
    }

    return step === 2
      ? t('Fall erfolgreich angelegt')
      : t('Neuen Fall anlegen');
  }, [step, t, updateMode]);

  const primaryButtonLabel = useMemo(() => {
    if (updateMode) {
      return step === 0 ? t('Zu den Falldetails') : t('Änderungen speichern');
    }

    return step === 0
      ? t('Weiter')
      : step === 1
      ? t('Fall erstellen')
      : undefined;
  }, [step, t, updateMode]);

  const secondaryButtonLabel = useMemo(() => {
    if (updateMode) {
      return t('Schließen');
    }

    return step === 0 ? t('Schließen') : step === 1 ? t('Zurück') : undefined;
  }, [step, t, updateMode]);

  const onCreate = useCallback(async () => {
    if (
      !CreateCaseRequestSchema.validate(formData.case).error &&
      !CreateLeadingPartyRequestSchema.validate(formData.leadingParty).error
    ) {
      setLoading(true);

      const newCase = await createCase(
        formData.case as CreateCaseRequest,
        true
      );

      if (newCase !== undefined) {
        await createLeadingParty(
          newCase.id,
          formData.leadingParty as CreateLeadingPartyRequest,
          true
        );

        setCreatedCase(newCase);
        setStep(2);
        setLoading(false);
      }
    }
  }, [createCase, createLeadingParty, formData]);

  const onUpdate = useCallback(async () => {
    if (
      caseToUpdate &&
      leadingPartyToUpdate &&
      !UpdateCaseRequestSchema.validate(formData.case).error &&
      !UpdateLeadingPartyRequestSchema.validate(formData.leadingParty).error
    ) {
      setLoading(true);

      const updatedCase = await updateCase(
        caseToUpdate.id,
        formData.case as UpdateCaseRequest,
        true
      );

      if (updatedCase !== undefined) {
        await updateLeadingParty(
          updatedCase.id,
          formData.leadingParty as UpdateLeadingPartyRequest,
          true
        );

        setLoading(false);

        alert('success', t('Änderungen gespeichert.'));

        onClose();
      }
    }
  }, [
    alert,
    caseToUpdate,
    formData.case,
    formData.leadingParty,
    leadingPartyToUpdate,
    onClose,
    t,
    updateCase,
    updateLeadingParty
  ]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={heading}
      id="upsert-case-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => submitForm(activeForm)}
      onSecondaryButtonClick={() =>
        !updateMode ? (step === 0 ? onClose() : setStep(0)) : onClose()
      }
      open
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonLabel={secondaryButtonLabel}
    >
      <FjdStack spacing="xl">
        {!updateMode && step < 2 && (
          <FjdContentTabBar>
            <FjdContentTabBarItem
              active={step === 0}
              label={t('1. Verwaltungsleistung auswählen')}
              onClick={() => setStep(0)}
            />
            <FjdContentTabBarItem
              active={step === 1}
              label={t('2. Fallinformationen ergänzen')}
              onClick={() => {
                if (step === 0) {
                  submitForm(activeForm);
                }
              }}
            />
          </FjdContentTabBar>
        )}

        {step === 0 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <FjdCard>
              <UpsertCaseFormStep1
                initialData={formData.case}
                onChange={(data) =>
                  setFormData((formData) => ({
                    ...formData,
                    case: { ...formData.case, ...data }
                  }))
                }
                onInit={(form) => setActiveForm(form)}
                onNext={() => setStep(1)}
                postboxId={postboxId}
              />
            </FjdCard>
          </Suspense>
        )}

        {step === 1 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <FjdCard>
              <UpsertCaseFormStep2
                initialData={formData}
                onBack={() => setStep(0)}
                onChange={(data) =>
                  setFormData((formData) => ({
                    ...formData,
                    case: { ...formData.case, ...data.case },
                    leadingParty: {
                      ...formData.leadingParty,
                      ...data.leadingParty
                    }
                  }))
                }
                onInit={(form) => setActiveForm(form)}
                onNext={updateMode ? onUpdate : onCreate}
                postboxId={postboxId}
              />
            </FjdCard>
          </Suspense>
        )}

        {step === 2 && createdCase && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <UpsertCaseFormStep3 case={createdCase} onClose={onClose} />
          </Suspense>
        )}
      </FjdStack>
    </FjdModal>
  );
}
