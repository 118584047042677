import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InvolvedParty } from '../../../models/InvolvedParty';
import { PersonType } from '../../../models/Person';
import { Postbox } from '../../../models/Postbox';
import { getPostboxOwnerName } from '../../../utils/postbox';

interface InvolvedPartyDetailsProps {
  participationId: string;
  postboxId: string;
}

export function InvolvedPartyDetails({
  participationId,
  postboxId
}: InvolvedPartyDetailsProps) {
  const { t } = useTranslation();

  const { data: involvedParty, isValidating: involvedPartyValidating } =
    useSWR<InvolvedParty>(
      `/postboxes/${postboxId}/participations/${participationId}/involved-party`
    );

  const {
    data: involvedPartyPostbox,
    isValidating: involvedPartyPostboxValidating
  } = useSWR<Postbox>(
    involvedParty ? `/postboxes/${involvedParty.postboxId}` : null,
    { suspense: false }
  );

  const isValidating =
    involvedPartyValidating || involvedPartyPostboxValidating;

  return involvedParty ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdDescriptionList>
        {involvedPartyPostbox &&
          involvedPartyPostbox.owner?.type === PersonType.NATURAL_PERSON && (
            <FjdDescriptionListItem
              description={getPostboxOwnerName(involvedPartyPostbox.owner)}
              term={t('Name')}
            />
          )}
        {involvedPartyPostbox &&
          involvedPartyPostbox.owner?.type !== PersonType.NATURAL_PERSON && (
            <FjdDescriptionListItem
              description={getPostboxOwnerName(involvedPartyPostbox.owner)}
              term={t('Organisation')}
            />
          )}

        {involvedPartyPostbox &&
          involvedPartyPostbox.owner?.type ===
            PersonType.PUBLIC_ORGANISATION && (
            <FjdDescriptionListItem
              description={involvedPartyPostbox.owner.typeOfPublicOrganization}
              term={t('Art der öffentlichen Stelle')}
            />
          )}
        {involvedParty.responsibleOrganisationalUnit && (
          <FjdDescriptionListItem
            description={involvedParty.responsibleOrganisationalUnit}
            term={t('Organisationseinheit Postfachinhaber')}
          />
        )}
        {involvedParty.processReference && (
          <FjdDescriptionListItem
            description={involvedParty.processReference}
            term={t('Geschäftszeichen')}
          />
        )}
        {involvedParty.fileReference && (
          <FjdDescriptionListItem
            description={involvedParty.fileReference}
            term={t('Aktenzeichen')}
          />
        )}
      </FjdDescriptionList>
    </FjdLoadingOverlay>
  ) : null;
}
