import { saveAs } from 'file-saver';
import {
  FjdButton,
  FjdFlexSpacer,
  FjdLoadingOverlay,
  FjdMarkdown,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { FjdSelectOption } from 'fjd-react-components/build/components/Select/Select';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useDebounceCallback } from '@react-hook/debounce';

import { useApi } from '../../../hooks/useApi';
import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { useUserRoles } from '../../../hooks/useUserRoles';
import { Postbox } from '../../../models/Postbox';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { PublicServiceTypesTable } from './PublicServiceTypesTable/PublicServiceTypesTable';
import { RegisterPublicServiceTypeModal } from './RegisterPublicServiceTypeModal/RegisterPublicServiceTypeModal';
import Config from '../../../config.json';

export function PublicServiceTypes() {
  const { makeRequest } = useApi();
  const { isApplicationAdmin } = useUserRoles();
  const { t } = useTranslation();
  const contactEmailUrl =
    process.env.REACT_APP_CONTACT_EMAIL || Config.REACT_APP_CONTACT_EMAIL;

  const [downloading, setDownloading] = useState(false);
  const [registering, setRegisterung] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>();
  const [selectedPostbox, setSelectedPostbox] = useState<Postbox | null>();

  const debouncedSetSearchTerm = useDebounceCallback(
    setSearchTerm,
    1000,
    false
  );

  const { data: postboxes } = usePaginatedSwr<Postbox>({
    elementLabel: t('Postfächer'),
    key: isApplicationAdmin ? '/postbox-results' : '/users/postboxes',
    pageSize: 9999
  });

  const postboxOptions = useMemo<FjdSelectOption[]>(
    () => [
      { text: `- ${t('Alle Verwaltungsleistungen')} -`, value: '' },
      ...postboxes.map((postbox) => ({ text: postbox.name, value: postbox.id }))
    ],
    [postboxes, t]
  );

  const createClarificationRequest = useCallback(() => {
    window.open(
      `mailto:${contactEmailUrl}?subject=Klärungsauftrag%20zu%20Verwaltungsleistungen`,
      '_blank'
    );
  }, [contactEmailUrl]);

  const downloadList = useCallback(async () => {
    setDownloading(true);

    const path = selectedPostbox
      ? `/public-service-type-results-download?page=0&size=99999&postboxId=${selectedPostbox.id}`
      : `/public-service-type-results-download?page=0&size=99999`;

    try {
      const res = await makeRequest<Blob>(path, 'GET', undefined, {
        responseType: 'blob'
      });

      saveAs(res, 'Verwaltungsleistungen.xlsx');
      setDownloading(false);
    } catch (e) {
      setDownloading(false);
    }
  }, [makeRequest, selectedPostbox]);

  useEffect(() => {
    if (postboxes && postboxes.length > 0 && selectedPostbox === undefined) {
      setSelectedPostbox(postboxes[0]);
    }
  }, [postboxes, selectedPostbox]);

  return (
    <>
      <Helmet>
        <title>
          {t('Verwaltungsleistungen')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense
        fallback={<LoadingSpinner label={t('Lade Verwaltungsleistungen …')} />}
      >
        <FjdLoadingOverlay loading={downloading}>
          <FjdStack spacing="5xl">
            <FjdStack spacing="xl">
              <FjdStack orientation="horizontal" spacing="s">
                <FjdSelect
                  ariaLabel={t('Postfachauswahl')}
                  disabled={postboxOptions.length <= 1}
                  id="postbox"
                  minWidth="18rem"
                  name="postbox"
                  onChange={(postboxId) =>
                    postboxId !== ''
                      ? setSelectedPostbox(
                          postboxes.find((postbox) => postbox.id === postboxId)
                        )
                      : setSelectedPostbox(null)
                  }
                  options={postboxOptions}
                  size="s"
                  value={selectedPostbox?.id || ''}
                />

                <FjdTextInput
                  id="public-service-type-search"
                  onChange={(event) =>
                    debouncedSetSearchTerm(event.target.value)
                  }
                  placeholder="Suche …"
                  size="s"
                  type="search"
                />

                <FjdFlexSpacer />

                {selectedPostbox && (
                  <FjdButton
                    appearance="primary-link"
                    iconLeft="link"
                    label={t('Neue Verwaltungsleistung registrieren')}
                    onClick={() => setRegisterung(true)}
                    size="s"
                  />
                )}

                <FjdButton
                  appearance="primary-link"
                  iconLeft="document-download"
                  label={t('Liste herunterladen')}
                  onClick={downloadList}
                  size="s"
                />

                <FjdButton
                  appearance="primary-link"
                  iconLeft="help-outline"
                  label={t('Klärungsauftrag')}
                  onClick={createClarificationRequest}
                  size="s"
                />
              </FjdStack>

              <PublicServiceTypesTable
                pageSize={20}
                postboxId={selectedPostbox?.id}
                searchTerm={searchTerm}
              />
            </FjdStack>

            <FjdMarkdown
              source={t(
                `Eine Verwaltungsleistung ist nicht oder inkorrekt verzeichnet? [Schreiben Sie uns.](mailto:{{contactEmail}})`,
                {
                  contactEmail: `${contactEmailUrl}?subject=Frage%20zu%20Verwaltungsleistungen`
                }
              )}
            />
          </FjdStack>
        </FjdLoadingOverlay>
      </Suspense>

      {registering && selectedPostbox && (
        <RegisterPublicServiceTypeModal
          onClose={() => setRegisterung(false)}
          onRegister={() => setRegisterung(false)}
          postbox={selectedPostbox}
        />
      )}
    </>
  );
}

export { PublicServiceTypes as default } from './PublicServiceTypes';
