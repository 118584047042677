import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreatePublicServiceTypeLeadRequest } from '../models/PublicServiceTypeLead';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function usePublicServiceTypeLead() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createPublicServiceTypeLead = useCallback(
    async (
      postboxIdOrIds: string | string[],
      data: CreatePublicServiceTypeLeadRequest
    ) => {
      setLoading(true);

      try {
        if (postboxIdOrIds instanceof Array) {
          if (postboxIdOrIds.length === 0) {
            return;
          }

          await Promise.all(
            postboxIdOrIds.map((postboxId) =>
              makeRequest(
                `/postboxes/${postboxId}/public-service-type-leads`,
                'POST',
                data
              )
            )
          );

          await Promise.all(
            postboxIdOrIds.map((postboxId) =>
              mutate(`/postboxes/${postboxId}/public-service-type-leads`)
            )
          );
        } else {
          await makeRequest(
            `/postboxes/${postboxIdOrIds}/public-service-type-leads`,
            'POST',
            data
          );

          await mutate(
            `/postboxes/${postboxIdOrIds}/public-service-type-leads`
          );
        }

        await matchMutate(new RegExp(`^/public-service-type-results.*$`));

        alert('success', t('Verwaltungsleistung erfolgreich registriert.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, t]
  );

  const deletePublicServiceTypeLead = useCallback(
    async (postboxIdOrIds: string | string[], publicServiceTypeId: string) => {
      setLoading(true);

      try {
        if (postboxIdOrIds instanceof Array) {
          if (postboxIdOrIds.length === 0) {
            return;
          }

          await Promise.all(
            postboxIdOrIds.map((postboxId) =>
              makeRequest(
                `/postboxes/${postboxId}/public-service-type-leads/${publicServiceTypeId}`,
                'DELETE'
              )
            )
          );

          await Promise.all(
            postboxIdOrIds.map((postboxId) =>
              mutate(`/postboxes/${postboxId}/public-service-type-leads`)
            )
          );
        } else {
          await makeRequest(
            `/postboxes/${postboxIdOrIds}/public-service-type-leads/${publicServiceTypeId}`,
            'DELETE'
          );

          await mutate(
            `/postboxes/${postboxIdOrIds}/public-service-type-leads`
          );
        }

        await matchMutate(new RegExp(`^/public-service-type-results.*$`));

        alert(
          'success',
          t('Registrierung der Verwaltungsleistung aufgehoben.')
        );
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, t]
  );

  return { createPublicServiceTypeLead, deletePublicServiceTypeLead, loading };
}
