import { FjdIconGlyph } from 'fjd-react-components/build/components/Icon/Icon';
import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStorageState } from 'react-storage-hooks';
import { atom, useRecoilState } from 'recoil';

import { Case, instanceOfCase } from '../models/Case';
import { instanceOfMessage, Message } from '../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../models/Participation';
import useElementByLocation from './useElementByLocation';
import { useElementNavigation } from './useElementNavigation';

export const tabsAtom = atom<TabsState>({
  default: {},
  key: 'tabs'
});

export const tabsStateLocalStorageKey = 'pdbk-tabs-state';

export interface TabsState {
  [postboxId: string]: {
    tabs: FjdTabBarTab[];
  };
}

function useTabs(postboxId: string) {
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();
  const locationElement = useElementByLocation();
  const navigate = useNavigate();

  const [, setStorageTabsState] = useStorageState<TabsState>(
    localStorage,
    tabsStateLocalStorageKey
  );

  const [tabsState, setTabsState] = useRecoilState<TabsState>(tabsAtom);

  const activeTab = useMemo(
    () => tabsState[postboxId]?.tabs.find((tab) => tab.active),
    [postboxId, tabsState]
  );

  const empty = useMemo(
    () =>
      !tabsState[postboxId]?.tabs ||
      tabsState[postboxId]?.tabs.length === 0 ||
      (tabsState[postboxId]?.tabs.length === 1 &&
        tabsState[postboxId]?.tabs[0].id === 'empty'),
    [postboxId, tabsState]
  );

  const closeAllTabs = useCallback(() => {
    setTabsState((tabsState) => ({ ...tabsState, [postboxId]: { tabs: [] } }));

    if (locationElement) {
      navigate(`/postboxes/${postboxId}`);
    }
  }, [locationElement, navigate, postboxId, setTabsState]);

  const closeInactiveTabs = useCallback(() => {
    setTabsState((tabsState) => ({
      ...tabsState,
      [postboxId]: {
        tabs: tabsState[postboxId].tabs.filter((tab) => tab.active)
      }
    }));
  }, [postboxId, setTabsState]);

  const closeTab = useCallback(
    (id: string) => {
      const closingTabIsActive = activeTab?.id === id;

      const updatedTabsState = {
        ...tabsState,
        [postboxId]: {
          tabs: tabsState[postboxId].tabs.filter((tab) => tab.id !== id)
        }
      };

      setTabsState(updatedTabsState);

      if (closingTabIsActive) {
        const lastTab =
          updatedTabsState[postboxId].tabs[
            updatedTabsState[postboxId].tabs.length - 1
          ];

        if (lastTab && lastTab.data?.element) {
          const element = lastTab.data.element;
          navigateToElement(element, postboxId);
        } else {
          navigate(`/postboxes/${postboxId}`);
        }
      }
    },
    [activeTab, navigate, navigateToElement, postboxId, setTabsState, tabsState]
  );

  const openTab = useCallback(
    (element: Case | Message | Participation, pin?: boolean) => {
      if (tabsState[postboxId]?.tabs.some((tab) => tab.id === element.id)) {
        setTabsState((tabsState) => ({
          ...tabsState,
          [postboxId]: {
            tabs: tabsState[postboxId].tabs
              .map((tab) =>
                tab.id === element.id
                  ? {
                      ...tab,
                      active: true,
                      pinned: pin !== undefined ? pin : tab.pinned
                    }
                  : {
                      ...tab,
                      active: false,
                      pinned: pin !== undefined ? pin : tab.pinned
                    }
              )
              .filter((tab) => tab.active || tab.pinned)
          }
        }));
      } else {
        const icon: FjdIconGlyph = instanceOfCase(element)
          ? 'box'
          : instanceOfParticipation(element)
          ? 'folder'
          : 'email';

        const type: 'case' | 'message' | 'participation' = instanceOfCase(
          element
        )
          ? 'case'
          : instanceOfParticipation(element)
          ? 'participation'
          : 'message';

        const tab: FjdTabBarTab = {
          active: true,
          closable: true,
          data: { element, type },
          id: element.id,
          label: instanceOfMessage(element)
            ? element.subject || element.id
            : element.title,
          icon,
          pinnable: true,
          pinned: pin !== undefined ? pin : undefined
        };

        setTabsState((tabsState) => ({
          ...tabsState,
          [postboxId]: {
            tabs: [
              ...(tabsState[postboxId]?.tabs || [])
                .filter(
                  (tab) =>
                    tab.id !== element.id && tab.id !== 'empty' && tab.pinned
                )
                .map((tab) => ({ ...tab, active: false })),
              tab
            ]
          }
        }));
      }
    },
    [postboxId, setTabsState, tabsState]
  );

  const toggleTabPin = useCallback(
    (elementId: string) => {
      setTabsState((tabsState) => ({
        ...tabsState,
        [postboxId]: {
          tabs: [
            ...tabsState[postboxId].tabs
              .map((tab) =>
                tab.id === elementId ? { ...tab, pinned: !tab.pinned } : tab
              )
              .filter((tab) => tab.pinned || tab.active)
          ]
        }
      }));
    },
    [postboxId, setTabsState]
  );

  const setTabs = useCallback(
    (tabs: FjdTabBarTab[]) => {
      setTabsState((tabsState) => ({ ...tabsState, [postboxId]: { tabs } }));
    },
    [postboxId, setTabsState]
  );

  useEffect(() => {
    if (tabsState[postboxId]?.tabs.length === 0) {
      setTabsState((tabsState) => ({
        ...tabsState,
        [postboxId]: {
          tabs: [
            {
              active: true,
              icon: 'help-outline',
              id: 'empty',
              label: t("Los geht's")
            }
          ]
        }
      }));
    }
  }, [postboxId, setTabsState, t, tabsState]);

  useEffect(() => {
    if (locationElement) {
      if (
        empty ||
        !activeTab ||
        (activeTab && activeTab.id !== locationElement.id)
      ) {
        openTab(locationElement);
      }
    }
  }, [activeTab, empty, locationElement, openTab]);

  useEffect(() => {
    setStorageTabsState(tabsState);
  }, [setStorageTabsState, tabsState]);

  useEffect(() => {
    if (postboxId && !tabsState[postboxId]) {
      setTabsState((tabsState) => ({
        ...tabsState,
        [postboxId]: { tabs: [] }
      }));
    }
  }, [postboxId, setTabsState, tabsState]);

  return {
    activeTab,
    closeAllTabs,
    closeInactiveTabs,
    closeTab,
    empty,
    openTab,
    setTabs,
    tabs: (tabsState[postboxId]?.tabs || []).map((t) => ({ ...t })),
    toggleTabPin
  };
}

export default useTabs;
