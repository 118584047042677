import { Case, instanceOfCase } from '../models/Case';
import {
  instanceOfParticipation,
  Participation
} from '../models/Participation';
import { Privileges } from '../models/Privileges';
import { useUserRoles } from './useUserRoles';

enum CaseOperation {
  CLOSE_CASE = 'CLOSE_CASE',
  CREATE_DATAROOM = 'CREATE_DATAROOM',
  CREATE_PARTICIPATION = 'CREATE_PARTICIPATION',
  DUPLICATE_CASE = 'DUPLICATE_CASE',
  EDIT_CASE_DETAILS = 'EDIT_CASE_DETAILS'
}

enum ParticipationOperation {
  CLOSE_PARTICIPATION = 'CLOSE_PARTICIPATION',
  CREATE_MESSAGE = 'CREATE_MESSAGE',
  DUPLICATE_PARTICIPATION = 'DUPLICATE_PARTICIPATION',
  EDIT_PARTICIPATION_DETAILS = 'EDIT_PARTICIPATION_DETAILS',
  EDIT_PARTICIPATION_VB_DETAILS = 'EDIT_PARTICIPATION_VB_DETAILS'
}

export function useElementOperations(element?: Case | Participation): {
  [operation in CaseOperation | ParticipationOperation]?: boolean;
} {
  const { isApplicationAdmin } = useUserRoles();

  if (!element) {
    return {};
  }

  if (instanceOfCase(element)) {
    return {
      [CaseOperation.CLOSE_CASE]:
        isApplicationAdmin || element.privileges?.includes(Privileges.ADMIN),
      [CaseOperation.CREATE_DATAROOM]:
        isApplicationAdmin || element.privileges?.includes(Privileges.ADMIN),
      [CaseOperation.CREATE_PARTICIPATION]:
        isApplicationAdmin || element.privileges?.includes(Privileges.ADMIN),
      [CaseOperation.DUPLICATE_CASE]:
        isApplicationAdmin || element.privileges?.includes(Privileges.ADMIN),
      [CaseOperation.EDIT_CASE_DETAILS]:
        isApplicationAdmin || element.privileges?.includes(Privileges.ADMIN)
    };
  } else if (instanceOfParticipation(element)) {
    return {
      [ParticipationOperation.CLOSE_PARTICIPATION]:
        isApplicationAdmin ||
        element.privileges?.includes(Privileges.ADMIN) ||
        element.privileges?.includes(Privileges.WRITE),
      [ParticipationOperation.CREATE_MESSAGE]:
        isApplicationAdmin ||
        element.privileges?.includes(Privileges.ADMIN) ||
        element.privileges?.includes(Privileges.WRITE_MESSAGE),
      [ParticipationOperation.DUPLICATE_PARTICIPATION]:
        isApplicationAdmin ||
        element.privileges?.includes(Privileges.ADMIN) ||
        element.privileges?.includes(Privileges.WRITE),
      [ParticipationOperation.EDIT_PARTICIPATION_DETAILS]:
        isApplicationAdmin ||
        element.privileges?.includes(Privileges.ADMIN) ||
        element.privileges?.includes(Privileges.WRITE),
      [ParticipationOperation.EDIT_PARTICIPATION_VB_DETAILS]: true
    };
  }

  return {};
}
