import Joi, { ObjectSchema } from 'joi';

export type PhoneContact = {
  description?: string;
  mobile?: boolean;
  number: string;
};

export const PhoneContactSchema: ObjectSchema = Joi.object({
  _id: Joi.string().optional().allow(''),
  description: Joi.string().optional().allow(''),
  mobile: Joi.boolean().optional(),
  number: Joi.string().max(23).required()
});
