import { FjdMarkdown, FjdModal, FjdStack } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { useCase } from '../../../hooks/useCase';
import useTabs from '../../../hooks/useTabs';
import { Case, CaseStatus } from '../../../models/Case';

interface DeleteCaseModalProps {
  caseToDelete?: Case;
  onClose: () => void;
  postboxId: string;
}

export function DeleteCaseModal({
  caseToDelete,
  onClose,
  postboxId
}: DeleteCaseModalProps) {
  const { loading, updateCase } = useCase(postboxId);
  const { t } = useTranslation();
  const { closeTab } = useTabs(postboxId);

  const onDelete = async () => {
    if (caseToDelete) {
      await updateCase(caseToDelete.id, {
        ...caseToDelete,
        caseStatus: CaseStatus.CLOSED
      });

      closeTab(caseToDelete.id);
      onClose();
    }
  };

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Fall abschließen')}
      id="delete-case-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={onDelete}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Bestätigen und Fall abschließen')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <FjdStack spacing="m">
        <FjdMarkdown
          source={`**${t(
            'Sind sie sich sicher, dass sie den Fall „{{caseTitle}}“ abschließen wollen?',
            { caseTitle: caseToDelete?.title }
          )}**`}
        />

        <FjdMarkdown
          source={t(
            'Der Fall kann danach nicht mehr bearbeitet werden. Nach dem Abschließen können innerhalb dieses Falls keine Mitwirkungen mehr erstellt und Nachrichten versendet werden.'
          )}
        />

        <FjdMarkdown
          source={t(
            'Alle zum Fall gehörigen Dateien und Dokumente werden hierdurch unwiederbringlich gelöscht.'
          )}
        />
      </FjdStack>
    </FjdModal>
  );
}
