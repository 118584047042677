import {
  FjdLoadingOverlay,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { Participation } from '../../../models/Participation';
import { ParticipationsTableRow } from '../ParticipationsTableRow/ParticipationsTableRow';

interface ParticipationsTableProps {
  caseId: string;
  postboxId: string;
}

export function ParticipationsTable({
  caseId,
  postboxId
}: ParticipationsTableProps) {
  const { t } = useTranslation();

  const {
    data: participations,
    isValidating,
    pagination
  } = usePaginatedSwr<Participation>({
    elementLabel: t('Mitwirkungen'),
    filter: [{ key: 'caseId', value: caseId }],
    key: `/postboxes/${postboxId}/participation-results`
  });
  return participations ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>
                {t('Mitwirkender')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Aufgabe')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="8rem">
                {t('Nachrichten')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="10rem">
                {t('Letzte Nachricht')}
              </FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {participations.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Mitwirkungen gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {participations.map((participation, index) => (
            <ParticipationsTableRow
              even={index % 2 === 1}
              key={participation.id}
              participation={participation}
            />
          ))}
        </FjdVirtualizedTable>

        {pagination.PaginationComponent}
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
