import {
  FjdGrid,
  FjdGridItem,
  FjdLoadingOverlay,
  FjdStack
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { useUserRoles } from '../../../hooks/useUserRoles';
import { CreatePostboxRequest, Postbox } from '../../../models/Postbox';
import { PostboxCard } from '../PostboxCard/PostboxCard';
import useSWR from 'swr';
import { User } from '../../../models/User';
import { useCallback, useEffect } from 'react';
import { usePostbox } from '../../../hooks/usePostbox';
import { onCreatePostboxRequest } from '../../../models/Attributes';
import { PersonType } from '../../../models/Person';

interface PostboxesCardsProps {
  ownerType?: PersonType;
}
export function PostboxesCards({ ownerType }: PostboxesCardsProps) {
  const { t } = useTranslation();
  const { isApplicationAdmin, isExtParticipant } = useUserRoles();
  const { createPostboxForServiceOrOrganizationAccount } = usePostbox();
  const {
    data: postboxes,
    isValidating,
    pagination
  } = usePaginatedSwr<Postbox>({
    elementLabel: t('Postfächer'),
    key: isApplicationAdmin ? '/postbox-results/admin' : '/users/postboxes',
    pageSize: 6,
    filter: ownerType ? [{ key: 'ownerType', value: ownerType }] : []
  });
  const { data: user } = useSWR<User>(`/usersInfo`);

  const onCreate = useCallback(
    async (data: CreatePostboxRequest) => {
      await createPostboxForServiceOrOrganizationAccount(data);
    },
    [createPostboxForServiceOrOrganizationAccount]
  );

  const createPostboxForUserWithServiceOrOrgaAccount = () => {
    if (user) {
      const request = onCreatePostboxRequest(user);
      onCreate(request as CreatePostboxRequest);
    }
  };

  useEffect(() => {
    if (
      postboxes === undefined ||
      postboxes === null ||
      postboxes.length === 0
    ) {
      if (isExtParticipant) {
        createPostboxForUserWithServiceOrOrgaAccount();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return postboxes ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        <FjdGrid gap="xl">
          {postboxes.map((postbox) => (
            <FjdGridItem key={postbox.id} span={6}>
              <PostboxCard postboxId={postbox.id} />
            </FjdGridItem>
          ))}
        </FjdGrid>

        {pagination.PaginationComponent}
      </FjdStack>
      {postboxes.length === 0 && <div>{t('Keine Postfächer gefunden.')}</div>}
    </FjdLoadingOverlay>
  ) : null;
}
