import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { InvolvedParty } from '../../../models/InvolvedParty';
import { PersonType } from '../../../models/Person';
import { Postbox } from '../../../models/Postbox';
import { getPostboxOwnerName } from '../../../utils/postbox';

interface LeadingPartyDetailsProps {
  caseId: string;
  postboxId: string;
}

export function LeadingPartyDetails({
  caseId,
  postboxId
}: LeadingPartyDetailsProps) {
  const { t } = useTranslation();

  const { data: leadingParty, isValidating: leadingPartyValidating } =
    useSWR<InvolvedParty>(
      `/postboxes/${postboxId}/cases/${caseId}/leading-party`
    );

  const {
    data: leadingPartyPostbox,
    isValidating: leadingPartyPostboxValidating
  } = useSWR<Postbox>(
    leadingParty ? `/postboxes/${leadingParty.postboxId}` : null,
    { suspense: false }
  );

  const isValidating = leadingPartyValidating || leadingPartyPostboxValidating;

  return leadingParty ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdDescriptionList>
        {leadingPartyPostbox &&
          leadingPartyPostbox.owner?.type === PersonType.NATURAL_PERSON && (
            <FjdDescriptionListItem
              description={getPostboxOwnerName(leadingPartyPostbox.owner)}
              term={t('Name')}
            />
          )}
        {leadingPartyPostbox &&
          leadingPartyPostbox.owner?.type !== PersonType.NATURAL_PERSON && (
            <FjdDescriptionListItem
              description={getPostboxOwnerName(leadingPartyPostbox.owner)}
              term={t('Organisation')}
            />
          )}
        {leadingParty.responsibleOrganisationalUnit && (
          <FjdDescriptionListItem
            description={leadingParty.responsibleOrganisationalUnit}
            term={t('Organisationseinheit')}
          />
        )}

        {leadingPartyPostbox &&
          leadingPartyPostbox.owner?.type ===
            PersonType.PUBLIC_ORGANISATION && (
            <FjdDescriptionListItem
              description={leadingPartyPostbox.owner?.typeOfPublicOrganization}
              term={t('Art der öffentlichen Stelle')}
            />
          )}
      </FjdDescriptionList>
    </FjdLoadingOverlay>
  ) : null;
}
