import { FjdModal, FjdSpinner, FjdStack } from 'fjd-react-components';
import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import useAlerts from '../../../hooks/useAlerts';
import { useInvolvedParty } from '../../../hooks/useInvolvedParty';
import {
  InvolvedParty,
  UpdateInvolvedPartyRequest,
  UpdateInvolvedPartyRequestSchema
} from '../../../models/InvolvedParty';
import { submitForm } from '../../../utils/form';
import { UpdateParticipationVBForm } from './UpdateParticipationVBForm/UpdateParticipationVBForm';

interface UpdateParticipationVBModalProps {
  onClose: () => void;
  participationId: string;
  postboxId: string;
}

type FormData = Partial<UpdateInvolvedPartyRequest>;

export function UpdateParticipationVBModal({
  onClose,
  participationId,
  postboxId
}: UpdateParticipationVBModalProps) {
  const { alert } = useAlerts();
  const { t } = useTranslation();

  const [activeForm, setActiveForm] = useState<HTMLFormElement>();

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${postboxId}/participations/${participationId}/involved-party`
  );

  const [formData, setFormData] = useState<FormData>({
    contactPersonId: involvedParty?.contactPersonId,
    fileReference: involvedParty?.fileReference,
    postboxId: involvedParty?.postboxId,
    processReference: involvedParty?.processReference,
    responsibleOrganisationalUnit: involvedParty?.responsibleOrganisationalUnit
  });

  const { loading, updateInvolvedParty } = useInvolvedParty(postboxId);

  const onUpdate = useCallback(async () => {
    if (!UpdateInvolvedPartyRequestSchema.validate(formData).error) {
      await updateInvolvedParty(
        participationId,
        formData as UpdateInvolvedPartyRequest,
        true
      );

      alert('success', t('Änderungen gespeichert.'));

      onClose();
    }
  }, [alert, formData, onClose, participationId, t, updateInvolvedParty]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Mitwirkung bearbeiten')}
      id="update-participation-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => submitForm(activeForm)}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Änderungen speichern')}
      secondaryButtonLabel={t('Schließen')}
    >
      <FjdStack spacing="xl">
        <Suspense fallback={<FjdSpinner size="s" />}>
          <UpdateParticipationVBForm
            initialData={formData}
            onChange={(data) =>
              setFormData((formData) => ({
                ...formData,
                ...data
              }))
            }
            onInit={(form) => setActiveForm(form)}
            onNext={onUpdate}
          />
        </Suspense>
      </FjdStack>
    </FjdModal>
  );
}
