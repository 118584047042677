import {
  FjdButton,
  FjdLoadingOverlay,
  FjdRadio,
  FjdSelect,
  FjdStack,
  FjdTextInput,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useDebounceCallback } from '@react-hook/debounce';

import { useApi } from '../../../hooks/useApi';
import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import {
  NaturalPerson,
  PersonType,
  PrivateLegalEntity,
  PublicOrganization
} from '../../../models/Person';
import { Postbox } from '../../../models/Postbox';
import { getPostboxOwnerName } from '../../../utils/postbox';
import saveAs from 'file-saver';

interface PostboxesOwnerTableProps {
  context?: PostboxesOwnerTableContext;
  defaultSelection?: string[];
  highlightedPostbox?: Postbox;
  onSelectionChange?: (selection: string[]) => void;
  pageSize?: number;
  selectionType?: 'none' | 'single';
}

export enum PostboxesOwnerTableContext {
  CREATE_PARTICIPATION = 'create-participation',
  DIRECTORY = 'directory'
}

export function PostboxesOwnerTable({
  context = PostboxesOwnerTableContext.CREATE_PARTICIPATION,
  defaultSelection,
  highlightedPostbox,
  onSelectionChange,
  pageSize = 5,
  selectionType = 'none'
}: PostboxesOwnerTableProps) {
  const { makeRequest } = useApi();
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const [downloading, setDownloading] = useState(false);
  const [personType, setPersonType] = useState<PersonType>(
    PersonType.PUBLIC_ORGANISATION
  );

  const [searchTerm, setSearchTerm] = useState<string>();

  const [selection, setSelection] = useState<string[]>(defaultSelection || []);

  const debouncedSetSearchTerm = useDebounceCallback(
    setSearchTerm,
    1000,
    false
  );

  const {
    data: postboxes,
    isValidating,
    pagination
  } = usePaginatedSwr<Postbox>({
    elementLabel: t('Stellen'),
    filter: [
      { key: 'currentPostboxId', value: currentPostboxId },
      { key: 'ownerType', value: personType },
      { key: 'sortBy', value: 'NAME' },
      { key: 'searchByName', value: searchTerm || '' },
      { key: 'order', value: 'ASC' }
    ],
    key: `/postbox-results`,
    pageSize
  });

  const downloadList = useCallback(async () => {
    setDownloading(true);

    const path = `/postbox-results-download?page=0&size=99999&ownerType=${personType}&currentPostboxId=${currentPostboxId}`;

    try {
      const res = await makeRequest<Blob>(path, 'GET', undefined, {
        responseType: 'blob'
      });

      saveAs(res, 'Postfächer.xlsx');
      setDownloading(false);
    } catch (e) {
      setDownloading(false);
    }
  }, [currentPostboxId, makeRequest, personType]);

  const setTab = useCallback((personTyp: PersonType) => {
    setPersonType(personTyp);
  }, []);

  useEffect(() => {
    if (highlightedPostbox && defaultSelection?.[0] !== highlightedPostbox.id) {
      const newSelection = [highlightedPostbox.id];

      setSelection(newSelection);
      setTab(highlightedPostbox.owner.type);

      if (typeof onSelectionChange === 'function') {
        onSelectionChange(newSelection);
      }
    }
  }, [defaultSelection, highlightedPostbox, onSelectionChange, setTab]);

  const setPostboxSelection = useCallback(
    (postboxId: string) => {
      let newSelection = [postboxId];

      setSelection(newSelection);

      if (typeof onSelectionChange === 'function') {
        onSelectionChange(newSelection);
      }
    },
    [onSelectionChange]
  );

  const postboxesByOwnerType = useMemo(
    () => ({
      [PersonType.NATURAL_PERSON]: postboxes?.filter(
        (postbox) => postbox.owner.type === PersonType.NATURAL_PERSON
      ),
      [PersonType.PRIVATE_LEGAL_ENTITY]: postboxes?.filter(
        (postbox) => postbox.owner.type === PersonType.PRIVATE_LEGAL_ENTITY
      ),
      [PersonType.PUBLIC_ORGANISATION]: postboxes?.filter(
        (postbox) => postbox.owner.type === PersonType.PUBLIC_ORGANISATION
      )
    }),
    [postboxes]
  );

  const tableBody = useMemo(() => {
    if (postboxesByOwnerType[personType]?.length === 0) {
      return (
        <FjdVirtualizedTableRow>
          <FjdVirtualizedTableCell>
            {t('Keine Stellen gefunden.')}
          </FjdVirtualizedTableCell>
        </FjdVirtualizedTableRow>
      );
    }

    if (context === PostboxesOwnerTableContext.CREATE_PARTICIPATION) {
      return postboxesByOwnerType[personType]?.map((postbox, index) => (
        <FjdVirtualizedTableRow even={index % 2 === 1} key={postbox.id}>
          {selectionType === 'single' && (
            <FjdVirtualizedTableCell maxWidth="2.75rem">
              <FjdRadio
                aria-labelledby={`label-${postbox.id}`}
                checked={selection.some(
                  (postboxId) => postboxId === postbox.id
                )}
                id={`select-${postbox.id}`}
                label=""
                name={`selection`}
                onChange={() => setPostboxSelection(postbox.id)}
                value={index}
              />
            </FjdVirtualizedTableCell>
          )}

          <FjdVirtualizedTableCell>
            <em id={`label-${postbox.id}`}>
              {getPostboxOwnerName(postbox.owner)}
            </em>
          </FjdVirtualizedTableCell>
          {postbox.owner.type !== PersonType.NATURAL_PERSON && (
            <FjdVirtualizedTableCell>
              {postbox.owner.organisationalUnit}
            </FjdVirtualizedTableCell>
          )}
          <FjdVirtualizedTableCell>{t('Registriert')}</FjdVirtualizedTableCell>
        </FjdVirtualizedTableRow>
      ));
    }

    if (context === PostboxesOwnerTableContext.DIRECTORY) {
      if (personType === PersonType.PUBLIC_ORGANISATION) {
        const postboxes = postboxesByOwnerType[
          personType
        ] as Postbox<PublicOrganization>[];

        return postboxes?.map((postbox, index) => {
          return (
            <FjdVirtualizedTableRow even={index % 2 === 1} key={postbox.id}>
              <FjdVirtualizedTableCell>
                {postbox.owner.organizationName}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.organisationalUnit}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.typeOfPublicOrganization}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>{postbox.name}</FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          );
        });
      } else if (personType === PersonType.PRIVATE_LEGAL_ENTITY) {
        const postboxes = postboxesByOwnerType[
          personType
        ] as Postbox<PrivateLegalEntity>[];

        return postboxes?.map((postbox, index) => {
          return (
            <FjdVirtualizedTableRow even={index % 2 === 1} key={postbox.id}>
              <FjdVirtualizedTableCell>
                {postbox.owner.organizationName}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.organisationalUnit}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>{postbox.name}</FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          );
        });
      } else if (personType === PersonType.NATURAL_PERSON) {
        const postboxes = postboxesByOwnerType[
          personType
        ] as Postbox<NaturalPerson>[];

        return postboxes?.map((postbox, index) => {
          return (
            <FjdVirtualizedTableRow even={index % 2 === 1} key={postbox.id}>
              <FjdVirtualizedTableCell>
                {postbox.owner.name.formOfAddress}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.name.doctoralDegrees}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.name.lastName}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                {postbox.owner.name.firstName}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>{postbox.name}</FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          );
        });
      }
    }
  }, [
    context,
    personType,
    postboxesByOwnerType,
    selection,
    selectionType,
    setPostboxSelection,
    t
  ]);

  const tableHeader = useMemo(() => {
    if (context === PostboxesOwnerTableContext.CREATE_PARTICIPATION) {
      return (
        <FjdVirtualizedTableRow>
          {selectionType === 'single' && (
            <FjdVirtualizedTableCol maxWidth="2.75rem"></FjdVirtualizedTableCol>
          )}
          <FjdVirtualizedTableCol>
            {personType !== PersonType.NATURAL_PERSON
              ? t('Organisation')
              : t('Name')}
          </FjdVirtualizedTableCol>
          {personType !== PersonType.NATURAL_PERSON && (
            <FjdVirtualizedTableCol>
              {t('Organisationseinheit')}
            </FjdVirtualizedTableCol>
          )}
          <FjdVirtualizedTableCol>
            {t('Plattformstatus')}
          </FjdVirtualizedTableCol>
        </FjdVirtualizedTableRow>
      );
    }

    if (context === PostboxesOwnerTableContext.DIRECTORY) {
      if (personType === PersonType.PUBLIC_ORGANISATION) {
        return (
          <FjdVirtualizedTableRow>
            <FjdVirtualizedTableCol>{t('Organisation')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>
              {t('Organisationseinheit')}
            </FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Art')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Postfachname')}</FjdVirtualizedTableCol>
          </FjdVirtualizedTableRow>
        );
      } else if (personType === PersonType.PRIVATE_LEGAL_ENTITY) {
        return (
          <FjdVirtualizedTableRow>
            <FjdVirtualizedTableCol>{t('Organisation')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>
              {t('Organisationseinheit')}
            </FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Postfachname')}</FjdVirtualizedTableCol>
          </FjdVirtualizedTableRow>
        );
      } else if (personType === PersonType.NATURAL_PERSON) {
        return (
          <FjdVirtualizedTableRow>
            <FjdVirtualizedTableCol>{t('Anrede')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Titel')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Vorname')}</FjdVirtualizedTableCol>
            <FjdVirtualizedTableCol>{t('Postfachname')}</FjdVirtualizedTableCol>
          </FjdVirtualizedTableRow>
        );
      }
    }
  }, [context, personType, selectionType, t]);

  return postboxesByOwnerType ? (
    <FjdLoadingOverlay loading={isValidating || downloading}>
      <FjdStack spacing="xl">
        <FjdStack spacing="s">
          <FjdStack orientation="horizontal">
            <FjdSelect
              disableSearch
              ariaLabel={t('personType')}
              id="personType"
              onChange={(value) => setTab(value as PersonType)}
              options={[
                {
                  text: t('Öffentliche Stellen'),
                  value: PersonType.PUBLIC_ORGANISATION
                },
                {
                  text: t('Nicht-öffentliche Stellen'),
                  value: PersonType.PRIVATE_LEGAL_ENTITY
                },
                {
                  text: t('Natürliche Personen'),
                  value: PersonType.NATURAL_PERSON
                }
              ]}
              size="s"
              value={personType}
            />

            <FjdTextInput
              id="postboxSearch"
              onChange={(event) => debouncedSetSearchTerm(event.target.value)}
              placeholder={t('Suche …')}
              size="s"
              type="search"
            />

            {context === PostboxesOwnerTableContext.DIRECTORY && (
              <FjdButton
                appearance="primary-link"
                iconLeft="document-download"
                label={t('Liste herunterladen')}
                onClick={downloadList}
                size="s"
              />
            )}
          </FjdStack>

          <FjdVirtualizedTable disableVirtualization header={tableHeader}>
            {tableBody}
          </FjdVirtualizedTable>

          {pagination.PaginationComponent}
        </FjdStack>
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
