import {
  FjdButton,
  FjdIcon,
  FjdMarkdown,
  FjdStack
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { useElementNavigation } from '../../../../hooks/useElementNavigation';
import { Case } from '../../../../models/Case';

interface UpsertCaseFormStep3Props {
  case: Case;
  onClose: () => void;
}

export function UpsertCaseFormStep3({
  case: caseData,
  onClose
}: UpsertCaseFormStep3Props) {
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();

  return (
    <FjdStack spacing="3xl">
      <FjdStack orientation="horizontal" spacing="xl">
        <FjdIcon appearance="success" glyph="checkmark-outline" size="4xl" />
        <FjdMarkdown
          source={t(
            `Herzlichen Glückwunsch, der Fall wurde erfolgreich angelegt! Sie können zu diesem Fall direkt eine Mitwirkung anlegen oder aber die Fallinformationen aufrufen.`
          )}
        />
      </FjdStack>

      <FjdStack orientation="horizontal" spacing="m">
        <FjdButton
          iconLeft="view"
          label={t('Fall öffnen')}
          onClick={() => {
            navigateToElement(caseData);
            onClose();
          }}
          size="s"
        />
        <FjdButton
          appearance="primary-link"
          iconLeft="add-outline"
          label={t('Mitwirkung anlegen')}
          onClick={() => {
            navigateToElement(caseData, undefined, false, {
              createParticipation: true
            });
            onClose();
          }}
          size="s"
        />
      </FjdStack>
    </FjdStack>
  );
}
