import {
  FjdBadgeList,
  FjdCheckbox,
  FjdLoadingOverlay,
  FjdMarkdown,
  FjdStack,
  FjdTruncatedText,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { useUserRoles } from '../../../hooks/useUserRoles';
import { Postbox } from '../../../models/Postbox';
import { getPostboxOwnerName } from '../../../utils/postbox';
import { getPostboxPath } from '../../../utils/router';
import { PostboxBadge } from '../PostboxBadge/PostboxBadge';
import { PersonType } from '../../../models/Person';

interface PostboxesTableProps {
  defaultSelection?: string[];
  linked?: boolean;
  onSelectionChange?: (selection: string[]) => void;
  pageSize?: number;
  selectionType?: 'multi' | 'none';
  ownerType?: PersonType;
}

export function PostboxesTable({
  defaultSelection,
  linked = true,
  onSelectionChange,
  pageSize = 20,
  selectionType = 'none',
  ownerType
}: PostboxesTableProps) {
  const { isApplicationAdmin } = useUserRoles();
  const { t } = useTranslation();

  const [selection, setSelection] = useState<string[]>(defaultSelection || []);

  const {
    data: postboxes,
    isValidating,
    pagination
  } = usePaginatedSwr<Postbox>({
    elementLabel: t('Postfächer'),
    key: isApplicationAdmin ? '/postbox-results/admin' : '/users/postboxes',
    pageSize,
    filter: ownerType ? [{ key: 'ownerType', value: ownerType }] : []
  });

  const setPostboxSelection = useCallback(
    (postboxId: string, selected: boolean) => {
      let newSelection = selection;

      if (!selected) {
        newSelection = selection.filter((pId) => pId !== postboxId);
      } else {
        newSelection = [...selection, postboxId];
      }

      setSelection(newSelection);

      if (typeof onSelectionChange === 'function') {
        onSelectionChange(newSelection);
      }
    },
    [onSelectionChange, selection]
  );

  return postboxes ? (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdStack spacing="xl">
          <FjdStack spacing="s">
            <FjdVirtualizedTable
              disableVirtualization
              header={
                <FjdVirtualizedTableRow>
                  {selectionType === 'multi' && (
                    <FjdVirtualizedTableCol maxWidth="2.75rem"></FjdVirtualizedTableCol>
                  )}
                  <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
                  <FjdVirtualizedTableCol>
                    {t('Bemerkung')}
                  </FjdVirtualizedTableCol>
                  <FjdVirtualizedTableCol>
                    {t('Inhaber')}
                  </FjdVirtualizedTableCol>
                </FjdVirtualizedTableRow>
              }
            >
              {postboxes.length === 0 && (
                <FjdVirtualizedTableRow>
                  <FjdVirtualizedTableCell>
                    {t('Keine Postfächer gefunden.')}
                  </FjdVirtualizedTableCell>
                </FjdVirtualizedTableRow>
              )}

              {postboxes.map((postbox, index) => (
                <FjdVirtualizedTableRow
                  even={index % 2 === 1}
                  key={postbox?.id}
                >
                  {selectionType === 'multi' && (
                    <FjdVirtualizedTableCell maxWidth="2.75rem">
                      <FjdCheckbox
                        aria-labelledby="postbox-id"
                        checked={selection.some(
                          (postboxId) => postboxId === postbox.id
                        )}
                        id={`select-${postbox.id}`}
                        label=""
                        name={`select-${postbox.id}`}
                        onChange={(event) =>
                          setPostboxSelection(postbox.id, event.target.checked)
                        }
                      />
                    </FjdVirtualizedTableCell>
                  )}
                  <FjdVirtualizedTableCell>
                    {linked ? (
                      <Link id="postbox-name" to={getPostboxPath(postbox)}>
                        {postbox.name}
                      </Link>
                    ) : (
                      <em id="postbox-name">{postbox.name}</em>
                    )}
                  </FjdVirtualizedTableCell>
                  <FjdVirtualizedTableCell>
                    <FjdTruncatedText allowHTML text={postbox.note || ''} />
                  </FjdVirtualizedTableCell>
                  <FjdVirtualizedTableCell>
                    {getPostboxOwnerName(postbox.owner)}
                  </FjdVirtualizedTableCell>
                </FjdVirtualizedTableRow>
              ))}
            </FjdVirtualizedTable>

            {pagination.PaginationComponent}
          </FjdStack>

          {selectionType === 'multi' && (
            <FjdStack orientation="horizontal" spacing="s">
              <FjdMarkdown
                source={`**${t('Ausgewählte&#160;Postfächer:')}**`}
              />
              {selection.length > 0 ? (
                <FjdBadgeList forBadgeSize="s">
                  {selection.map((postboxId) => (
                    <PostboxBadge
                      key={postboxId}
                      onClick={(event) => {
                        event.stopPropagation();
                        setPostboxSelection(postboxId, false);
                      }}
                      postboxId={postboxId}
                    />
                  ))}
                </FjdBadgeList>
              ) : (
                <span>{t('keine')}</span>
              )}
            </FjdStack>
          )}
        </FjdStack>
      </FjdLoadingOverlay>
    </>
  ) : null;
}
