import { PublicServiceType } from '../models/PublicServiceType';
import useSWR from 'swr';

export interface IUsePublicServiceType {
  publicServiceType: PublicServiceType | undefined;
}

export function usePublicServiceType(
  publicServiceTypeId?: string
): IUsePublicServiceType {
  const { data: publicServiceType } = useSWR<PublicServiceType>(
    publicServiceTypeId ? `/public-service-types/${publicServiceTypeId}` : null
  );

  return {
    publicServiceType
  } as IUsePublicServiceType;
}
