import {
  FjdIcon,
  FjdMarkdown,
  FjdTooltip,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { InvolvedParty } from '../../../models/InvolvedParty';
import { LeadingParty } from '../../../models/LeadingParty';
import { LogEntryStatus } from '../../../models/LogEntry';
import { Message } from '../../../models/Message';
import { Postbox } from '../../../models/Postbox';
import { getMessageTypeLabel } from '../../../utils/message';
import { getPostboxOwnerName } from '../../../utils/postbox';
import { getMessagePath } from '../../../utils/router';
import { defaultDateFormatOptions, truncate } from '../../../utils/string';

interface MessagesTableRowProps {
  even: boolean;
  message: Message;
  postboxId: string;
}

export function MessagesTableRow({
  even,
  message,
  postboxId
}: MessagesTableRowProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${message.postboxId}/cases/${message.caseId}/leading-party`
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${message.postboxId}/participations/${message.participationId}/involved-party`
  );

  const { data: senderPostbox } = useSWR<Postbox>(
    leadingParty && involvedParty
      ? message.fromLeader
        ? `/postboxes/${leadingParty.postboxId}`
        : `/postboxes/${involvedParty.postboxId}`
      : null,
    {
      suspense: false
    }
  );

  const messageReceived = !!message?.logs?.find(
    (entry) => entry.status === LogEntryStatus.RECEIVED
  );

  return (
    <FjdVirtualizedTableRow
      even={even}
      highlightOnHover
      onClick={(event) => {
        if ((event.target as HTMLElement).tagName !== 'A') {
          navigate(getMessagePath(message, postboxId));
        }
      }}
    >
      <FjdVirtualizedTableCell maxWidth="2.75rem">
        <div style={{ display: 'flex' }}>
          <FjdIcon
            glyph={
              senderPostbox?.id === postboxId
                ? 'reply'
                : messageReceived
                ? 'email'
                : 'email-unread'
            }
            size="s"
          />
        </div>
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="19rem">
        {getMessageTypeLabel(message.type, t)}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell>
        {message.text ? (
          <FjdTooltip
            focusable={false}
            placement="top"
            tooltip={
              <FjdMarkdown
                allowHTML
                source={`**${t('Vorschau')}**<br/>${truncate(
                  message.text.replace(/<p>/g, '').replace(/<\/p>/g, ' '),
                  200,
                  false
                )}`}
              />
            }
          >
            <div style={{ lineHeight: 1 }}>
              <Link to={getMessagePath(message, postboxId)}>
                {messageReceived ? (
                  message.subject
                ) : (
                  <strong>{message.subject}</strong>
                )}
              </Link>
            </div>
          </FjdTooltip>
        ) : (
          <Link to={getMessagePath(message, postboxId)}>{message.subject}</Link>
        )}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="10rem">
        {message.receivedOn &&
          new Date(message.receivedOn).toLocaleString(
            [],
            defaultDateFormatOptions
          )}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="12rem">
        {getPostboxOwnerName(senderPostbox?.owner)}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="7rem">
        {message.docs?.length || 0}
      </FjdVirtualizedTableCell>
    </FjdVirtualizedTableRow>
  );
}
