import { FjdFormControl, FjdStack } from 'fjd-react-components';
import Joi from 'joi';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Except } from 'type-fest';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { CreateCaseRequest } from '../../../../models/Case';
import { PublicServiceTypesTable } from '../../../Settings/PublicServiceTypes/PublicServiceTypesTable/PublicServiceTypesTable';

interface UpsertCaseFormStep1Props {
  initialData?: Partial<CreateCaseRequest>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  postboxId: string;
}

export type FormControls = Except<CreateCaseRequest, 'description' | 'title'>;

export function UpsertCaseFormStep1({
  initialData,
  onChange,
  onInit,
  onNext,
  postboxId
}: UpsertCaseFormStep1Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: {
      publicServiceTypeId: initialData?.publicServiceTypeId
    },
    resolver: joiResolver(
      Joi.object({
        publicServiceTypeId: Joi.string()
          .required()
          .messages({
            'any.required': t('Bitte wählen Sie eine Verwaltungsleistung aus.')
          })
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdStack spacing="xl">
        <FjdFormControl
          inputId="publicServiceTypeId"
          label={t('Verwaltungsleistung auswählen')}
          tooltip={t(
            'Es können nur Verwaltungsleistungen angezeigt werden, die für das vorliegende Postfach als verfahrensführend registriert wurden.'
          )}
          validationMessage={errors.publicServiceTypeId?.message}
        >
          <Controller
            name="publicServiceTypeId"
            control={control}
            render={({ field }) => {
              return (
                <>
                  <PublicServiceTypesTable
                    defaultSelection={
                      initialData?.publicServiceTypeId
                        ? [initialData?.publicServiceTypeId]
                        : []
                    }
                    linked={false}
                    onSelectionChange={(selection) => {
                      field.onBlur();
                      field.onChange(selection[0]);
                      onChange({ [field.name]: selection[0] });
                    }}
                    pageSize={10}
                    postboxId={postboxId}
                    selectionType="single"
                  />
                </>
              );
            }}
          />
        </FjdFormControl>
      </FjdStack>

      <input type="submit" hidden />
    </form>
  );
}
