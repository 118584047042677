import { saveAs } from 'file-saver';
import {
  FjdButton,
  FjdColumns,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdFlexSpacer,
  FjdIcon,
  FjdLoadingOverlay,
  FjdStack,
  FjdTooltip,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useApi } from '../../../hooks/useApi';
import {
  ExternalDocumentReference,
  FileReference,
  instanceOfExternalDocumentReference,
  instanceOfFileReference
} from '../../../models/Document';
import { Message } from '../../../models/Message';
import { getMimeTypeIcon } from '../../../utils/document';
import { defaultDateFormatOptions } from '../../../utils/string';

interface DocumentsTableProps {
  caseId: string;
  messageId: string;
  participationId: string;
  postboxId: string;
}

export function DocumentsTable({
  caseId,
  messageId,
  participationId,
  postboxId
}: DocumentsTableProps) {
  const { makeRequestWithFullResponse } = useApi();
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const [expandedDocuments, setExpandedDocuments] = useState<string[]>([]);

  const { data: message, isValidating } = useSWR<Message>(
    `/postboxes/${postboxId}/participations/${participationId}/messages/${messageId}`
  );

  const documents = message?.docs || [];

  const downloadFile = useCallback(
    async (
      reference?: ExternalDocumentReference | FileReference,
      filename?: string
    ) => {
      if (reference) {
        if (instanceOfFileReference(reference)) {
          const file = await makeRequestWithFullResponse(
            `/postboxes/${postboxId}/cases/${caseId}/files/${reference.fileId}?currentPostboxId=${currentPostboxId}`,
            'GET',
            undefined,
            {
              responseType: 'blob'
            }
          );

          saveAs(file.data as Blob, filename);
        } else if (instanceOfExternalDocumentReference(reference)) {
          saveAs(reference.uri);
        }
      }
    },
    [caseId, currentPostboxId, makeRequestWithFullResponse, postboxId]
  );

  const openFile = useCallback(
    async (reference?: ExternalDocumentReference | FileReference) => {
      if (reference) {
        if (instanceOfFileReference(reference)) {
          const file = await makeRequestWithFullResponse(
            `/postboxes/${postboxId}/cases/${caseId}/files/${reference.fileId}?currentPostboxId=${currentPostboxId}`,
            'GET',
            undefined,
            {
              responseType: 'blob'
            }
          );

          window.open(URL.createObjectURL(file.data as Blob), '_blank');
        } else if (instanceOfExternalDocumentReference(reference)) {
          window.open(reference.uri, '_blank');
        }
      }
    },
    [caseId, currentPostboxId, makeRequestWithFullResponse, postboxId]
  );

  const toggleDocument = useCallback((documentId: string) => {
    setExpandedDocuments((expandedDocuments) =>
      expandedDocuments.includes(documentId)
        ? expandedDocuments.filter((docId) => docId !== documentId)
        : [...expandedDocuments, documentId]
    );
  }, []);

  return documents ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol maxWidth="2.5rem"></FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Dateiname')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Typ')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="8.25rem"></FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {documents.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Dokumente gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {documents.map((document, index) => (
            <Fragment key={document?.id}>
              <FjdVirtualizedTableRow
                bordered={!expandedDocuments.includes(document.id)}
                even={index % 2 === 1}
              >
                <FjdVirtualizedTableCell maxWidth="2.5rem">
                  <div style={{ display: 'flex' }}>
                    <FjdIcon
                      glyph={getMimeTypeIcon(document.mimeType)}
                      size="m"
                    />
                  </div>
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>
                  <em>{document.title}</em>
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>
                  {document.filename}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>
                  {document.mimeType}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="8.25rem">
                  <FjdStack orientation="horizontal" spacing="xs">
                    <FjdFlexSpacer />
                    {document.reference &&
                      document.mimeType === 'application/pdf' && (
                        <FjdTooltip
                          focusable={false}
                          placement="top"
                          tooltip={t('Öffnen')}
                        >
                          <FjdButton
                            appearance="primary-link"
                            hideLabel
                            iconLeft="view"
                            label={t('Öffnen')}
                            onClick={() => openFile(document.reference)}
                            size="s"
                          />
                        </FjdTooltip>
                      )}
                    {document.reference?.type === 'FILE' && (
                      <FjdTooltip
                        focusable={false}
                        placement="top"
                        tooltip={t('Herunterladen')}
                      >
                        <FjdButton
                          appearance="primary-link"
                          hideLabel
                          iconLeft="download"
                          label={t('Herunterladen')}
                          onClick={() =>
                            downloadFile(document.reference, document.filename)
                          }
                          size="s"
                        />
                      </FjdTooltip>
                    )}
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      iconLeft="chevron-down"
                      label={t('Aus-/Einklappen')}
                      onClick={() => toggleDocument(document.id)}
                      size="s"
                    />
                  </FjdStack>
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>

              {expandedDocuments.includes(document.id) && (
                <FjdVirtualizedTableRow even={index % 2 === 1} padded>
                  <FjdVirtualizedTableCell>
                    <FjdColumns>
                      <FjdDescriptionList>
                        <FjdDescriptionListItem
                          description={
                            document.documentDate
                              ? new Date(document.documentDate).toLocaleString(
                                  [],
                                  defaultDateFormatOptions
                                )
                              : '&ndash;'
                          }
                          term={t('Datum des Dokuments')}
                        />
                        <FjdDescriptionListItem
                          description={document.version || '&ndash;'}
                          term={t('Version des Dokuments')}
                        />
                        <FjdDescriptionListItem
                          description={
                            document.documentDate
                              ? new Date(document.documentDate).toLocaleString(
                                  [],
                                  defaultDateFormatOptions
                                )
                              : '&ndash;'
                          }
                          term={t('Dokumentinhalt')}
                        />
                      </FjdDescriptionList>

                      <FjdDescriptionList>
                        <FjdDescriptionListItem
                          description={document.authorization || '&ndash;'}
                          term={t('Autorisierungshinweis')}
                        />
                        <FjdDescriptionListItem
                          allowHTML
                          description={document.mimeType || '&ndash;'}
                          term={t('Erläuterung zum technischen Format')}
                        />
                        <FjdDescriptionListItem
                          allowHTML
                          description={document.note || '&ndash;'}
                          term={t('Bemerkungen zum Dokument')}
                        />
                      </FjdDescriptionList>
                    </FjdColumns>
                  </FjdVirtualizedTableCell>
                </FjdVirtualizedTableRow>
              )}
            </Fragment>
          ))}
        </FjdVirtualizedTable>
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
