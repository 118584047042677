import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: './locales/{{lng}}.json'
    },
    debug: process.env.NODE_ENV === 'development',
    detection: {
      caches: ['localStorage'],
      lookupLocalStorage: 'pdbk-lang',
      lookupQuerystring: 'lang',
      order: ['querystring', 'localStorage']
    },
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false
    },
    keySeparator: false,
    nsSeparator: false
  });

export default i18n;
