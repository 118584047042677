import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import App from './App';
import { FjdSpinner } from 'fjd-react-components';
import './i18n';
import './theme/variables-cl-v1.css';

import { HelmetProvider } from 'react-helmet-async';

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Root element not found');

const root = createRoot(rootElement);

root.render(
  // TODO error: "A 'Keycloak' instance can only be initialized once." when react strict mode is used
  // <React.StrictMode>
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{ checkLoginIframe: false }}
  >
    <HelmetProvider>
      <Suspense fallback={<FjdSpinner size="s" />}>
        <App />
      </Suspense>
    </HelmetProvider>
  </ReactKeycloakProvider>
  // </React.StrictMode>
);
