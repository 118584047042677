import {
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdHeading,
  FjdStack
} from 'fjd-react-components';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import PostboxContactPersons from './PostboxContactPersons/PostboxContactPersons';
import PostboxDetails from './PostboxDetails/PostboxDetails';
import PostboxDirectories from './PostboxDirectories/PostboxDirectories';
import PostboxNotifications from './PostboxNotifications/PostboxNotifications';
import { PersonType } from '../../models/Person';
import useSWR from 'swr';
import { Postbox } from '../../models/Postbox';
import { useParams } from 'react-router';
import { useUserRoles } from '../../hooks/useUserRoles';
import useUserByLocation from '../../hooks/useUserByLocation';
import { instanceOfUserResponse } from '../../models/User';

enum ContentTab {
  POSTBOX = 'postbox',
  NOTIFICATIONS = 'notifications',
  CONTACT_PERSONS = 'contactPersons',
  DIRECTORIES = 'directories'
}

export function PostboxSettings() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const locationElement = useUserByLocation();

  const [activeContentTab, setActiveContentTab] = useState<ContentTab>(
    ContentTab.POSTBOX
  );

  const [canUpdatePostbox, setCanUpdatePostbox] = useState<boolean>(false);

  const { postboxId: currentPostboxId } = useParams();

  const { isApplicationAdmin: isSystemAdmin } = useUserRoles();

  const { data: postbox } = useSWR<Postbox>(`/postboxes/${currentPostboxId}`);

  const checkCanUpdatePostbox = useCallback(async () => {
    if (instanceOfUserResponse(locationElement)) {
      if (
        (postbox?.owner.type as PersonType) === PersonType.PUBLIC_ORGANISATION
      ) {
        setCanUpdatePostbox(
          locationElement.admin || (isSystemAdmin as boolean)
        );
      } else {
        setCanUpdatePostbox(true);
      }
    } else {
      setCanUpdatePostbox(isSystemAdmin as boolean);
    }
  }, [isSystemAdmin, locationElement, postbox?.owner.type]);

  useEffect(() => {
    const tabFromUrl = searchParams.get('tab');

    if (tabFromUrl && tabFromUrl !== activeContentTab) {
      setActiveContentTab(tabFromUrl as ContentTab);
    }
    checkCanUpdatePostbox();
  }, [activeContentTab, searchParams, checkCanUpdatePostbox]);

  return (
    <>
      <Helmet>
        <title>
          {t('Postfacheinstellungen')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack spacing="xl">
        <FjdHeading level={1} text={t('Postfacheinstellungen')} />

        <FjdStack spacing="5xl">
          <FjdContentTabBar>
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.POSTBOX}
              label={t('Postfach-Details')}
              onClick={() => {
                setActiveContentTab(ContentTab.POSTBOX);
                setSearchParams({ tab: ContentTab.POSTBOX });
              }}
            />
            {canUpdatePostbox ? (
              <FjdContentTabBarItem
                active={activeContentTab === ContentTab.NOTIFICATIONS}
                label={t('Benachrichtigungen')}
                onClick={() => {
                  setActiveContentTab(ContentTab.NOTIFICATIONS);
                  setSearchParams({ tab: ContentTab.NOTIFICATIONS });
                }}
              />
            ) : (
              <></>
            )}
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.CONTACT_PERSONS}
              label={t('Kontaktpersonen')}
              onClick={() => {
                setActiveContentTab(ContentTab.CONTACT_PERSONS);
                setSearchParams({ tab: ContentTab.CONTACT_PERSONS });
              }}
            />
            <FjdContentTabBarItem
              active={activeContentTab === ContentTab.DIRECTORIES}
              label={t('Verzeichnisse')}
              onClick={() => {
                setActiveContentTab(ContentTab.DIRECTORIES);
                setSearchParams({ tab: ContentTab.DIRECTORIES });
              }}
            />
          </FjdContentTabBar>

          {activeContentTab === ContentTab.POSTBOX && (
            <PostboxDetails canUpdatePostbox={canUpdatePostbox} />
          )}
          {activeContentTab === ContentTab.NOTIFICATIONS &&
            canUpdatePostbox && <PostboxNotifications />}
          {activeContentTab === ContentTab.CONTACT_PERSONS && (
            <PostboxContactPersons />
          )}
          {activeContentTab === ContentTab.DIRECTORIES && (
            <PostboxDirectories />
          )}
        </FjdStack>
      </FjdStack>
    </>
  );
}

export { PostboxSettings as default } from './PostboxSettings';
