import { FjdIconGlyph } from 'fjd-react-components/build/components/Icon/Icon';

export function getFileHash(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const hashArray = Array.from(
        new Uint8Array(
          await crypto.subtle.digest(
            'SHA-256',
            event.target?.result as ArrayBuffer
          )
        )
      );

      resolve(hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''));
    };

    reader.onerror = async () => {
      reject();
    };

    reader.readAsArrayBuffer(file);
  });
}

export function getMimeTypeIcon(mimeType: string): FjdIconGlyph {
  if (!mimeType) {
    return 'document-unknown';
  }

  if (['application/pdf'].includes(mimeType)) {
    return 'document-pdf';
  }

  if (
    [
      'application/pdf',
      'application/rtf',
      'application/msword',
      'application/vnd.ms-excel',
      'application/vnd.ms-powerpoint',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/calendar',
      'text/css',
      'text/csv',
      'text/html',
      'text/javascript',
      'text/javascript',
      'text/plain'
    ].includes(mimeType)
  ) {
    return 'document';
  }

  if (
    [
      'audio/aac',
      'audio/midi audio/x-midi',
      'audio/mpeg',
      'audio/ogg',
      'audio/opus',
      'audio/wav',
      'audio/webm'
    ].includes(mimeType)
  ) {
    return 'document-audio';
  }

  if (
    [
      'image/bmp',
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'image/tiff',
      'image/vnd.microsoft.icon',
      'image/webp'
    ].includes(mimeType)
  ) {
    return 'image';
  }

  if (
    [
      'video/3gpp',
      'video/3gpp2',
      'video/mp2t',
      'video/mp4',
      'video/mpeg',
      'video/ogg',
      'video/webm',
      'video/x-msvideo'
    ].includes(mimeType)
  ) {
    return 'document-video';
  }

  if (
    mimeType.includes('compressed') ||
    mimeType.includes('rar') ||
    mimeType.includes('tar') ||
    mimeType.includes('zip')
  ) {
    return 'box';
  }

  return 'document-blank';
}
