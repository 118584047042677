import { FjdMarkdown, FjdModal } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useContactPerson } from '../../../../hooks/useContactPerson';
import { ContactPerson } from '../../../../models/ContactPerson';

interface DeleteContactPersonModalProps {
  contactPersonToDelete?: ContactPerson;
  onClose: () => void;
}

export function DeleteContactPersonModal({
  contactPersonToDelete,
  onClose
}: DeleteContactPersonModalProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const { deleteContactPerson, loading } = useContactPerson(currentPostboxId);

  const onDelete = async () => {
    if (contactPersonToDelete) {
      await deleteContactPerson(contactPersonToDelete.id);
      onClose();
    }
  };

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Kontaktperson löschen')}
      id="delete-contact-person-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={onDelete}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Unwiderruflich löschen')}
      secondaryButtonLabel={t('Schließen')}
    >
      <FjdMarkdown
        source={t(
          'Möchten Sie die Kontaktperson **„{{firstName}} {{lastName}}“** wirklich löschen? Die Aktion kann nicht rückgängig gemacht werden.',
          {
            firstName: contactPersonToDelete?.name.firstName,
            lastName: contactPersonToDelete?.name.lastName
          }
        )}
      />
    </FjdModal>
  );
}
