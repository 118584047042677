import {
  FjdButton,
  FjdColumns,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdFlyout,
  FjdLoadingOverlay,
  FjdMenu,
  FjdMenuItem,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { AddressType, InternationalAddress } from '../../../../models/Address';
import { ContactPerson } from '../../../../models/ContactPerson';
import { PhoneContact } from '../../../../models/PhoneContact';
import { countryOptions } from '../../../../utils/address';
import { DeleteContactPersonModal } from '../DeleteContactPersonModal/DeleteContactPersonModal';
import { UpsertContactPersonModal } from '../UpsertContactPersonModal/UpsertContactPersonModal';

export function ContactPersonTable() {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const { data: contactPersons, isValidating } = useSWR<ContactPerson[]>(
    `/postboxes/${currentPostboxId}/contact-persons`
  );

  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState<ContactPerson>();
  const [updating, setUpdating] = useState<ContactPerson>();

  const [expandedContactPersons, setExpandedContactPersons] = useState<
    string[]
  >([]);

  const formatPhoneNumber = useCallback((phone: PhoneContact) => {
    let addition = phone.description ? ` (${phone.description})` : '';

    return `${phone.number}${addition}`;
  }, []);

  const toggleContactPerson = useCallback((contactPersonId: string) => {
    setExpandedContactPersons((expandedContactPersons) =>
      expandedContactPersons.includes(contactPersonId)
        ? expandedContactPersons.filter((id) => id !== contactPersonId)
        : [...expandedContactPersons, contactPersonId]
    );
  }, []);

  const tableBody = useMemo(() => {
    if (contactPersons?.length === 0) {
      return (
        <FjdVirtualizedTableRow>
          <FjdVirtualizedTableCell>
            {t('Keine Kontaktpersonen gefunden.')}
          </FjdVirtualizedTableCell>
        </FjdVirtualizedTableRow>
      );
    }

    return contactPersons?.map((contactPerson, index) => (
      <Fragment key={contactPerson.id}>
        <FjdVirtualizedTableRow even={index % 2 === 1}>
          <FjdVirtualizedTableCell maxWidth="8rem">
            {contactPerson.name.formOfAddress}
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCell maxWidth="10rem">
            {contactPerson.name.doctoralDegrees}
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCell>
            {contactPerson.name.lastName}
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCell>
            {contactPerson.name.firstName}
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCell>
            {contactPerson.function}
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCell maxWidth="3.75rem">
            <FjdButton
              appearance="primary-link"
              disabled={
                !contactPerson.physicalAddress &&
                contactPerson.telephones.length === 0
              }
              hideLabel
              iconLeft={
                expandedContactPersons.includes(contactPerson.id)
                  ? 'chevron-up'
                  : 'chevron-down'
              }
              label={t('Aus-/Einklappen')}
              onClick={() => toggleContactPerson(contactPerson.id)}
              size="s"
            />
          </FjdVirtualizedTableCell>
          <FjdVirtualizedTableCol maxWidth="4.25rem">
            <FjdFlyout
              flyout={
                <FjdMenu>
                  <FjdMenuItem
                    appearance="light"
                    icon="edit"
                    label={t('Bearbeiten')}
                    onClick={() => setUpdating(contactPerson)}
                  />
                  <FjdMenuItem
                    appearance="light"
                    icon="trashcan"
                    label={t('Löschen')}
                    onClick={() => setDeleting(contactPerson)}
                  />
                </FjdMenu>
              }
              placement="bottom-end"
            >
              <FjdButton
                appearance="primary-link"
                hideLabel
                iconLeft="overflow-menu-vertical"
                label={t('Optionen')}
                size="s"
              />
            </FjdFlyout>
          </FjdVirtualizedTableCol>
        </FjdVirtualizedTableRow>

        {expandedContactPersons.includes(contactPerson.id) && (
          <FjdVirtualizedTableRow even={index % 2 === 1} padded>
            <FjdVirtualizedTableCell>
              <FjdColumns>
                {contactPerson.telephones.length > 0 && (
                  <FjdDescriptionList>
                    {contactPerson.emailAddress && (
                      <FjdDescriptionListItem
                        description={
                          <a href={`mailto:${contactPerson.emailAddress}`}>
                            {contactPerson.emailAddress}
                          </a>
                        }
                        term={t('E-Mail-Adresse')}
                      />
                    )}
                    {contactPerson.telephones.map((phone, index) => (
                      <FjdDescriptionListItem
                        allowHTML
                        description={formatPhoneNumber(phone)}
                        key={index}
                        term={`${t('Telefonnummer')} (${
                          phone.mobile ? t('Mobil') : t('Festnetz')
                        })`}
                      />
                    ))}
                  </FjdDescriptionList>
                )}

                <FjdDescriptionList>
                  {contactPerson.physicalAddress?.type ===
                    AddressType.NATIONAL && (
                    <FjdDescriptionListItem
                      description={`${contactPerson.physicalAddress.street} ${contactPerson.physicalAddress.houseNumber}${contactPerson.physicalAddress.houseNumberSuffix}`}
                      term={t('Straße, Hausnummer')}
                    />
                  )}

                  {contactPerson.physicalAddress?.type ===
                    AddressType.INTERNATIONAL && (
                    <FjdDescriptionListItem
                      allowHTML
                      description={`${contactPerson.physicalAddress.lines.join(
                        '<br/>'
                      )}`}
                      term={t('Adresszeilen')}
                    />
                  )}

                  {contactPerson.physicalAddress?.type ===
                    AddressType.POSTBOX && (
                    <FjdDescriptionListItem
                      allowHTML
                      description={`${contactPerson.physicalAddress.postOfficeBox}`}
                      term={t('Postfach')}
                    />
                  )}

                  {(contactPerson.physicalAddress?.type ===
                    AddressType.NATIONAL ||
                    contactPerson.physicalAddress?.type ===
                      AddressType.POSTBOX) && (
                    <FjdDescriptionListItem
                      description={`${contactPerson.physicalAddress.postalCode} ${contactPerson.physicalAddress.city}`}
                      term={t('Postleitzahl, Ort')}
                    />
                  )}

                  {contactPerson.physicalAddress?.type ===
                    AddressType.INTERNATIONAL && (
                    <FjdDescriptionListItem
                      description={`${
                        countryOptions.find(
                          (c) =>
                            c.value ===
                            (
                              contactPerson.physicalAddress as InternationalAddress
                            ).country
                        )?.label
                      }`}
                      term={t('Land')}
                    />
                  )}
                </FjdDescriptionList>
              </FjdColumns>
            </FjdVirtualizedTableCell>
          </FjdVirtualizedTableRow>
        )}
      </Fragment>
    ));
  }, [
    contactPersons,
    expandedContactPersons,
    formatPhoneNumber,
    t,
    toggleContactPerson
  ]);

  const tableHeader = useMemo(() => {
    return (
      <FjdVirtualizedTableRow>
        <FjdVirtualizedTableCol maxWidth="8rem">
          {t('Anrede')}
        </FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol maxWidth="10rem">
          {t('Titel')}
        </FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol>{t('Vorname')}</FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol>{t('Funktion')}</FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol maxWidth="3.75rem"></FjdVirtualizedTableCol>
        <FjdVirtualizedTableCol maxWidth="4.25rem"></FjdVirtualizedTableCol>
      </FjdVirtualizedTableRow>
    );
  }, [t]);

  return (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdStack spacing="l">
          <FjdStack orientation="horizontal">
            <FjdButton
              appearance="primary-link"
              iconLeft="add-outline"
              label={t('Neue Kontaktperson anlegen')}
              onClick={() => setCreating(true)}
              size="s"
            />
          </FjdStack>

          <FjdVirtualizedTable disableVirtualization header={tableHeader}>
            {tableBody}
          </FjdVirtualizedTable>
        </FjdStack>
      </FjdLoadingOverlay>

      {creating && (
        <UpsertContactPersonModal onClose={() => setCreating(false)} />
      )}

      {deleting && (
        <DeleteContactPersonModal
          contactPersonToDelete={deleting}
          onClose={() => setDeleting(undefined)}
        />
      )}

      {updating && (
        <UpsertContactPersonModal
          contactPersonToUpdate={updating}
          onClose={() => setUpdating(undefined)}
        />
      )}
    </>
  );
}
