import { FjdModal } from 'fjd-react-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePdfExport } from '../../../hooks/usePdfExport';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';

interface ExportModalProps {
  caseId: string;
  onClose: () => void;
  postboxId: string;
}

export function ExportModal({ caseId, onClose, postboxId }: ExportModalProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const [loadingStarted, setLoadingStarted] = useState(false);

  const { loading, open: openPdf } = usePdfExport({
    caseId,
    currentPostboxId,
    onOpen: onClose,
    onError: onClose,
    parentPostboxId: postboxId
  });

  useEffect(() => {
    if (!loading) {
      openPdf();
    }
  }, [loading, openPdf]);

  useEffect(() => {
    if (!loadingStarted && loading) {
      setLoadingStarted(true);
    } else if (loadingStarted && !loading) {
      setLoadingStarted(false);
    }
  }, [loading, loadingStarted, onClose]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      closable={false}
      heading={t('PDF-Export')}
      id="export-modal"
      open
    >
      <LoadingSpinner
        label={t('Die PDF wird vorbereitet. Dies kann einen Moment dauern …')}
      />
    </FjdModal>
  );
}
