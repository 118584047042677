import { FjdMarkdown, FjdModal, FjdStack } from 'fjd-react-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { usePublicServiceTypeLead } from '../../../../hooks/usePublicServiceTypeLead';
import { Postbox } from '../../../../models/Postbox';
import { PublicServiceType } from '../../../../models/PublicServiceType';

interface UnregisterPublicServiceTypeModalProps {
  onClose: () => void;
  onUnregister?: () => void;
  postbox: Postbox;
  publicServiceType: PublicServiceType;
}

export function UnregisterPublicServiceTypeModal({
  onClose,
  onUnregister,
  postbox,
  publicServiceType
}: UnregisterPublicServiceTypeModalProps) {
  const { t } = useTranslation();

  const { deletePublicServiceTypeLead, loading } = usePublicServiceTypeLead();

  const onUnregisterPublicServiceType = useCallback(async () => {
    await deletePublicServiceTypeLead(postbox.id, publicServiceType.id);

    if (typeof onUnregister === 'function') {
      onUnregister();
    }
  }, [
    deletePublicServiceTypeLead,
    onUnregister,
    postbox.id,
    publicServiceType
  ]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Registrierung der Verwaltungsleistung aufheben')}
      id="register-public-service-type-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={onUnregisterPublicServiceType}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Registrierung aufheben')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <FjdStack spacing="xl">
        <FjdMarkdown
          source={t(
            `Möchten Sie die Registrierung der Verwaltungsleistung **„{{publicServiceTypeName}}“** mit dem Postfach **„{{postboxName}}“** wirklich aufheben?`,
            {
              postboxName: postbox.name,
              publicServiceTypeName: publicServiceType.name
            }
          )}
        />
      </FjdStack>
    </FjdModal>
  );
}
