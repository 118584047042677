import { FjdButton, FjdHorizontalRule, FjdStack } from 'fjd-react-components';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import useTabs from '../../../hooks/useTabs';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { RouterLink } from '../../../shared/RouterLink';
import { RouteKey, routes } from '../../../utils/router';
import { CasesTree } from './CasesTree/CasesTree';

export function CasesSidebar() {
  const { t } = useTranslation();

  const casesSubPageMatch = useMatch(`${routes[RouteKey.CASES].path}/*`);
  const currentPostboxId = casesSubPageMatch?.params.postboxId;

  const { empty } = useTabs(currentPostboxId || '');

  return currentPostboxId && !empty ? (
    <FjdStack spacing="xl">
      <FjdStack orientation="horizontal">
        <RouterLink
          component={({ href, onClick }) => (
            <FjdButton
              appearance="primary-link"
              href={href}
              iconLeft="arrow-left"
              label={t('Zurück zur Übersicht')}
              onClick={onClick}
              size="s"
            />
          )}
          to={`/postboxes/${currentPostboxId}`}
        />
      </FjdStack>

      <FjdHorizontalRule />

      <ErrorBoundary fallback={<></>}>
        <Suspense fallback={<LoadingSpinner label={t('Lade Fall …')} />}>
          <CasesTree postboxId={currentPostboxId} />
        </Suspense>
      </ErrorBoundary>
    </FjdStack>
  ) : null;
}
