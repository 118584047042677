import { FjdAlert } from 'fjd-react-components';
import { ReactNode, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from './LoadingSpinner';

interface TabPageProps {
  children?: ReactNode | ReactNode[];
}

export function TabPage({ children }: TabPageProps) {
  const { t } = useTranslation();

  return (
    <Suspense fallback={<LoadingSpinner label={t('Lade …')} />}>
      <ErrorBoundary
        fallback={
          <FjdAlert
            appearance="muted"
            closable={false}
            intent="error"
            title={t(
              'Beim Laden des Elements ist leider ein Fehler aufgetreten.'
            )}
          />
        }
      >
        {children}
      </ErrorBoundary>
    </Suspense>
  );
}
