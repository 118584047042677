import axios, { AxiosResponse, Method, ResponseType } from 'axios';
import { useCallback } from 'react';
import { useMatch } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';

import { instanceOfMessage, Message } from '../models/Message';
import { Participation } from '../models/Participation';
import { ResultsObject } from '../models/ResultsObject';
import { RouteKey, routes } from '../utils/router';
import Config from '../config.json';

interface RequestOptions {
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  responseType?: ResponseType;
}

export function useApi() {
  const { keycloak } = useKeycloak();
  const caseSubPageMatch = useMatch(`${routes[RouteKey.CASE].path}/*`);
  const apiUrl = process.env.REACT_APP_API_URL || Config.REACT_APP_API_URL;

  const augmentMessagesWithCaseId = useCallback(
    async (
      response: AxiosResponse<Message | Message[] | ResultsObject<Message>>
    ) => {
      let caseId = caseSubPageMatch?.params.caseId;

      async function getParticipationOfMessage(
        participationId: string,
        postboxId: string
      ) {
        return (
          await axios.request<Participation>({
            headers: {
              Authorization: keycloak.token
                ? `Bearer ${keycloak.token}`
                : undefined,
              'Content-Type': 'application/json'
            },
            method: 'GET',
            responseType: 'json',
            url: `${apiUrl}/postboxes/${postboxId}/participations/${participationId}`
          })
        ).data;
      }

      if (instanceOfMessage(response.data)) {
        if (!caseId) {
          caseId = (
            await getParticipationOfMessage(
              response.data.participationId,
              response.data.postboxId
            )
          ).caseId;
        }

        return {
          ...response,
          data: { ...response.data, caseId }
        };
      } else if (
        Array.isArray(response.data) &&
        instanceOfMessage(response.data[0])
      ) {
        if (!caseId) {
          caseId = (
            await getParticipationOfMessage(
              response.data[0].participationId,
              response.data[0].postboxId
            )
          ).caseId;
        }

        return {
          ...response,
          data: response.data.map((message) => ({ ...message, caseId }))
        };
      } else if (
        response.data &&
        'items' in response.data &&
        instanceOfMessage(response.data.items[0])
      ) {
        if (!caseId) {
          caseId = (
            await getParticipationOfMessage(
              response.data.items[0].participationId,
              response.data.items[0].postboxId
            )
          ).caseId;
        }

        return {
          ...response,
          data: {
            ...response.data,
            items: response.data.items.map((message) => ({
              ...message,
              caseId
            }))
          }
        };
      }

      return response;
    },
    [caseSubPageMatch, keycloak.token, apiUrl]
  );

  const makeRequestWithFullResponse = useCallback(
    async <T>(
      url: string,
      method: Method,
      data?: any,
      options?: RequestOptions
    ) => {
      const response = await axios.request<T>({
        data,
        // TODO
        // @ts-ignore
        onUploadProgress: options?.onUploadProgress,
        headers: {
          Authorization: keycloak.token
            ? `Bearer ${keycloak.token}`
            : undefined,
          'Content-Type': data
            ? data instanceof FormData
              ? 'multipart/form-data'
              : 'application/json'
            : undefined
        },
        method,
        responseType: options?.responseType || 'json',
        url: `${apiUrl}${url}`
      });

      return augmentMessagesWithCaseId(
        response as unknown as AxiosResponse<
          Message | Message[] | ResultsObject<Message>
        >
      ) as unknown as AxiosResponse<T>;
    },
    [augmentMessagesWithCaseId, keycloak.token, apiUrl]
  );

  const makeRequest = useCallback(
    async <T>(
      url: string,
      method: Method,
      data?: any,
      options?: RequestOptions
    ) => {
      return (await makeRequestWithFullResponse<T>(url, method, data, options))
        .data;
    },
    [makeRequestWithFullResponse]
  );

  return { makeRequest, makeRequestWithFullResponse };
}
