import {
  FjdAutocomplete,
  FjdCard,
  FjdColumns,
  FjdFormControl,
  FjdRichtextEditor,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import {
  CreateParticipationRequest,
  CreateParticipationRequestSchema,
  ParticipationObligation
} from '../../../../models/Participation';
import {
  getParticipationObligationLabel,
  participationTaskOptions
} from '../../../../utils/participation';

interface CreateParticipationFormStep2Props {
  initialData?: Partial<CreateParticipationRequest>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
}

type FormControls = CreateParticipationRequest;

export function CreateParticipationFormStep2({
  initialData,
  onChange,
  onInit,
  onNext
}: CreateParticipationFormStep2Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: {
      caseId: initialData?.caseId,
      deadline: initialData?.deadline,
      description: initialData?.description,
      legalBasis: initialData?.legalBasis,
      obligation: initialData?.obligation,
      task: initialData?.task,
      title: initialData?.title
    },
    resolver: joiResolver(
      CreateParticipationRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdCard>
        <FjdStack spacing="xl">
          <FjdColumns>
            <FjdFormControl
              inputId="title"
              label={t('Titel')}
              validationMessage={errors.title?.message}
            >
              <Controller
                name="title"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      autoFocus
                      defaultValue={field.value}
                      id="title"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="task"
              label={t('Mitwirkungsaufgabe')}
              validationMessage={errors.task?.message}
            >
              <Controller
                name="task"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdAutocomplete
                      id="task"
                      label={t('Mitwirkungsaufgabe')}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange({ [field.name]: value });
                      }}
                      options={participationTaskOptions}
                      value={field.value}
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdColumns>

          <FjdFormControl
            inputId="description"
            label={t('Beschreibung')}
            optional
            validationMessage={errors.description?.message}
          >
            <Controller
              name="description"
              control={control}
              render={({ field }) => {
                return (
                  <FjdRichtextEditor
                    id="description"
                    initialValue={field.value}
                    name={field.name}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({ [field.name]: value });
                    }}
                    output="html"
                    rows={3}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdColumns>
            <FjdFormControl
              inputId="obligation"
              label={t('Verbindlichkeit der Mitwirkung')}
              validationMessage={errors.obligation?.message}
            >
              <Controller
                name="obligation"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdSelect
                      disableSearch
                      id="obligation"
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange({
                          [field.name]: value as ParticipationObligation
                        });
                      }}
                      options={Object.values(ParticipationObligation).map(
                        (obligation) => ({
                          selected: field.value === obligation,
                          text: getParticipationObligationLabel(obligation, t),
                          value: obligation
                        })
                      )}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="deadline"
              label={t('Mitwirkungsfrist')}
              optional
              validationMessage={errors.deadline?.message}
            >
              <Controller
                name="deadline"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="deadline"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                      type="date"
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdColumns>

          <FjdFormControl
            inputId="legalBasis"
            label={t('Rechtsgrundlage')}
            optional
            validationMessage={errors.legalBasis?.message}
          >
            <Controller
              name="legalBasis"
              control={control}
              render={({ field }) => {
                return (
                  <FjdRichtextEditor
                    id="legalBasis"
                    initialValue={field.value}
                    name={field.name}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({ [field.name]: value });
                    }}
                    output="html"
                    rows={3}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdStack>

        <input type="submit" hidden />
      </FjdCard>
    </form>
  );
}
