import { FjdTabBar } from 'fjd-react-components';
import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import { useCallback } from 'react';

import { useElementNavigation } from '../../../hooks/useElementNavigation';
import useTabs from '../../../hooks/useTabs';

interface CasesTabBarProps {
  postboxId: string;
}

export function CasesTabBar({ postboxId }: CasesTabBarProps) {
  const { navigateToElement } = useElementNavigation();
  const {
    closeAllTabs,
    closeInactiveTabs,
    closeTab,
    setTabs,
    tabs,
    toggleTabPin
  } = useTabs(postboxId);

  const openElement = useCallback(
    (tab: FjdTabBarTab) => {
      if (tab.id === 'empty') {
        return;
      }

      navigateToElement(tab.data.element, postboxId);
    },
    [navigateToElement, postboxId]
  );

  return (
    <FjdTabBar
      items={tabs.map((tab) => ({ ...tab, onTogglePin: toggleTabPin }))}
      onAllTabsClose={closeAllTabs}
      onInactiveTabsClose={closeInactiveTabs}
      onSort={setTabs}
      onTabClick={openElement}
      onTabClose={(tab) => tab && closeTab(tab.id)}
    />
  );
}
