import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import Cases from '../app/Cases/Cases';
import { Message } from '../app/Message/Message';
import { Participation } from '../app/Participation/Participation';
import Postbox from '../app/Postbox/Postbox';
import Postboxes from '../app/Postboxes/Postboxes';
import PostboxSettings from '../app/PostboxSettings/PostboxSettings';
import PublicServiceType from '../app/PublicServiceType/PublicServiceType';
import { Settings } from '../app/Settings/Settings';
import { Case } from '../models/Case';
import { Message as MessageModel } from '../models/Message';
import { Participation as ParticipationModel } from '../models/Participation';
import { Postbox as PostboxModel } from '../models/Postbox';
import { TabPage } from '../shared/TabPage';

export enum RouteKey {
  CASE,
  CASES,
  HOME,
  MESSAGE,
  MESSAGES,
  PARTICIPATION,
  PARTICIPATIONS,
  POSTBOX,
  POSTBOX_SETTINGS,
  POSTBOXES,
  PUBLIC_SERVICE_TYPE,
  SETTINGS
}

export interface Route {
  disabled?: boolean;
  element: ReactElement | null;
  path: string;
}

export const routes: {
  [key in RouteKey]: Route;
} = {
  [RouteKey.CASE]: {
    element: (
      <TabPage>
        <Cases />
      </TabPage>
    ),
    path: 'postboxes/:postboxId/cases/:caseId'
  },
  [RouteKey.CASES]: {
    disabled: true,
    element: <Cases />,
    path: 'postboxes/:postboxId/cases'
  },
  [RouteKey.HOME]: {
    element: <Navigate to="/postboxes" />,
    path: '/'
  },
  [RouteKey.MESSAGE]: {
    element: (
      <TabPage>
        <Message />
      </TabPage>
    ),
    path: 'postboxes/:postboxId/cases/:caseId/participations/:participationId/messages/:messageId'
  },
  [RouteKey.MESSAGES]: {
    disabled: true,
    element: null,
    path: 'postboxes/:postboxId/cases/:caseId/participations/:participationId/messages'
  },
  [RouteKey.PARTICIPATION]: {
    element: (
      <TabPage>
        <Participation />
      </TabPage>
    ),
    path: 'postboxes/:postboxId/cases/:caseId/participations/:participationId'
  },
  [RouteKey.PARTICIPATIONS]: {
    disabled: true,
    element: null,
    path: 'postboxes/:postboxId/cases/:caseId/participations'
  },
  [RouteKey.POSTBOX]: {
    element: <Postbox />,
    path: 'postboxes/:postboxId'
  },
  [RouteKey.POSTBOX_SETTINGS]: {
    element: <PostboxSettings />,
    path: 'postboxes/:postboxId/settings'
  },
  [RouteKey.POSTBOXES]: {
    element: <Postboxes />,
    path: 'postboxes'
  },
  [RouteKey.PUBLIC_SERVICE_TYPE]: {
    element: <PublicServiceType />,
    path: 'public-service-types/:publicServiceTypeId'
  },
  [RouteKey.SETTINGS]: {
    element: <Settings />,
    path: 'settings'
  }
};

export const routeSegmentLabels: { [segment: string]: string } = {
  cases: 'Fälle',
  messages: 'Nachrichten',
  participations: 'Mitwirkungen',
  postboxes: 'Postfächer',
  'public-service-types': 'Verwaltungsleistungen',
  settings: 'Einstellungen'
};

export function getCasePath(caseData: Case, currentPostboxId?: string) {
  return currentPostboxId
    ? `/postboxes/${currentPostboxId}/cases/${caseData.id}?parentPostboxId=${caseData.postboxId}`
    : `/postboxes/${caseData.postboxId}/cases/${caseData.id}`;
}

export function getMessagePath(
  message: MessageModel,
  currentPostboxId?: string
) {
  return currentPostboxId
    ? `/postboxes/${currentPostboxId}/cases/${message.caseId}/participations/${message.participationId}/messages/${message.id}?parentPostboxId=${message.postboxId}`
    : `/postboxes/${message.postboxId}/cases/${message.caseId}/participations/${message.participationId}/messages/${message.id}`;
}

export function getParticipationPath(
  participation: ParticipationModel,
  currentPostboxId?: string
) {
  return currentPostboxId
    ? `/postboxes/${currentPostboxId}/cases/${participation.caseId}/participations/${participation.id}?parentPostboxId=${participation.postboxId}`
    : `/postboxes/${participation.postboxId}/cases/${participation.caseId}/participations/${participation.id}`;
}

export function getPostboxPath(postbox: PostboxModel) {
  return `/postboxes/${postbox.id}`;
}
