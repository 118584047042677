import {
  FjdButton,
  FjdCard,
  FjdFlexSpacer,
  FjdFormControl,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import Joi from 'joi';
import { useCallback, useEffect } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { ExternalDocumentReference } from '../../../../models/Document';

interface ExternalDocumentFormProps {
  onAdd?: (value: FormControls) => void;
}

export type FormControls = ExternalDocumentReference;

const defaultValues: FormControls = {
  accessMode: '',
  uri: '',
  type: 'EXTERNAL'
};

export function ExternalDocumentForm({ onAdd }: ExternalDocumentFormProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    reset
  } = useForm<FormControls>({
    defaultValues,
    resolver: joiResolver(
      Joi.object({
        accessMode: Joi.string().required().allow(''),
        type: Joi.string().required().valid('EXTERNAL'),
        uri: Joi.string().uri().required()
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onAddExternalDocument = useCallback(
    (document: ExternalDocumentReference) => {
      if (typeof onAdd === 'function') {
        onAdd(document);
      }
    },
    [onAdd]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <FjdCard elevated solid style={{ minWidth: '20rem' }}>
      <FjdStack spacing="l">
        <FjdFormControl
          inputId={'uri'}
          label={t('Dokument-URL')}
          validationMessage={errors?.uri?.message}
        >
          <Controller
            control={control}
            name={'uri'}
            render={({ field }) => {
              return (
                <FjdTextInput
                  autoFocus
                  id={'uri'}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  placeholder="https://"
                  value={field.value}
                />
              );
            }}
          />
        </FjdFormControl>

        <FjdStack orientation="horizontal" spacing="s">
          <FjdFlexSpacer />
          <FjdButton
            label={t('Einfügen')}
            onClick={handleSubmit(onAddExternalDocument)}
            size="s"
          />
        </FjdStack>
      </FjdStack>
    </FjdCard>
  );
}
