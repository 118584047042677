import {
  FjdFormControl,
  FjdModal,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useCallback, useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { joiResolver } from '@hookform/resolvers/joi';
import { useJoi } from '../../../../hooks/useJoi';
import { usePostbox } from '../../../../hooks/usePostbox';
import {
  Postbox,
  UpdatePostboxRequest,
  UpdatePostboxRequestSchema
} from '../../../../models/Postbox';
import { submitForm } from '../../../../utils/form';

interface UpdatePostboxDetailsModalProps {
  onClose: () => void;
  postboxToUpdate: Postbox;
}

type FormControls = UpdatePostboxRequest;

export function UpdatePostboxDetailsModal({
  onClose,
  postboxToUpdate
}: UpdatePostboxDetailsModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement>(null);

  const { loading, updatePostbox } = usePostbox();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register
  } = useForm<FormControls>({
    defaultValues: {
      name: postboxToUpdate.name,
      note: postboxToUpdate.note,
      notifications: [...postboxToUpdate.notifications],
      owner: { ...postboxToUpdate.owner }
    },
    resolver: joiResolver(
      UpdatePostboxRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onUpdate = useCallback(
    async (data: FormControls) => {
      await updatePostbox(postboxToUpdate.id, data);
      onClose();
    },
    [onClose, postboxToUpdate.id, updatePostbox]
  );

  useEffect(() => {
    register('notifications', { value: [...postboxToUpdate.notifications] });
  }, [postboxToUpdate.notifications, register]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Postfach-Details bearbeiten')}
      id="update-postbox-details-modal"
      loading={loading}
      onClose={() => !loading && onClose()}
      onPrimaryButtonClick={() => submitForm(form.current)}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Änderungen speichern')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <form onSubmit={handleSubmit(onUpdate)} ref={form}>
        <FjdStack spacing="xl">
          <FjdFormControl
            inputId="name"
            label={t('Postfachname')}
            validationMessage={errors.name?.message}
          >
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="name"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId="note"
            label={t('Bemerkung zum Postfach')}
            optional
            validationMessage={errors.note?.message}
          >
            <Controller
              name="note"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="note"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    rows={3}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdStack>

        <input type="submit" hidden />
      </form>
    </FjdModal>
  );
}
