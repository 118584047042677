import { formatISO } from 'date-fns';
import {
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdModal,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { useMessage } from '../../../hooks/useMessage';
import { useParentPostboxId } from '../../../hooks/useParentPostboxId';
import { useParticipation } from '../../../hooks/useParticipation';
import { Case } from '../../../models/Case';
import { CreateInvolvedPartyRequest } from '../../../models/InvolvedParty';
import {
  CreateMessageRequest,
  CreateMessageRequestSchema,
  MessageType
} from '../../../models/Message';
import {
  CreateParticipationRequest,
  CreateParticipationRequestSchema,
  Participation,
  ParticipationObligation
} from '../../../models/Participation';
import { submitForm } from '../../../utils/form';
import { CreateParticipationFormStep1 } from './CreateParticipationFormStep1/CreateParticipationFormStep1';
import { CreateParticipationFormStep2 } from './CreateParticipationFormStep2/CreateParticipationFormStep2';
import { CreateParticipationFormStep3 } from './CreateParticipationFormStep3/CreateParticipationFormStep3';
import { CreateParticipationFormStep4 } from './CreateParticipationFormStep4/CreateParticipationFormStep4';

interface CreateParticipationModalProps {
  caseId: string;
  initialData?: Partial<CreateParticipationRequest>;
  onClose: () => void;
  postboxId: string;
}

type FormData = {
  involvedParty: Partial<CreateInvolvedPartyRequest>;
  message: Partial<CreateMessageRequest>;
  participation: Partial<CreateParticipationRequest>;
};

export function CreateParticipationModal({
  caseId,
  initialData = {},
  onClose,
  postboxId
}: CreateParticipationModalProps) {
  const parentPostboxId = useParentPostboxId();
  const { t } = useTranslation();

  const [activeForm, setActiveForm] = useState<HTMLFormElement>();

  const [createdParticipation, setCreatedParticipation] =
    useState<Participation>();

  const [formData, setFormData] = useState<FormData>({
    involvedParty: {},
    message: {
      fromLeader: true,
      sentOn: formatISO(new Date()),
      type: MessageType.START
    },
    participation: {
      caseId,
      obligation: ParticipationObligation.MANDATORY,
      ...initialData
    }
  });

  const [primaryButtonDisabled, setPrimaryButtonDisabled] = useState(false);
  const [step, setStep] = useState<number>(0);
  const [uploading, setUploading] = useState(false);
  const [width, setWidth] = useState<'wide' | 'wider'>('wide');

  const { createParticipation } = useParticipation(postboxId);
  const { createMessage } = useMessage(postboxId);

  const [loading, setLoading] = useState(false);

  const { data: caseData } = useSWR<Case>(
    `/postboxes/${parentPostboxId}/cases/${caseId}`
  );

  const heading = useMemo(() => {
    return step === 3
      ? t('Mitwirkung erfolgreich ausgelöst')
      : t('Neue Mitwirkung auslösen');
  }, [step, t]);

  const primaryButtonLabel = useMemo(() => {
    return step === 0 || step === 1
      ? t('Weiter')
      : step === 2
      ? t('Mitwirkung jetzt auslösen')
      : undefined;
  }, [step, t]);

  const secondaryButtonLabel = useMemo(() => {
    return step === 0
      ? t('Schließen')
      : step === 1 || step === 2
      ? t('Zurück')
      : undefined;
  }, [step, t]);

  const onCreate = useCallback(async () => {
    if (
      !CreateParticipationRequestSchema.validate(formData.participation)
        .error &&
      !CreateMessageRequestSchema.validate(formData.message).error
    ) {
      setLoading(true);

      const newParticipation = await createParticipation(
        {
          ...(formData.participation as CreateParticipationRequest),
          deadline: formData.participation.deadline
            ? formatISO(new Date(formData.participation.deadline as string))
            : undefined
        },
        formData.involvedParty as CreateInvolvedPartyRequest,
        true
      );

      if (newParticipation !== undefined) {
        setCreatedParticipation(newParticipation);

        await createMessage(
          newParticipation.id,
          formData.message as CreateMessageRequest,
          true
        );

        setStep(3);
        setLoading(false);
      }
    }
  }, [
    createMessage,
    createParticipation,
    formData.involvedParty,
    formData.message,
    formData.participation
  ]);

  const reset = useCallback(() => {
    setStep(0);
    setActiveForm(undefined);

    setFormData({
      involvedParty: {},
      message: {
        fromLeader: true,
        sentOn: formatISO(new Date()),
        type: MessageType.START
      },
      participation: {
        caseId,
        obligation: ParticipationObligation.MANDATORY
      }
    });
  }, [caseId]);

  return caseData ? (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={heading}
      id="create-participation-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => submitForm(activeForm)}
      onSecondaryButtonClick={() => {
        if (!uploading)
          if (step === 0) {
            onClose();
          } else {
            setStep((step) => step - 1);
          }
      }}
      open
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonDisabled={uploading}
      secondaryButtonLabel={secondaryButtonLabel}
      width={width}
    >
      <FjdStack spacing="xl">
        {!loading && step < 3 && (
          <FjdContentTabBar>
            <FjdContentTabBarItem
              active={step === 0}
              label={t('1. Mitwirkende Stelle auswählen')}
              onClick={() => !uploading && setStep(0)}
            />
            <FjdContentTabBarItem
              active={step === 1}
              label={t('2. Details eingeben')}
              onClick={() => {
                if (uploading) {
                  return;
                }

                if (step === 0) {
                  submitForm(activeForm);
                } else if (step > 1) {
                  setStep(1);
                }
              }}
            />
            <FjdContentTabBarItem
              active={step === 2}
              label={t('3. Nachricht schreiben')}
              onClick={() => {
                if (uploading) {
                  return;
                }

                if (step === 1) {
                  submitForm(activeForm);
                }
              }}
            />
          </FjdContentTabBar>
        )}

        {step === 0 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <CreateParticipationFormStep1
              caseData={caseData}
              initialData={formData.involvedParty}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  involvedParty: { ...formData.involvedParty, ...data }
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={() => setStep(1)}
              publicServiceTypeId={caseData?.publicServiceTypeId}
            />
          </Suspense>
        )}

        {step === 1 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <CreateParticipationFormStep2
              initialData={formData.participation}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  participation: { ...formData.participation, ...data }
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={() => setStep(2)}
            />
          </Suspense>
        )}

        {step === 2 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <CreateParticipationFormStep3
              caseId={caseId}
              initialData={formData.message}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  message: { ...formData.message, ...data }
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={onCreate}
              onToggleDocs={(showDocs) =>
                showDocs ? setWidth('wider') : setWidth('wide')
              }
              onUploading={(uploading) => {
                setUploading(uploading);
                setPrimaryButtonDisabled(uploading);
              }}
              postboxId={postboxId}
            />
          </Suspense>
        )}

        {step === 3 && createdParticipation && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <CreateParticipationFormStep4
              onClose={onClose}
              onReset={reset}
              participation={createdParticipation}
            />
          </Suspense>
        )}
      </FjdStack>
    </FjdModal>
  ) : null;
}
