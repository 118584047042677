import { FjdBaseLayout } from 'fjd-react-components';
import { useState } from 'react';
import { Outlet, useMatch } from 'react-router-dom';

import { Footer } from '../../../shared/Footer';
import { Header } from '../../../shared/Header';
import { LanguageSwitch } from '../../../shared/LanguageSwitch';
import { MainNavigation } from '../../../shared/MainNavigation';
import { RouteKey, routes } from '../../../utils/router';
import { UpsertCaseModal } from '../../Case/UpsertCaseModal/UpsertCaseModal';
import { CasesSidebar } from '../CasesSidebar/CasesSidebar';
import { CasesTabBar } from '../CasesTabBar/CasesTabBar';

export function CasesLayout() {
  const [creatingCase, setCreatingCase] = useState(false);

  const postboxSubPageMatch = useMatch(`${routes[RouteKey.POSTBOX].path}/*`);

  const postboxId = postboxSubPageMatch?.params.postboxId;

  return (
    <FjdBaseLayout
      footer={<Footer />}
      footerToolsLeft={<LanguageSwitch />}
      header={<Header />}
      mainNavigation={
        <MainNavigation onCreateCaseClick={() => setCreatingCase(true)} />
      }
      leftSidebar={<CasesSidebar />}
      responsiveLeftSidebar={false}
      secondaryNavigation={
        postboxId ? <CasesTabBar postboxId={postboxId} /> : null
      }
      wideContent
    >
      <Outlet />

      {creatingCase && postboxId && (
        <UpsertCaseModal
          onClose={() => setCreatingCase(false)}
          postboxId={postboxId}
        />
      )}
    </FjdBaseLayout>
  );
}
