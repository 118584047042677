import {
  FjdCard,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay,
  FjdMarkdown
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import useSWR from 'swr';

import { PublicServiceType as PublicServiceTypeModel } from '../../../models/PublicServiceType';

interface PublicServiceTypeDetailsProps {
  publicServiceTypeId: string;
}

export function PublicServiceTypeDetails({
  publicServiceTypeId
}: PublicServiceTypeDetailsProps) {
  const { t } = useTranslation();

  const { data: publicServiceType, isValidating } =
    useSWR<PublicServiceTypeModel>(
      `/public-service-types/${publicServiceTypeId}`
    );

  return publicServiceType ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdCard>
        <FjdDescriptionList>
          <FjdDescriptionListItem
            description={publicServiceType.id}
            term={t('ID')}
          />
          <FjdDescriptionListItem
            description={publicServiceType.leikaIds?.join(', ')}
            term={t('LeiKa IDs')}
          />
          <FjdDescriptionListItem
            description={publicServiceType.name}
            term={t('Name')}
          />
          <FjdDescriptionListItem
            description={
              <FjdMarkdown
                allowHTML
                source={sanitizeHtml(publicServiceType.description || '')}
                useParagraphs
              />
            }
            term={t('Beschreibung')}
          />
          <FjdDescriptionListItem
            description={publicServiceType.legalBasis
              ?.map((legalBasis) => `- [${legalBasis.name}](${legalBasis.url})`)
              .join('\n')}
            term={t('Rechtsgrundlage')}
            useMarkdown
          />
        </FjdDescriptionList>
      </FjdCard>
    </FjdLoadingOverlay>
  ) : null;
}
