import { Attributes } from './Attributes';

export interface User {
  admin?: boolean;
  createdTimestamp: number;
  userName?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  userId: string;
  attributes?: Attributes;
}

export function instanceOfUserResponse(object?: any): object is User {
  if (!object || typeof object !== 'object') {
    return false;
  }

  return 'userId' in object && 'email' in object && 'admin' in object;
}
