import { FjdBaseLayout } from 'fjd-react-components';
import { Outlet } from 'react-router-dom';

import { Footer } from '../../../shared/Footer';
import { Header } from '../../../shared/Header';
import { LanguageSwitch } from '../../../shared/LanguageSwitch';

export function SettingsLayout() {
  return (
    <FjdBaseLayout
      footer={<Footer />}
      footerToolsLeft={<LanguageSwitch />}
      header={<Header />}
      wideContent
    >
      <Outlet />
    </FjdBaseLayout>
  );
}
