import { format } from 'date-fns';
import {
  FjdAlert,
  FjdButton,
  FjdCard,
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdFlexSpacer,
  FjdFlyout,
  FjdHeading,
  FjdMenu,
  FjdMenuItem,
  FjdModal,
  FjdSection,
  FjdSectionHeader,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { useElementOperations } from '../../hooks/useElementOperations';
import { useParentPostboxId } from '../../hooks/useParentPostboxId';
import { Message, MessageType } from '../../models/Message';
import {
  CreateParticipationRequest,
  Participation as ParticipationModel
} from '../../models/Participation';
import { ContactPersonDetails } from '../../shared/ContactPersonDetails';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { defaultDateFormatOptions } from '../../utils/string';
import { CreateParticipationModal } from '../Case/CreateParticipationModal/CreateParticipationModal';
import { CreateMessageModal } from './CreateMessageModal/CreateMessageModal';
import { InvolvedPartyDetails } from './InvolvedPartyDetails/InvolvedPartyDetails';
import { MessagesTable } from './MessagesTable/MessagesTable';
import { ParticipationDetails } from './ParticipationDetails/ParticipationDetails';
import { UpdateParticipationModal } from './UpdateParticipationModal/UpdateParticipationModal';
import { UpdateParticipationVBModal } from './UpdateParticipationVBModal/UpdateParticipationVBModal';

export function Participation() {
  const { caseId, participationId, postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();
  const parentPostboxId = useParentPostboxId();

  const [activeContentTab, setActiveContentTab] = useState<
    'messages' | 'details'
  >('messages');

  const [closing, setClosing] = useState(false);
  const [creatingMessage, setCreatingMessage] = useState(false);
  const [duplicating, setDuplicating] = useState<CreateParticipationRequest>();
  const [updating, setUpdating] = useState(false);
  const [updatingVB, setUpdatingVB] = useState(false);

  const { data } = useSWR<ParticipationModel>(
    `/postboxes/${parentPostboxId}/participations/${participationId}`
  );

  const { data: messages } = useSWR<Message[]>(
    `/postboxes/${parentPostboxId}/participations/${participationId}/messages`
  );

  const allowedOperations = useElementOperations(data);

  const closingMessage = useMemo(
    () => messages?.find((message) => message.type === MessageType.CLOSE),
    [messages]
  );

  return data ? (
    <>
      <Helmet>
        <title>
          {t('Mitwirkung: {{participationTitle}}', {
            participationTitle: data.title
          })}{' '}
          - {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack spacing="5xl">
        <FjdStack spacing="xl">
          <FjdSectionHeader
            align="start"
            heading={<FjdHeading level={1} text={data.title} />}
            tools={
              <FjdStack orientation="horizontal">
                <FjdFlexSpacer />
                {(allowedOperations.DUPLICATE_PARTICIPATION ||
                  allowedOperations.CLOSE_PARTICIPATION) && (
                  <FjdFlyout
                    flyout={
                      <FjdMenu>
                        {allowedOperations.DUPLICATE_PARTICIPATION && (
                          <FjdMenuItem
                            appearance="light"
                            icon="copy"
                            label={t('Mitwirkung duplizieren')}
                            onClick={() =>
                              setDuplicating({
                                caseId: data.caseId,
                                deadline: data.deadline
                                  ? format(
                                      new Date(data.deadline),
                                      'yyyy-MM-dd'
                                    )
                                  : undefined,
                                description: data.description,
                                legalBasis: data.legalBasis,
                                obligation: data.obligation,
                                task: data.task,
                                title: data.title
                              })
                            }
                          />
                        )}
                        {allowedOperations.CLOSE_PARTICIPATION &&
                          !closingMessage && (
                            <FjdMenuItem
                              appearance="light"
                              icon="close-outline"
                              label={t('Mitwirkung abschließen')}
                              onClick={() => setClosing(true)}
                            />
                          )}
                      </FjdMenu>
                    }
                    placement="bottom-end"
                  >
                    <FjdButton
                      appearance="outline"
                      hideLabel
                      iconRight="overflow-menu-vertical"
                      label={t('Mehr')}
                      size="s"
                    />
                  </FjdFlyout>
                )}

                {allowedOperations.CREATE_MESSAGE && !closingMessage && (
                  <FjdButton
                    iconLeft="add-outline"
                    label={t('Neue Nachricht schreiben')}
                    onClick={() => setCreatingMessage(true)}
                    size="s"
                  />
                )}
              </FjdStack>
            }
          />

          {closingMessage && (
            <FjdAlert
              appearance="muted"
              closable={false}
              intent="info"
              title={t(
                'Diese Mitwirkung wurde am {{closingDate}} geschlossen.',
                {
                  closingDate: new Date(closingMessage.sentOn).toLocaleString(
                    [],
                    defaultDateFormatOptions
                  )
                }
              )}
            />
          )}
        </FjdStack>

        <FjdSection>
          <FjdStack spacing="xl">
            <FjdContentTabBar>
              <FjdContentTabBarItem
                active={activeContentTab === 'messages'}
                label={t('Nachrichtenübersicht')}
                onClick={() => setActiveContentTab('messages')}
              />
              <FjdContentTabBarItem
                active={activeContentTab === 'details'}
                label={t('Mitwirkungsdetails')}
                onClick={() => setActiveContentTab('details')}
              />
            </FjdContentTabBar>

            {activeContentTab === 'messages' && (
              <FjdCard>
                <FjdStack spacing="xxl">
                  <FjdSectionHeader
                    align="center"
                    heading={
                      <FjdHeading
                        level={2}
                        text={t('Nachrichten zur Mitwirkung')}
                      />
                    }
                  />

                  <Suspense
                    fallback={
                      <LoadingSpinner label={t('Lade Nachrichten …')} />
                    }
                  >
                    <MessagesTable
                      participationId={participationId}
                      postboxId={parentPostboxId}
                    />
                  </Suspense>
                </FjdStack>
              </FjdCard>
            )}

            {activeContentTab === 'details' && (
              <FjdCard>
                <FjdStack spacing="5xl">
                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t('Informationen zur Mitwirkung')}
                        />
                      }
                      tools={
                        <FjdStack orientation="horizontal">
                          <FjdFlexSpacer />
                          {allowedOperations.EDIT_PARTICIPATION_DETAILS &&
                            !closingMessage && (
                              <FjdButton
                                appearance="primary-link"
                                iconLeft="edit"
                                label={t('Informationen bearbeiten')}
                                onClick={() => setUpdating(true)}
                                size="s"
                              />
                            )}
                          {allowedOperations.EDIT_PARTICIPATION_VB_DETAILS &&
                            !allowedOperations.EDIT_PARTICIPATION_DETAILS &&
                            !closingMessage && (
                              <FjdButton
                                appearance="primary-link"
                                iconLeft="edit"
                                label={t('Informationen bearbeiten')}
                                onClick={() => setUpdatingVB(true)}
                                size="s"
                              />
                            )}
                        </FjdStack>
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner
                          label={t('Lade Mitwirkungsdetails …')}
                        />
                      }
                    >
                      <ParticipationDetails
                        participationId={participationId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>

                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t('Informationen zur mitwirkenden Partei')}
                        />
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner label={t('Lade Informationen …')} />
                      }
                    >
                      <InvolvedPartyDetails
                        participationId={participationId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>

                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t('Informationen zur Kontaktperson')}
                        />
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner label={t('Lade Informationen …')} />
                      }
                    >
                      <ContactPersonDetails
                        caseId={caseId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>
                </FjdStack>
              </FjdCard>
            )}
          </FjdStack>
        </FjdSection>
      </FjdStack>

      {(creatingMessage || closing) && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              closeOnBackdropClick={false}
              heading={t('Neue Nachricht schreiben')}
              open
            >
              <FjdSpinner size="s" />
            </FjdModal>
          }
        >
          <CreateMessageModal
            caseId={caseId}
            closingMessage={closing}
            onClose={() => {
              setCreatingMessage(false);
              setClosing(false);
            }}
            participationId={participationId}
            postboxId={parentPostboxId}
          />
        </Suspense>
      )}

      {duplicating && (
        <CreateParticipationModal
          caseId={caseId}
          initialData={duplicating}
          onClose={() => setDuplicating(undefined)}
          postboxId={currentPostboxId}
        />
      )}

      {updating && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              closeOnBackdropClick={false}
              heading={t('Mitwirkung bearbeiten')}
              open
            >
              <FjdSpinner size="s" />
            </FjdModal>
          }
        >
          <UpdateParticipationModal
            caseId={caseId}
            onClose={() => setUpdating(false)}
            participationId={participationId}
            postboxId={parentPostboxId}
          />
        </Suspense>
      )}

      {updatingVB && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              closeOnBackdropClick={false}
              heading={t('Mitwirkung bearbeiten')}
              open
            >
              <FjdSpinner size="s" />
            </FjdModal>
          }
        >
          <UpdateParticipationVBModal
            onClose={() => setUpdatingVB(false)}
            participationId={participationId}
            postboxId={currentPostboxId}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
