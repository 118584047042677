import FileSaver from 'file-saver';
import { useCallback, useState } from 'react';

import { Case, instanceOfCase } from '../models/Case';
import { instanceOfMessage, Message } from '../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../models/Participation';
import { Postbox } from '../models/Postbox';
import { useApi } from './useApi';

export function useProtocol() {
  const { makeRequest } = useApi();

  const [loading, setLoading] = useState(false);

  const downloadProtocol = useCallback(
    async (element: Case | Message | Participation | Postbox) => {
      setLoading(true);

      const key = instanceOfCase(element)
        ? 'cases'
        : instanceOfMessage(element)
        ? 'messages'
        : instanceOfParticipation(element)
        ? 'participations'
        : 'postboxes';

      try {
        const blob = await makeRequest<Blob>(
          `/logging-download/${key}/${element.id}?page=0&size=9999999`,
          'GET',
          undefined,
          { responseType: 'blob' }
        );

        setLoading(false);
        FileSaver.saveAs(blob, `protocol_${key}_${element.id}.xlsx`);
      } catch (e) {
        setLoading(false);
      }
    },
    [makeRequest]
  );

  return { downloadProtocol, loading };
}
