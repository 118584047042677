import {
  FjdModal,
  FjdStack,
  FjdTruncatedText,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { Case, instanceOfCase } from '../../../../models/Case';
import { LogEntry } from '../../../../models/LogEntry';
import { instanceOfMessage, Message } from '../../../../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../../../../models/Participation';
import { Postbox } from '../../../../models/Postbox';
import { defaultDateFormatOptions } from '../../../../utils/string';

interface ProtocolModalProps {
  element: Case | Message | Participation | Postbox;
  onClose: () => void;
}

export function ProtocolModal({ element, onClose }: ProtocolModalProps) {
  const { t } = useTranslation();

  const key = instanceOfCase(element)
    ? 'cases'
    : instanceOfMessage(element)
    ? 'messages'
    : instanceOfParticipation(element)
    ? 'participations'
    : 'postboxes';

  const { data: logs, pagination } = usePaginatedSwr<LogEntry>({
    key: `/logging/${key}/${element.id}`,
    pageSize: 10,
    suspense: false
  });

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      heading={t('Protokolleinträge')}
      id="protocol-modal"
      onClose={onClose}
      open
      width="wide"
    >
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol maxWidth="8rem">
                {t('Zeit')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="10rem">
                {t('Typ')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="12rem">
                {t('Status')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Info')}</FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {logs.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Einträge gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {logs.map((logEntry) => (
            <FjdVirtualizedTableRow key={logEntry.id}>
              <FjdVirtualizedTableCell maxWidth="8rem">
                {new Date(logEntry.createdAt).toLocaleString(
                  [],
                  defaultDateFormatOptions
                )}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell maxWidth="10rem">
                {logEntry.type}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell maxWidth="12rem">
                {logEntry.status}
              </FjdVirtualizedTableCell>
              <FjdVirtualizedTableCell>
                <FjdTruncatedText text={logEntry.information || '-'} />
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          ))}
        </FjdVirtualizedTable>

        {pagination.PaginationComponent}
      </FjdStack>
    </FjdModal>
  );
}
