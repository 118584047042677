import {
  FjdButton,
  FjdCard,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdHeading,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { PersonType } from '../../../models/Person';
import { Postbox } from '../../../models/Postbox';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { RouterLink } from '../../../shared/RouterLink';
import { getPersonTypeLabel } from '../../../utils/postbox';
import { NotificationsByEscalationLevel } from '../PostboxNotifications/EscalationLevels/EscalationLevels';
import { UpdatePostboxDetailsModal } from './UpdatePostboxDetailsModal/UpdatePostboxDetailsModal';
import { UpdatePostboxOwnerModal } from './UpdatePostboxOwnerModal/UpdatePostboxOwnerModal';

interface PostboxDetailsProps {
  canUpdatePostbox: boolean;
}

export function PostboxDetails({ canUpdatePostbox }: PostboxDetailsProps) {
  const { t } = useTranslation();

  const [updatingPostboxDetails, setUpdatingPostboxDetails] =
    useState<Postbox>();

  const { postboxId: currentPostboxId } = useParams();

  const [updatingPostboxOwner, setUpdatingPostboxOwner] = useState<Postbox>();

  const { data: postbox } = useSWR<Postbox>(`/postboxes/${currentPostboxId}`);

  const escalationLevels = useMemo<NotificationsByEscalationLevel>(
    () =>
      postbox
        ? postbox.notifications.reduce(
            (levels, notification) => ({
              ...levels,
              [notification.escalationLevel]: [
                ...(levels[notification.escalationLevel] || []),
                notification
              ]
            }),
            {} as NotificationsByEscalationLevel
          )
        : {},
    [postbox]
  );

  return (
    <>
      <Helmet>
        <title>
          {t('Postfach-Details')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense
        fallback={<LoadingSpinner label={t('Lade Postfach-Details …')} />}
      >
        <FjdStack spacing="5xl">
          <FjdStack spacing="l">
            <FjdSectionHeader
              align="end"
              heading={<FjdHeading level={2} text={t('Postfach')} />}
              tools={
                <FjdButton
                  appearance="primary-link"
                  hideLabel
                  disabled={!canUpdatePostbox}
                  iconLeft="edit"
                  label={t('Postfach-Details bearbeiten')}
                  onClick={() => setUpdatingPostboxDetails(postbox)}
                  size="s"
                />
              }
            />

            <FjdCard>
              <FjdDescriptionList>
                <FjdDescriptionListItem
                  description={postbox?.name}
                  term={t('Postfachname')}
                  useMarkdown={false}
                />
                <FjdDescriptionListItem
                  description={postbox?.note}
                  term={t('Bemerkung zum Postfach')}
                />
              </FjdDescriptionList>
            </FjdCard>
          </FjdStack>

          <FjdStack spacing="l">
            <FjdSectionHeader
              align="end"
              heading={<FjdHeading level={2} text={t('Postfachinhaber')} />}
              tools={
                <FjdButton
                  appearance="primary-link"
                  hideLabel
                  disabled={!canUpdatePostbox}
                  iconLeft="edit"
                  label={t('Postfachinhaber bearbeiten')}
                  onClick={() => setUpdatingPostboxOwner(postbox)}
                  size="s"
                />
              }
            />

            <FjdCard>
              <FjdDescriptionList>
                <FjdDescriptionListItem
                  description={
                    postbox?.owner.type
                      ? getPersonTypeLabel(postbox.owner.type, t)
                      : undefined
                  }
                  term={t('Rechtsperson')}
                />
                {(postbox?.owner.type === PersonType.PUBLIC_ORGANISATION ||
                  postbox?.owner.type === PersonType.PRIVATE_LEGAL_ENTITY) && (
                  <>
                    {postbox?.owner.type === PersonType.PUBLIC_ORGANISATION && (
                      <FjdDescriptionListItem
                        description={
                          postbox?.owner.typeOfPublicOrganization || '-'
                        }
                        term={t('Art der öffentlichen Stelle')}
                        useMarkdown={false}
                      />
                    )}
                    <FjdDescriptionListItem
                      description={postbox?.owner.organizationName}
                      term={t('Organisation')}
                      useMarkdown={false}
                    />
                    <FjdDescriptionListItem
                      description={postbox?.owner.organisationalUnit || '-'}
                      term={t('Organisationseinheit')}
                      useMarkdown={false}
                    />
                  </>
                )}

                {postbox?.owner.type === PersonType.NATURAL_PERSON && (
                  <>
                    <FjdDescriptionListItem
                      description={postbox?.owner.name.doctoralDegrees || '-'}
                      term={t('Titel')}
                      useMarkdown={false}
                    />
                    <FjdDescriptionListItem
                      description={postbox?.owner.name.formOfAddress || '-'}
                      term={t('Anrede')}
                      useMarkdown={false}
                    />
                    <FjdDescriptionListItem
                      description={postbox?.owner.name.lastName}
                      term={t('Name')}
                      useMarkdown={false}
                    />
                    <FjdDescriptionListItem
                      description={postbox?.owner.name.firstName}
                      term={t('Vorname')}
                      useMarkdown={false}
                    />
                  </>
                )}
              </FjdDescriptionList>
            </FjdCard>
          </FjdStack>

          <FjdStack spacing="l">
            <FjdSectionHeader
              align="end"
              heading={<FjdHeading level={2} text={t('Benachrichtigungen')} />}
              tools={
                !canUpdatePostbox ? (
                  <FjdButton
                    appearance="primary-link"
                    hideLabel
                    iconLeft="edit"
                    disabled={!canUpdatePostbox}
                    label={t('Benachrichtigungen bearbeiten')}
                    size="s"
                  />
                ) : (
                  <RouterLink
                    component={({ href, onClick }) => (
                      <FjdButton
                        appearance="primary-link"
                        hideLabel
                        href={href}
                        iconLeft="edit"
                        label={t('Benachrichtigungen bearbeiten')}
                        onClick={onClick}
                        size="s"
                      />
                    )}
                    to="?tab=notifications"
                  />
                )
              }
            />

            <FjdCard>
              <FjdDescriptionList>
                {Object.keys(escalationLevels).map((level) => (
                  <FjdDescriptionListItem
                    allowHTML
                    description={escalationLevels[+level]
                      .map((notification) => notification.email)
                      .join('<br/>')}
                    key={level}
                    term={t('Eskalationsstufe {{level}}', { level })}
                  />
                ))}
              </FjdDescriptionList>
            </FjdCard>
          </FjdStack>
        </FjdStack>
      </Suspense>

      {updatingPostboxDetails && canUpdatePostbox && (
        <UpdatePostboxDetailsModal
          onClose={() => setUpdatingPostboxDetails(undefined)}
          postboxToUpdate={updatingPostboxDetails}
        />
      )}

      {updatingPostboxOwner && canUpdatePostbox && (
        <UpdatePostboxOwnerModal
          onClose={() => setUpdatingPostboxOwner(undefined)}
          postboxToUpdate={updatingPostboxOwner}
        />
      )}
    </>
  );
}

export { PostboxDetails as default } from './PostboxDetails';
