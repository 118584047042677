import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { pdf } from '@react-pdf/renderer';

import { ExportPdf } from '../app/Case/ExportPdf/ExportPdf';
import { Case } from '../models/Case';
import { InvolvedParty } from '../models/InvolvedParty';
import { LeadingParty } from '../models/LeadingParty';
import { Message } from '../models/Message';
import { Participation } from '../models/Participation';
import { PdfExportData } from '../models/PdfExportData';
import { Postbox } from '../models/Postbox';
import { PublicServiceType } from '../models/PublicServiceType';
import useAlerts from './useAlerts';
import { useApi } from './useApi';
import { useProfile } from './useProfile';

export interface UsePdfExportConfig {
  caseId: string;
  currentPostboxId: string;
  onOpen?: () => void;
  onError?: () => void;
  parentPostboxId: string;
}

export function usePdfExport({
  caseId,
  currentPostboxId,
  onOpen,
  onError,
  parentPostboxId
}: UsePdfExportConfig) {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const { profile } = useProfile();
  const { t } = useTranslation();

  const [data, setData] = useState<PdfExportData>();
  const [fetching, setFetching] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const caseData = await makeRequest<Case>(
        `/postboxes/${parentPostboxId}/cases/${caseId}`,
        'GET'
      );

      const publicServiceType = await makeRequest<PublicServiceType>(
        `/public-service-types/${caseData.publicServiceTypeId}`,
        'GET'
      );

      const leadingParty = await makeRequest<LeadingParty>(
        `/postboxes/${parentPostboxId}/cases/${caseId}/leading-party`,
        'GET'
      );

      const leadingPartyPostbox = await makeRequest<Postbox>(
        `/postboxes/${leadingParty.postboxId}`,
        'GET'
      );

      const participations = (
        await makeRequest<{ items: Participation[] }>(
          `/postboxes/${currentPostboxId}/participation-results?caseId=${caseId}&page=0&size=999999`,
          'GET'
        )
      ).items;

      const involvedParties = await Promise.all(
        participations.map((participation) =>
          makeRequest<InvolvedParty>(
            `/postboxes/${currentPostboxId}/participations/${participation.id}/involved-party`,
            'GET'
          )
        )
      );

      const involvedPartyPostboxes = await Promise.all(
        involvedParties.map((involvedParty) =>
          makeRequest<Postbox>(`/postboxes/${involvedParty.postboxId}`, 'GET')
        )
      );

      const messages = await (
        await Promise.all(
          participations.map((participation) =>
            makeRequest<Message[]>(
              `/postboxes/${parentPostboxId}/participations/${participation.id}/messages`,
              'GET'
            )
          )
        )
      ).map((messages) =>
        messages.sort(
          (a, b) => new Date(a.sentOn).getTime() - new Date(b.sentOn).getTime()
        )
      );

      const data: PdfExportData = {
        ...caseData,
        leadingParty: {
          ...leadingParty,
          postbox: leadingPartyPostbox
        },
        participations: participations.map((participation, index) => ({
          ...participation,
          involvedParty: {
            ...involvedParties[index],
            postbox: involvedPartyPostboxes[index]
          },
          messages: messages[index]
        })),
        publicServiceType,
        profile
      };

      setData(data);
      setFetching(false);

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }, [caseId, currentPostboxId, makeRequest, parentPostboxId, profile]);

  const open = useCallback(async () => {
    if (data) {
      const blob = await pdf(<ExportPdf data={data} />).toBlob();

      window.open(window.URL.createObjectURL(blob), '_blank');

      if (typeof onOpen === 'function') {
        onOpen();
      }
    }
  }, [data, onOpen]);

  useEffect(() => {
    if (!fetching && !data) {
      setFetching(true);

      fetchData().catch(() => {
        alert(
          'error',
          t('Beim Erstellen der PDF ist leider ein Fehler aufgetreten.')
        );

        if (typeof onError === 'function') {
          onError();
        }
      });
    }
  }, [alert, data, fetchData, fetching, onError, t]);

  return {
    loading: fetching || !data,
    open
  };
}
