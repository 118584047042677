import { FjdBaseLayout } from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch } from 'react-router-dom';

import { Footer } from '../../../shared/Footer';
import { Header } from '../../../shared/Header';
import { LanguageSwitch } from '../../../shared/LanguageSwitch';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { MainNavigation } from '../../../shared/MainNavigation';
import { RouteKey, routes } from '../../../utils/router';
import { UpsertCaseModal } from '../../Case/UpsertCaseModal/UpsertCaseModal';

export function PostboxLayout() {
  const { t } = useTranslation();
  const postboxPageMatch = useMatch(routes[RouteKey.POSTBOX]);

  const [creatingCase, setCreatingCase] = useState(false);

  return (
    <FjdBaseLayout
      footer={<Footer />}
      footerToolsLeft={<LanguageSwitch />}
      fullWidthContent
      header={<Header />}
      mainNavigation={
        <MainNavigation onCreateCaseClick={() => setCreatingCase(true)} />
      }
      noContentPadding
    >
      <Suspense fallback={<LoadingSpinner label={t('Lade Postfach …')} />}>
        <Outlet />
      </Suspense>

      {creatingCase && postboxPageMatch && (
        <UpsertCaseModal
          onClose={() => setCreatingCase(false)}
          postboxId={postboxPageMatch.params.postboxId}
        />
      )}
    </FjdBaseLayout>
  );
}
