import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import {
  AddressLinesSchema,
  AddressSupplementSchema,
  AddressType,
  CitySchema,
  CountrySchema,
  HouseNumberSchema,
  HouseNumberSuffixSchema,
  InternationalAddress,
  NationalAddress,
  PostalCodeSchema,
  PostboxAddress,
  PostOfficeBoxSchema,
  StreetSchema
} from './Address';
import { OtherIdentifiers } from './OtherIdentifiers';
import { NaturalPersonNameSchema, PersonName } from './Person';
import { PhoneContact, PhoneContactSchema } from './PhoneContact';

export type ContactPerson<
  AddressType = InternationalAddress | NationalAddress | PostboxAddress
> = {
  emailAddress?: string;
  function?: string;
  id: string;
  identifiers: OtherIdentifiers[];
  name: PersonName;
  postboxId: string;
  physicalAddress?: AddressType;
  telephones: PhoneContact[];
};

export type CreateContactPersonRequest = Except<
  ContactPerson,
  'id' | 'postboxId'
>;

export type UpdateContactPersonRequest = CreateContactPersonRequest;

export const EmailAddressSchema: Schema = Joi.string()
  .email({ tlds: { allow: false } })
  .allow('');

export const FunctionSchema: Schema = Joi.string().optional().allow('');

export const PhysicalAddressSchema: Schema = Joi.object({
  addressSupplement: AddressSupplementSchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: Joi.forbidden()
  }),
  city: CitySchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: CitySchema.when('type', {
      is: AddressType.POSTBOX,
      otherwise: Joi.forbidden()
    })
  }),
  country: CountrySchema.when('type', {
    is: AddressType.INTERNATIONAL,
    otherwise: Joi.forbidden()
  }),
  houseNumber: HouseNumberSchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: Joi.forbidden()
  }),
  houseNumberSuffix: HouseNumberSuffixSchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: Joi.forbidden()
  }),
  lines: AddressLinesSchema.when('type', {
    is: AddressType.INTERNATIONAL,
    otherwise: Joi.forbidden()
  }),
  postalCode: PostalCodeSchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: PostalCodeSchema.when('type', {
      is: AddressType.POSTBOX,
      otherwise: Joi.forbidden()
    })
  }),
  postOfficeBox: PostOfficeBoxSchema.when('type', {
    is: AddressType.POSTBOX,
    otherwise: Joi.forbidden()
  }),
  street: StreetSchema.when('type', {
    is: AddressType.NATIONAL,
    otherwise: Joi.forbidden()
  }),
  type: Joi.string()
    .valid(...Object.values(AddressType))
    .optional()
    .allow('')
});

export const TelephonesSchema: Schema = Joi.array()
  .items(PhoneContactSchema)
  .required();

export const CreateContactPersonRequestSchema: Schema = Joi.object({
  emailAddress: EmailAddressSchema,
  function: FunctionSchema,
  identifiers: Joi.array()
    .items(
      Joi.object({
        id: Joi.string().required(),
        schemeAgencyId: Joi.string().optional().allow(''),
        schemeAgencyName: Joi.string().optional().allow(''),
        schemeDataURI: Joi.string().optional().allow(''),
        schemeId: Joi.string().optional().allow(''),
        schemeName: Joi.string().optional().allow(''),
        schemeURI: Joi.string().optional().allow(''),
        schemeVersionId: Joi.string().optional().allow('')
      }).required()
    )
    .required(),
  name: NaturalPersonNameSchema,
  physicalAddress: PhysicalAddressSchema,
  telephones: TelephonesSchema
});

export const UpdateContactPersonRequestSchema =
  CreateContactPersonRequestSchema;
