import {
  FjdAlert,
  FjdButton,
  FjdCard,
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdFlexSpacer,
  FjdFlyout,
  FjdHeading,
  FjdMenu,
  FjdMenuItem,
  FjdSection,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';

import { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Alert, Button } from '@efast_public/fjd-component-library';

import { useElementOperations } from '../../hooks/useElementOperations';
import { useParentPostboxId } from '../../hooks/useParentPostboxId';
import { Case as CaseModel, CaseStatus } from '../../models/Case';
import { LeadingParty } from '../../models/LeadingParty';
import { ContactPersonDetails } from '../../shared/ContactPersonDetails';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { CaseDetails } from './CaseDetails/CaseDetails';
import { CreateParticipationModal } from './CreateParticipationModal/CreateParticipationModal';
import { DeleteCaseModal } from './DeleteCaseModal/DeleteCaseModal';
import { DuplicateCaseModal } from './DuplicateCaseModal/DuplicateCaseModal';
import { ExportModal } from './ExportModal/ExportModal';
import { LeadingPartyDetails } from './LeadingPartyDetails/LeadingPartyDetails';
import { ParticipationsTable } from './ParticipationsTable/ParticipationsTable';
import { UpsertCaseModal } from './UpsertCaseModal/UpsertCaseModal';
import { CaseContentTab } from './Case.types';
import { CreateDataRoomModal } from './CreateDataRoomModal/CreateDataRoomModal';
import { useDataRoom } from '../../hooks/useDataRoom';
import { usePublicServiceType } from '../../hooks/usePublicServiceType';

export function Case() {
  const parentPostboxId = useParentPostboxId();
  const { caseId, postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();
  const { state } = useLocation();

  const [activeContentTab, setActiveContentTab] =
    useState<CaseContentTab>('participations');

  const [creatingParticipation, setCreatingParticipation] = useState(
    (state as any)?.createParticipation
  );

  const [deleting, setDeleting] = useState(false);
  const [duplicating, setDuplicating] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openCreateDataSpaceDialog, setOpenCreateDataSpaceDialog] =
    useState(false);

  const { data } = useSWR<CaseModel>(
    `/postboxes/${parentPostboxId}/cases/${caseId}`
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    data ? `/postboxes/${data.postboxId}/cases/${caseId}/leading-party` : null,
    { suspense: false }
  );

  const allowedOperations = useElementOperations(data);

  const { createDataRoom } = useDataRoom(parentPostboxId, caseId);

  const { publicServiceType } = usePublicServiceType(data?.publicServiceTypeId);

  return data ? (
    <>
      <Helmet>
        <title>
          {t('Fall: {{caseTitle}}', { caseTitle: data.title })} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack spacing="5xl">
        <FjdStack spacing="xl">
          <FjdSectionHeader
            align="start"
            heading={
              <FjdHeading
                level={1}
                text={
                  leadingParty?.processReference
                    ? `${leadingParty?.processReference} | ${data.title}`
                    : data.title
                }
              />
            }
            tools={
              data.caseStatus !== CaseStatus.CLOSED &&
              (allowedOperations.DUPLICATE_CASE ||
                allowedOperations.CLOSE_CASE ||
                allowedOperations.CREATE_DATAROOM) ? (
                <FjdStack orientation="horizontal">
                  <FjdFlexSpacer />
                  <FjdFlyout
                    flyout={
                      <FjdMenu>
                        {allowedOperations.CREATE_DATAROOM && (
                          <FjdMenuItem
                            appearance="light"
                            icon="add-outline"
                            label={t('dataRoomCreateText')}
                            onClick={() => setOpenCreateDataSpaceDialog(true)}
                          />
                        )}
                        {allowedOperations.DUPLICATE_CASE && (
                          <FjdMenuItem
                            appearance="light"
                            icon="copy"
                            label={t('Fall duplizieren')}
                            onClick={() => setDuplicating(true)}
                          />
                        )}
                        {allowedOperations.CLOSE_CASE && (
                          <FjdMenuItem
                            appearance="light"
                            icon="close-outline"
                            label={t('Fall abschließen')}
                            onClick={() => setDeleting(true)}
                          />
                        )}
                      </FjdMenu>
                    }
                    placement="bottom-end"
                  >
                    <FjdButton
                      appearance="outline"
                      hideLabel
                      iconRight="overflow-menu-vertical"
                      label={t('Mehr')}
                      size="s"
                    />
                  </FjdFlyout>

                  {allowedOperations.CREATE_PARTICIPATION && (
                    <FjdButton
                      iconLeft="add-outline"
                      label={t('Neue Mitwirkung auslösen')}
                      onClick={() => setCreatingParticipation(true)}
                      size="s"
                    />
                  )}
                </FjdStack>
              ) : null
            }
          />
          {data.caseStatus === CaseStatus.CLOSED && (
            <FjdAlert
              appearance="muted"
              closable={false}
              intent="info"
              title={t('Dieser Fall wurde abgeschlossen.')}
            />
          )}
        </FjdStack>

        <FjdSection>
          <FjdStack spacing="xl">
            <FjdContentTabBar>
              <FjdContentTabBarItem
                active={activeContentTab === 'participations'}
                label={t('Mitwirkungsübersicht')}
                onClick={() => setActiveContentTab('participations')}
              />
              <FjdContentTabBarItem
                active={activeContentTab === 'details'}
                label={t('Falldetails')}
                onClick={() => setActiveContentTab('details')}
              />
              <FjdContentTabBarItem
                active={activeContentTab === 'dataRoom'}
                label={t('Datenraum')}
                onClick={() => setActiveContentTab('dataRoom')}
              />
            </FjdContentTabBar>

            {activeContentTab === 'details' && (
              <FjdCard>
                <FjdStack spacing="5xl">
                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t('Informationen zum Fall')}
                        />
                      }
                      tools={
                        <FjdStack orientation="horizontal">
                          <FjdFlexSpacer />
                          <FjdButton
                            appearance="primary-link"
                            iconLeft="document-pdf"
                            label={t('Als PDF herunterladen')}
                            onClick={() => setExporting(true)}
                            size="s"
                          />
                          {data.caseStatus !== CaseStatus.CLOSED &&
                            allowedOperations.EDIT_CASE_DETAILS && (
                              <FjdButton
                                appearance="primary-link"
                                iconLeft="edit"
                                label={t('Informationen bearbeiten')}
                                onClick={() => setUpdating(true)}
                                size="s"
                              />
                            )}
                        </FjdStack>
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner label={t('Lade Falldetails …')} />
                      }
                    >
                      <CaseDetails
                        caseId={caseId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>

                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t(
                            'Informationen zur verfahrensführenden Stelle'
                          )}
                        />
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner label={t('Lade Informationen …')} />
                      }
                    >
                      <LeadingPartyDetails
                        caseId={caseId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>

                  <FjdStack spacing="xxl">
                    <FjdSectionHeader
                      align="center"
                      heading={
                        <FjdHeading
                          level={2}
                          text={t('Informationen zur Kontaktperson')}
                        />
                      }
                    />

                    <Suspense
                      fallback={
                        <LoadingSpinner label={t('Lade Informationen …')} />
                      }
                    >
                      <ContactPersonDetails
                        caseId={caseId}
                        postboxId={parentPostboxId}
                      />
                    </Suspense>
                  </FjdStack>
                </FjdStack>
              </FjdCard>
            )}

            {activeContentTab === 'participations' && (
              <FjdCard>
                <FjdStack spacing="xxl">
                  <FjdSectionHeader
                    align="center"
                    heading={
                      <FjdHeading level={2} text={t('Mitwirkungen zum Fall')} />
                    }
                  />

                  <Suspense
                    fallback={
                      <LoadingSpinner label={t('Lade Mitwirkungen …')} />
                    }
                  >
                    <ParticipationsTable
                      caseId={caseId}
                      postboxId={currentPostboxId}
                    />
                  </Suspense>
                </FjdStack>
              </FjdCard>
            )}
            {activeContentTab === 'dataRoom' && (
              <>
                <FjdHeading level={2} text={t('dataRoomHeading')} />
                <Alert variant="info" message={t('dataRoomAlertText')}>
                  {allowedOperations.CREATE_DATAROOM && (
                    <Button
                      beforeIcon="add-outline"
                      size="sm"
                      onClick={() => setOpenCreateDataSpaceDialog(true)}
                    >
                      {t('dataRoomAlertButtonText')}
                    </Button>
                  )}
                </Alert>
              </>
            )}
          </FjdStack>
        </FjdSection>
      </FjdStack>

      {creatingParticipation && (
        <CreateParticipationModal
          caseId={caseId}
          onClose={() => setCreatingParticipation(false)}
          postboxId={currentPostboxId}
        />
      )}

      {deleting && (
        <DeleteCaseModal
          caseToDelete={data}
          onClose={() => setDeleting(false)}
          postboxId={currentPostboxId}
        />
      )}

      {exporting && (
        <ExportModal
          caseId={caseId}
          onClose={() => setExporting(false)}
          postboxId={parentPostboxId}
        />
      )}

      {updating && leadingParty && (
        <UpsertCaseModal
          caseToUpdate={data}
          leadingPartyToUpdate={leadingParty}
          onClose={() => setUpdating(false)}
          postboxId={currentPostboxId}
        />
      )}

      {duplicating && (
        <DuplicateCaseModal
          caseToDuplicate={data}
          onClose={() => setDuplicating(false)}
          postboxId={currentPostboxId}
        />
      )}
      <CreateDataRoomModal
        caseTitle={data.title}
        publicServiceTitle={publicServiceType?.name}
        open={openCreateDataSpaceDialog}
        onCreateDataRoom={createDataRoom}
        onClose={() => setOpenCreateDataSpaceDialog(false)}
      />
    </>
  ) : null;
}
