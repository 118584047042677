import {
  FjdBadge,
  FjdButton,
  FjdFlyout,
  FjdLoadingOverlay,
  FjdMenu,
  FjdMenuItem,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { usePostbox } from '../../../../hooks/usePostbox';
import { useProfile } from '../../../../hooks/useProfile';
import { User } from '../../../../models/User';
import { UnauthorizeUserModal } from '../UnauthorizeUserModal/UnauthorizeUserModal';

interface PostboxUsersTableProps {
  postboxId?: string;
}

export function PostboxUsersTable({ postboxId }: PostboxUsersTableProps) {
  const { profile } = useProfile();
  const { addAdminRole, removeAdminRole } = usePostbox();
  const { t } = useTranslation();

  const [unauthorizing, setUnauthorizing] = useState<string>();

  const { data: users, isValidating } = useSWR<User[]>(
    `/users/postboxes/${postboxId}/users`
  );

  return postboxId ? (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdStack data-testid="user-public-service-type-table" spacing="s">
          <FjdVirtualizedTable
            disableVirtualization
            header={
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol>{t('Vorname')}</FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol>
                  {t('E-Mail-Adresse')}
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol maxWidth="8rem">
                  {t('Rolle')}
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol maxWidth="3.75rem"></FjdVirtualizedTableCol>
              </FjdVirtualizedTableRow>
            }
          >
            {users?.length === 0 && (
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCell>
                  {t('Keine Nutzer gefunden.')}
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            )}

            {users?.map((user, index) => (
              <FjdVirtualizedTableRow even={index % 2 === 1} key={user?.userId}>
                <FjdVirtualizedTableCell>
                  {user.lastName}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>
                  {user.firstName}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell>{user.email}</FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="8rem">
                  {user.admin && (
                    <FjdBadge
                      appearance="outline"
                      label={t('Admin')}
                      size="s"
                    />
                  )}
                </FjdVirtualizedTableCell>
                <FjdVirtualizedTableCell maxWidth="3.75rem">
                  {user.userId !== profile?.id && (
                    <FjdFlyout
                      flyout={
                        <FjdMenu>
                          <FjdMenuItem
                            appearance="light"
                            label={t('Autorisierung entziehen')}
                            onClick={() => setUnauthorizing(user.userId)}
                          />
                          {!user.admin && (
                            <FjdMenuItem
                              appearance="light"
                              label={t('Admin-Rolle vergeben')}
                              onClick={() =>
                                addAdminRole(postboxId, user.userId)
                              }
                            />
                          )}
                          {user.admin && (
                            <FjdMenuItem
                              appearance="light"
                              label={t('Admin-Rolle entziehen')}
                              onClick={() =>
                                removeAdminRole(postboxId, user.userId)
                              }
                            />
                          )}
                        </FjdMenu>
                      }
                      placement="bottom-end"
                    >
                      <FjdButton
                        appearance="primary-link"
                        hideLabel
                        iconRight="overflow-menu-vertical"
                        label={t('Mehr')}
                        size="s"
                      />
                    </FjdFlyout>
                  )}
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            ))}
          </FjdVirtualizedTable>
        </FjdStack>
      </FjdLoadingOverlay>

      {postboxId && unauthorizing && (
        <UnauthorizeUserModal
          onClose={() => setUnauthorizing(undefined)}
          postboxId={postboxId}
          userId={unauthorizing}
        />
      )}
    </>
  ) : null;
}
