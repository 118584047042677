import { useMemo } from 'react';
import useSWR from 'swr';

import { ResultsObject } from '../models/ResultsObject';
import { PaginationStyle, usePagination } from './usePagination';

interface UsePaginatedSwrConfig {
  elementLabel?: string;
  filter?: { key: string; value: string }[];
  key: string;
  pageSize?: number;
  paginationStyle?: PaginationStyle;
  sortByOrder?: 'ASC' | 'DESC';
  sortByProperty?: string;
  suspense?: boolean;
}

export function usePaginatedSwr<ResourceType>({
  elementLabel,
  filter = [],
  key,
  pageSize = 20,
  paginationStyle = 'default',
  sortByOrder,
  sortByProperty,
  suspense = true
}: UsePaginatedSwrConfig) {
  const filterQueryString = filter
    .filter((f) => !!f.value)
    .reduce(
      (queryString, filter) => `${queryString}&${filter.key}=${filter.value}`,
      ''
    );

  const sortQueryString = sortByProperty
    ? `&sortBy=${sortByProperty}&order=${sortByOrder}`
    : '';

  const queryString = filterQueryString + sortQueryString;

  let { data: results, mutate } = useSWR<
    ResultsObject<ResourceType> | ResourceType[]
  >(`${key}?page=0&size=${pageSize}${queryString}`, { suspense });

  const pages = useMemo(
    () =>
      results && 'items' in results
        ? Math.ceil((results?.total || 0) / pageSize)
        : Math.ceil((results?.length || 0) / pageSize),
    [pageSize, results]
  );

  const rowCount = useMemo(
    () =>
      results && 'items' in results ? results.total : results?.length || 0,
    [results]
  );

  const pagination = usePagination({
    elementLabel,
    pages,
    pageSize: pageSize,
    paginationStyle,
    rowCount
  });

  const { data, isValidating } = useSWR<ResultsObject<ResourceType>>(
    `${key}?${pagination.apiQueryString}${queryString}`,
    { suspense }
  );

  const items = useMemo(
    () =>
      data && 'items' in data
        ? data.items
        : (data || []).slice(
            pagination.currentPage * pageSize,
            pagination.currentPage * pageSize + pageSize
          ),
    [data, pageSize, pagination]
  );

  return {
    count: data?.total,
    data: items,
    isValidating,
    mutate,
    pagination
  };
}
