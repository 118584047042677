import {
  FjdButton,
  FjdFlyout,
  FjdTruncatedText,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { FjdVirtualizedTableCellFormat } from 'fjd-react-components/build/components/VirtualizedTableCell/VirtualizedTableCell';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStorageState } from 'react-storage-hooks';
import { atom, useRecoilState } from 'recoil';

import { Case } from '../models/Case';
import { Message } from '../models/Message';
import { Participation } from '../models/Participation';
import { ColumnConfiguration } from '../shared/ColumnConfiguration';
import { useElementNavigation } from './useElementNavigation';
import useTabs from './useTabs';

export const tablesAtom = atom<TablesState>({
  default: {},
  key: 'tables'
});

export const tablesStateLocalStorageKey = 'pdbk-tables-state';

export interface TablesState {
  [tableId: string]: {
    columns: TableColumn[];
  };
}

export interface TableColumn {
  active: boolean;
  id: string;
  fixed?: boolean;
  format?: FjdVirtualizedTableCellFormat;
  header: string;
  maxWidth?: string;
  minWidth?: string;
  resizable?: boolean;
  sort?: 'asc' | 'desc' | undefined;
  sortable?: boolean;
  sortKey?: string;
  width?: string;
}

export function useTable(
  tableId: string,
  postboxId: string,
  initialColumns: TableColumn[]
) {
  const { t } = useTranslation();
  const { openTab } = useTabs(postboxId);
  const { navigateToElement } = useElementNavigation();

  const [, setStorageTreeExpansionState] = useStorageState<TablesState>(
    localStorage,
    tablesStateLocalStorageKey
  );

  const [tablesState, setTablesState] = useRecoilState(tablesAtom);

  const [selection, setSelection] = useState<
    (Case | Message | Participation)[]
  >([]);

  const columns = useMemo(
    () => tablesState[tableId]?.columns || [],
    [tableId, tablesState]
  );

  const activeColumns = useMemo(
    () => columns.filter((column) => column.active),
    [columns]
  );

  const sortOptions = useMemo<('asc' | 'desc' | undefined)[]>(
    () => ['asc', 'desc', undefined],
    []
  );

  const openSelection = useCallback(() => {
    selection.forEach((element) => {
      openTab(element, true);
    });

    if (selection.length > 0) {
      navigateToElement(selection[selection.length - 1], postboxId);
    }
  }, [navigateToElement, openTab, postboxId, selection]);

  const resetSelection = useCallback(() => {
    setSelection([]);
  }, []);

  const resizeColumn = useCallback(
    (columnId: string, width: number) => {
      setTablesState((tablesState) => ({
        ...tablesState,
        [tableId]: {
          ...tablesState[tableId],
          columns: tablesState[tableId].columns.map((column) =>
            column.id === columnId
              ? {
                  ...column,
                  width: `${width / 16}rem`
                }
              : column
          )
        }
      }));
    },
    [setTablesState, tableId]
  );

  const setColumns = useCallback(
    (columns: TableColumn[]) => {
      setTablesState((tablesState) => ({
        ...tablesState,
        [tableId]: {
          ...tablesState[tableId],
          columns
        }
      }));
    },
    [setTablesState, tableId]
  );

  const toggleColumn = useCallback(
    (columnId: string) => {
      setTablesState((tablesState) => ({
        ...tablesState,
        [tableId]: {
          ...tablesState[tableId],
          columns: tablesState[tableId].columns.map((column) =>
            column.id === columnId
              ? { ...column, active: !column.active }
              : column
          )
        }
      }));
    },
    [setTablesState, tableId]
  );

  const toggleSelection = useCallback(
    (element: Case | Message | Participation) => {
      setSelection((selection) =>
        selection.some((el) => el.id === element.id)
          ? selection.filter((el) => el.id !== element.id)
          : [...selection, element]
      );
    },
    []
  );

  const toggleSort = useCallback(
    (columnId: string) => {
      setTablesState((tablesState) => ({
        ...tablesState,
        [tableId]: {
          ...tablesState[tableId],
          columns: tablesState[tableId].columns.map((column) =>
            column.id === columnId
              ? {
                  ...column,
                  sort: sortOptions[
                    (sortOptions.indexOf(column.sort) + 1) % sortOptions.length
                  ]
                }
              : { ...column, sort: undefined }
          )
        }
      }));
    },
    [setTablesState, sortOptions, tableId]
  );

  const sortByOrder = useMemo(
    () =>
      columns.find((column) => column.sort)?.sort?.toUpperCase() as
        | 'ASC'
        | 'DESC',
    [columns]
  );

  const sortByProperty = useMemo(
    () => columns.find((column) => column.sort)?.sortKey,
    [columns]
  );

  const tableHeader = useMemo(
    () => (
      <FjdVirtualizedTableRow>
        {activeColumns.map(
          (
            {
              header,
              id,
              maxWidth,
              minWidth,
              resizable,
              sort,
              sortable,
              width
            },
            index
          ) => (
            <FjdVirtualizedTableCol
              key={id}
              maxWidth={maxWidth}
              minWidth={minWidth}
              onResize={(width) => resizeColumn(id, width)}
              resizable={resizable}
              tools={
                sortable && (
                  <FjdButton
                    appearance="primary-link"
                    hideLabel
                    iconLeft={
                      sort === undefined
                        ? 'sort-chevron'
                        : sort === 'asc'
                        ? 'chevron-up'
                        : 'chevron-down'
                    }
                    label={t('Sortierung umschalten')}
                    onClick={() => toggleSort(id)}
                    size="s"
                  />
                )
              }
              verticalSeparator={index < activeColumns.length - 1}
              width={width}
            >
              <div title={header}>
                {header && <FjdTruncatedText text={header} />}

                {id === 'config' && (
                  <FjdFlyout
                    container={document.body}
                    flyout={
                      <ColumnConfiguration
                        columns={columns}
                        setColumns={setColumns}
                        toggleColumn={toggleColumn}
                      />
                    }
                    keepOpenIfFocused
                  >
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      iconLeft="settings"
                      label={t('Spalten konfigurieren')}
                      size="s"
                    />
                  </FjdFlyout>
                )}
              </div>
            </FjdVirtualizedTableCol>
          )
        )}
      </FjdVirtualizedTableRow>
    ),
    [
      activeColumns,
      columns,
      resizeColumn,
      setColumns,
      t,
      toggleColumn,
      toggleSort
    ]
  );

  useEffect(() => {
    if (!tablesState[tableId]) {
      setTablesState((tablesState) => ({
        ...tablesState,
        [tableId]: { columns: initialColumns }
      }));
    }
  }, [initialColumns, setTablesState, tableId, tablesState]);

  useEffect(() => {
    // sync columns from local storage with complete columns list
    if (columns) {
      const newColumns = initialColumns.filter(
        (column) => !columns.some((c) => c.id === column.id)
      );

      const deletedColumns = columns.filter(
        (column) => !initialColumns.some((c) => c.id === column.id)
      );

      if (newColumns.length > 0 || deletedColumns.length > 0) {
        const columnsWithoutDeletedOnes =
          tablesState[tableId]?.columns.filter(
            (column) => !deletedColumns.some((c) => c.id === column.id)
          ) || [];

        const insertionIndexForNewColumns =
          columnsWithoutDeletedOnes.length - 1;

        setTablesState((tableState) => ({
          ...tableState,
          [tableId]: {
            columns: columnsWithoutDeletedOnes
              .slice(0, insertionIndexForNewColumns)
              .concat(
                newColumns,
                columnsWithoutDeletedOnes.slice(insertionIndexForNewColumns)
              )
          }
        }));
      }
    }
  }, [columns, initialColumns, setTablesState, tableId, tablesState]);

  useEffect(() => {
    setStorageTreeExpansionState(tablesState);
  }, [setStorageTreeExpansionState, tablesState]);

  return {
    activeColumns,
    columns,
    openSelection,
    resetSelection,
    selection,
    setColumns,
    sortByOrder,
    sortByProperty,
    tableHeader,
    toggleColumn,
    toggleSelection,
    toggleSort
  };
}
