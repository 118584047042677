import { formatISO } from 'date-fns';
import {
  FjdButton,
  FjdCard,
  FjdFileInput,
  FjdFlyout,
  FjdFormControl,
  FjdHeading,
  FjdStack
} from 'fjd-react-components';
import { useCallback, useMemo, useState } from 'react';
import { Control, DeepMap, FieldError, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

import useAlerts from '../../../../hooks/useAlerts';
import { useFile } from '../../../../hooks/useFile';
import { useParentPostboxId } from '../../../../hooks/useParentPostboxId';
import {
  CreateDocumentRequest,
  Document,
  ExternalDocumentReference,
  FileReference
} from '../../../../models/Document';
import { File, FileHashAlgorithm } from '../../../../models/File';
import { CreateMessageRequest } from '../../../../models/Message';
import { getFileHash } from '../../../../utils/document';
import { CaseDocumentSelection } from '../CaseDocumentSelection/CaseDocumentSelection';
import { Uploads } from '../CreateMessageForm/CreateMessageForm';
import { DocumentPreview } from '../DocumentPreview/DocumentPreview';
import { ExternalDocumentForm } from '../ExternalDocumentForm/ExternalDocumentForm';

interface AttachmentsProps {
  caseId: string;
  control: Control<CreateMessageRequest>;
  errors: DeepMap<CreateMessageRequest, FieldError>;
  initialUploads?: Uploads;
  onChange?: (docs: any[]) => void;
  onUploading: (uploading: boolean) => void;
}

export function Attachments({
  caseId,
  control,
  errors,
  initialUploads,
  onChange,
  onUploading
}: AttachmentsProps) {
  const { alert } = useAlerts();
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();
  const parentPostboxId = useParentPostboxId();

  const [expandedDocumentId, setExpandedDocumentId] = useState<string>();
  const [uploads, setUploads] = useState<Uploads>(initialUploads || {});

  const { uploadFile, deleteFile } = useFile(
    parentPostboxId,
    currentPostboxId,
    caseId
  );

  const { data: caseFiles } = useSWR<File[]>(
    `/postboxes/${currentPostboxId}/cases/${caseId}/files`
  );

  const {
    append: appendDoc,
    fields: docs,
    remove: removeDoc,
    update: updateDoc
  } = useFieldArray({
    control,
    name: 'docs',
    keyName: 'id'
  });

  const externalDocs = useMemo(
    () => docs.filter((doc) => doc.reference.type === 'EXTERNAL'),
    [docs]
  );

  const internalDocs = useMemo(
    () => docs.filter((doc) => doc.reference.type === 'FILE'),
    [docs]
  );

  const createExternalDoc = useCallback(
    (externalDocumentReference: ExternalDocumentReference) => {
      if (
        docs.some(
          (doc) =>
            (doc.reference as ExternalDocumentReference).uri ===
            externalDocumentReference.uri
        )
      ) {
        alert(
          'warning',
          t('Ein externes Dokument mit dieser URL existiert bereits.'),
          8000
        );

        return;
      }

      const newDoc = {
        documentDate: formatISO(new Date()),
        hash: {
          algorithm: FileHashAlgorithm.SHA256,
          digest: ''
        },
        id: uuidv4(),
        mimeType: 'application/octet-stream',
        reference: externalDocumentReference,
        title: externalDocumentReference.uri
      };

      appendDoc(newDoc);

      if (typeof onChange === 'function') {
        onChange([...docs, newDoc]);
      }
    },
    [alert, appendDoc, docs, onChange, t]
  );

  const createFileDocs = useCallback(
    async (fileList: FileList | null) => {
      if (fileList && fileList.length > 0) {
        const files = Array.from(fileList);

        const hashes = await Promise.all(
          files.map((file) => getFileHash(file))
        );

        const newFiles = files.filter(
          (_, index) => !docs.some((doc) => doc.hash?.digest === hashes[index])
        );

        const newDocs: Document[] = newFiles.map((file, index) => {
          const existingFile = caseFiles?.find(
            (file) => file.hash.digest === hashes[index]
          );

          const name = existingFile?.originalFileName || file.name;
          const mimeType = existingFile?.mimeType || file.type;

          return {
            filename: name,
            hash: {
              algorithm: FileHashAlgorithm.SHA256,
              digest: hashes[index]
            },
            id: uuidv4(),
            mimeType,
            reference: { fileId: existingFile?.id || '', type: 'FILE' },
            sentOn: formatISO(new Date()),
            title: name
          };
        });

        appendDoc(newDocs);

        if (typeof onChange === 'function') {
          onChange([...docs, ...newDocs]);
        }

        const filesToUpload = newFiles.map((file, index) => ({
          docId: newDocs[index].id,
          file: !caseFiles?.some((f) => f.hash.digest === hashes[index])
            ? file
            : undefined
        }));

        onUploading(true);

        for (const fileRef of filesToUpload) {
          if (fileRef.file) {
            try {
              const uploadedFile = await uploadFile(
                [fileRef.file],
                (event) =>
                  setUploads((uploads) => ({
                    ...uploads,
                    [fileRef.docId]: {
                      progress: event,
                      finishedUploading: false
                    }
                  })),
                false
              );

              setUploads((uploads) => {
                const newUploads = {
                  ...uploads,
                  [fileRef.docId]: {
                    ...uploads[fileRef.docId],
                    id: uploadedFile.id,
                    finishedUploading: true
                  }
                };

                return newUploads;
              });

              const docIndex = newDocs.findIndex(
                (doc) => doc.id === fileRef.docId
              );

              const offset = docs.length;

              updateDoc(offset + docIndex, {
                ...newDocs[docIndex],
                reference: {
                  ...newDocs[docIndex].reference,
                  fileId: uploadedFile.id
                } as FileReference
              });

              if (typeof onChange === 'function') {
                onChange([
                  ...docs,
                  ...newDocs.map((doc, i) =>
                    i === docIndex
                      ? {
                          ...doc,
                          reference: {
                            ...doc.reference,
                            fileId: uploadedFile.id
                          } as FileReference
                        }
                      : doc
                  )
                ]);
              }
            } catch (error) {
              newDocs.map((doc) =>
                removeDoc(docs.findIndex((d) => d.id === doc.id))
              );
            }
          } else {
            alert('warning', t('Das Dokument wurde bereits hochgeladen.'));
          }
        }

        onUploading(false);
      }
    },
    [
      appendDoc,
      caseFiles,
      docs,
      onChange,
      onUploading,
      removeDoc,
      updateDoc,
      uploadFile,
      alert,
      t
    ]
  );

  const deleteDoc = useCallback(
    async (id: string) => {
      if (expandedDocumentId === id) {
        setExpandedDocumentId(undefined);
      }

      const index = docs.findIndex((d) => d.id === id);

      const uploadedDocId = uploads[id]?.id;

      removeDoc(index);
      if (uploadedDocId) {
        await deleteFile(uploadedDocId);
      }

      if (typeof onChange === 'function') {
        onChange(docs.filter((_, i) => i !== index));
      }
    },
    [deleteFile, docs, expandedDocumentId, onChange, removeDoc, uploads]
  );

  // TODO: does not throw an error, but @Tobias and @Patrick do not understand the sense of the change, why we need both finishedUploading and existingFile, and why we return 1 if not finished
  // Waiting für @Lasse for further answers
  const getUploadProgress = useCallback(
    (doc: CreateDocumentRequest) => {
      const finishedUploading = uploads[doc.id]?.finishedUploading;

      const existingFile = caseFiles?.find(
        (d) => d.hash?.digest === doc.hash?.digest
      );

      return finishedUploading || existingFile ? 100 : 1;
    },
    [caseFiles, uploads]
  );

  const updateExternalDocument = useCallback(
    (data: CreateDocumentRequest) => {
      const index = docs.findIndex(
        (d) =>
          d.reference.type === 'EXTERNAL' &&
          (d.reference as ExternalDocumentReference).uri ===
            (data.reference as ExternalDocumentReference).uri
      );

      updateDoc(index, {
        ...data,
        reference: {
          ...data.reference,
          uri: data.title
        } as ExternalDocumentReference
      });

      if (typeof onChange === 'function') {
        onChange(
          docs.map((doc, i) =>
            i === index
              ? {
                  ...doc,
                  reference: {
                    ...data.reference,
                    uri: data.title
                  } as ExternalDocumentReference
                }
              : doc
          )
        );
      }
    },
    [docs, onChange, updateDoc]
  );

  return (
    <FjdCard>
      <FjdStack spacing="3xl">
        <FjdFormControl label={t('Dokumentenverwaltung')}>
          <FjdStack orientation="horizontal">
            <FjdFileInput
              appearance="primary-link"
              icon="export"
              id="upload-file"
              label={t('Vom Rechner hochladen')}
              multiple
              onChange={(event) => createFileDocs(event.target.files)}
              size="xs"
            />

            <FjdFlyout
              flyout={
                <CaseDocumentSelection
                  caseId={caseId}
                  disabledFiles={internalDocs.map(
                    (doc) => (doc.reference as FileReference).fileId
                  )}
                  onAdd={(selection) => {
                    appendDoc(selection);

                    if (typeof onChange === 'function') {
                      onChange([...docs, ...selection]);
                    }
                  }}
                  postboxId={currentPostboxId}
                />
              }
              hasMaxWidth={false}
              keepOpenIfFocused
              offset={8}
              placement="bottom-end"
            >
              <FjdButton
                appearance="primary-link"
                iconLeft="document"
                label={t('Aus Fallverzeichnis wählen')}
                size="xs"
              />
            </FjdFlyout>

            <FjdFlyout
              flyout={
                <ExternalDocumentForm
                  onAdd={(externalDocumentReference) =>
                    createExternalDoc(externalDocumentReference)
                  }
                />
              }
              hasMaxWidth={false}
              keepOpenIfFocused
              offset={8}
              placement="bottom-end"
            >
              <FjdButton
                appearance="primary-link"
                iconLeft="link"
                label={t('Als Link einfügen')}
                size="xs"
              />
            </FjdFlyout>
          </FjdStack>
        </FjdFormControl>

        {internalDocs && internalDocs?.length > 0 && (
          <FjdStack spacing="s">
            <FjdHeading
              level={3}
              text={t('Vom Rechner hochgeladen')}
              visualLevel={4}
            />

            {internalDocs?.map((doc) => {
              const progress = getUploadProgress(doc);

              return (
                <DocumentPreview
                  control={control}
                  document={doc}
                  errors={errors}
                  expanded={expandedDocumentId === doc.id}
                  index={0}
                  key={doc.filename}
                  onChange={(doc) => {
                    if (typeof onChange === 'function') {
                      onChange(docs.map((d) => (d.id === doc.id ? doc : d)));
                    }
                  }}
                  onDelete={() => {
                    if (progress === 100) {
                      deleteDoc(doc.id);
                    }
                  }}
                  onToggle={() => {
                    if (progress === 100) {
                      setExpandedDocumentId((expandedDocumentId) =>
                        expandedDocumentId === doc.id ? undefined : doc.id
                      );
                    }
                  }}
                  progress={progress}
                />
              );
            })}
          </FjdStack>
        )}

        {externalDocs && externalDocs?.length > 0 && (
          <FjdStack spacing="s">
            <FjdHeading
              level={3}
              text={t('Als Link eingefügt')}
              visualLevel={4}
            />

            {externalDocs?.map((doc) => {
              return (
                <DocumentPreview
                  control={control}
                  document={doc}
                  errors={errors}
                  expanded={expandedDocumentId === doc.id}
                  index={docs.findIndex((d) => d.id === doc.id)}
                  key={doc.id}
                  onChange={updateExternalDocument}
                  onDelete={() => {
                    deleteDoc(doc.id);
                  }}
                  onToggle={() => {
                    setExpandedDocumentId((expandedDocumentId) =>
                      expandedDocumentId === doc.id ? undefined : doc.id
                    );
                  }}
                  progress={100}
                />
              );
            })}
          </FjdStack>
        )}
      </FjdStack>
    </FjdCard>
  );
}
