import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import { Privileges } from './Privileges';

export interface Participation {
  caseId: string;
  deadline?: string;
  description?: string;
  id: string;
  legalBasis?: string;
  obligation: ParticipationObligation;
  postboxId: string;
  privileges?: Privileges[];
  startDate: string;
  task: string;
  title: string;
}

export enum ParticipationObligation {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL'
}

export type CreateParticipationRequest = Except<
  Participation,
  'id' | 'postboxId' | 'privileges' | 'startDate'
>;

export type UpdateParticipationRequest = CreateParticipationRequest;

export const CreateParticipationRequestSchema: Schema = Joi.object({
  caseId: Joi.string().required(),
  deadline: Joi.string().optional().allow(''),
  description: Joi.string().optional().allow(''),
  legalBasis: Joi.string().optional().allow(''),
  obligation: Joi.string()
    .valid(...Object.values(ParticipationObligation))
    .required(),
  task: Joi.string().required(),
  title: Joi.string().required()
});

export const UpdateParticipationRequestSchema =
  CreateParticipationRequestSchema;

export function instanceOfParticipation(object?: any): object is Participation {
  if (!object) {
    return false;
  }

  return (
    'caseId' in object &&
    'postboxId' in object &&
    'task' in object &&
    'title' in object
  );
}
