import { formatISO } from 'date-fns';
import {
  FjdButton,
  FjdCard,
  FjdColumns,
  FjdFlexSpacer,
  FjdFormControl,
  FjdMarkdown,
  FjdRichtextEditor,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { joiResolver } from '@hookform/resolvers/joi';

import { useElementNavigation } from '../../../../hooks/useElementNavigation';
import { useJoi } from '../../../../hooks/useJoi';
import { useMessage } from '../../../../hooks/useMessage';
import { CreateDocumentRequest } from '../../../../models/Document';
import { InvolvedParty } from '../../../../models/InvolvedParty';
import { LeadingParty } from '../../../../models/LeadingParty';
import {
  CreateMessageRequest,
  CreateMessageRequestSchema,
  MessageType
} from '../../../../models/Message';
import { Postbox } from '../../../../models/Postbox';
import { LoadingSpinner } from '../../../../shared/LoadingSpinner';
import { getMessageTypeLabel } from '../../../../utils/message';
import { getPostboxOwnerName } from '../../../../utils/postbox';
import { Attachments } from '../Attachments/Attachments';

interface CreateMessageFormProps {
  caseId: string;
  initialData?: Partial<FormControls>;
  isParticipationCloseMessage?: boolean;
  isParticipationStartMessage?: boolean;
  onChange?: (data: Partial<FormControls>) => void;
  onCreate?: () => void;
  onInit: (form: HTMLFormElement) => void;
  onLoading?: (loading: boolean) => void;
  onToggleDocs?: (showDocs: boolean) => void;
  onUploadChange?: (uploads: Uploads) => void;
  onUploading?: (uploading: boolean) => void;
  participationId?: string;
  postboxId: string;
}

export type FormControls = CreateMessageRequest;

export type Uploads = {
  [id: string]:
    | {
        progress?: ProgressEvent | undefined;
        id?: string;
        finishedUploading: boolean;
      }
    | undefined;
};

export function CreateMessageForm({
  caseId,
  initialData,
  isParticipationCloseMessage,
  isParticipationStartMessage,
  onChange,
  onCreate,
  onInit,
  onLoading,
  onToggleDocs,
  onUploading,
  participationId,
  postboxId
}: CreateMessageFormProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const [showDocs, setShowDocs] = useState(
    !!initialData?.docs && initialData?.docs?.length > 0
  );

  const [uploading, setUploading] = useState(false);

  const { createMessage, loading } = useMessage(postboxId);

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${postboxId}/cases/${caseId}/leading-party`
  );

  const { data: leadingPartyPostbox } = useSWR<Postbox>(
    leadingParty ? `/postboxes/${leadingParty.postboxId}` : null,
    { suspense: false }
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    participationId
      ? `/postboxes/${postboxId}/participations/${participationId}/involved-party`
      : null,
    { suspense: false }
  );

  const { data: involvedPartyPostbox } = useSWR<Postbox>(
    involvedParty ? `/postboxes/${involvedParty.postboxId}` : null,
    { suspense: false }
  );

  const fromLeader = useMemo(
    () => leadingParty?.postboxId === currentPostboxId,
    [currentPostboxId, leadingParty]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      docs: initialData?.docs || ([] as CreateDocumentRequest[]),
      fromLeader: initialData?.fromLeader || fromLeader,
      sentOn: initialData?.sentOn || formatISO(new Date()),
      subject: initialData?.subject || '',
      type: isParticipationStartMessage
        ? MessageType.START
        : isParticipationCloseMessage
        ? MessageType.CLOSE
        : (initialData?.type as MessageType) || MessageType.COMMUNICATION
    },
    resolver: joiResolver(
      CreateMessageRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const docs = watch('docs');

  const messageTypes = useMemo(() => {
    if (fromLeader || isParticipationCloseMessage) {
      return Object.values(MessageType).filter(
        (messageType) => messageType !== MessageType.PARTICIPATION_RESULT
      );
    } else {
      return [
        MessageType.COMMUNICATION,
        MessageType.DOWNSTREAM,
        MessageType.PARTICIPATION_RESULT
      ];
    }
  }, [fromLeader, isParticipationCloseMessage]);

  const onCreateMessage = useCallback(
    async (data: CreateMessageRequest) => {
      if (!uploading && !CreateMessageRequestSchema.validate(data).error) {
        if (!isParticipationStartMessage && participationId) {
          const newMessage = await createMessage(participationId, data);

          if (newMessage !== undefined) {
            if (typeof onCreate === 'function') {
              onCreate();
            }

            navigateToElement(newMessage, currentPostboxId);
          }
        } else if (isParticipationStartMessage) {
          if (typeof onCreate === 'function') {
            onCreate();
          }
        }
      }
    },
    [
      createMessage,
      currentPostboxId,
      isParticipationStartMessage,
      navigateToElement,
      onCreate,
      participationId,
      uploading
    ]
  );

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  useEffect(() => {
    if (typeof onToggleDocs === 'function') {
      onToggleDocs(showDocs);
    }
  }, [onToggleDocs, showDocs]);

  useEffect(() => {
    if (typeof onUploading === 'function') {
      onUploading(uploading);
    }
  }, [onUploading, uploading]);

  useEffect(() => {
    if (typeof onLoading === 'function') {
      onLoading(loading);
    }
  }, [loading, onLoading]);

  return (
    <form onSubmit={handleSubmit(onCreateMessage)} ref={form}>
      <FjdColumns>
        <FjdCard>
          <FjdStack spacing="xl">
            {!isParticipationStartMessage &&
              involvedPartyPostbox &&
              leadingPartyPostbox && (
                <FjdFormControl
                  inputId="recipient"
                  label={t('Empfänger der Nachricht')}
                >
                  <FjdTextInput
                    defaultValue={
                      fromLeader
                        ? getPostboxOwnerName(involvedPartyPostbox?.owner)
                        : getPostboxOwnerName(leadingPartyPostbox?.owner)
                    }
                    id="recipient"
                    name="recipient"
                    readonly
                  />
                </FjdFormControl>
              )}

            <FjdFormControl
              inputId="type"
              label={t('Art der Nachricht')}
              validationMessage={errors.type?.message}
            >
              <Controller
                name="type"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdSelect
                      disabled={
                        isParticipationStartMessage ||
                        isParticipationCloseMessage
                      }
                      disableSearch
                      id="type"
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);

                        if (typeof onChange === 'function') {
                          onChange({ [field.name]: value as MessageType });
                        }
                      }}
                      options={messageTypes.map((type) => ({
                        selected: field.value === type,
                        text: getMessageTypeLabel(type, t),
                        value: type
                      }))}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="subject"
              label={t('Betreff')}
              validationMessage={errors.subject?.message}
            >
              <Controller
                name="subject"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      autoFocus
                      defaultValue={field.value}
                      id="subject"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);

                        if (typeof onChange === 'function') {
                          onChange({ [field.name]: event.target.value });
                        }
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="text"
              label={t('Nachrichtentext')}
              optional
              validationMessage={errors.text?.message}
            >
              <Controller
                name="text"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdRichtextEditor
                      id="text"
                      initialValue={field.value}
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);

                        if (typeof onChange === 'function') {
                          onChange({ [field.name]: value });
                        }
                      }}
                      output="html"
                      rows={10}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdStack orientation="horizontal" spacing="m">
              {(!docs || docs?.length === 0) && (
                <FjdMarkdown
                  source={`**${t(
                    'Sie haben keine Dokumente an die Nachricht angehängt.'
                  )}**`}
                />
              )}

              {docs && docs.length > 0 && (
                <FjdMarkdown
                  source={`**${t(
                    'Sie haben {{count}} Dokumente an die Nachricht angehängt.',
                    { count: docs?.length }
                  )}**`}
                />
              )}

              <FjdFlexSpacer />

              <FjdButton
                appearance="primary-link"
                iconLeft="document"
                label={
                  showDocs
                    ? t('Dokumentenverwaltung ausblenden')
                    : t('Dokumentenverwaltung einblenden')
                }
                onClick={() => setShowDocs((showDocs) => !showDocs)}
                size="s"
              />
            </FjdStack>
          </FjdStack>

          <input type="submit" hidden />
        </FjdCard>

        {showDocs && (
          <Suspense fallback={<LoadingSpinner />}>
            <Attachments
              caseId={caseId}
              control={control}
              errors={errors}
              onChange={(docs) => {
                if (typeof onChange === 'function') {
                  onChange({ docs });
                }
              }}
              onUploading={setUploading}
            />
          </Suspense>
        )}
      </FjdColumns>
    </form>
  );
}
