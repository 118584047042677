import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateMessageRequest, Message } from '../models/Message';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useMessage(postboxId: string) {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createMessage = useCallback(
    async (
      participationId: string,
      data: CreateMessageRequest,
      silent?: boolean
    ) => {
      setLoading(true);

      try {
        const newMessageResponse = await makeRequestWithFullResponse<Message>(
          `/postboxes/${postboxId}/participations/${participationId}/messages`,
          'POST',
          data
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/messages.*$`
          )
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/message-results.*$`
          )
        );

        if (!silent) {
          alert('success', t('Nachricht erfolgreich erstellt.'));
        }

        setLoading(false);

        return makeRequest<Message>(newMessageResponse.headers.location, 'GET');
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, postboxId, t]
  );

  const deleteMessage = useCallback(
    async (participationId: string, messageId: string) => {
      setLoading(true);

      try {
        await makeRequest(
          `/postboxes/${postboxId}/participations/${participationId}/messages/${messageId}`,
          'DELETE'
        );

        await mutate(
          `/postboxes/${postboxId}/participations/${participationId}/messages/${messageId}`
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/messages.*$`
          )
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/message-results.*$`
          )
        );

        alert('success', t('Nachricht gelöscht.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  const markAsRead = useCallback(
    async (
      currentPostboxId: string,
      participationId: string,
      messageId: string
    ) => {
      setLoading(true);

      try {
        await makeRequestWithFullResponse(
          `/postboxes/${postboxId}/participations/${participationId}/messages/${messageId}/status?currentPostboxId=${currentPostboxId}`,
          'PUT',
          {
            status: 'RECEIVED'
          }
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/messages.*$`
          )
        );

        await matchMutate(
          new RegExp(
            `^/postboxes/${postboxId}/participations/${participationId}/message-results.*$`
          )
        );

        alert('success', t('Empfang erfolgreich bestätigt.'));

        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, postboxId, t]
  );

  return { createMessage, deleteMessage, loading, markAsRead };
}
