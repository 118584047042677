import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InvitationResponse, InvitationUserRequest } from '../models/Invite';
import { matchMutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useInvite() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const invite = useCallback(
    async (data: InvitationUserRequest) => {
      setLoading(true);

      try {
        const newInviteResponse = await makeRequest<InvitationResponse>(
          `/users`,
          'POST',
          data
        );

        await matchMutate(new RegExp(`^/postbox-results.*$`));

        alert('success', t('Nutzer erfolgreich eingeladen.'));
        setLoading(false);

        return newInviteResponse;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, t]
  );

  return { invite, loading };
}
