import { differenceInMilliseconds } from 'date-fns';
import { FjdIcon, FjdTreeItem } from 'fjd-react-components';
import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import humanizeDuration from 'humanize-duration';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { InvolvedParty } from '../../../../models/InvolvedParty';
import { LeadingParty } from '../../../../models/LeadingParty';
import { LogEntryStatus } from '../../../../models/LogEntry';
import { Message } from '../../../../models/Message';
import { Postbox } from '../../../../models/Postbox';
import { getPostboxOwnerName } from '../../../../utils/postbox';
import { getMessagePath } from '../../../../utils/router';

interface MessageTreeItemProps {
  activeTab?: FjdTabBarTab;
  message: Message;
  postboxId: string;
}

export function MessageTreeItem({
  activeTab,
  message,
  postboxId
}: MessageTreeItemProps) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const id = `message-${message.id}`;

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${message.postboxId}/cases/${message.caseId}/leading-party`
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${message.postboxId}/participations/${message.participationId}/involved-party`
  );

  const { data: senderPostbox } = useSWR<Postbox>(
    leadingParty && involvedParty
      ? message.fromLeader
        ? `/postboxes/${leadingParty.postboxId}`
        : `/postboxes/${involvedParty.postboxId}`
      : null,
    {
      suspense: false
    }
  );

  const age = useMemo(() => {
    if (!message.receivedOn) {
      return undefined;
    }

    const diffInMilliseconds = differenceInMilliseconds(
      new Date(),
      new Date(message.receivedOn)
    );

    const humanized =
      diffInMilliseconds < 60000
        ? t('vor wenigen Sekunden')
        : `${t('vor')} ${humanizeDuration(diffInMilliseconds, {
            language: i18n.language,
            largest: 1,
            round: true
          })}`;

    return humanized;
  }, [i18n.language, message.receivedOn, t]);

  const messageReceived = !!message?.logs?.find(
    (entry) => entry.status === LogEntryStatus.RECEIVED
  );

  return message ? (
    <FjdTreeItem
      active={activeTab?.id === message.id}
      badges={
        <>
          {message.docs && message.docs.length > 0 && (
            <FjdIcon glyph="link" size="xs" />
          )}
        </>
      }
      key={id}
      label={
        messageReceived ? message.subject : `**${message.subject.trim()}**`
      }
      icon={messageReceived ? 'email' : 'email-unread'}
      iconIntent="info"
      meta={`${age ? `${age}, ` : ''} von ${getPostboxOwnerName(
        senderPostbox?.owner
      )}`}
      onClick={() => {
        navigate(getMessagePath(message, postboxId));
      }}
    />
  ) : null;
}
