import { FjdMarkdown, FjdModal, FjdStack } from 'fjd-react-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { submitForm } from '../../../utils/form';
import { CreateMessageForm } from './CreateMessageForm/CreateMessageForm';

interface CreateMessageModalProps {
  caseId: string;
  closingMessage?: boolean;
  initialSubject?: string;
  onClose: () => void;
  participationId: string;
  postboxId: string;
}

export function CreateMessageModal({
  caseId,
  closingMessage,
  initialSubject,
  onClose,
  participationId,
  postboxId
}: CreateMessageModalProps) {
  const { t } = useTranslation();

  const [busy, setBusy] = useState(false);
  const [form, setForm] = useState<HTMLFormElement>();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<0 | 1>(closingMessage ? 0 : 1);
  const [width, setWidth] = useState<'wide' | 'wider'>('wide');

  const heading = useMemo(
    () =>
      closingMessage
        ? t('Mitwirkung abschließen')
        : initialSubject
        ? t('Auf Nachricht antworten')
        : t('Neue Nachricht schreiben'),
    [closingMessage, initialSubject, t]
  );

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={heading}
      id="create-message-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => (step === 0 ? setStep(1) : submitForm(form))}
      onSecondaryButtonClick={() =>
        step === 0 || !closingMessage ? onClose() : setStep(0)
      }
      open
      primaryButtonDisabled={busy}
      primaryButtonLabel={
        closingMessage
          ? step === 0
            ? t('Bestätigen und weiter')
            : t('Senden und Mitwirkung abschließen')
          : t('Jetzt senden')
      }
      secondaryButtonLabel={
        step === 0 || !closingMessage ? t('Abbrechen') : t('Zurück')
      }
      width={width}
    >
      {step === 0 && (
        <FjdStack spacing="m">
          <FjdMarkdown
            source={`**${t(
              'Sind sie sich sicher, dass sie die Mitwirkung abschließen wollen?'
            )}**`}
          />

          <FjdMarkdown
            source={t(
              'Die Mitwirkung kann danach nicht mehr bearbeitet werden. Nach dem Abschließen können innerhalb dieser Mitwirkung keine Nachrichten mehr versendet werden.'
            )}
          />
        </FjdStack>
      )}

      {step === 1 && (
        <CreateMessageForm
          caseId={caseId}
          initialData={{
            subject: initialSubject
          }}
          isParticipationCloseMessage={closingMessage}
          onCreate={onClose}
          onInit={setForm}
          onLoading={setLoading}
          onToggleDocs={(showDocs) => setWidth(showDocs ? 'wider' : 'wide')}
          onUploading={setBusy}
          participationId={participationId}
          postboxId={postboxId}
        />
      )}
    </FjdModal>
  );
}
