import { TFunction } from 'react-i18next';

import {
  instanceOfNaturalPerson,
  instanceOfPrivateLegalEntity,
  instanceOfPublicOrganization,
  NaturalPerson,
  PersonType,
  PrivateLegalEntity,
  PublicOrganization
} from '../models/Person';
import { NotificationDuration } from '../models/Postbox';

export function getNotificationDurationLabel(
  duration: NotificationDuration,
  t: TFunction
): string {
  if (!duration) {
    return '';
  }

  switch (duration) {
    case NotificationDuration.IMMEDIATE:
      return t('sofort');
    case NotificationDuration.ONE_DAY:
      return t('Ein Tag');
    case NotificationDuration.ONE_MONTH:
      return t('Ein Monat');
    case NotificationDuration.ONE_WEEK:
      return t('Eine Woche');
    case NotificationDuration.TWO_DAYS:
      return t('Zwei Tage');
    case NotificationDuration.TWO_WEEKS:
      return t('Zwei Wochen');
    default:
      return '';
  }
}

export function getPersonTypeLabel(
  personType: PersonType,
  t: TFunction
): string {
  if (!personType) {
    return '';
  }

  switch (personType) {
    case PersonType.NATURAL_PERSON:
      return t('Natürliche Person');
    case PersonType.PRIVATE_LEGAL_ENTITY:
      return t('Nicht-öffentliche Stelle');
    case PersonType.PUBLIC_ORGANISATION:
      return t('Öffentliche Stelle');
    default:
      return '';
  }
}

export function getPostboxOwnerName(
  owner?: NaturalPerson | PrivateLegalEntity | PublicOrganization
) {
  if (!owner) {
    return '';
  }

  if (instanceOfNaturalPerson(owner)) {
    return `${owner?.name?.formOfAddress} ${owner?.name?.firstName} ${owner?.name?.lastName}`;
  } else if (instanceOfPrivateLegalEntity(owner)) {
    return owner?.organizationName;
  } else if (instanceOfPublicOrganization(owner)) {
    return owner?.organizationName;
  }
}

export const typeOfPublicOrganizationOptions: string[] = [
  'Bundesbehörde',
  'Landesbehörde',
  'Kommunalverwaltung (Kreis)',
  'Kommunalverwaltung (Stadt)',
  'Kommunalverwaltung (Gemeinde)',
  'Unternehmen der Kommunalwirtschaft',
  'Kammer',
  'Verband',
  'Zivilgesellschaftliche Organisation',
  'mit öffentlichen Aufgaben belehntes Unternehmen',
  'Sonstige öffentliche Stelle'
];
