import {
  FjdButton,
  FjdCollapsibleCard,
  FjdColumns,
  FjdFlexSpacer,
  FjdFormControl,
  FjdHeading,
  FjdLoadingOverlay,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useCallback, useState } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { joiResolver } from '@hookform/resolvers/joi';

import { useInvite } from '../../../../hooks/useInvite';
import { useJoi } from '../../../../hooks/useJoi';
import { Case } from '../../../../models/Case';
import {
  CreateInvitationUserRequestSchema,
  InvitationResponse,
  InvitationUserRequest
} from '../../../../models/Invite';
import {
  FormOfAddress,
  NaturalPerson,
  Person,
  PersonType,
  PrivateLegalEntity,
  PublicOrganization
} from '../../../../models/Person';
import { PublicServiceType } from '../../../../models/PublicServiceType';
import { getPersonTypeLabel } from '../../../../utils/postbox';

interface InviteInvolvedPartyFormProps {
  caseData: Case;
  onInvite: (owner: InvitationResponse) => void;
  publicServiceTypeId: string;
}

export type FormControls = InvitationUserRequest;

export function InviteInvolvedPartyForm({
  caseData,
  onInvite,
  publicServiceTypeId
}: InviteInvolvedPartyFormProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const { invite, loading } = useInvite();

  const [expanded, setExpanded] = useState(false);

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    `/public-service-types/${publicServiceTypeId}`
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      owner: { type: PersonType.PUBLIC_ORGANISATION },
      postboxIdLeading: caseData.postboxId,
      invitationEmail: ''
    },

    resolver: joiResolver(
      CreateInvitationUserRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const type = watch('owner.type');

  const onSubmit = useCallback(
    async (data: InvitationUserRequest) => {
      const invitationResponse = await invite(data);
      setExpanded(false);

      if (invitationResponse) {
        onInvite(invitationResponse);
      }
    },
    [invite, onInvite]
  );

  return (
    <FjdLoadingOverlay loading={loading}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FjdCollapsibleCard
          expanded={expanded}
          heading={<FjdHeading level={3} text={t('Zur Mitwirkung einladen')} />}
          onToggle={setExpanded}
        >
          <div style={{ paddingTop: '1rem' }}></div>
          <FjdStack spacing="xl">
            <FjdColumns>
              <FjdFormControl
                inputId="owner.type"
                testId="owner.type"
                label={t('Art des Empfängers')}
                validationMessage={
                  (errors.owner as DeepMap<Person, FieldError>)?.type?.message
                }
              >
                <Controller
                  name="owner.type"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdSelect
                        ariaLabel={t('Empfängersauswahl')}
                        disableSearch
                        id="owner.type"
                        name={field.name}
                        onChange={field.onChange}
                        options={Object.values(PersonType).map((type) => ({
                          selected: field.value === type,
                          text: getPersonTypeLabel(type, t),
                          value: type
                        }))}
                      />
                    );
                  }}
                />
              </FjdFormControl>

              <FjdFormControl
                inputId="publicServiceTypeId"
                label={t('Ausgewählte Verwaltungsleistung')}
                validationMessage={errors.publicServiceTypeId?.message}
              >
                <Controller
                  name="publicServiceTypeId"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdTextInput
                        id="publicServiceTypeId"
                        name={field.name}
                        readonly
                        value={publicServiceType?.name}
                      />
                    );
                  }}
                />
              </FjdFormControl>
            </FjdColumns>

            {(type === PersonType.PUBLIC_ORGANISATION ||
              type === PersonType.PRIVATE_LEGAL_ENTITY) && (
              <FjdColumns>
                <FjdFormControl
                  inputId="owner.organizationName"
                  label={t('Name der Organisation')}
                  validationMessage={
                    (
                      errors.owner as DeepMap<
                        PublicOrganization | PrivateLegalEntity,
                        FieldError
                      >
                    )?.organizationName?.message
                  }
                >
                  <Controller
                    name="owner.organizationName"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.organizationName"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                <FjdFormControl
                  inputId="owner.organisationalUnit"
                  label={t('Organisationseinheit')}
                  optional
                  validationMessage={
                    (errors.owner as DeepMap<PublicOrganization, FieldError>)
                      ?.organisationalUnit?.message
                  }
                >
                  <Controller
                    name="owner.organisationalUnit"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.organisationalUnit"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </FjdColumns>
            )}

            {type === PersonType.NATURAL_PERSON && (
              <>
                <FjdColumns>
                  <FjdFormControl
                    inputId="owner.name.doctoralDegrees"
                    testId="owner.name.doctoralDegrees"
                    label={t('Titel')}
                    optional
                    validationMessage={
                      (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                        ?.doctoralDegrees?.message
                    }
                  >
                    <Controller
                      name="owner.name.doctoralDegrees"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="owner.name.doctoralDegrees"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>

                  <FjdFormControl
                    inputId="owner.name.formOfAddress"
                    testId="owner.name.formOfAddress"
                    label={t('Anrede')}
                    validationMessage={
                      (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                        ?.formOfAddress?.message
                    }
                  >
                    <Controller
                      name="owner.name.formOfAddress"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdSelect
                            disableSearch
                            id="owner.name.formOfAddress"
                            name={field.name}
                            onChange={field.onChange}
                            options={Object.values(FormOfAddress).map(
                              (formOfAddress) => ({
                                selected: field.value === formOfAddress,
                                text: formOfAddress,
                                value: formOfAddress,
                                id: formOfAddress.toLowerCase()
                              })
                            )}
                            value={field.value}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>
                </FjdColumns>
                <FjdColumns>
                  <FjdFormControl
                    inputId="owner.name.lastName"
                    label={t('Name')}
                    validationMessage={
                      (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                        ?.lastName?.message
                    }
                  >
                    <Controller
                      name="owner.name.lastName"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="owner.name.lastName"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>

                  <FjdFormControl
                    inputId="owner.name.firstName"
                    label={t('Vorname')}
                    validationMessage={
                      (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                        ?.firstName?.message
                    }
                  >
                    <Controller
                      name="owner.name.firstName"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="owner.name.firstName"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>
                </FjdColumns>
              </>
            )}

            <FjdFormControl
              inputId="invitationEmail"
              label={t('E-Mail-Adresse')}
              validationMessage={errors.invitationEmail?.message}
            >
              <Controller
                name="invitationEmail"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="invitationEmail"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="text"
              label={t('Einladungstext')}
              validationMessage={errors.text?.message}
            >
              <Controller
                name="text"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="text"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      rows={5}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdStack orientation="horizontal">
              <FjdFlexSpacer />
              <FjdButton
                appearance="link"
                label={t('Abbrechen')}
                onClick={() => setExpanded(false)}
                size="s"
              />
              <FjdButton
                label={t('Zur Mitwirkung einladen')}
                size="s"
                type="submit"
              />
            </FjdStack>
          </FjdStack>
        </FjdCollapsibleCard>
      </form>
    </FjdLoadingOverlay>
  );
}
