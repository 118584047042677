import {
  FjdSpinner,
  FjdStack,
  FjdTree,
  FjdTreeItem
} from 'fjd-react-components';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import useElementByLocation from '../../../../hooks/useElementByLocation';
import { useParentPostboxId } from '../../../../hooks/useParentPostboxId';
import useTabs from '../../../../hooks/useTabs';
import { useTreeExpansion } from '../../../../hooks/useTreeExpansion';
import { Case, instanceOfCase } from '../../../../models/Case';
import { LeadingParty } from '../../../../models/LeadingParty';
import { instanceOfParticipation } from '../../../../models/Participation';
import { getCasePath } from '../../../../utils/router';
import { ParticipationsTree } from '../ParticipationsTree/ParticipationsTree';

interface CasesTreeProps {
  postboxId: string;
}

export function CasesTree({ postboxId }: CasesTreeProps) {
  const { expandNode, toggleNode, treeExpansion } =
    useTreeExpansion('sidebar-tree');

  const { activeTab } = useTabs(postboxId);
  const locationElement = useElementByLocation();
  const parentPostboxId = useParentPostboxId();
  const navigate = useNavigate();

  const locationCaseId = instanceOfCase(locationElement)
    ? locationElement.id
    : locationElement?.caseId;

  const { data: caseData } = useSWR<Case>(
    locationCaseId
      ? `/postboxes/${parentPostboxId}/cases/${locationCaseId}`
      : null
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    locationCaseId
      ? `/postboxes/${postboxId}/cases/${locationCaseId}/leading-party`
      : null
  );

  useEffect(() => {
    if (locationElement) {
      if (instanceOfParticipation(locationElement)) {
        expandNode(locationElement.caseId);
      }

      if (instanceOfCase(locationElement)) {
        expandNode(locationElement.id);
      }
    }
  }, [expandNode, locationElement]);

  return caseData ? (
    <FjdStack>
      <FjdTree>
        <FjdTreeItem
          active={activeTab?.id === caseData.id}
          collapsible
          expanded={!!treeExpansion[caseData.id]}
          key={caseData.id}
          label={
            leadingParty?.processReference
              ? `${leadingParty?.processReference} | ${caseData.title}`
              : caseData.title
          }
          icon="box"
          iconIntent="warning"
          onClick={() => {
            expandNode(caseData.id);
            navigate(getCasePath(caseData, postboxId));
          }}
          onExpandedChange={() => {
            toggleNode(caseData.id);
          }}
        >
          <Suspense fallback={<FjdSpinner size="s" />}>
            <ParticipationsTree caseId={caseData.id} postboxId={postboxId} />
          </Suspense>
        </FjdTreeItem>
      </FjdTree>
    </FjdStack>
  ) : null;
}
