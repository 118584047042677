import { FjdModal } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { PublicServiceTypeDetails } from '../PublicServiceTypeDetails/PublicServiceType';

interface PublicServiceTypeModalProps {
  onClose: () => void;
  publicServiceTypeId: string;
}

export function PublicServiceTypeModal({
  onClose,
  publicServiceTypeId
}: PublicServiceTypeModalProps) {
  const { t } = useTranslation();

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      heading={t('Verwaltungsleistung')}
      id="public-service-type-details-modal"
      onClose={onClose}
      open
      width="wide"
    >
      <PublicServiceTypeDetails publicServiceTypeId={publicServiceTypeId} />
    </FjdModal>
  );
}
