import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContactPerson,
  CreateContactPersonRequest,
  UpdateContactPersonRequest
} from '../models/ContactPerson';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useContactPerson(postboxId: string) {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const createContactPerson = useCallback(
    async (data: CreateContactPersonRequest) => {
      setLoading(true);

      try {
        const newContactPersonResponse =
          await makeRequestWithFullResponse<ContactPerson>(
            `/postboxes/${postboxId}/contact-persons`,
            'POST',
            data
          );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/contact-persons.*$`)
        );

        alert('success', t('Kontaktperson erfolgreich erstellt.'));
        setLoading(false);

        return makeRequest<ContactPerson>(
          newContactPersonResponse.headers.location,
          'GET'
        );
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, postboxId, t]
  );

  const deleteContactPerson = useCallback(
    async (contactPersonId: string) => {
      setLoading(true);

      try {
        await makeRequest(
          `/postboxes/${postboxId}/contact-persons/${contactPersonId}`,
          'DELETE'
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/contact-persons.*$`)
        );

        alert('success', t('Kontaktperson gelöscht.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  const updateContactPerson = useCallback(
    async (contactPersonId: string, data: UpdateContactPersonRequest) => {
      setLoading(true);

      try {
        const updatedContactPerson = await makeRequest<ContactPerson>(
          `/postboxes/${postboxId}/contact-persons/${contactPersonId}`,
          'PUT',
          data
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/contact-persons.*$`)
        );
        await mutate(
          `/postboxes/${postboxId}/contact-persons/${contactPersonId}`
        );

        alert('success', t('Änderungen gespeichert.'));
        setLoading(false);

        return updatedContactPerson;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, postboxId, t]
  );

  return {
    createContactPerson,
    deleteContactPerson,
    loading,
    updateContactPerson
  };
}
