import { FjdBadge } from 'fjd-react-components';
import { MouseEventHandler } from 'react';
import useSWR from 'swr';

import { Postbox } from '../../../models/Postbox';

interface PostboxBadgeProps {
  onClick?: MouseEventHandler;
  postboxId: string;
}

export function PostboxBadge({ onClick, postboxId }: PostboxBadgeProps) {
  const { data: postbox } = useSWR<Postbox>(`/postboxes/${postboxId}`, {
    suspense: false
  });

  return postbox ? (
    <FjdBadge
      appearance="secondary"
      deletable
      key={postboxId}
      label={postbox.name}
      onClick={onClick}
      size="s"
    />
  ) : null;
}
