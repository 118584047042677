import { formatISO } from 'date-fns';
import {
  FjdButton,
  FjdCard,
  FjdCheckbox,
  FjdFlexSpacer,
  FjdFormControl,
  FjdStack
} from 'fjd-react-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { Document } from '../../../../models/Document';
import { File } from '../../../../models/File';
import { LoadingSpinner } from '../../../../shared/LoadingSpinner';

interface CaseDocumentSelectionProps {
  caseId: string;
  disabledFiles?: string[];
  onAdd?: (documents: Document[]) => void;
  postboxId: string;
}

export function CaseDocumentSelection({
  caseId,
  disabledFiles,
  onAdd,
  postboxId
}: CaseDocumentSelectionProps) {
  const { t } = useTranslation();

  const [selection, setSelection] = useState<File[]>([]);

  const {
    data: caseFiles,
    isValidating,
    pagination
  } = usePaginatedSwr<File>({
    key: `/postboxes/${postboxId}/cases/${caseId}/files`,
    pageSize: 5,
    paginationStyle: 'simple',
    suspense: false
  });

  return (
    <FjdCard elevated solid style={{ minWidth: '24rem' }}>
      <FjdStack spacing="l">
        {isValidating ? (
          <LoadingSpinner />
        ) : (
          <FjdFormControl inputId={'document'} label={t('Dokumente')}>
            <FjdStack spacing="xs">
              {caseFiles && caseFiles.length > 0 ? (
                caseFiles.map((file) => (
                  <FjdCheckbox
                    checked={selection.some((f) => f.id === file.id)}
                    disabled={disabledFiles?.includes(file.id)}
                    id={file.id}
                    key={file.id}
                    label={`${file.originalFileName}`}
                    name="document"
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelection((selection) => [...selection, file]);
                      } else {
                        setSelection((selection) =>
                          selection.filter((f) => f.id !== file.id)
                        );
                      }
                    }}
                  />
                ))
              ) : (
                <>{t('Keine Dokumente vorhanden.')}</>
              )}
            </FjdStack>
          </FjdFormControl>
        )}

        <FjdStack orientation="horizontal" spacing="s">
          {pagination.PaginationComponent}
          <FjdFlexSpacer />
          {caseFiles && caseFiles.length > 0 && (
            <FjdButton
              label={t('Einfügen')}
              onClick={() => {
                if (typeof onAdd === 'function') {
                  onAdd(
                    selection.map((file) => ({
                      filename: file.originalFileName,
                      id: file.id,
                      hash: file.hash,
                      mimeType: file.mimeType,
                      reference: {
                        fileId: file.id,
                        type: 'FILE'
                      },
                      sentOn: formatISO(new Date()),
                      title: file.originalFileName
                    }))
                  );
                }

                setSelection([]);
              }}
              size="s"
            />
          )}
        </FjdStack>
      </FjdStack>
    </FjdCard>
  );
}
