import {
  FjdButton,
  FjdIcon,
  FjdMarkdown,
  FjdStack
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { useElementNavigation } from '../../../../hooks/useElementNavigation';
import { Participation } from '../../../../models/Participation';

interface CreateParticipationFormStep4Props {
  participation: Participation;
  onClose: () => void;
  onReset: () => void;
}

export function CreateParticipationFormStep4({
  participation,
  onClose,
  onReset
}: CreateParticipationFormStep4Props) {
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();

  return (
    <FjdStack spacing="3xl">
      <FjdStack orientation="horizontal" spacing="xl">
        <FjdIcon appearance="success" glyph="checkmark-outline" size="4xl" />
        <FjdMarkdown
          source={t(
            `Herzlichen Glückwunsch, die Mitwirkung wurde erfolgreich ausgelöst und an die mitwirkende Stelle übertragen! Jetzt heißt es abwarten. In der Zwischenzeit können Sie weitere Mitwirkungen auslösen oder Details der ausgelösten Mitwirkung einsehen.`
          )}
        />
      </FjdStack>

      <FjdStack orientation="horizontal" spacing="m">
        <FjdButton
          iconLeft="view"
          label={t('Mitwirkung öffnen')}
          onClick={() => {
            navigateToElement(participation);
            onClose();
          }}
          size="s"
        />
        <FjdButton
          appearance="primary-link"
          iconLeft="add-outline"
          label={t('Neue Mitwirkung auslösen')}
          onClick={onReset}
          size="s"
        />
      </FjdStack>
    </FjdStack>
  );
}
