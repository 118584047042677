import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay,
  FjdModal,
  FjdSpinner
} from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { Case } from '../../../models/Case';
import { LeadingParty } from '../../../models/LeadingParty';
import { PublicServiceType } from '../../../models/PublicServiceType';
import { PublicServiceTypeModal } from '../../PublicServiceType/PublicServiceTypeModal/PublicServiceTypeModal';

interface CaseDetailsProps {
  caseId: string;
  postboxId: string;
}

export function CaseDetails({ caseId, postboxId }: CaseDetailsProps) {
  const { t } = useTranslation();

  const [publicServiceTypeDetail, setPublicServiceTypeDetail] =
    useState<string>();

  const { data: caseData, isValidating } = useSWR<Case>(
    `/postboxes/${postboxId}/cases/${caseId}`
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${postboxId}/cases/${caseId}/leading-party`
  );

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    caseData?.publicServiceTypeId
      ? `/public-service-types/${caseData.publicServiceTypeId}`
      : null
  );

  return caseData ? (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdDescriptionList>
          <FjdDescriptionListItem
            description={
              <a
                href={`/public-service-types/${publicServiceType?.id}`}
                onClick={(event) => {
                  event.preventDefault();
                  setPublicServiceTypeDetail(publicServiceType?.id);
                }}
              >
                {publicServiceType?.name}
              </a>
            }
            term={t('Verwaltungsleistung')}
          />
          {caseData.description && (
            <FjdDescriptionListItem
              allowHTML
              description={caseData.description}
              term={t('Beschreibung')}
              useMarkdown
            />
          )}
          {caseData.startDate && (
            <FjdDescriptionListItem
              description={new Date(caseData.startDate).toLocaleDateString()}
              term={t('Datum der Auslösung')}
            />
          )}
          {leadingParty?.responsibleOrganisationalUnit && (
            <FjdDescriptionListItem
              description={leadingParty?.responsibleOrganisationalUnit}
              term={t('Zuständige Organisationseinheit')}
            />
          )}
          {leadingParty?.fileReference && (
            <FjdDescriptionListItem
              description={leadingParty.fileReference}
              term={t('Aktenzeichen')}
            />
          )}
          {leadingParty?.processReference && (
            <FjdDescriptionListItem
              description={leadingParty.processReference}
              term={t('Geschäftszeichen')}
            />
          )}
        </FjdDescriptionList>
      </FjdLoadingOverlay>

      {publicServiceTypeDetail && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              heading={t('Lade …')}
              id="public-service-type-details-modal"
              open
            >
              <FjdSpinner />
            </FjdModal>
          }
        >
          <PublicServiceTypeModal
            onClose={() => setPublicServiceTypeDetail(undefined)}
            publicServiceTypeId={publicServiceTypeDetail}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
