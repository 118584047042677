import {
  FjdCard,
  FjdContentTabBar,
  FjdContentTabBarItem,
  FjdModal,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import { Suspense, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useContactPerson } from '../../../../hooks/useContactPerson';
import {
  ContactPerson,
  CreateContactPersonRequest,
  CreateContactPersonRequestSchema,
  UpdateContactPersonRequest,
  UpdateContactPersonRequestSchema
} from '../../../../models/ContactPerson';
import { FormOfAddress } from '../../../../models/Person';
import { submitForm } from '../../../../utils/form';
import { UpsertContactPersonFormStep1 } from './UpsertContactPersonFormStep1/UpsertContactPersonFormStep1';
import { UpsertContactPersonFormStep2 } from './UpsertContactPersonFormStep2/UpsertContactPersonFormStep2';

interface UpsertContactPersonModalProps {
  contactPersonToUpdate?: ContactPerson;
  onClose: () => void;
}

export type UpsertContactPersonFormData =
  | CreateContactPersonRequest
  | UpdateContactPersonRequest;

export function UpsertContactPersonModal({
  contactPersonToUpdate,
  onClose
}: UpsertContactPersonModalProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const updateMode = useMemo(
    () => !!contactPersonToUpdate,
    [contactPersonToUpdate]
  );

  const [activeForm, setActiveForm] = useState<HTMLFormElement>();

  const [formData, setFormData] = useState<UpsertContactPersonFormData>(
    updateMode && contactPersonToUpdate
      ? {
          emailAddress: contactPersonToUpdate.emailAddress,
          function: contactPersonToUpdate.function,
          identifiers: contactPersonToUpdate.identifiers,
          name: contactPersonToUpdate.name,
          telephones: contactPersonToUpdate.telephones,
          physicalAddress: contactPersonToUpdate.physicalAddress
        }
      : {
          identifiers: [{ id: 'id' }],
          name: {
            firstName: '',
            formOfAddress: FormOfAddress.NONE,
            lastName: '',
            doctoralDegrees: ''
          },
          telephones: [],
          physicalAddress: undefined
        }
  );

  const [step, setStep] = useState<0 | 1>(0);

  const { createContactPerson, loading, updateContactPerson } =
    useContactPerson(currentPostboxId);

  const heading = useMemo(() => {
    if (updateMode) {
      return t('Kontaktperson bearbeiten');
    }

    return t('Neue Kontaktperson anlegen');
  }, [t, updateMode]);

  const primaryButtonLabel = useMemo(() => {
    return step === 0
      ? t('Weiter')
      : step === 1
      ? updateMode
        ? t('Änderungen speichern')
        : t('Kontaktperson erstellen')
      : undefined;
  }, [step, t, updateMode]);

  const secondaryButtonLabel = useMemo(() => {
    return step === 0 ? t('Schließen') : step === 1 ? t('Zurück') : undefined;
  }, [step, t]);

  const onCreate = useCallback(async () => {
    if (!CreateContactPersonRequestSchema.validate(formData).error) {
      await createContactPerson(formData as CreateContactPersonRequest);

      onClose();
    }
  }, [createContactPerson, formData, onClose]);

  const onUpdate = useCallback(async () => {
    if (
      contactPersonToUpdate &&
      !UpdateContactPersonRequestSchema.validate(formData).error
    ) {
      await updateContactPerson(
        contactPersonToUpdate.id,
        formData as UpdateContactPersonRequest
      );

      onClose();
    }
  }, [contactPersonToUpdate, formData, onClose, updateContactPerson]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={heading}
      id="upsert-contact-person-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => submitForm(activeForm)}
      onSecondaryButtonClick={() => (step === 0 ? onClose() : setStep(0))}
      open
      primaryButtonLabel={primaryButtonLabel}
      secondaryButtonLabel={secondaryButtonLabel}
    >
      <FjdStack spacing="xl">
        <FjdContentTabBar>
          <FjdContentTabBarItem
            active={step === 0}
            label={t('1. Details zur Person')}
            onClick={() => setStep(0)}
          />
          <FjdContentTabBarItem
            active={step === 1}
            label={t('2. Kontaktdaten')}
            onClick={() => {
              if (step === 0) {
                submitForm(activeForm);
              }
            }}
          />
        </FjdContentTabBar>

        {step === 0 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <FjdCard>
              <UpsertContactPersonFormStep1
                initialData={formData}
                onChange={(data) =>
                  setFormData((formData) => ({
                    ...formData,
                    ...data
                  }))
                }
                onInit={(form) => setActiveForm(form)}
                onNext={() => setStep(1)}
              />
            </FjdCard>
          </Suspense>
        )}

        {step === 1 && (
          <Suspense fallback={<FjdSpinner size="s" />}>
            <UpsertContactPersonFormStep2
              initialData={formData}
              onChange={(data) =>
                setFormData((formData) => ({
                  ...formData,
                  ...data
                }))
              }
              onInit={(form) => setActiveForm(form)}
              onNext={updateMode ? onUpdate : onCreate}
            />
          </Suspense>
        )}
      </FjdStack>
    </FjdModal>
  );
}
