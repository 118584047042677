import {
  FjdAutocomplete,
  FjdCard,
  FjdColumns,
  FjdFormControl,
  FjdRichtextEditor,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { Case } from '../../../../models/Case';
import {
  ParticipationObligation,
  UpdateParticipationRequest,
  UpdateParticipationRequestSchema
} from '../../../../models/Participation';
import { PublicServiceType } from '../../../../models/PublicServiceType';
import {
  getParticipationObligationLabel,
  participationTaskOptions
} from '../../../../utils/participation';

interface UpdateParticipationFormStep1Props {
  caseId: string;
  initialData: Partial<FormControls>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  postboxId: string;
}

export type FormControls = UpdateParticipationRequest;

export function UpdateParticipationFormStep1({
  caseId,
  initialData,
  onChange,
  onInit,
  onNext,
  postboxId
}: UpdateParticipationFormStep1Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const { data: caseData } = useSWR<Case>(
    `/postboxes/${postboxId}/cases/${caseId}`
  );

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    caseData ? `/public-service-types/${caseData.publicServiceTypeId}` : null
  );

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: initialData,
    resolver: joiResolver(
      UpdateParticipationRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdStack spacing="m">
        <FjdCard>
          <FjdStack spacing="xl">
            <FjdColumns>
              <FjdFormControl
                inputId="public-service-type"
                label={t('Verwaltungsleistung')}
              >
                <FjdTextInput
                  id="public-service-type"
                  name="public-service-type"
                  readonly
                  value={publicServiceType?.name}
                />
              </FjdFormControl>

              <FjdFormControl inputId="case" label={t('Fall')}>
                <FjdTextInput
                  id="case"
                  name="case"
                  readonly
                  value={caseData?.title}
                />
              </FjdFormControl>
            </FjdColumns>

            <FjdColumns>
              <FjdFormControl
                inputId="title"
                label={t('Titel')}
                validationMessage={errors.title?.message}
              >
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdTextInput
                        autoFocus
                        defaultValue={field.value}
                        id="title"
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={(event) => {
                          field.onChange(event);
                          onChange({ [field.name]: event.target.value });
                        }}
                      />
                    );
                  }}
                />
              </FjdFormControl>

              <FjdFormControl
                inputId="task"
                label={t('Mitwirkungsaufgabe')}
                validationMessage={errors.task?.message}
              >
                <Controller
                  name="task"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdAutocomplete
                        id="task"
                        label={t('Mitwirkungsaufgabe')}
                        onChange={(value) => {
                          field.onChange(value);
                          onChange({ [field.name]: value });
                        }}
                        options={participationTaskOptions}
                        value={field.value}
                      />
                    );
                  }}
                />
              </FjdFormControl>
            </FjdColumns>

            <FjdFormControl
              inputId="description"
              label={t('Beschreibung')}
              optional
              validationMessage={errors.description?.message}
            >
              <Controller
                name="description"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdRichtextEditor
                      id="description"
                      initialValue={field.value}
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange({ [field.name]: value });
                      }}
                      output="html"
                      rows={3}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdColumns>
              <FjdFormControl
                inputId="obligation"
                label={t('Verbindlichkeit der Mitwirkung')}
                validationMessage={errors.obligation?.message}
              >
                <Controller
                  name="obligation"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdSelect
                        disableSearch
                        id="obligation"
                        name={field.name}
                        onChange={(value) => {
                          field.onChange(value);
                          onChange({
                            [field.name]: value as ParticipationObligation
                          });
                        }}
                        options={Object.values(ParticipationObligation).map(
                          (obligation) => ({
                            selected: field.value === obligation,
                            text: getParticipationObligationLabel(
                              obligation,
                              t
                            ),
                            value: obligation
                          })
                        )}
                      />
                    );
                  }}
                />
              </FjdFormControl>

              <FjdFormControl
                inputId="deadline"
                label={t('Mitwirkungsfrist')}
                optional
                validationMessage={errors.deadline?.message}
              >
                <Controller
                  name="deadline"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdTextInput
                        defaultValue={field.value}
                        id="deadline"
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={(event) => {
                          field.onChange(event);
                          onChange({ [field.name]: event.target.value });
                        }}
                        type="date"
                      />
                    );
                  }}
                />
              </FjdFormControl>
            </FjdColumns>

            <FjdFormControl
              inputId="legalBasis"
              label={t('Rechtsgrundlage')}
              optional
              validationMessage={errors.legalBasis?.message}
            >
              <Controller
                name="legalBasis"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdRichtextEditor
                      id="legalBasis"
                      initialValue={field.value}
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);
                        onChange({ [field.name]: value });
                      }}
                      output="html"
                      rows={3}
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdStack>

          <input type="submit" hidden />
        </FjdCard>
      </FjdStack>
    </form>
  );
}
