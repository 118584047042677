import {
  FjdCard,
  FjdColumns,
  FjdFormControl,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import {
  UpdateInvolvedPartyRequest,
  UpdateInvolvedPartyRequestSchema
} from '../../../../models/InvolvedParty';
import { Postbox } from '../../../../models/Postbox';
import { getPostboxOwnerName } from '../../../../utils/postbox';

interface UpdateParticipationVBFormProps {
  initialData: Partial<FormControls>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
}

type FormControls = UpdateInvolvedPartyRequest;

export function UpdateParticipationVBForm({
  initialData,
  onChange,
  onInit,
  onNext
}: UpdateParticipationVBFormProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const { data: postbox } = useSWR<Postbox>(
    `/postboxes/${initialData.postboxId}`
  );

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: initialData,
    resolver: joiResolver(
      UpdateInvolvedPartyRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdCard>
        <FjdStack spacing="xl">
          <FjdColumns>
            <FjdFormControl inputId="name" label={t('Name')}>
              <FjdTextInput
                id="name"
                name="name"
                readonly
                value={getPostboxOwnerName(postbox?.owner)}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="organisationalUnit"
              label={t('Organisationseinheit')}
            >
              <FjdTextInput
                id="organisationalUnit"
                name="organisationalUnit"
                readonly
                value={initialData?.responsibleOrganisationalUnit}
              />
            </FjdFormControl>
          </FjdColumns>

          <FjdColumns>
            <FjdFormControl
              inputId="fileReference"
              label={t('Aktenzeichen')}
              optional
              validationMessage={errors.fileReference?.message}
            >
              <Controller
                name="fileReference"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      autoFocus
                      defaultValue={field.value}
                      id="fileReference"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="processReference"
              label={t('Geschäftszeichen')}
              optional
              validationMessage={errors.processReference?.message}
            >
              <Controller
                name="processReference"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="processReference"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdColumns>
        </FjdStack>

        <input type="submit" hidden />
      </FjdCard>
    </form>
  );
}
