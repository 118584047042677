import {
  Button,
  ButtonGroup,
  Modal,
  Paragraph
} from '@efast_public/fjd-component-library';
import { useTranslation } from 'react-i18next';

interface CreateDataRoomModalProps {
  open: boolean;
  onClose: () => void;
  onCreateDataRoom: () => void;
  caseTitle: string;
  publicServiceTitle?: string;
}

export function CreateDataRoomModal(props: CreateDataRoomModalProps) {
  const { t } = useTranslation();

  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Header id={'CreateDataRoomModal'}>
        {t(`dataRoomCreateModalHeading {{caseTitle}}`, {
          caseTitle: props.caseTitle
        })}
      </Modal.Header>
      <Modal.Content>
        <Paragraph>
          {t(`dataRoomCreateModalText {{caseTitle}} {{publicServiceTitle}}`, {
            caseTitle: props.caseTitle,
            publicServiceTitle: props.publicServiceTitle
          })}
        </Paragraph>
      </Modal.Content>
      <Modal.Footer>
        <ButtonGroup alignment="none" auto direction="streched">
          <Button onClick={props.onClose}>{t('Abbrechen')}</Button>
          <Button onClick={props.onCreateDataRoom}>
            {t('dataRoomCreateText')}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}
