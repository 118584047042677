import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import EscalationLevels from './EscalationLevels/EscalationLevels';

export function PostboxNotifications() {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('Benachrichtigungen')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <EscalationLevels postboxId={currentPostboxId} />
    </>
  );
}

export { PostboxNotifications as default } from './PostboxNotifications';
