import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { useTable } from '../../../../hooks/useTable';
import { Participation } from '../../../../models/Participation';
import { CasesTableParticipationRow } from '../CasesTableParticipationRow/CasesTableParticipationRow';

interface CasesTableParticipationRowsProps {
  caseId: string;
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  postboxId: string;
  table: ReturnType<typeof useTable>;
}

export function CasesTableParticipationRows({
  caseId,
  onOpenPublicServiceType,
  postboxId,
  table
}: CasesTableParticipationRowsProps) {
  const { t } = useTranslation();

  const { data: participations } = usePaginatedSwr<Participation>({
    elementLabel: t('Mitwirkungen'),
    filter: [{ key: 'caseId', value: caseId }],
    key: `/postboxes/${postboxId}/participation-results`,
    pageSize: 9999
  });

  return participations ? (
    <>
      {participations.length === 0 && (
        <FjdVirtualizedTableRow>
          {table.activeColumns.map((column) => (
            <FjdVirtualizedTableCell
              key={`no-participations-${column.id}`}
              level={column.id === 'name' ? 2 : undefined}
              levelIndent="xxl"
              maxWidth={column.maxWidth}
              minWidth={column.minWidth}
              verticalSeparator
              width={column.width}
            >
              {column.id === 'name' && <>{t('Keine Mitwirkungen')}</>}
            </FjdVirtualizedTableCell>
          ))}
        </FjdVirtualizedTableRow>
      )}

      {participations.map((participation, index) => (
        <CasesTableParticipationRow
          first={index === 0}
          key={participation.id}
          last={index === participations.length - 1}
          onOpenPublicServiceType={onOpenPublicServiceType}
          participation={participation}
          table={table}
        />
      ))}
    </>
  ) : null;
}
