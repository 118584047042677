import {
  FjdLoadingOverlay,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { Message } from '../../../models/Message';
import { MessagesTableRow } from '../MessagesTableRow/MessagesTableRow';

interface MessagesTableProps {
  participationId: string;
  postboxId: string;
}

export function MessagesTable({
  participationId,
  postboxId
}: MessagesTableProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const {
    data: messages,
    isValidating,
    pagination
  } = usePaginatedSwr<Message>({
    elementLabel: t('Nachrichten'),
    key: `/postboxes/${postboxId}/participations/${participationId}/message-results`,
    sortByOrder: 'DESC',
    sortByProperty: 'RECEIVED_ON'
  });

  return messages ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol maxWidth="2.75rem"></FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="19rem">
                {t('Art')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('Betreff')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="10rem">
                {t('Empfangsdatum')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="12rem">
                {t('Gesendet von')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="7rem">
                {t('Dokumente')}
              </FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {messages.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Nachrichten gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {messages.map((message, index) => (
            <MessagesTableRow
              even={index % 2 === 1}
              key={message?.id}
              message={message}
              postboxId={currentPostboxId}
            />
          ))}
        </FjdVirtualizedTable>

        {pagination.PaginationComponent}
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
