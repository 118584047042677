import { TFunction } from 'i18next';

import { ParticipationObligation } from '../models/Participation';

export const participationTaskOptions: string[] = [
  'Autorisierung',
  'Bekanntgabe',
  'Bewilligung',
  'Eintragung',
  'Einvernehmen',
  'Entgegennahme',
  'Erlaubnis',
  'Genehmigung',
  'Kenntnisnahme',
  'Nachreichen von Informationen / Unterlagen',
  'Prüfung',
  'Stellungnahme',
  'Zulassung'
];

export function getParticipationObligationLabel(
  obligation: ParticipationObligation,
  t: TFunction
): string {
  if (!obligation) {
    return '';
  }

  switch (obligation) {
    case ParticipationObligation.MANDATORY:
      return t('verpflichtend');
    case ParticipationObligation.OPTIONAL:
      return t('optional');
    default:
      return '';
  }
}
