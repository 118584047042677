import { FjdHorizontalRule, FjdMenu, FjdMenuItem } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../hooks/usePaginatedSwr';
import { useUserRoles } from '../hooks/useUserRoles';
import { Postbox } from '../models/Postbox';
import { getPostboxPath } from '../utils/router';
import { truncate } from '../utils/string';
import { RouterLink } from './RouterLink';

export function PostboxMenu() {
  const { isApplicationAdmin } = useUserRoles();
  const { t } = useTranslation();

  const { data: postboxes } = usePaginatedSwr<Postbox>({
    elementLabel: t('Postfächer'),
    key: isApplicationAdmin ? '/postbox-results' : '/users/postboxes',
    pageSize: 5
  });

  return postboxes ? (
    <FjdMenu>
      {postboxes.map((postbox) => (
        <RouterLink
          component={({ href, onClick }) => (
            <div>
              <FjdMenuItem
                appearance="light"
                href={href}
                label={truncate(postbox.name)}
                onClick={onClick}
              />
            </div>
          )}
          key={postbox.id}
          to={getPostboxPath(postbox)}
        />
      ))}

      <FjdHorizontalRule />

      <RouterLink
        component={({ href, onClick }) => (
          <FjdMenuItem
            appearance="light"
            href={href}
            label={t('Alle Postfächer anzeigen')}
            onClick={onClick}
          />
        )}
        to="/postboxes"
      />
    </FjdMenu>
  ) : null;
}
