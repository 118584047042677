import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { useTable } from '../../../../hooks/useTable';
import { Message } from '../../../../models/Message';
import { CasesTableMessageRow } from '../CasesTableMessageRow/CasesTableMessageRow';

interface CasesTableMessageRowsProps {
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  participationId: string;
  postboxId: string;
  table: ReturnType<typeof useTable>;
}

export function CasesTableMessageRows({
  onOpenPublicServiceType,
  participationId,
  postboxId,
  table
}: CasesTableMessageRowsProps) {
  const { t } = useTranslation();

  const { data: messages } = usePaginatedSwr<Message>({
    elementLabel: t('Nachrichten'),
    key: `/postboxes/${postboxId}/participations/${participationId}/message-results`,
    pageSize: 9999,
    sortByOrder: 'DESC',
    sortByProperty: 'RECEIVED_ON'
  });

  return messages ? (
    <>
      {messages.length === 0 && (
        <FjdVirtualizedTableRow>
          {table.activeColumns.slice(0, 3).map((column) => (
            <FjdVirtualizedTableCell
              key={`no-messages-${column.id}`}
              level={column.id === 'name' ? 2 : undefined}
              levelIndent="xxl"
              maxWidth={column.maxWidth}
              minWidth={column.minWidth}
              verticalSeparator
              width={column.width}
            >
              {column.id === 'name' && <>{t('Keine Nachrichten')}</>}
            </FjdVirtualizedTableCell>
          ))}
        </FjdVirtualizedTableRow>
      )}

      {messages.map((message, index) => (
        <CasesTableMessageRow
          first={index === 0}
          key={message.id}
          last={index === messages.length - 1}
          message={message}
          onOpenPublicServiceType={onOpenPublicServiceType}
          table={table}
        />
      ))}
    </>
  ) : null;
}
