export enum Privileges {
  ADD_FILES = 'ADD_FILES',
  ADMIN = 'ADMIN',
  LIST = 'LIST',
  LIST_FILES = 'LIST_FILES',
  LIST_MESSAGES = 'LIST_MESSAGES',
  MARK_AS_RECEIVED = 'MARK_AS_RECEIVED',
  READ = 'READ',
  READ_MESSAGE = 'READ_MESSAGE',
  REGISTER = 'REGISTER',
  WRITE = 'WRITE',
  WRITE_MESSAGE = 'WRITE_MESSAGE'
}
