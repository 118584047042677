import {
  FjdCard,
  FjdColumns,
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Case } from '../../../models/Case';
import { InvolvedParty } from '../../../models/InvolvedParty';
import { LeadingParty } from '../../../models/LeadingParty';
import { Message } from '../../../models/Message';
import { Participation } from '../../../models/Participation';
import { Postbox } from '../../../models/Postbox';
import { getMessageTypeLabel } from '../../../utils/message';
import { getPostboxOwnerName } from '../../../utils/postbox';
import { getCasePath, getParticipationPath } from '../../../utils/router';
import { defaultDateFormatOptions } from '../../../utils/string';

interface MessageDetailsProps {
  caseId: string;
  messageId: string;
  participationId: string;
  postboxId: string;
}

export function MessageDetails({
  caseId,
  messageId,
  participationId,
  postboxId
}: MessageDetailsProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();

  const { data: message, isValidating } = useSWR<Message>(
    `/postboxes/${postboxId}/participations/${participationId}/messages/${messageId}`
  );

  const { data: participation } = useSWR<Participation>(
    `/postboxes/${postboxId}/participations/${participationId}`
  );

  const { data: caseData } = useSWR<Case>(
    participation
      ? `/postboxes/${postboxId}/cases/${participation.caseId}`
      : null
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${postboxId}/cases/${caseId}/leading-party`
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${postboxId}/participations/${participationId}/involved-party`
  );

  const { data: senderPostbox } = useSWR<Postbox>(
    leadingParty && involvedParty && message
      ? message.fromLeader
        ? `/postboxes/${leadingParty.postboxId}`
        : `/postboxes/${involvedParty.postboxId}`
      : null,
    {
      suspense: false
    }
  );

  const { data: recipientPostbox } = useSWR<Postbox>(
    leadingParty && involvedParty && message
      ? !message.fromLeader
        ? `/postboxes/${leadingParty.postboxId}`
        : `/postboxes/${involvedParty.postboxId}`
      : null,
    {
      suspense: false
    }
  );

  return caseData && message && participation ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdColumns spacing="xl">
        <FjdCard>
          <FjdDescriptionList>
            <FjdDescriptionListItem
              description={getPostboxOwnerName(senderPostbox?.owner)}
              maxLines={1}
              term={t('Absender')}
            />
            <FjdDescriptionListItem
              description={getPostboxOwnerName(recipientPostbox?.owner)}
              maxLines={1}
              term={t('Empfänger')}
            />
            <FjdDescriptionListItem
              description={getMessageTypeLabel(message.type, t)}
              maxLines={1}
              term={t('Art der Nachricht')}
            />
            <FjdDescriptionListItem
              description={message.subject}
              maxLines={1}
              term={t('Betreff')}
            />
          </FjdDescriptionList>
        </FjdCard>

        <FjdCard>
          <FjdDescriptionList>
            <FjdDescriptionListItem
              description={
                <Link
                  to={getParticipationPath(participation, currentPostboxId)}
                >
                  {participation?.title}
                </Link>
              }
              maxLines={1}
              term={t('Mitwirkung')}
            />
            <FjdDescriptionListItem
              description={
                <Link to={getCasePath(caseData, currentPostboxId)}>
                  {caseData?.title}
                </Link>
              }
              maxLines={1}
              term={t('Fall')}
            />
            <FjdDescriptionListItem
              description={new Date(message.sentOn).toLocaleString(
                [],
                defaultDateFormatOptions
              )}
              maxLines={1}
              term={t('Versendet am')}
            />
            <FjdDescriptionListItem
              description={
                message.receivedOn
                  ? new Date(message.receivedOn).toLocaleString(
                      [],
                      defaultDateFormatOptions
                    )
                  : '-'
              }
              maxLines={1}
              term={t('Empfangen am')}
            />
          </FjdDescriptionList>
        </FjdCard>
      </FjdColumns>
    </FjdLoadingOverlay>
  ) : null;
}
