import { AxiosError } from 'axios';
import {
  FjdAlert,
  FjdBaseLayout,
  FjdButton,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useCallback, useMemo } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { Footer } from './Footer';
import { Header } from './Header';
import { LanguageSwitch } from './LanguageSwitch';

export function ServerError({ error }: FallbackProps) {
  const { t } = useTranslation();

  const errorObject = useMemo(
    () =>
      Buffer.from(
        JSON.stringify({
          axios: (error as any).isAxiosError
            ? (error as AxiosError).toJSON()
            : undefined,
          message: error?.message,
          stack: error?.stack
        })
      ).toString('base64'),
    [error]
  );

  const reload = useCallback(() => {
    window.location.href = window.location.href.split('#')[0];
  }, []);

  return (
    <FjdBaseLayout
      footer={<Footer />}
      footerToolsLeft={<LanguageSwitch />}
      header={<Header />}
    >
      <FjdStack spacing="xl">
        <FjdAlert
          appearance="muted"
          closable={false}
          intent="error"
          title="Da ist leider etwas schiefgegangen."
        />

        <FjdTextInput id="error" readonly value={errorObject} />

        <FjdButton label={t('Neuladen')} onClick={reload} />
      </FjdStack>
    </FjdBaseLayout>
  );
}
