import { Schema } from 'joi';
import * as Joi from 'joi';
import { Except } from 'type-fest';

import {
  NaturalPerson,
  NaturalPersonNameSchema,
  OrganisationalUnitSchema,
  OrganizationNameSchema,
  PersonType,
  PrivateLegalEntity,
  PublicOrganization,
  TypeOfPublicOrganizationSchema
} from './Person';

export interface Postbox<
  OwnerType = NaturalPerson | PrivateLegalEntity | PublicOrganization
> {
  id: string;
  name: string;
  note?: string;
  notifications: PostboxNotification[];
  owner: OwnerType;
}

export interface PostboxNotification {
  escalationLevel: number;
  email: string;
}

export enum NotificationDuration {
  IMMEDIATE = 'IMMEDIATE',
  ONE_DAY = 'ONE_DAY',
  TWO_DAYS = 'TWO_DAYS',
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  ONE_MONTH = 'ONE_MONTH'
}

export type CreatePostboxRequest = Except<Postbox, 'id'>;

export type UpdatePostboxRequest = CreatePostboxRequest;

export type AuthorizePostboxUserRequest = { email: string };

export const AuthorizePostboxUserRequestSchema: Schema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
});

export const CreatePostboxRequestSchema: Schema = Joi.object({
  name: Joi.string().required(),
  note: Joi.string().optional().allow(''),
  notifications: Joi.array()
    .items(
      Joi.object({
        escalationLevel: Joi.number().min(0).required(),
        email: Joi.string()
          .email({ tlds: { allow: false } })
          .required()
      }).optional()
    )
    .required(),
  owner: Joi.object()
    .keys({
      name: NaturalPersonNameSchema.when('type', {
        is: PersonType.NATURAL_PERSON,
        otherwise: Joi.forbidden()
      }),
      organisationalUnit: OrganisationalUnitSchema.when('type', {
        is: PersonType.PRIVATE_LEGAL_ENTITY,
        otherwise: OrganisationalUnitSchema.when('type', {
          is: PersonType.PUBLIC_ORGANISATION,
          otherwise: Joi.forbidden()
        })
      }),
      organizationName: OrganizationNameSchema.when('type', {
        is: PersonType.PRIVATE_LEGAL_ENTITY,
        otherwise: OrganizationNameSchema.when('type', {
          is: PersonType.PUBLIC_ORGANISATION,
          otherwise: Joi.forbidden()
        })
      }),
      type: Joi.string()
        .valid(...Object.values(PersonType))
        .required(),
      typeOfPublicOrganization: TypeOfPublicOrganizationSchema.when('type', {
        is: PersonType.PUBLIC_ORGANISATION,
        otherwise: Joi.forbidden()
      })
    })
    .required(),
  publicServiceTypeIds: Joi.array().items(Joi.string()).optional()
});

export const UpdatePostboxRequestSchema = CreatePostboxRequestSchema;
