import FileSaver from 'file-saver';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { File as PdbkFile } from '../models/File';
import { matchMutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export function useFile(
  postboxId: string,
  currentPostboxId: string,
  caseId: string
) {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const deleteFile = useCallback(
    async (fileId: string, silent?: boolean) => {
      setLoading(true);

      try {
        await makeRequest(
          `/postboxes/${postboxId}/cases/${caseId}/files/${fileId}`,
          'DELETE'
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/cases/${caseId}/files.*$`)
        );

        await matchMutate(
          new RegExp(`^/postboxes/${currentPostboxId}/cases/${caseId}/files.*$`)
        );

        if (!silent) {
          alert('success', t('Dokument wurde erfolgreich gelöscht.'));
        }

        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, caseId, currentPostboxId, makeRequest, postboxId, t]
  );

  const downloadFile = useCallback(
    async (file: PdbkFile) => {
      const blob = await makeRequest<Blob>(
        `/postboxes/${postboxId}/cases/${caseId}/files/${file.id}?currentPostboxId=${currentPostboxId}`,
        'GET',
        undefined,
        { responseType: 'blob' }
      );

      FileSaver.saveAs(blob, file.originalFileName);
    },
    [caseId, currentPostboxId, makeRequest, postboxId]
  );

  const uploadFile = useCallback(
    async (
      files: File[],
      onUploadProgress?: (event: ProgressEvent) => void,
      silent?: boolean
    ) => {
      setLoading(true);

      const formData = new FormData();

      files.forEach((file) => {
        formData.append('file', file);
      });

      try {
        const newFile = await makeRequest<PdbkFile>(
          `/postboxes/${postboxId}/cases/${caseId}/files?currentPostboxId=${currentPostboxId}`,
          'POST',
          formData,
          { onUploadProgress }
        );

        await matchMutate(
          new RegExp(`^/postboxes/${postboxId}/cases/${caseId}/files.*$`)
        );

        await matchMutate(
          new RegExp(`^/postboxes/${currentPostboxId}/cases/${caseId}/files.*$`)
        );

        if (!silent) {
          alert('success', t('Dokument erfolgreich hochgeladen.'));
        }

        setLoading(false);

        return newFile;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, caseId, currentPostboxId, makeRequest, postboxId, t]
  );

  return { deleteFile, downloadFile, loading, uploadFile };
}
