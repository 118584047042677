import { User } from './User';
import { PersonType } from './Person';
import { AccountType } from './AccountType';

export interface Attributes {
  postbox_identifier: string[];
}

export const onCreatePostboxRequest = (user: User) => {
  let postboxIdentifier = '';
  user?.attributes?.postbox_identifier.map((id) => {
    postboxIdentifier = id;
    return postboxIdentifier;
  });
  if (postboxIdentifier === AccountType.SERVICEKONTO) {
    if (user.firstName !== undefined && user.lastName !== undefined) {
      return {
        name: user.firstName + ' ' + user.lastName,
        notifications: [],
        note: ' ',
        owner: {
          type: PersonType.NATURAL_PERSON,
          name: {
            formOfAddress: '',
            firstName: user.firstName,
            lastName: user.lastName
          }
        }
      };
    }
  } else if (postboxIdentifier === AccountType.ORGANISATIONSKONTO) {
    return {
      name: user.firstName + ' ' + user.lastName,
      notifications: [],
      note: ' ',
      owner: {
        type: PersonType.PRIVATE_LEGAL_ENTITY,
        organizationName: postboxIdentifier,
        organisationalUnit: postboxIdentifier
      }
    };
  }
};
