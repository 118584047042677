import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';

import { useTable } from '../../../../hooks/useTable';
import { useTableCellRenderer } from '../../../../hooks/useTableCellRenderer';
import { Message } from '../../../../models/Message';

interface CasesTableMessageRowProps {
  first: boolean;
  last: boolean;
  message: Message;
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  table: ReturnType<typeof useTable>;
}

export function CasesTableMessageRow({
  first,
  last,
  message,
  onOpenPublicServiceType,
  table
}: CasesTableMessageRowProps) {
  const { isJoinedCell, renderCell } = useTableCellRenderer({
    element: message,
    first,
    last,
    onOpenPublicServiceType,
    table,
    treeId: 'cases-table'
  });

  return message ? (
    <FjdVirtualizedTableRow bordered={false} highlightOnHover key={message?.id}>
      {table.activeColumns.map((column) => (
        <FjdVirtualizedTableCell
          format={column.format || 'default'}
          horizontalSeparator={!isJoinedCell(column.id)}
          level={column.id === 'name' ? 2 : undefined}
          levelIndent="5xl"
          key={`${message.id}-${column.id}`}
          maxWidth={column.maxWidth}
          minWidth={column.minWidth}
          verticalSeparator
          width={column.width}
        >
          {renderCell(column.id)}
        </FjdVirtualizedTableCell>
      ))}
    </FjdVirtualizedTableRow>
  ) : null;
}
