import {
  FjdButton,
  FjdCard,
  FjdCheckbox,
  FjdColumns,
  FjdFormControl,
  FjdHorizontalRule,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import Joi from 'joi';
import { Fragment, useEffect, useRef } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useFieldArray,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Except } from 'type-fest';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../../hooks/useJoi';
import {
  AddressType,
  InternationalAddress,
  NationalAddress,
  PostboxAddress
} from '../../../../../models/Address';
import {
  CreateContactPersonRequest,
  EmailAddressSchema,
  PhysicalAddressSchema,
  TelephonesSchema
} from '../../../../../models/ContactPerson';
import {
  countryOptions,
  getAddressTypeLabel
} from '../../../../../utils/address';

interface UpsertContactPersonFormStep2Props {
  initialData?: Partial<CreateContactPersonRequest>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
}

export type FormControls = Except<
  CreateContactPersonRequest,
  'identifiers' | 'name'
>;

export function UpsertContactPersonFormStep2({
  initialData,
  onChange,
  onInit,
  onNext
}: UpsertContactPersonFormStep2Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      emailAddress: initialData?.emailAddress,
      physicalAddress: initialData?.physicalAddress,
      telephones: initialData?.telephones
    },
    resolver: joiResolver(
      Joi.object({
        emailAddress: EmailAddressSchema,
        physicalAddress: PhysicalAddressSchema,
        telephones: TelephonesSchema
      }).messages({
        ...validationMessages,
        'array.min': t('Bitte geben Sie mindestens zwei Adresszeilen ein.'),
        'array.includesRequiredUnknowns': t(
          'Bitte geben Sie mindestens zwei Adresszeilen ein.'
        ),
        'string.max': t('Die Postleitzahl muss genau 5 Zeichen lang sein.'),
        'string.min': t('Die Postleitzahl muss genau 5 Zeichen lang sein.')
      })
    ) as Resolver<FormControls, object>
  });

  const {
    append: appendPhone,
    fields: telephones,
    remove: removePhone,
    update: updatePhone
  } = useFieldArray({
    control,
    name: 'telephones',
    keyName: '_id'
  });

  const physicalAddress = watch('physicalAddress');

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  useEffect(() => {
    if (physicalAddress?.type === AddressType.INTERNATIONAL) {
      unregister('physicalAddress.addressSupplement');
      unregister('physicalAddress.city');
      unregister('physicalAddress.houseNumber');
      unregister('physicalAddress.houseNumberSuffix');
      unregister('physicalAddress.postOfficeBox');
      unregister('physicalAddress.postalCode');
      unregister('physicalAddress.street');
    }

    if (physicalAddress?.type === AddressType.NATIONAL) {
      unregister('physicalAddress.country');
      unregister('physicalAddress.lines');
      unregister('physicalAddress.postOfficeBox');
    }

    if (physicalAddress?.type === AddressType.POSTBOX) {
      unregister('physicalAddress.addressSupplement');
      unregister('physicalAddress.country');
      unregister('physicalAddress.houseNumber');
      unregister('physicalAddress.houseNumberSuffix');
      unregister('physicalAddress.lines');
      unregister('physicalAddress.street');
    }
  }, [physicalAddress?.type, unregister]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdStack spacing="xl">
        <FjdCard>
          <FjdStack spacing="xl">
            <FjdFormControl
              inputId="emailAddress"
              label={t('E-Mail-Adresse')}
              optional
              validationMessage={errors.emailAddress?.message}
            >
              <Controller
                name="emailAddress"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="emailAddress"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);

                        onChange({
                          emailAddress: event.target.value
                        });
                      }}
                      type="tel"
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdStack>
        </FjdCard>

        <FjdCard>
          <FjdStack spacing="xl">
            {telephones.map((telephone, index) => (
              <Fragment key={telephone._id}>
                <FjdFormControl
                  inputId={`telephones.${index}.number`}
                  label={t('Telefonnummer')}
                  validationMessage={
                    errors.telephones?.[index]?.number?.message
                  }
                >
                  <div>
                    <FjdStack orientation="horizontal" spacing="xl">
                      <Controller
                        name={`telephones.${index}.number`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id={`telephones.${index}.number`}
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                field.onChange(event);

                                updatePhone(index, {
                                  ...telephones[index],
                                  [field.name.split('.')[2]]: event.target.value
                                });

                                onChange({
                                  telephones: telephones.map((p, i) =>
                                    i === index
                                      ? {
                                          ...p,
                                          [field.name.split('.')[2]]:
                                            event.target.value
                                        }
                                      : p
                                  )
                                });
                              }}
                              type="tel"
                            />
                          );
                        }}
                      />

                      <FjdButton
                        appearance="primary-link"
                        hideLabel
                        iconLeft="subtract-outline"
                        label={t('Telefonnummer entfernen')}
                        onClick={() => {
                          removePhone(index);
                          onChange({
                            telephones: telephones.filter((_, i) => i !== index)
                          });
                        }}
                        size="s"
                      />
                    </FjdStack>
                  </div>
                </FjdFormControl>

                <FjdFormControl
                  inputId={`telephones.${index}.description`}
                  key={index}
                  label={t('Bemerkung zur Telefonnummer')}
                  optional
                  validationMessage={
                    errors.telephones?.[index]?.description?.message
                  }
                >
                  <div>
                    <FjdStack orientation="horizontal" spacing="xl">
                      <Controller
                        name={`telephones.${index}.description`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id={`telephones.${index}.description`}
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                field.onChange(event);

                                updatePhone(index, {
                                  ...telephones[index],
                                  [field.name.split('.')[2]]: event.target.value
                                });

                                onChange({
                                  telephones: telephones.map((p, i) =>
                                    i === index
                                      ? {
                                          ...p,
                                          [field.name.split('.')[2]]:
                                            event.target.value
                                        }
                                      : p
                                  )
                                });
                              }}
                            />
                          );
                        }}
                      />

                      <Controller
                        name={`telephones.${index}.mobile`}
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdCheckbox
                              checked={field.value}
                              id={`telephones.${index}.mobile`}
                              label={t('Mobilnummer')}
                              name={field.name}
                              onChange={(event) => {
                                field.onChange(event);

                                updatePhone(index, {
                                  ...telephones[index],
                                  [field.name.split('.')[2]]:
                                    event.target.checked
                                });

                                onChange({
                                  telephones: telephones.map((p, i) =>
                                    i === index
                                      ? {
                                          ...p,
                                          [field.name.split('.')[2]]:
                                            event.target.checked
                                        }
                                      : p
                                  )
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </FjdStack>
                  </div>
                </FjdFormControl>

                <FjdHorizontalRule />
              </Fragment>
            ))}

            <FjdButton
              appearance="primary-link"
              iconLeft="add-outline"
              label={t('Telefonnummer hinzufügen')}
              onClick={() => {
                const newPhone = { description: '', mobile: false, number: '' };
                appendPhone(newPhone);
                onChange({ telephones: [...telephones, newPhone] });
              }}
              size="s"
            />
          </FjdStack>
        </FjdCard>

        <FjdCard>
          <FjdStack spacing="xl">
            <FjdFormControl
              inputId="physicalAddress.type"
              label={t('Anschrift')}
              validationMessage={errors.physicalAddress?.type?.message}
            >
              <Controller
                name="physicalAddress.type"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdSelect
                      disableSearch
                      id="physicalAddress.type"
                      name={field.name}
                      onChange={(value) => {
                        field.onChange(value);

                        if (value === '') {
                          onChange({ physicalAddress: undefined });
                        } else {
                          onChange({
                            physicalAddress: {
                              ...(physicalAddress as
                                | InternationalAddress
                                | NationalAddress
                                | PostboxAddress),
                              [field.name.split('.')[1]]: value
                            }
                          });
                        }
                      }}
                      options={[
                        {
                          selected: physicalAddress === undefined,
                          text: t('- keine -'),
                          value: ''
                        },
                        ...Object.values(AddressType).map((addressType) => ({
                          selected: field.value === addressType,
                          text: getAddressTypeLabel(addressType, t),
                          value: addressType
                        }))
                      ]}
                    />
                  );
                }}
              />
            </FjdFormControl>

            {physicalAddress?.type === AddressType.POSTBOX && (
              <FjdFormControl
                inputId="physicalAddress.postOfficeBox"
                label={t('Postfach')}
                validationMessage={
                  (
                    errors.physicalAddress as DeepMap<
                      PostboxAddress,
                      FieldError
                    >
                  )?.postOfficeBox?.message
                }
              >
                <Controller
                  name="physicalAddress.postOfficeBox"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdTextInput
                        defaultValue={field.value}
                        id="physicalAddress.postOfficeBox"
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={(event) => {
                          field.onChange(event);
                          onChange({
                            physicalAddress: {
                              ...physicalAddress,
                              [field.name.split('.')[1]]: event.target.value
                            }
                          });
                        }}
                      />
                    );
                  }}
                />
              </FjdFormControl>
            )}

            {physicalAddress?.type === AddressType.NATIONAL && (
              <>
                <FjdColumns>
                  <FjdFormControl
                    inputId="physicalAddress.street"
                    label={t('Straße')}
                    validationMessage={
                      (
                        errors.physicalAddress as DeepMap<
                          NationalAddress,
                          FieldError
                        >
                      )?.street?.message
                    }
                  >
                    <Controller
                      name="physicalAddress.street"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="physicalAddress.street"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={(event) => {
                              field.onChange(event);
                              onChange({
                                physicalAddress: {
                                  ...physicalAddress,
                                  [field.name.split('.')[1]]: event.target.value
                                }
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>

                  <FjdStack orientation="horizontal">
                    <FjdFormControl
                      inputId="physicalAddress.houseNumber"
                      label={t('Hausnummer')}
                      optional
                      validationMessage={
                        (
                          errors.physicalAddress as DeepMap<
                            NationalAddress,
                            FieldError
                          >
                        )?.houseNumber?.message
                      }
                    >
                      <Controller
                        name="physicalAddress.houseNumber"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id="physicalAddress.houseNumber"
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                field.onChange(event);
                                onChange({
                                  physicalAddress: {
                                    ...physicalAddress,
                                    [field.name.split('.')[1]]:
                                      event.target.value
                                  }
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </FjdFormControl>

                    <FjdFormControl
                      inputId="physicalAddress.houseNumberSuffix"
                      label={t('Zusatz')}
                      optional
                      validationMessage={
                        (
                          errors.physicalAddress as DeepMap<
                            NationalAddress,
                            FieldError
                          >
                        )?.houseNumberSuffix?.message
                      }
                    >
                      <Controller
                        name="physicalAddress.houseNumberSuffix"
                        control={control}
                        render={({ field }) => {
                          return (
                            <FjdTextInput
                              defaultValue={field.value}
                              id="physicalAddress.houseNumberSuffix"
                              name={field.name}
                              onBlur={field.onBlur}
                              onChange={(event) => {
                                field.onChange(event);
                                onChange({
                                  physicalAddress: {
                                    ...physicalAddress,
                                    [field.name.split('.')[1]]:
                                      event.target.value
                                  }
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </FjdFormControl>
                  </FjdStack>
                </FjdColumns>

                <FjdFormControl
                  inputId="physicalAddress.addressSupplement"
                  label={t('Adresszusatz')}
                  optional
                  validationMessage={
                    (
                      errors.physicalAddress as DeepMap<
                        NationalAddress,
                        FieldError
                      >
                    )?.addressSupplement?.message
                  }
                >
                  <Controller
                    name="physicalAddress.addressSupplement"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="physicalAddress.addressSupplement"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={(event) => {
                            field.onChange(event);
                            onChange({
                              physicalAddress: {
                                ...physicalAddress,
                                [field.name.split('.')[1]]: event.target.value
                              }
                            });
                          }}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </>
            )}

            {(physicalAddress?.type === AddressType.NATIONAL ||
              physicalAddress?.type === AddressType.POSTBOX) && (
              <>
                <FjdColumns>
                  <FjdFormControl
                    inputId="physicalAddress.postalCode"
                    label={t('Postleitzahl')}
                    validationMessage={
                      (
                        errors.physicalAddress as DeepMap<
                          NationalAddress,
                          FieldError
                        >
                      )?.postalCode?.message
                    }
                  >
                    <Controller
                      name="physicalAddress.postalCode"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="physicalAddress.postalCode"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={(event) => {
                              field.onChange(event);
                              onChange({
                                physicalAddress: {
                                  ...physicalAddress,
                                  [field.name.split('.')[1]]: event.target.value
                                }
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>

                  <FjdFormControl
                    inputId="physicalAddress.city"
                    label={t('Ort')}
                    validationMessage={
                      (
                        errors.physicalAddress as DeepMap<
                          NationalAddress,
                          FieldError
                        >
                      )?.city?.message
                    }
                  >
                    <Controller
                      name="physicalAddress.city"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdTextInput
                            defaultValue={field.value}
                            id="physicalAddress.city"
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={(event) => {
                              field.onChange(event);
                              onChange({
                                physicalAddress: {
                                  ...physicalAddress,
                                  [field.name.split('.')[1]]: event.target.value
                                }
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>
                </FjdColumns>
              </>
            )}

            {physicalAddress?.type === AddressType.INTERNATIONAL && (
              <>
                <FjdFormControl
                  inputId="physicalAddress.lines"
                  label={t('Adresszeilen')}
                  validationMessage={
                    (
                      (
                        errors.physicalAddress as DeepMap<
                          InternationalAddress,
                          FieldError
                        >
                      )?.lines as unknown as FieldError
                    )?.message
                  }
                >
                  <Controller
                    name="physicalAddress.lines"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="physicalAddress.lines"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={(event) => {
                            field.onChange(event.target.value.split('\n'));
                            onChange({
                              physicalAddress: {
                                ...physicalAddress,
                                [field.name.split('.')[1]]:
                                  event.target.value.split('\n')
                              }
                            });
                          }}
                          rows={3}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                <FjdFormControl
                  inputId="physicalAddress.country"
                  label={t('Land')}
                  validationMessage={
                    (
                      errors.physicalAddress as DeepMap<
                        InternationalAddress,
                        FieldError
                      >
                    )?.country?.message
                  }
                >
                  <Controller
                    name="physicalAddress.country"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdSelect
                          id="physicalAddress.country"
                          name={field.name}
                          onChange={(value) => {
                            field.onChange(value);
                            onChange({
                              physicalAddress: {
                                ...physicalAddress,
                                [field.name.split('.')[1]]: value
                              }
                            });
                          }}
                          options={[
                            {
                              placeholder: true,
                              selected: field.value === undefined,
                              text: t('- Bitte wählen -'),
                              value: ''
                            },
                            ...countryOptions.map((country) => ({
                              selected: field.value === country.value,
                              text: country.label,
                              value: country.value
                            }))
                          ]}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </>
            )}
          </FjdStack>
        </FjdCard>
      </FjdStack>

      <input type="submit" hidden />
    </form>
  );
}
