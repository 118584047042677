import { FjdMarkdown, FjdModal } from 'fjd-react-components';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { usePostbox } from '../../../../hooks/usePostbox';

interface UnauthorizeUserModalProps {
  onClose: () => void;
  postboxId: string;
  userId: string;
}

export function UnauthorizeUserModal({
  onClose,
  postboxId,
  userId
}: UnauthorizeUserModalProps) {
  const { t } = useTranslation();
  const { loading, unauthorizePostboxUser } = usePostbox();

  const onUnauthorize = useCallback(async () => {
    await unauthorizePostboxUser(postboxId, userId);

    onClose();
  }, [onClose, postboxId, unauthorizePostboxUser, userId]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Nutzer-Autorisierung entziehen')}
      id="aunuthorize-postbox-user-modal"
      loading={loading}
      onClose={() => !loading && onClose()}
      onPrimaryButtonClick={onUnauthorize}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Autorisieren entziehen')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <FjdMarkdown
        source={t(
          'Sind sie sich sicher, dass sie dem Nutzer die Autorisierung entziehen möchten? Er kann danach nicht mehr auf das Postfach zugreifen.'
        )}
      />
    </FjdModal>
  );
}
