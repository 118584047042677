import { FjdButton, FjdButtonGroup } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';
import Config from '../config.json';

export function Footer() {
  const { t } = useTranslation();

  return (
    <FjdButtonGroup>
      <FjdButton
        appearance="link"
        href={process.env.REACT_APP_CONTACT || Config.REACT_APP_CONTACT}
        label={t('Kontakt')}
        size="s"
      />

      <FjdButton
        appearance="link"
        href={process.env.REACT_APP_DOCS || Config.REACT_APP_DOCS}
        label={t('Hilfe')}
        size="s"
      />

      <FjdButton
        appearance="link"
        href={process.env.REACT_APP_IMPRINT || Config.REACT_APP_IMPRINT}
        label={t('Impressum')}
        size="s"
      />

      <FjdButton
        appearance="link"
        href={process.env.REACT_APP_PRIVACY || Config.REACT_APP_PRIVACY}
        label={t('Datenschutz')}
        size="s"
      />
    </FjdButtonGroup>
  );
}
