import Joi, { Schema } from 'joi';

export type LeadingParty = {
  contactPersonId?: string;
  fileReference?: string;
  postboxId: string;
  processReference?: string;
  responsibleOrganisationalUnit?: string;
};

export type CreateLeadingPartyRequest = LeadingParty;

export type UpdateLeadingPartyRequest = CreateLeadingPartyRequest;

export const CreateLeadingPartyRequestSchema: Schema = Joi.object({
  contactPersonId: Joi.string().optional().allow(''),
  fileReference: Joi.string().optional().allow(''),
  postboxId: Joi.string().required(),
  processReference: Joi.string().optional().allow(''),
  responsibleOrganisationalUnit: Joi.string().optional().allow('')
});

export const UpdateLeadingPartyRequestSchema = CreateLeadingPartyRequestSchema;
