import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export interface IUseDataRoom {
  createDataRoom: () => Promise<void>;
  loading: boolean;
}

export function useDataRoom(postboxId: string, caseId: string): IUseDataRoom {
  const { alert } = useAlerts();
  const { makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const dataRoomPath = `/postboxes/${postboxId}/cases/${caseId}/dataroom`;

  const createDataRoom = useCallback(async () => {
    setLoading(true);
    try {
      await makeRequestWithFullResponse<void>(dataRoomPath, 'POST');
      alert('success', t('Datenraum erfolgreich erstellt.'));
      setLoading(false);
    } catch (e) {
      alert('error', t('Da ist leider etwas schiefgelaufen.'));
      setLoading(false);
    }
  }, [alert, makeRequestWithFullResponse, dataRoomPath, t]);

  return {
    createDataRoom,
    loading
  } as IUseDataRoom;
}
