import Joi, { Schema } from 'joi';
import { fileListExtension } from 'joi-filelist';

export interface File {
  hash: FileHash;
  id: string;
  mimeType: string;
  originalFileName: string;
  size: number;
}

export interface FileHash {
  algorithm: FileHashAlgorithm;
  digest: string;
}

export enum FileHashAlgorithm {
  'SHA256' = 'SHA256',
  'SHA512' = 'SHA512'
}

export type UploadFileRequest = {
  files: FileList;
};

export const allowedFileTypes = [
  'application/epub+zip',
  'application/gzip',
  'application/java-archive',
  'application/json',
  'application/ld+json',
  'application/msword',
  'application/octet-stream',
  'application/ogg',
  'application/pdf',
  'application/rtf',
  'application/vnd.amazon.ebook',
  'application/vnd.apple.installer+xml',
  'application/vnd.mozilla.xul+xml',
  'application/vnd.ms-excel',
  'application/vnd.ms-fontobject',
  'application/vnd.ms-powerpoint',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.rar',
  'application/vnd.visio',
  'application/x-7z-compressed',
  'application/x-abiword',
  'application/x-bzip',
  'application/x-bzip2',
  'application/x-cdf',
  'application/x-csh',
  'application/x-freearc',
  'application/x-httpd-php',
  'application/x-sh',
  'application/x-shockwave-flash',
  'application/x-tar',
  'application/xhtml+xml',
  'application/xml',
  'application/zip',
  'audio/aac',
  'audio/midi audio/x-midi',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
  'font/otf',
  'font/ttf',
  'font/woff',
  'font/woff2',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/vnd.microsoft.icon',
  'image/webp',
  'text/calendar',
  'text/css',
  'text/csv',
  'text/html',
  'text/javascript',
  'text/javascript',
  'text/plain',
  'video/3gpp',
  'video/3gpp2',
  'video/mp2t',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/x-msvideo'
];

export const JoiWithFileList = fileListExtension(Joi);

export const UploadFileRequestSchema: Schema = JoiWithFileList.object({
  files: JoiWithFileList.filelist()
    .min(1)
    .max(1)
    .mimeType(allowedFileTypes)
    .required()
});
