import { FjdStack } from 'fjd-react-components';
import { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useMatch, useParams } from 'react-router-dom';

import { useElementNavigation } from '../../hooks/useElementNavigation';
import useTabs from '../../hooks/useTabs';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { RouteKey, routes } from '../../utils/router';
import { Case } from '../Case/Case';

export function Cases() {
  const casePageMatch = useMatch(routes[RouteKey.CASE].path);
  const casesPageMatch = useMatch(routes[RouteKey.CASES].path);
  const { postboxId: currentPostboxId } = useParams();
  const { activeTab, empty } = useTabs(currentPostboxId);
  const { navigateToElement } = useElementNavigation();
  const { t } = useTranslation();

  const showEmptyTab = casesPageMatch && empty;
  const showCaseTab = casePageMatch;

  useEffect(() => {
    if (!showEmptyTab && !showCaseTab && activeTab) {
      navigateToElement(activeTab.data.element, currentPostboxId, true);
    }
  }, [
    activeTab,
    currentPostboxId,
    navigateToElement,
    showCaseTab,
    showEmptyTab
  ]);

  return casesPageMatch && empty ? (
    <>
      <Helmet>
        <title>
          {t('Schreibtisch')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdStack orientation="horizontal" spacing="xs">
        <div>
          <Trans>
            Bitte öffnen Sie einen Fall, eine Mitwirkung oder Nachricht über die
            Übersicht.
          </Trans>
        </div>

        <Link to={`/postboxes/${currentPostboxId}`}>{t('Zur Übersicht')}</Link>
      </FjdStack>
    </>
  ) : casePageMatch ? (
    <Suspense fallback={<LoadingSpinner label={t('Lade Fall …')} />}>
      <Case />
    </Suspense>
  ) : null;
}

export { Cases as default } from './Cases';
