import {
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import { usePaginatedSwr } from '../../../hooks/usePaginatedSwr';
import { InvolvedParty } from '../../../models/InvolvedParty';
import { Message } from '../../../models/Message';
import { Participation } from '../../../models/Participation';
import { Postbox } from '../../../models/Postbox';
import { getPostboxOwnerName } from '../../../utils/postbox';
import { getParticipationPath } from '../../../utils/router';
import { defaultDateFormatOptions } from '../../../utils/string';

interface ParticipationsTableRowProps {
  even: boolean;
  participation: Participation;
}

export function ParticipationsTableRow({
  even,
  participation
}: ParticipationsTableRowProps) {
  const navigate = useNavigate();
  const { postboxId: currentPostboxId } = useParams();

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${participation.postboxId}/participations/${participation.id}/involved-party`
  );

  const { data: involvedPartyPostbox } = useSWR<Postbox>(
    involvedParty
      ? `/postboxes/${involvedParty.postboxId}?currentPostboxId=${currentPostboxId}`
      : null
  );

  const { count, data: recentMessages } = usePaginatedSwr<Message>({
    key: `/postboxes/${participation.postboxId}/participations/${participation.id}/message-results`,
    sortByOrder: 'DESC',
    sortByProperty: 'RECEIVED_ON'
  });

  const mostRecentActivity = useMemo(() => {
    return recentMessages &&
      recentMessages.length > 0 &&
      recentMessages[0].receivedOn
      ? new Date(recentMessages[0].receivedOn).toLocaleString(
          [],
          defaultDateFormatOptions
        )
      : '-';
  }, [recentMessages]);

  return involvedParty ? (
    <FjdVirtualizedTableRow
      even={even}
      highlightOnHover
      key={participation.id}
      onClick={(event) => {
        if ((event.target as HTMLElement).tagName !== 'A') {
          navigate(getParticipationPath(participation, currentPostboxId));
        }
      }}
    >
      <FjdVirtualizedTableCell>
        <Link to={getParticipationPath(participation, currentPostboxId)}>
          {participation.title}
        </Link>
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell>
        {getPostboxOwnerName(involvedPartyPostbox?.owner)}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell>{participation.task}</FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="8rem">
        {count || 0}
      </FjdVirtualizedTableCell>
      <FjdVirtualizedTableCell maxWidth="10rem">
        {mostRecentActivity}
      </FjdVirtualizedTableCell>
    </FjdVirtualizedTableRow>
  ) : null;
}
