import {
  FjdButton,
  FjdCheckbox,
  FjdFlyout,
  FjdIcon,
  FjdLoadingOverlay,
  FjdMenu,
  FjdMenuItem,
  FjdRadio,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import useSWR from 'swr';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { Postbox } from '../../../../models/Postbox';
import { PublicServiceType } from '../../../../models/PublicServiceType';
import { UpsertCaseModal } from '../../../Case/UpsertCaseModal/UpsertCaseModal';
import { RegisterPostboxesModal } from '../RegisterPostboxesModal/RegisterPostboxesModal';
import { UnregisterPublicServiceTypeModal } from '../UnregisterPublicServiceTypeModal/UnregisterPublicServiceTypeModal';
import Config from '../../../../config.json';

interface PublicServiceTypesTableProps {
  defaultSelection?: string[];
  linked?: boolean;
  onNameClick?: (publicServiceTypeId: string) => void;
  onSelectionChange?: (selection: string[]) => void;
  pageSize?: number;
  postboxId?: string;
  searchTerm?: string;
  selectionType?: 'none' | 'single' | 'multi';
}

export function PublicServiceTypesTable({
  defaultSelection,
  linked = true,
  onNameClick,
  onSelectionChange,
  pageSize,
  postboxId,
  searchTerm,
  selectionType = 'none'
}: PublicServiceTypesTableProps) {
  const { t } = useTranslation();
  const contactEmailUrl =
    process.env.REACT_APP_CONTACT_EMAIL || Config.REACT_APP_CONTACT_EMAIL;

  const [creatingCase, setCreatingCase] = useState<string>();
  const [linking, setLinking] = useState<PublicServiceType>();
  const [selection, setSelection] = useState<string[]>(defaultSelection || []);
  const [unregistering, setUnregistering] = useState<PublicServiceType>();

  const showLeadingPostboxes = selectionType === 'none';
  const showRegistrationStatus = selectionType === 'none';
  const showMenu = selectionType === 'none';

  const searchFilter = { key: 'searchByName', value: searchTerm || '' };

  const { data: postbox } = useSWR<Postbox>(
    postboxId ? `/postboxes/${postboxId}` : null,
    { suspense: false }
  );

  const {
    data: publicServiceTypes,
    isValidating,
    pagination
  } = usePaginatedSwr<PublicServiceType>({
    elementLabel: t('Verwaltungsleistungen'),
    filter: postboxId
      ? [{ key: 'postboxId', value: postboxId }, searchFilter]
      : [searchFilter],
    key: '/public-service-type-results',
    pageSize
  });

  const createClarificationRequest = useCallback(
    (publicServiceTypeName: string) => {
      window.open(
        `mailto:${contactEmailUrl}?subject=Klärungsauftrag%20zur%20Verwaltungsleistung%20"${publicServiceTypeName}"`,
        '_blank'
      );
    },
    [contactEmailUrl]
  );

  const setPublicServiceTypeSelection = useCallback(
    (publicServiceTypeId: string, selected?: boolean) => {
      let newSelection = selection;

      if (selectionType === 'multi') {
        if (!selected) {
          newSelection = selection.filter((pId) => pId !== publicServiceTypeId);
        } else {
          newSelection = [...selection, publicServiceTypeId];
        }
      } else if (selectionType === 'single') {
        if (newSelection.includes(publicServiceTypeId)) {
          newSelection = [];
        } else {
          newSelection = [publicServiceTypeId];
        }
      }

      setSelection(newSelection);

      if (typeof onSelectionChange === 'function') {
        onSelectionChange(newSelection);
      }
    },
    [onSelectionChange, selection, selectionType]
  );

  useEffect(() => {
    if (postboxId || searchTerm) {
      pagination.setPage(0);
    }
  }, [pagination, postboxId, searchTerm]);

  return publicServiceTypes ? (
    <>
      <FjdLoadingOverlay loading={isValidating}>
        <FjdStack data-testid="public-service-type-table" spacing="s">
          <FjdVirtualizedTable
            disableVirtualization
            header={
              <FjdVirtualizedTableRow>
                {selectionType !== 'none' && (
                  <FjdVirtualizedTableCol maxWidth="2.75rem"></FjdVirtualizedTableCol>
                )}
                {showRegistrationStatus && (
                  <FjdVirtualizedTableCol maxWidth="2.75rem"></FjdVirtualizedTableCol>
                )}
                <FjdVirtualizedTableCol maxWidth="20rem">
                  {t('Name')}
                </FjdVirtualizedTableCol>
                <FjdVirtualizedTableCol>
                  {t('Beschreibung')}
                </FjdVirtualizedTableCol>
                {showLeadingPostboxes && (
                  <FjdVirtualizedTableCol maxWidth="12rem">
                    {t('Verknüpfte Postfächer')}
                  </FjdVirtualizedTableCol>
                )}
                {showMenu && (
                  <FjdVirtualizedTableCol maxWidth="4.25rem"></FjdVirtualizedTableCol>
                )}
              </FjdVirtualizedTableRow>
            }
          >
            {publicServiceTypes.length === 0 && (
              <FjdVirtualizedTableRow>
                <FjdVirtualizedTableCell>
                  {t('Keine Verwaltungsleistungen gefunden.')}
                </FjdVirtualizedTableCell>
              </FjdVirtualizedTableRow>
            )}

            {publicServiceTypes.map((publicServiceType, index) => (
              <FjdVirtualizedTableRow
                even={index % 2 === 1}
                key={publicServiceType?.id}
              >
                {selectionType === 'single' && (
                  <FjdVirtualizedTableCell maxWidth="2.75rem">
                    <FjdRadio
                      aria-labelledby={`label-${publicServiceType.name}`}
                      checked={selection.some(
                        (publicServiceTypeId) =>
                          publicServiceTypeId === publicServiceType.id
                      )}
                      data-testid="test"
                      id={`select-${publicServiceType.name}`}
                      label=""
                      name={`selection`}
                      onChange={() =>
                        setPublicServiceTypeSelection(publicServiceType.id)
                      }
                      value={publicServiceType.id}
                    />
                  </FjdVirtualizedTableCell>
                )}

                {selectionType === 'multi' && (
                  <FjdVirtualizedTableCell maxWidth="2.75rem">
                    <FjdCheckbox
                      aria-labelledby="postbox-id"
                      checked={selection.some(
                        (publicServiceTypeId) =>
                          publicServiceTypeId === publicServiceType.id
                      )}
                      id={`select-${publicServiceType.name}`}
                      label=""
                      name={`select-${publicServiceType.id}`}
                      onChange={(event) =>
                        setPublicServiceTypeSelection(
                          publicServiceType.id,
                          event.target.checked
                        )
                      }
                    />
                  </FjdVirtualizedTableCell>
                )}

                {showRegistrationStatus && (
                  <FjdVirtualizedTableCell maxWidth="2.75rem">
                    <div style={{ display: 'flex' }}>
                      <FjdIcon
                        appearance={
                          publicServiceType.leadPostboxIds.length > 0
                            ? 'success'
                            : 'warn'
                        }
                        glyph={
                          publicServiceType.leadPostboxIds.length > 0
                            ? 'link'
                            : 'unlink'
                        }
                        size="s"
                        title={
                          publicServiceType.leadPostboxIds.length > 0
                            ? t('registriert')
                            : t('nicht registriert')
                        }
                      />
                    </div>
                  </FjdVirtualizedTableCell>
                )}

                <FjdVirtualizedTableCell maxWidth="20rem">
                  {linked ? (
                    <Link
                      id={`label-${publicServiceType.name}`}
                      to={`/public-service-types/${publicServiceType.id}`}
                    >
                      {publicServiceType.name}
                    </Link>
                  ) : onNameClick ? (
                    <a
                      href={`/public-service-types/${publicServiceType.id}`}
                      id={`label-${publicServiceType.name}`}
                      onClick={(event) => {
                        event.preventDefault();
                        onNameClick(publicServiceType.id);
                      }}
                    >
                      {publicServiceType.name}
                    </a>
                  ) : (
                    <em id={`label-${publicServiceType.name}`}>
                      {publicServiceType.name}
                    </em>
                  )}
                </FjdVirtualizedTableCell>

                <FjdVirtualizedTableCell>
                  {sanitizeHtml(publicServiceType.description || '', {
                    allowedTags: [],
                    allowedAttributes: {}
                  })}
                </FjdVirtualizedTableCell>

                {showLeadingPostboxes && (
                  <FjdVirtualizedTableCell maxWidth="12rem">
                    {publicServiceType.leadPostboxIds?.length || 0}
                  </FjdVirtualizedTableCell>
                )}

                {showMenu && (
                  <FjdVirtualizedTableCol maxWidth="4.25rem">
                    <FjdFlyout
                      flyout={
                        postboxId ? (
                          <FjdMenu>
                            <FjdMenuItem
                              appearance="light"
                              icon="unlink"
                              label={t('Registrierung aufheben')}
                              onClick={() =>
                                setUnregistering({ ...publicServiceType })
                              }
                            />
                            <FjdMenuItem
                              appearance="light"
                              icon="help-outline"
                              label={t('Klärungsauftrag')}
                              onClick={() =>
                                createClarificationRequest(
                                  publicServiceType.name
                                )
                              }
                            />
                            <FjdMenuItem
                              appearance="light"
                              icon="add-outline"
                              label={t('Fall erstellen')}
                              onClick={() =>
                                setCreatingCase(publicServiceType.id)
                              }
                            />
                          </FjdMenu>
                        ) : (
                          <FjdMenu>
                            <FjdMenuItem
                              appearance="light"
                              icon="link"
                              label={t('Verwaltungsleistung registrieren')}
                              onClick={() =>
                                setLinking({ ...publicServiceType })
                              }
                            />
                            <FjdMenuItem
                              appearance="light"
                              icon="help-outline"
                              label={t('Klärungsauftrag')}
                              onClick={() =>
                                createClarificationRequest(
                                  publicServiceType.name
                                )
                              }
                            />
                          </FjdMenu>
                        )
                      }
                      placement="bottom-end"
                    >
                      <FjdButton
                        appearance="primary-link"
                        hideLabel
                        iconLeft="overflow-menu-vertical"
                        label={t('Optionen')}
                        size="s"
                      />
                    </FjdFlyout>
                  </FjdVirtualizedTableCol>
                )}
              </FjdVirtualizedTableRow>
            ))}
          </FjdVirtualizedTable>

          {pagination.PaginationComponent}
        </FjdStack>

        {creatingCase && postboxId && (
          <UpsertCaseModal
            defaultPublicServiceTypeId={creatingCase}
            initialStep={1}
            onClose={() => setCreatingCase(undefined)}
            postboxId={postboxId}
          />
        )}

        {linking && (
          <RegisterPostboxesModal
            onClose={() => setLinking(undefined)}
            onRegister={() => setLinking(undefined)}
            publicServiceTypeToRegister={linking}
          />
        )}

        {unregistering && postbox && (
          <UnregisterPublicServiceTypeModal
            onClose={() => setUnregistering(undefined)}
            onUnregister={() => setUnregistering(undefined)}
            postbox={postbox}
            publicServiceType={unregistering}
          />
        )}
      </FjdLoadingOverlay>
    </>
  ) : null;
}
