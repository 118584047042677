import {
  FjdCard,
  FjdColumns,
  FjdFormControl,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { ContactPerson } from '../../../../models/ContactPerson';
import { InvolvedParty } from '../../../../models/InvolvedParty';
import {
  UpdateLeadingPartyRequest,
  UpdateLeadingPartyRequestSchema
} from '../../../../models/LeadingParty';
import { Postbox } from '../../../../models/Postbox';
import { getPostboxOwnerName } from '../../../../utils/postbox';

interface UpdateParticipationFormStep2Props {
  initialData: Partial<FormControls>;
  involvedParty: InvolvedParty;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  postboxId: string;
}

type FormControls = UpdateLeadingPartyRequest;

export function UpdateParticipationFormStep2({
  initialData,
  involvedParty,
  onChange,
  onInit,
  onNext,
  postboxId
}: UpdateParticipationFormStep2Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const { data: postbox } = useSWR<Postbox>(
    `/postboxes/${involvedParty.postboxId}`
  );

  const { data: contactPersons } = useSWR<ContactPerson[]>(
    `/postboxes/${postboxId}/contact-persons`
  );

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: initialData,
    resolver: joiResolver(
      UpdateLeadingPartyRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdCard>
        <FjdStack spacing="xl">
          <FjdColumns>
            <FjdFormControl inputId="name" label={t('Name')}>
              <FjdTextInput
                id="name"
                name="name"
                readonly
                value={getPostboxOwnerName(postbox?.owner)}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="organisationalUnit"
              label={t('Organisationseinheit')}
            >
              <FjdTextInput
                id="organisationalUnit"
                name="organisationalUnit"
                readonly
                value={involvedParty?.responsibleOrganisationalUnit}
              />
            </FjdFormControl>
          </FjdColumns>

          <FjdColumns>
            <FjdFormControl
              inputId="fileReference"
              label={t('Aktenzeichen')}
              optional
              validationMessage={errors.fileReference?.message}
            >
              <Controller
                name="fileReference"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      autoFocus
                      defaultValue={field.value}
                      id="fileReference"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>

            <FjdFormControl
              inputId="processReference"
              label={t('Geschäftszeichen')}
              optional
              validationMessage={errors.processReference?.message}
            >
              <Controller
                name="processReference"
                control={control}
                render={({ field }) => {
                  return (
                    <FjdTextInput
                      defaultValue={field.value}
                      id="processReference"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={(event) => {
                        field.onChange(event);
                        onChange({ [field.name]: event.target.value });
                      }}
                    />
                  );
                }}
              />
            </FjdFormControl>
          </FjdColumns>

          <FjdFormControl
            inputId="contactPersonId"
            label={t('Kontaktperson')}
            optional
            validationMessage={errors.contactPersonId?.message}
          >
            <Controller
              name="contactPersonId"
              control={control}
              render={({ field }) => {
                return (
                  <FjdSelect
                    id="contactPersonId"
                    name={field.name}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({ [field.name]: value });
                    }}
                    options={[
                      {
                        selected:
                          field.value === '' || field.value === undefined,
                        text: t('- keine -'),
                        value: ''
                      },
                      ...(contactPersons || []).map((contactPerson) => ({
                        selected: field.value === contactPerson.id,
                        text: `${contactPerson.name.formOfAddress} ${contactPerson.name.firstName} ${contactPerson.name.lastName}`,
                        value: contactPerson.id
                      }))
                    ]}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdStack>

        <input type="submit" hidden />
      </FjdCard>
    </form>
  );
}
