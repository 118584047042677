import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AuthorizePostboxUserRequest,
  CreatePostboxRequest,
  Postbox as PostboxModel,
  Postbox,
  UpdatePostboxRequest
} from '../models/Postbox';
import { matchMutate, mutate } from '../swr';
import useAlerts from './useAlerts';
import { useApi } from './useApi';

export interface IUsePostbox {
  addAdminRole: (postboxId: string, userId: string) => Promise<void>;
  authorizePostboxUser: (
    postboxId: string,
    data: AuthorizePostboxUserRequest
  ) => Promise<void>;
  createPostbox: (data: CreatePostboxRequest) => Promise<PostboxModel>;
  deletePostbox: (postboxId: string) => Promise<void>;
  loading: boolean;
  removeAdminRole: (postboxId: string, userId: string) => Promise<void>;
  unauthorizePostboxUser: (postboxId: string, userId: string) => Promise<void>;
  updatePostbox: (
    postboxId: string,
    data: UpdatePostboxRequest,
    silent?: boolean
  ) => Promise<PostboxModel>;
  createPostboxForServiceOrOrganizationAccount: (
    data: CreatePostboxRequest
  ) => Promise<void>;
}

export function usePostbox() {
  const { alert } = useAlerts();
  const { makeRequest, makeRequestWithFullResponse } = useApi();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const addAdminRole = useCallback(
    async (postboxId: string, userId: string) => {
      setLoading(true);

      try {
        await makeRequestWithFullResponse<Postbox>(
          `/users/postboxes/${postboxId}/users/${userId}/admin`,
          'POST'
        );

        await matchMutate(new RegExp(`^/users.*$`));

        alert('success', t('Admin-Rolle erfolgreich vergeben.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, t]
  );

  const authorizePostboxUser = useCallback(
    async (postboxId: string, data: AuthorizePostboxUserRequest) => {
      setLoading(true);

      try {
        await makeRequestWithFullResponse(
          `/users/postboxes/${postboxId}/users`,
          'POST',
          data
        );

        await matchMutate(new RegExp(`^/users.*$`));

        alert('success', t('Nutzer erfolgreich autorisiert.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, t]
  );

  const createPostbox = useCallback(
    async (data: CreatePostboxRequest) => {
      setLoading(true);

      try {
        const newCaseResponse = await makeRequestWithFullResponse<Postbox>(
          `/postboxes`,
          'POST',
          data
        );

        await matchMutate(new RegExp(`^/postboxes.*$`));
        await matchMutate(new RegExp(`^/postbox-results.*$`));

        alert('success', t('Postfach erfolgreich erstellt.'));
        setLoading(false);
        return makeRequest<Postbox>(newCaseResponse.headers.location, 'GET');
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, makeRequestWithFullResponse, t]
  );

  const createPostboxForServiceOrOrganizationAccount = useCallback(
    async (data: CreatePostboxRequest) => {
      setLoading(true);
      try {
        await makeRequestWithFullResponse<Postbox>(
          `/extern-users/postboxes`,
          'POST',
          data
        );

        await matchMutate(new RegExp(`^/postboxes.*$`));
        await matchMutate(new RegExp(`^/users/postboxes.*$`));

        alert('success', t('Postfach erfolgreich erstellt.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, t]
  );

  const deletePostbox = useCallback(
    async (postboxId: string) => {
      setLoading(true);

      try {
        await makeRequest(`/postboxes/${postboxId}`, 'DELETE');
        await matchMutate(new RegExp(`^/postboxes.*$`));
        await matchMutate(new RegExp(`^/postbox-results.*$`));

        alert('success', t('Postfach gelöscht.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);
        throw e;
      }
    },
    [alert, makeRequest, t]
  );

  const removeAdminRole = useCallback(
    async (postboxId: string, userId: string) => {
      setLoading(true);

      try {
        await makeRequestWithFullResponse<Postbox>(
          `/users/postboxes/${postboxId}/users/${userId}/admin`,
          'DELETE'
        );

        await matchMutate(new RegExp(`^/users.*$`));

        alert('success', t('Admin-Rolle entzogen.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, t]
  );

  const unauthorizePostboxUser = useCallback(
    async (postboxId: string, userId: string) => {
      setLoading(true);

      try {
        await makeRequestWithFullResponse(
          `/users/postboxes/${postboxId}/users/${userId}`,
          'DELETE'
        );

        await matchMutate(new RegExp(`^/users.*$`));

        alert('success', t('Autorisierung erfolgreich entzogen.'));
        setLoading(false);
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequestWithFullResponse, t]
  );

  const updatePostbox = useCallback(
    async (postboxId: string, data: UpdatePostboxRequest, silent?: boolean) => {
      setLoading(true);

      try {
        const updatedPostbox = await makeRequest<Postbox>(
          `/postboxes/${postboxId}`,
          'PUT',
          data
        );

        await matchMutate(new RegExp(`^/postboxes.*$`));
        await mutate(`/postboxes/${postboxId}`);

        if (!silent) {
          alert('success', t('Änderungen gespeichert.'));
        }

        setLoading(false);

        return updatedPostbox;
      } catch (e) {
        alert('error', t('Da ist leider etwas schiefgelaufen.'));
        setLoading(false);

        throw e;
      }
    },
    [alert, makeRequest, t]
  );

  return {
    addAdminRole,
    authorizePostboxUser,
    createPostbox,
    deletePostbox,
    loading,
    removeAdminRole,
    unauthorizePostboxUser,
    updatePostbox,
    createPostboxForServiceOrOrganizationAccount
  } as IUsePostbox;
}
