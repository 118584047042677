import {
  FjdSpinner,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Suspense } from 'react';

import { useTable } from '../../../../hooks/useTable';
import { useTableCellRenderer } from '../../../../hooks/useTableCellRenderer';
import { useTreeExpansion } from '../../../../hooks/useTreeExpansion';
import { Participation } from '../../../../models/Participation';
import { CasesTableMessageRows } from '../CasesTableMessageRows/CasesTableMessageRows';

interface CasesTableParticipationRowProps {
  first: boolean;
  last: boolean;
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  participation: Participation;
  table: ReturnType<typeof useTable>;
}

export function CasesTableParticipationRow({
  first,
  last,
  onOpenPublicServiceType,
  participation,
  table
}: CasesTableParticipationRowProps) {
  const { isJoinedCell, renderCell } = useTableCellRenderer({
    element: participation,
    first,
    last,
    onOpenPublicServiceType,
    table,
    treeId: 'cases-table'
  });

  const { treeExpansion } = useTreeExpansion('cases-table');

  return participation ? (
    <>
      <FjdVirtualizedTableRow bordered={false} highlightOnHover>
        {table.activeColumns.map((column) => (
          <FjdVirtualizedTableCell
            format={column.format || 'default'}
            horizontalSeparator={!isJoinedCell(column.id)}
            key={`${participation.id}-${column.id}`}
            level={column.id === 'name' ? 1 : undefined}
            levelIndent="5xl"
            maxWidth={column.maxWidth}
            minWidth={column.minWidth}
            verticalSeparator
            width={column.width}
          >
            {renderCell(column.id)}
          </FjdVirtualizedTableCell>
        ))}
      </FjdVirtualizedTableRow>

      {treeExpansion[participation.id] && (
        <Suspense
          fallback={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                <FjdSpinner size="s" />
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          }
        >
          <CasesTableMessageRows
            onOpenPublicServiceType={onOpenPublicServiceType}
            participationId={participation.id}
            postboxId={participation.postboxId}
            table={table}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
