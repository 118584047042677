import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { Postbox as PostboxModel } from '../../models/Postbox';
import { CasesTable } from './CasesTable/CasesTable';

export function Postbox() {
  const { t } = useTranslation();
  const { postboxId: currentPostboxId } = useParams();

  const { data } = useSWR<PostboxModel>(`/postboxes/${currentPostboxId}`);

  return data ? (
    <>
      <Helmet>
        <title>
          {t('Postfach {{postboxName}}', { postboxName: data.name })} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <CasesTable postboxId={currentPostboxId} />
    </>
  ) : null;
}

export { Postbox as default } from './Postbox';
