import * as Joi from 'joi';

export enum FormOfAddress {
  NONE = 'keine Angabe',
  MS = 'Frau',
  MR = 'Herr'
}

export interface NaturalPerson {
  name: PersonName;
  type: PersonType.NATURAL_PERSON;
}

export interface PrivateLegalEntity {
  organisationalUnit?: string;
  organizationName: string;
  type: PersonType.PRIVATE_LEGAL_ENTITY;
}

export interface PublicOrganization {
  organisationalUnit?: string;
  organizationName: string;
  type: PersonType.PUBLIC_ORGANISATION;
  typeOfPublicOrganization?: string;
}

export interface PersonName {
  doctoralDegrees?: string;
  firstName: string;
  formOfAddress: FormOfAddress;
  lastName: string;
}

export type Person = PublicOrganization | NaturalPerson | PrivateLegalEntity;

export enum PersonType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  PRIVATE_LEGAL_ENTITY = 'PRIVATE_LEGAL_ENTITY',
  PUBLIC_ORGANISATION = 'PUBLIC_ORGANIZATION'
}

export enum NotPublicPersonType {
  NATURAL_PERSON = 'NATURAL_PERSON',
  PRIVATE_LEGAL_ENTITY = 'PRIVATE_LEGAL_ENTITY'
}

export const NaturalPersonNameSchema = Joi.object({
  doctoralDegrees: Joi.string().optional().allow(''),
  firstName: Joi.string().required(),
  formOfAddress: Joi.string().required().allow(''),
  lastName: Joi.string().required()
}).required();

export const OrganisationalUnitSchema = Joi.string().optional().allow('');

export const OrganizationNameSchema = Joi.string().required();

export const TypeOfPublicOrganizationSchema = Joi.string().optional().allow('');

export function instanceOfNaturalPerson(object: any): object is NaturalPerson {
  return object?.type === PersonType.NATURAL_PERSON;
}

export function instanceOfPrivateLegalEntity(
  object: any
): object is PrivateLegalEntity {
  return object?.type === PersonType.PRIVATE_LEGAL_ENTITY;
}

export function instanceOfPublicOrganization(
  object: any
): object is PublicOrganization {
  return object?.type === PersonType.PUBLIC_ORGANISATION;
}
