import {
  FjdFormControl,
  FjdMarkdown,
  FjdModal,
  FjdSpinner,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import Joi from 'joi';
import { Suspense, useCallback, useRef, useState } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';
import { useDebounceCallback } from '@react-hook/debounce';

import { useJoi } from '../../../../hooks/useJoi';
import { usePublicServiceTypeLead } from '../../../../hooks/usePublicServiceTypeLead';
import { Postbox } from '../../../../models/Postbox';
import { LoadingSpinner } from '../../../../shared/LoadingSpinner';
import { submitForm } from '../../../../utils/form';
import { PublicServiceTypeDetails } from '../../../PublicServiceType/PublicServiceTypeDetails/PublicServiceType';
import { PublicServiceTypesTable } from '../PublicServiceTypesTable/PublicServiceTypesTable';

interface RegisterPublicServiceTypeModalProps {
  onClose: () => void;
  onRegister?: () => void;
  postbox: Postbox;
}

type FormControls = { publicServiceTypeIds: string[] };

export function RegisterPublicServiceTypeModal({
  onClose,
  onRegister,
  postbox
}: RegisterPublicServiceTypeModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const [searchTerm, setSearchTerm] = useState<string>();
  const [showDetails, setShowDetails] = useState<string>();

  const debouncedSetSearchTerm = useDebounceCallback(
    setSearchTerm,
    1000,
    false
  );

  const { createPublicServiceTypeLead, loading } = usePublicServiceTypeLead();

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm<FormControls>({
    defaultValues: {
      publicServiceTypeIds: []
    },
    resolver: joiResolver(
      Joi.object({
        publicServiceTypeIds: Joi.array()
          .items(Joi.string().optional())
          .required()
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onRegisterPublicServiceType = useCallback(
    async (data: FormControls) => {
      await Promise.all(
        data.publicServiceTypeIds.map((publicServiceTypeId) =>
          createPublicServiceTypeLead(postbox.id, { publicServiceTypeId })
        )
      );

      if (typeof onRegister === 'function') {
        onRegister();
      }
    },
    [createPublicServiceTypeLead, onRegister, postbox.id]
  );

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Neue Verwaltungsleistung registrieren')}
      id="register-public-service-type-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={() => {
        submitForm(form.current);
      }}
      onSecondaryButtonClick={() =>
        !showDetails ? onClose() : setShowDetails(undefined)
      }
      open
      primaryButtonLabel={
        !showDetails ? t('Verwaltungsleistungen jetzt registrieren') : undefined
      }
      secondaryButtonLabel={!showDetails ? t('Abbrechen') : t('Zurück')}
      width="wider"
    >
      {!showDetails && (
        <FjdStack spacing="xl">
          <FjdMarkdown
            source={t(
              `Bitte wählen Sie die Verwaltungsleistungen, für die Sie das Postfach **„{{postboxName}}“** als verfahrensführend registrieren möchten.`,
              { postboxName: postbox.name }
            )}
          />

          <form onSubmit={handleSubmit(onRegisterPublicServiceType)} ref={form}>
            <FjdStack spacing="xl">
              <FjdFormControl
                inputId="publicServiceTypeIds"
                label={t('Verwaltungsleistungen')}
                validationMessage={
                  (errors.publicServiceTypeIds as DeepMap<any, FieldError>)
                    ?.errors
                }
              >
                <div>
                  <FjdStack spacing="m">
                    <FjdTextInput
                      id="public-service-type-search"
                      onChange={(event) =>
                        debouncedSetSearchTerm(event.target.value)
                      }
                      placeholder="Suche …"
                      size="s"
                      type="search"
                    />

                    <Controller
                      name="publicServiceTypeIds"
                      control={control}
                      render={({ field }) => {
                        return (
                          <Suspense
                            fallback={
                              <LoadingSpinner
                                label={t('Lade Verwaltungsleistungen …')}
                              />
                            }
                          >
                            <PublicServiceTypesTable
                              defaultSelection={field.value}
                              linked={false}
                              onNameClick={setShowDetails}
                              onSelectionChange={(selection) => {
                                field.onChange(selection);
                                trigger('publicServiceTypeIds');
                              }}
                              pageSize={10}
                              searchTerm={searchTerm}
                              selectionType="multi"
                            />
                          </Suspense>
                        );
                      }}
                    />
                  </FjdStack>
                </div>
              </FjdFormControl>
            </FjdStack>

            <input type="submit" hidden />
          </form>
        </FjdStack>
      )}

      {showDetails && (
        <Suspense fallback={<FjdSpinner size="s" />}>
          <PublicServiceTypeDetails publicServiceTypeId={showDetails} />
        </Suspense>
      )}
    </FjdModal>
  );
}
