import {
  FjdCard,
  FjdFormControl,
  FjdSpinner,
  FjdStack
} from 'fjd-react-components';
import Joi from 'joi';
import { Suspense, useEffect, useRef, useState } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { Case } from '../../../../models/Case';
import { Postbox } from '../../../../models/Postbox';
import {
  PostboxesOwnerTable,
  PostboxesOwnerTableContext
} from '../../PostboxesOwnerTable/PostboxesOwnerTable';
import { InviteInvolvedPartyForm } from '../InviteInvolvedPartyForm/InviteInvolvedPartyForm';

interface CreateParticipationFormStep1Props {
  caseData: Case;
  initialData?: Partial<FormControls>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  publicServiceTypeId: string;
}

export type FormControls = {
  postboxId: string;
};

export function CreateParticipationFormStep1({
  caseData,
  initialData,
  onChange,
  onInit,
  onNext,
  publicServiceTypeId
}: CreateParticipationFormStep1Props) {
  const { validationMessages } = useJoi();
  const [newlyInvitedPostbox, setNewlyInvitedPostbox] = useState<
    Postbox | undefined
  >(undefined);

  const form = useRef<HTMLFormElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    defaultValues: {
      postboxId: initialData?.postboxId
    },
    resolver: joiResolver(
      Joi.object({
        postboxId: Joi.string()
          .required()
          .messages({ 'any.required': 'Bitte wählen Sie eine Stelle.' })
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <FjdStack spacing="xl">
      <form onSubmit={handleSubmit(onNext)} ref={form}>
        <FjdCard>
          <FjdStack spacing="xl">
            <FjdFormControl
              inputId="postboxId"
              label=""
              validationMessage={errors.postboxId?.message}
            >
              <Controller
                name="postboxId"
                control={control}
                render={({ field }) => {
                  return (
                    <Suspense fallback={<FjdSpinner size="s" />}>
                      <PostboxesOwnerTable
                        context={
                          PostboxesOwnerTableContext.CREATE_PARTICIPATION
                        }
                        defaultSelection={field.value ? [field.value] : []}
                        onSelectionChange={(selection) => {
                          const postboxId =
                            selection.length > 0 ? selection[0] : undefined;

                          field.onChange(postboxId);

                          onChange({
                            [field.name]: postboxId
                          });
                        }}
                        selectionType="single"
                        highlightedPostbox={newlyInvitedPostbox}
                      />
                    </Suspense>
                  );
                }}
              />
            </FjdFormControl>
          </FjdStack>

          <input type="submit" hidden />
        </FjdCard>
      </form>

      <InviteInvolvedPartyForm
        caseData={caseData}
        onInvite={(invitationResponse) => {
          setNewlyInvitedPostbox(invitationResponse.createdPostbox);
        }}
        publicServiceTypeId={publicServiceTypeId}
      />
    </FjdStack>
  );
}
