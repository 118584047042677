import { useCallback, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HashRouter as Router, Routes } from 'react-router-dom';
import { useStorageState } from 'react-storage-hooks';
import { MutableSnapshot, RecoilRoot } from 'recoil';

import { useKeycloak } from '@react-keycloak/web';

import { AppContainer } from './app/AppContainer';
import { CasesLayout } from './app/Cases/CasesLayout/CasesLayout';
import { PostboxLayout } from './app/Postbox/PostboxLayout/PostboxLayout';
import { PostboxesLayout } from './app/Postboxes/PostboxesLayout/PostboxesLayout';
import { PostboxSettingsLayout } from './app/PostboxSettings/PostboxSettingsLayout/PostboxSettingsLayout';
import { PublicServiceTypeLayout } from './app/PublicServiceType/PublicServiceTypeLayout/PublicServiceTypeLayout';
import { SettingsLayout } from './app/Settings/SettingsLayout/SettingsLayout';
import {
  tablesAtom,
  TablesState,
  tablesStateLocalStorageKey
} from './hooks/useTable';
import { tabsAtom, TabsState, tabsStateLocalStorageKey } from './hooks/useTabs';
import {
  treeExpansionAtom,
  TreeExpansionState,
  treeExpansionStateLocalStorageKey
} from './hooks/useTreeExpansion';
import { GuardedRoute } from './shared/GuardedRoute';
import { ServerError } from './shared/ServerError';
import { RouteKey, routes } from './utils/router';
import { getUiTemplateFaviconString } from './shared/UiTemplate';

function App() {
  const { initialized } = useKeycloak();

  const [tabsState] = useStorageState<TabsState>(
    localStorage,
    tabsStateLocalStorageKey
  );

  const [tablesState] = useStorageState<TablesState>(
    localStorage,
    tablesStateLocalStorageKey
  );

  const [treeExpansionState] = useStorageState<TreeExpansionState>(
    localStorage,
    treeExpansionStateLocalStorageKey
  );

  const initializeRecoilState = useCallback<
    (mutableSnapshot: MutableSnapshot) => void
  >(
    ({ set }) => {
      if (tabsState) {
        set(tabsAtom, tabsState);
      }

      if (tablesState) {
        set(tablesAtom, tablesState);
      }

      if (treeExpansionState) {
        set(treeExpansionAtom, treeExpansionState);
      }
    },
    [tablesState, tabsState, treeExpansionState]
  );

  useEffect(() => {
    var link: Element | null = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement('link');
      link.setAttribute('rel', 'icon');
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.setAttribute('href', getUiTemplateFaviconString());
  }, []);

  return initialized ? (
    <RecoilRoot initializeState={initializeRecoilState}>
      <Router>
        <AppContainer>
          <ErrorBoundary FallbackComponent={ServerError}>
            <Routes>
              <GuardedRoute path={routes[RouteKey.HOME].path}>
                {routes[RouteKey.HOME].element}
              </GuardedRoute>

              <GuardedRoute
                element={<PostboxesLayout />}
                path={routes[RouteKey.POSTBOXES].path}
              >
                <GuardedRoute
                  element={routes[RouteKey.POSTBOXES].element}
                  path="/"
                />
              </GuardedRoute>

              <GuardedRoute
                element={<PostboxLayout />}
                path={routes[RouteKey.POSTBOX].path}
              >
                <GuardedRoute
                  element={routes[RouteKey.POSTBOX].element}
                  path="/"
                ></GuardedRoute>
              </GuardedRoute>

              <GuardedRoute
                element={<PostboxSettingsLayout />}
                path={routes[RouteKey.POSTBOX_SETTINGS].path}
              >
                <GuardedRoute
                  element={routes[RouteKey.POSTBOX_SETTINGS].element}
                  path="/"
                />
              </GuardedRoute>

              <GuardedRoute element={<CasesLayout />} path="postboxes/*">
                <GuardedRoute
                  element={routes[RouteKey.CASE].element}
                  path={routes[RouteKey.CASE].path.replace('postboxes', '')}
                />
                <GuardedRoute
                  element={routes[RouteKey.CASES].element}
                  path={routes[RouteKey.CASES].path.replace('postboxes', '')}
                />
                <GuardedRoute
                  element={routes[RouteKey.MESSAGE].element}
                  path={routes[RouteKey.MESSAGE].path.replace('postboxes', '')}
                />
                <GuardedRoute
                  element={routes[RouteKey.PARTICIPATION].element}
                  path={routes[RouteKey.PARTICIPATION].path.replace(
                    'postboxes',
                    ''
                  )}
                />
              </GuardedRoute>

              <GuardedRoute
                element={<PublicServiceTypeLayout />}
                path={routes[RouteKey.PUBLIC_SERVICE_TYPE].path}
              >
                <GuardedRoute
                  element={routes[RouteKey.PUBLIC_SERVICE_TYPE].element}
                  path="/"
                />
              </GuardedRoute>

              <GuardedRoute
                element={<SettingsLayout />}
                path={routes[RouteKey.SETTINGS].path}
              >
                <GuardedRoute
                  element={routes[RouteKey.SETTINGS].element}
                  path="/"
                />
              </GuardedRoute>
            </Routes>
          </ErrorBoundary>
        </AppContainer>
      </Router>
    </RecoilRoot>
  ) : null;
}

export default App;
