import { FjdFormControl, FjdModal, FjdTextInput } from 'fjd-react-components';
import { useCallback, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { usePostbox } from '../../../../hooks/usePostbox';
import {
  AuthorizePostboxUserRequest,
  AuthorizePostboxUserRequestSchema
} from '../../../../models/Postbox';
import { submitForm } from '../../../../utils/form';

interface AuthorizeUserModalProps {
  onClose: () => void;
  postboxId: string;
}

type FormControls = AuthorizePostboxUserRequest;

export function AuthorizeUserModal({
  onClose,
  postboxId
}: AuthorizeUserModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();
  const { authorizePostboxUser, loading } = usePostbox();

  const form = useRef<HTMLFormElement>(null);

  const {
    control,
    formState: { errors },
    handleSubmit
  } = useForm<FormControls>({
    resolver: joiResolver(
      AuthorizePostboxUserRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onAuthorize = useCallback(
    async (data: FormControls) => {
      await authorizePostboxUser(postboxId, data);

      onClose();
    },
    [authorizePostboxUser, onClose, postboxId]
  );

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Neuen Nutzer autorisieren')}
      id="authorize-postbox-user-modal"
      loading={loading}
      onClose={() => !loading && onClose()}
      onPrimaryButtonClick={() => submitForm(form.current)}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Nutzer autorisieren')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <form onSubmit={handleSubmit(onAuthorize)} ref={form}>
        <FjdFormControl
          inputId="email"
          label={t('E-Mail-Adresse')}
          validationMessage={errors.email?.message}
        >
          <Controller
            name="email"
            control={control}
            render={({ field }) => {
              return (
                <FjdTextInput
                  defaultValue={field.value}
                  id="email"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  type="email"
                />
              );
            }}
          />
        </FjdFormControl>

        <input type="submit" hidden />
      </form>
    </FjdModal>
  );
}
