import Joi from 'joi';

export enum AddressType {
  INTERNATIONAL = 'INTERNATIONAL',
  NATIONAL = 'NATIONAL',
  POSTBOX = 'POSTBOX'
}

export type InternationalAddress = {
  country: string;
  lines: string[];
  type: AddressType.INTERNATIONAL;
};

export type NationalAddress = {
  addressSupplement?: string;
  city: string;
  houseNumber?: number | string;
  houseNumberSuffix?: string;
  postalCode: string;
  street: string;
  type: AddressType.NATIONAL;
};

export type PostboxAddress = {
  city: string;
  postalCode: string;
  postOfficeBox?: string;
  type: AddressType.POSTBOX;
};

export const AddressLinesSchema = Joi.array()
  .items(Joi.string().max(35).required())
  .min(2)
  .required();

export const AddressSupplementSchema = Joi.string().optional().allow('');
export const CountrySchema = Joi.string()
  .pattern(/^[0-9]{3}$/)
  .required();

export const CitySchema = Joi.string().max(50).required();
export const HouseNumberSchema = Joi.string().optional().allow('');

export const HouseNumberSuffixSchema = Joi.string().optional().allow('');
export const PostalCodeSchema = Joi.string().min(5).max(5).required();
export const PostOfficeBoxSchema = Joi.string().required();
export const StreetSchema = Joi.string().required();
