import {
  FjdButton,
  FjdCard,
  FjdFlexSpacer,
  FjdHeading,
  FjdLoadingOverlay,
  FjdMarkdown,
  FjdModal,
  FjdSection,
  FjdSectionHeader,
  FjdSpinner,
  FjdStack,
  FjdStatus
} from 'fjd-react-components';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import useSWR from 'swr';

import useAlerts from '../../hooks/useAlerts';
import { useElementOperations } from '../../hooks/useElementOperations';
import { useMessage } from '../../hooks/useMessage';
import { useParentPostboxId } from '../../hooks/useParentPostboxId';
import { InvolvedParty } from '../../models/InvolvedParty';
import { LeadingParty } from '../../models/LeadingParty';
import { LogEntryStatus } from '../../models/LogEntry';
import { Message as MessageModel, MessageType } from '../../models/Message';
import { Participation } from '../../models/Participation';
import { CreateMessageModal } from '../Participation/CreateMessageModal/CreateMessageModal';
import { DocumentsTable } from './DocumentsTable/DocumentsTable';
import { MessageDetails } from './MessageDetails/MessageDetails';

export function Message() {
  const { alert } = useAlerts();

  const {
    caseId,
    messageId,
    participationId,
    postboxId: currentPostboxId
  } = useParams();
  const { t } = useTranslation();
  const parentPostboxId = useParentPostboxId();

  const [statusAlertShown, setStatusAlertShown] = useState(false);
  const [replying, setReplying] = useState(false);

  const { loading, markAsRead } = useMessage(parentPostboxId);

  const { data: message } = useSWR<MessageModel>(
    `/postboxes/${parentPostboxId}/participations/${participationId}/messages/${messageId}`
  );

  const { data: participation } = useSWR<Participation>(
    `/postboxes/${parentPostboxId}/participations/${participationId}`
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    `/postboxes/${parentPostboxId}/participations/${participationId}/involved-party`
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    `/postboxes/${parentPostboxId}/cases/${caseId}/leading-party`
  );

  const { data: messages } = useSWR<MessageModel[]>(
    `/postboxes/${parentPostboxId}/participations/${participationId}/messages`
  );

  const allowedParticipationOperations = useElementOperations(participation);

  const closingMessage = useMemo(
    () => messages?.find((message) => message.type === MessageType.CLOSE),
    [messages]
  );

  const messageReceived = useMemo(
    () =>
      !!message?.logs?.find(
        (entry) => entry.status === LogEntryStatus.RECEIVED
      ),
    [message?.logs]
  );

  const canSetStatus = useMemo(
    () =>
      !messageReceived &&
      ((message?.fromLeader && currentPostboxId === involvedParty?.postboxId) ||
        (!message?.fromLeader && currentPostboxId === leadingParty?.postboxId)),
    [
      currentPostboxId,
      involvedParty?.postboxId,
      leadingParty?.postboxId,
      message?.fromLeader,
      messageReceived
    ]
  );

  useEffect(() => {
    if (!statusAlertShown && canSetStatus && !messageReceived) {
      alert(
        'info',
        t('Der Empfang dieser Nachricht wurde von Ihnen noch nicht bestätigt.'),
        5000
      );

      setStatusAlertShown(true);
    }
  }, [alert, canSetStatus, messageReceived, statusAlertShown, t]);

  return message ? (
    <>
      <Helmet>
        <title>
          {t('Nachricht: {{messageSubject}}', {
            messageSubject: message.subject
          })}{' '}
          - {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <FjdLoadingOverlay loading={loading}>
        <FjdStack spacing="5xl">
          <FjdSection>
            <FjdStack spacing="xxl">
              <FjdStack spacing="m">
                <FjdSectionHeader
                  align="start"
                  heading={
                    <FjdHeading level={1} text={message.subject || '-'} />
                  }
                  tools={
                    <FjdStack orientation="horizontal">
                      <FjdFlexSpacer />
                      {canSetStatus && (
                        <FjdButton
                          iconLeft="checkmark-outline"
                          label={t('Empfang bestätigen')}
                          onClick={() =>
                            markAsRead(
                              currentPostboxId,
                              participationId,
                              messageId
                            )
                          }
                          size="s"
                        />
                      )}

                      {allowedParticipationOperations.CREATE_MESSAGE &&
                        !closingMessage && (
                          <FjdButton
                            iconLeft="send"
                            label={t('Antworten')}
                            onClick={() => setReplying(true)}
                            size="s"
                          />
                        )}
                    </FjdStack>
                  }
                />

                <FjdStatus
                  intent={messageReceived ? 'positive' : 'neutral'}
                  label={
                    messageReceived
                      ? t('Empfang bestätigt')
                      : t('Empfang noch nicht bestätigt')
                  }
                />
              </FjdStack>

              <MessageDetails
                caseId={caseId}
                messageId={messageId}
                participationId={participationId}
                postboxId={parentPostboxId}
              />
            </FjdStack>
          </FjdSection>

          <FjdSection>
            <FjdCard>
              <FjdStack spacing="xxl">
                <FjdSectionHeader
                  align="center"
                  heading={
                    <FjdHeading level={2} text={t('Nachrichteninhalt')} />
                  }
                />

                {message?.text ? (
                  <FjdMarkdown
                    allowHTML
                    source={sanitizeHtml(message.text)}
                    useParagraphs
                  />
                ) : (
                  <FjdMarkdown
                    disabled
                    source={`- ${t('Kein Nachrichtentext')} -`}
                  />
                )}
              </FjdStack>
            </FjdCard>
          </FjdSection>

          <FjdSection>
            <FjdCard>
              <FjdStack spacing="xxl">
                <FjdSectionHeader
                  align="center"
                  heading={
                    <FjdHeading level={2} text={t('Angehängte Dokumente')} />
                  }
                />

                <DocumentsTable
                  caseId={caseId}
                  messageId={messageId}
                  participationId={participationId}
                  postboxId={parentPostboxId}
                />
              </FjdStack>
            </FjdCard>
          </FjdSection>
        </FjdStack>
      </FjdLoadingOverlay>

      {replying && (
        <Suspense
          fallback={
            <FjdModal
              appElement={
                document.querySelector('.fjd-base-layout') as HTMLElement
              }
              closeOnBackdropClick={false}
              heading={t('Auf Nachricht antworten')}
              open
            >
              <FjdSpinner size="s" />
            </FjdModal>
          }
        >
          <CreateMessageModal
            caseId={caseId}
            initialSubject={`Re: ${message.subject || ''}`}
            onClose={() => setReplying(false)}
            participationId={participationId}
            postboxId={parentPostboxId}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
