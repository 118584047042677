import {
  FjdIcon,
  FjdSpinner,
  FjdTooltip,
  FjdTreeItem
} from 'fjd-react-components';
import { FjdTabBarTab } from 'fjd-react-components/build/components/TabBar/TabBar';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { useTreeExpansion } from '../../../../hooks/useTreeExpansion';
import { Message, MessageType } from '../../../../models/Message';
import { Participation } from '../../../../models/Participation';
import { getParticipationPath } from '../../../../utils/router';
import { MessagesTree } from '../MessagesTree/MessagesTree';

interface ParticipationTreeItemProps {
  activeTab?: FjdTabBarTab;
  participation: Participation;
  postboxId: string;
}

export function ParticipationTreeItem({
  activeTab,
  participation,
  postboxId
}: ParticipationTreeItemProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { expandNode, toggleNode, treeExpansion } =
    useTreeExpansion('sidebar-tree');

  const { data: messages } = useSWR<Message[]>(
    `/postboxes/${participation.postboxId}/participations/${participation.id}/messages`,
    { suspense: false }
  );

  const isClosed = useMemo(
    () => !!messages?.some((message) => message.type === MessageType.CLOSE),
    [messages]
  );

  return participation ? (
    <FjdTreeItem
      active={activeTab?.id === participation.id}
      badges={
        isClosed ? (
          <FjdTooltip tooltip={t('Mitwirkung abgeschlossen')}>
            <FjdIcon glyph="locked" size="s" />
          </FjdTooltip>
        ) : undefined
      }
      bottomSpacing={treeExpansion[participation.id] ? 'xl' : 'none'}
      collapsible
      expanded={!!treeExpansion[participation.id]}
      label={
        messages
          ? `${participation.title} (${messages.length})`
          : participation.title
      }
      icon="folder"
      iconIntent="success"
      onClick={() => {
        expandNode(participation.id);
        navigate(getParticipationPath(participation, postboxId));
      }}
      onExpandedChange={() => toggleNode(participation.id)}
    >
      <Suspense fallback={<FjdSpinner size="s" />}>
        <MessagesTree
          participationId={participation.id}
          postboxId={postboxId}
        />
      </Suspense>
    </FjdTreeItem>
  ) : null;
}
