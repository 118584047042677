import { useCallback, useMemo, useState } from 'react';

import { Pagination } from '../shared/Pagination';

interface UsePaginationConfig {
  elementLabel?: string;
  pages: number;
  pageSize: number;
  paginationStyle: PaginationStyle;
  rowCount: number;
}

export type PaginationStyle = 'default' | 'simple';

export function usePagination({
  elementLabel,
  pages,
  pageSize,
  paginationStyle = 'default',
  rowCount
}: UsePaginationConfig) {
  const [currentPage, setCurrentPage] = useState(0);

  const apiQueryString = `page=${currentPage}&size=${pageSize}`;
  const nextPageEnabled = currentPage + 1 < pages;
  const prevPageEnabled = currentPage - 1 >= 0;

  const setPage = useCallback(
    (page: number) => {
      if (page < pages && page >= 0) {
        setCurrentPage(page);
      }
    },
    [pages]
  );

  const nextPage = useCallback(() => {
    setPage(currentPage + 1);
  }, [currentPage, setPage]);

  const prevPage = useCallback(() => {
    setPage(currentPage - 1);
  }, [currentPage, setPage]);

  const pagination = useMemo(
    () => ({
      apiQueryString,
      currentPage,
      nextPageEnabled,
      pages,
      pageSize,
      prevPageEnabled,
      nextPage,
      prevPage,
      rowCount,
      setPage
    }),
    [
      apiQueryString,
      currentPage,
      nextPage,
      nextPageEnabled,
      pageSize,
      pages,
      prevPage,
      prevPageEnabled,
      rowCount,
      setPage
    ]
  );

  const PaginationComponent = useMemo(
    () =>
      pagination.pages > 0 ? (
        <Pagination
          elementLabel={elementLabel}
          pagination={pagination}
          paginationStyle={paginationStyle}
        />
      ) : null,
    [elementLabel, pagination, paginationStyle]
  );

  return {
    ...pagination,
    PaginationComponent
  };
}
