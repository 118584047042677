import {
  FjdDescriptionList,
  FjdDescriptionListItem,
  FjdLoadingOverlay,
  FjdMarkdown
} from 'fjd-react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { AddressType } from '../models/Address';
import { ContactPerson } from '../models/ContactPerson';
import { LeadingParty } from '../models/LeadingParty';

interface ContactPersonDetailsProps {
  caseId?: string;
  postboxId: string;
}

export function ContactPersonDetails({
  caseId,
  postboxId
}: ContactPersonDetailsProps) {
  const { t } = useTranslation();

  const { data: leadingParty } = useSWR<LeadingParty>(
    caseId ? `/postboxes/${postboxId}/cases/${caseId}/leading-party` : null,
    { suspense: false }
  );

  const { data: contactPerson, isValidating } = useSWR<ContactPerson>(
    leadingParty?.contactPersonId
      ? `/postboxes/${postboxId}/contact-persons/${leadingParty.contactPersonId}`
      : null,
    { suspense: false }
  );

  const address = useMemo<string>(() => {
    if (!contactPerson?.physicalAddress) {
      return '-';
    }

    const physicalAddress = contactPerson.physicalAddress;

    if (physicalAddress.type === AddressType.INTERNATIONAL) {
      return `${physicalAddress.lines.join('<br/>')}<br/>${
        physicalAddress.country
      }`;
    }

    if (physicalAddress.type === AddressType.NATIONAL) {
      return `${
        physicalAddress.addressSupplement
          ? physicalAddress.addressSupplement + ', '
          : ''
      }${physicalAddress.street} ${physicalAddress.houseNumber}${
        physicalAddress.houseNumberSuffix
      }, ${physicalAddress.postalCode} ${physicalAddress.city}`;
    }

    if (physicalAddress.type === AddressType.POSTBOX) {
      return `Postfach ${physicalAddress.postOfficeBox}, ${physicalAddress.postalCode} ${physicalAddress.city}`;
    }

    return '';
  }, [contactPerson]);

  const phone = useMemo<string>(() => {
    if (!contactPerson?.telephones) {
      return '-';
    }

    return contactPerson.telephones
      .map((phone) => {
        let addition = phone.mobile || phone.description ? ' (' : '';

        if (phone.mobile) {
          addition += 'mobil';

          if (phone.description) {
            addition += ', ';
          }
        }

        if (phone.description) {
          addition += phone.description;
        }

        if (phone.mobile || phone.description) {
          addition += ')';
        }

        return `${phone.number}${addition}`;
      })
      .join('<br/>');
  }, [contactPerson?.telephones]);

  return leadingParty && !leadingParty.contactPersonId ? (
    <FjdMarkdown source={t('Keine Kontaktperson gefunden.')} />
  ) : contactPerson ? (
    <FjdLoadingOverlay loading={isValidating}>
      <FjdDescriptionList>
        <FjdDescriptionListItem
          description={contactPerson.name.formOfAddress || '-'}
          term={t('Anrede')}
          useMarkdown={false}
        />
        <FjdDescriptionListItem
          description={contactPerson.name.doctoralDegrees || '-'}
          term={t('Titel')}
          useMarkdown={false}
        />
        <FjdDescriptionListItem
          description={contactPerson.name.lastName || '-'}
          term={t('Name')}
          useMarkdown={false}
        />
        <FjdDescriptionListItem
          description={contactPerson.name.firstName || '-'}
          term={t('Vorname')}
          useMarkdown={false}
        />
        <FjdDescriptionListItem
          allowHTML
          description={address}
          term={t('Anschrift')}
        />
        <FjdDescriptionListItem
          allowHTML
          description={phone}
          term={t('Telefon')}
        />
      </FjdDescriptionList>
    </FjdLoadingOverlay>
  ) : null;
}
