import {
  FjdBadge,
  FjdButton,
  FjdCheckbox,
  FjdIcon,
  FjdStack,
  FjdTooltip,
  FjdTruncatedText
} from 'fjd-react-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { Case, instanceOfCase } from '../models/Case';
import { InvolvedParty } from '../models/InvolvedParty';
import { LeadingParty } from '../models/LeadingParty';
import { LogEntryStatus } from '../models/LogEntry';
import { instanceOfMessage, Message } from '../models/Message';
import {
  instanceOfParticipation,
  Participation
} from '../models/Participation';
import { Postbox } from '../models/Postbox';
import { PublicServiceType } from '../models/PublicServiceType';
import { RouterLink } from '../shared/RouterLink';
import { getParticipationPath } from '../utils/router';
import { defaultDateFormatOptions } from '../utils/string';
import { usePaginatedSwr } from './usePaginatedSwr';
import { useTable } from './useTable';
import { useTreeExpansion } from './useTreeExpansion';

interface UseTableCellRendererConfig {
  element: Case | Message | Participation;
  first: boolean;
  last: boolean;
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  table: ReturnType<typeof useTable>;
  treeId: string;
}

const caseCellIds = [
  'amountParticipations',
  'authority',
  'elementDate',
  'name',
  'leadContactPerson',
  'leadProcessReference',
  'leadResponsibleOrganisationalUnit',
  'publicServiceType',
  'selection',
  'type'
];

const messageCellIds = [
  'amountDocuments',
  'elementDate',
  'messagePreview',
  'messageReceivedOn',
  'messageType',
  'name',
  'recipient',
  'selection',
  'sender',
  'type'
];

const participationCellIds = [
  'amountDocuments',
  'amountMessages',
  'authority',
  'deadline',
  'elementDate',
  'name',
  'participantProcessReference',
  'participantResponsibleOrganisationalUnit',
  'participationName',
  'selection',
  'type'
];

export function useTableCellRenderer({
  element,
  first,
  last,
  onOpenPublicServiceType,
  table,
  treeId
}: UseTableCellRendererConfig) {
  const { postboxId: currentPostboxId } = useParams();
  const { t } = useTranslation();
  const { toggleNode, treeExpansion } = useTreeExpansion(treeId);

  const { data: caseDataResponse } = useSWR<Case>(
    !instanceOfCase(element)
      ? `/postboxes/${element.postboxId}/cases/${element.caseId}`
      : null,
    { suspense: false }
  );

  const { data: involvedParty } = useSWR<InvolvedParty>(
    instanceOfParticipation(element)
      ? `/postboxes/${element.postboxId}/participations/${element.id}/involved-party`
      : instanceOfMessage(element)
      ? `/postboxes/${element.postboxId}/participations/${element.participationId}/involved-party`
      : null,
    { suspense: false }
  );

  const { data: involvedPartyPostbox } = useSWR<Postbox>(
    involvedParty ? `/postboxes/${involvedParty.postboxId}` : null,
    { suspense: false }
  );

  const { count: amountParticipations } = usePaginatedSwr<Participation>({
    elementLabel: t('Mitwirkungen'),
    filter: [{ key: 'caseId', value: element.id }],
    key: `/postboxes/${currentPostboxId}/participation-results`
  });

  const { data: participationDataResponse } = useSWR<Participation>(
    instanceOfMessage(element)
      ? `/postboxes/${element.postboxId}/participations/${element.participationId}`
      : null,
    { suspense: false }
  );

  const { data: participationMessages } = useSWR<Message[]>(
    instanceOfParticipation(element)
      ? `/postboxes/${element.postboxId}/participations/${element.id}/messages`
      : null,
    { suspense: false }
  );

  const caseData = useMemo(
    () => (instanceOfCase(element) ? element : caseDataResponse),
    [caseDataResponse, element]
  );

  const { data: leadingParty } = useSWR<LeadingParty>(
    caseData
      ? `/postboxes/${caseData.postboxId}/cases/${caseData?.id}/leading-party`
      : null,
    { suspense: false }
  );

  const { data: leadingPartyPostbox } = useSWR<Postbox>(
    leadingParty ? `/postboxes/${leadingParty.postboxId}` : null,
    { suspense: false }
  );

  const participation = useMemo(
    () =>
      instanceOfParticipation(element) ? element : participationDataResponse,
    [element, participationDataResponse]
  );

  const publicServiceTypeId = useMemo(
    () =>
      instanceOfCase(element)
        ? element.publicServiceTypeId
        : caseData?.publicServiceTypeId,
    [caseData, element]
  );

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    publicServiceTypeId ? `/public-service-types/${publicServiceTypeId}` : null,
    { suspense: false }
  );

  const isJoinedCell = useCallback(
    (columnId: string) => {
      const rowIsExpanded = treeExpansion[element.id];

      if (
        columnId === 'name' ||
        columnId === 'selection' ||
        columnId === 'type' ||
        columnId === 'elementDate' ||
        columnId === 'amountDocuments' ||
        columnId === 'authority'
      ) {
        return false;
      }

      if (caseCellIds.includes(columnId)) {
        if (instanceOfParticipation(element) && last && !rowIsExpanded) {
          return false;
        }

        return (
          (instanceOfCase(element) && rowIsExpanded) || !instanceOfCase(element)
        );
      }

      if (participationCellIds.includes(columnId)) {
        if (instanceOfMessage(element) && last) {
          return false;
        }

        return (
          (instanceOfParticipation(element) && rowIsExpanded) ||
          instanceOfMessage(element)
        );
      }

      return false;
    },
    [element, last, treeExpansion]
  );

  const renderCell = useCallback(
    (columnId: string) => {
      if (instanceOfCase(element) && !caseCellIds.includes(columnId)) {
        return null;
      }

      if (instanceOfMessage(element) && !messageCellIds.includes(columnId)) {
        return null;
      }

      if (
        instanceOfParticipation(element) &&
        !participationCellIds.includes(columnId)
      ) {
        return null;
      }

      const messageReceived =
        instanceOfMessage(element) &&
        !!element?.logs?.find(
          (entry) => entry.status === LogEntryStatus.RECEIVED
        );

      if (columnId === 'amountDocuments') {
        return instanceOfMessage(element)
          ? element.docs?.length || 0
          : instanceOfParticipation(element) && participationMessages
          ? participationMessages.reduce(
              (amountDocuments, message) =>
                amountDocuments + (message.docs?.length || 0),
              0
            )
          : null;
      }

      if (columnId === 'amountMessages') {
        return participationMessages ? participationMessages.length : null;
      }

      if (columnId === 'amountParticipations') {
        return amountParticipations !== undefined ? amountParticipations : null;
      }

      if (columnId === 'authority') {
        if (instanceOfCase(element)) {
          return (
            <FjdStack orientation="horizontal" spacing="xs">
              <FjdTooltip tooltip={t('Verfahrensführende Stelle')}>
                <FjdBadge appearance="outline" label="VF" size="xs" />
              </FjdTooltip>
              <FjdTruncatedText text={leadingPartyPostbox?.name || ''} />
            </FjdStack>
          );
        } else if (instanceOfParticipation(element)) {
          return (
            <FjdStack orientation="horizontal" spacing="xs">
              <FjdTooltip tooltip={t('Verfahrensbeteiligte Stelle')}>
                <FjdBadge appearance="outline" label="VB" size="xs" />
              </FjdTooltip>
              <FjdTruncatedText text={involvedPartyPostbox?.name || ''} />
            </FjdStack>
          );
        } else {
          return null;
        }
      }

      if (columnId === 'elementDate') {
        if (instanceOfCase(element)) {
          return element.startDate
            ? new Date(element.startDate).toLocaleString(
                [],
                defaultDateFormatOptions
              )
            : null;
        } else if (instanceOfParticipation(element)) {
          return new Date(element.startDate).toLocaleString(
            [],
            defaultDateFormatOptions
          );
        } else {
          return new Date(element.sentOn).toLocaleString(
            [],
            defaultDateFormatOptions
          );
        }
      }

      if (columnId === 'leadProcessReference') {
        return leadingParty?.processReference;
      }

      if (columnId === 'messageReceivedOn') {
        if (!instanceOfMessage(element)) {
          return null;
        }

        return element?.receivedOn
          ? new Date(element.receivedOn).toLocaleString(
              [],
              defaultDateFormatOptions
            )
          : null;
      }

      if (columnId === 'name') {
        const elementPostboxId = element.postboxId;

        const name = instanceOfMessage(element)
          ? messageReceived
            ? element.subject || ''
            : `<strong>${element.subject || ''}</strong>`
          : instanceOfParticipation(element) && participationMessages
          ? `${element.title} (${participationMessages?.length})`
          : element.title;

        const path = instanceOfCase(element)
          ? `/postboxes/${currentPostboxId}/cases/${element.id}?parentPostboxId=${elementPostboxId}`
          : instanceOfParticipation(element)
          ? `/postboxes/${currentPostboxId}/cases/${element.caseId}/participations/${element.id}?parentPostboxId=${elementPostboxId}`
          : `/postboxes/${currentPostboxId}/cases/${element.caseId}/participations/${element.participationId}/messages/${element.id}?parentPostboxId=${elementPostboxId}`;

        return (
          <FjdStack orientation="horizontal" spacing="s">
            <span
              style={
                instanceOfMessage(element)
                  ? { visibility: 'hidden' }
                  : undefined
              }
            >
              <FjdButton
                appearance="primary-link"
                hideLabel
                iconLeft={treeExpansion[element.id] ? 'caret-up' : 'caret-down'}
                label={t('Zeile ein-/ausklappen')}
                onClick={() => toggleNode(element.id)}
                size="s"
              />
            </span>
            <RouterLink
              component={({ href, onClick }) => (
                <FjdTruncatedText
                  allowHTML
                  href={href}
                  onClick={onClick}
                  text={name}
                />
              )}
              to={path}
            />
          </FjdStack>
        );
      }

      if (columnId === 'participationName') {
        if (instanceOfCase(element) || !participation) {
          return null;
        }

        return (
          <RouterLink
            component={({ href, onClick }) => (
              <FjdTruncatedText
                href={href}
                onClick={onClick}
                text={participation?.title || ''}
              />
            )}
            to={getParticipationPath(participation, currentPostboxId)}
          />
        );
      }

      if (columnId === 'participantProcessReference') {
        return involvedParty?.processReference;
      }

      if (columnId === 'publicServiceType') {
        return (
          <FjdTruncatedText
            href={`/public-service-types/${publicServiceTypeId}`}
            onClick={(event) => {
              event.preventDefault();

              if (
                typeof onOpenPublicServiceType === 'function' &&
                publicServiceType
              ) {
                onOpenPublicServiceType(publicServiceType?.id);
              }
            }}
            text={publicServiceType?.name || ''}
          />
        );
      }

      if (columnId === 'selection') {
        return (
          <FjdCheckbox
            checked={table.selection.some((el) => el.id === element.id)}
            id={`select-${element.id}`}
            label=""
            onChange={() => table.toggleSelection(element)}
            size="s"
          />
        );
      }

      if (columnId === 'type') {
        if (instanceOfCase(element)) {
          return (
            <FjdTooltip tooltip={t('Fall')}>
              <FjdIcon appearance="warn" glyph="box" />
            </FjdTooltip>
          );
        }

        if (instanceOfMessage(element)) {
          return (
            <FjdTooltip tooltip={t('Nachricht')}>
              <FjdIcon
                appearance="info"
                glyph={messageReceived ? 'email' : 'email-unread'}
              />
            </FjdTooltip>
          );
        }

        if (instanceOfParticipation(element)) {
          return (
            <FjdTooltip tooltip={t('Mitwirkung')}>
              <FjdIcon appearance="success" glyph="folder" />
            </FjdTooltip>
          );
        }
      }

      return null;
    },
    [
      element,
      participationMessages,
      amountParticipations,
      leadingPartyPostbox?.name,
      involvedPartyPostbox?.name,
      leadingParty?.processReference,
      currentPostboxId,
      treeExpansion,
      t,
      toggleNode,
      participation,
      involvedParty?.processReference,
      publicServiceTypeId,
      publicServiceType,
      onOpenPublicServiceType,
      table
    ]
  );

  return { isJoinedCell, renderCell };
}
