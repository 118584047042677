import {
  FjdButton,
  FjdButtonSelectGroup,
  FjdFlexSpacer,
  FjdFormControl,
  FjdStack,
  FjdTooltip
} from 'fjd-react-components';
import { Suspense, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { useProtocol } from '../../../hooks/useProtocol';
import { Case } from '../../../models/Case';
import { Message } from '../../../models/Message';
import { Participation } from '../../../models/Participation';
import { Postbox } from '../../../models/Postbox';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { PostboxSelect } from '../../../shared/PostboxSelect';
import { CaseSelectionTable } from './CaseSelectionTable/CaseSelectionTable';
import { ParticipationSelectionTable } from './ParticipationSelectionTable/ParticipationSelectionTable';
import { ProtocolModal } from './ProtocolModal/ProtocolModal';

export function Protocols() {
  const { t } = useTranslation();

  const [selectedElement, setSelectedElement] = useState<
    Case | Message | Participation | Postbox
  >();

  const [selectedPostbox, setSelectedPostbox] = useState<Postbox | null>();
  const [filter, setFilter] = useState<string>('cases');

  const { downloadProtocol } = useProtocol();

  return (
    <>
      <Helmet>
        <title>
          {t('Protokolle & Statistik')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense fallback={<LoadingSpinner label={t('Lade Daten …')} />}>
        <FjdStack spacing="xl">
          <FjdStack orientation="horizontal" spacing="xl">
            <PostboxSelect
              onChange={setSelectedPostbox}
              selectedPostbox={selectedPostbox}
              showEditButton={false}
            />

            {selectedPostbox && (
              <FjdFormControl label=" ">
                <div>
                  <FjdStack orientation="horizontal" spacing="s">
                    <FjdTooltip
                      focusable={false}
                      placement="top"
                      tooltip={t('Protokolleinträge abrufen')}
                    >
                      <FjdButton
                        appearance="primary-link"
                        iconLeft="view"
                        label={t('Protokolleinträge abrufen')}
                        onClick={() => setSelectedElement(selectedPostbox)}
                        size="s"
                      />
                    </FjdTooltip>
                    <FjdTooltip
                      focusable={false}
                      placement="top"
                      tooltip={t('Protokolleinträge herunterladen')}
                    >
                      <FjdButton
                        appearance="primary-link"
                        iconLeft="download"
                        label={t('Protokolleinträge herunterladen')}
                        onClick={() => downloadProtocol(selectedPostbox)}
                        size="s"
                      />
                    </FjdTooltip>
                  </FjdStack>
                </div>
              </FjdFormControl>
            )}

            <FjdFlexSpacer />

            {selectedPostbox && (
              <FjdFormControl inputId="filter" label={t('Filter')}>
                <div style={{ paddingTop: '0.25rem' }}>
                  <FjdButtonSelectGroup
                    id="filter"
                    onChange={(event) => setFilter(event.target.value)}
                    options={[
                      {
                        selected: filter === 'cases',
                        text: t('Fälle'),
                        value: 'cases'
                      },
                      {
                        selected: filter === 'participations',
                        text: t('Mitwirkungen und Nachrichten'),
                        value: 'participations'
                      }
                    ]}
                    size="s"
                  />
                </div>
              </FjdFormControl>
            )}
          </FjdStack>

          {selectedPostbox && filter === 'cases' && (
            <CaseSelectionTable
              onSelect={setSelectedElement}
              postboxId={selectedPostbox.id}
            />
          )}

          {selectedPostbox && filter === 'participations' && (
            <ParticipationSelectionTable
              onSelect={setSelectedElement}
              postboxId={selectedPostbox.id}
            />
          )}
        </FjdStack>
      </Suspense>

      {selectedElement && (
        <ProtocolModal
          element={selectedElement}
          onClose={() => setSelectedElement(undefined)}
        />
      )}
    </>
  );
}

export { Protocols as default } from './Protocols';
