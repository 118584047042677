import {
  FjdAutocomplete,
  FjdColumns,
  FjdFormControl,
  FjdModal,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useCallback, useEffect, useRef } from 'react';
import {
  Controller,
  DeepMap,
  FieldError,
  Resolver,
  useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { usePostbox } from '../../../../hooks/usePostbox';
import {
  FormOfAddress,
  NaturalPerson,
  PersonType,
  PublicOrganization
} from '../../../../models/Person';
import {
  Postbox,
  UpdatePostboxRequest,
  UpdatePostboxRequestSchema
} from '../../../../models/Postbox';
import { submitForm } from '../../../../utils/form';
import { typeOfPublicOrganizationOptions } from '../../../../utils/postbox';

interface UpdatePostboxOwnerModalProps {
  onClose: () => void;
  postboxToUpdate: Postbox;
}

type FormControls = UpdatePostboxRequest;

export function UpdatePostboxOwnerModal({
  onClose,
  postboxToUpdate
}: UpdatePostboxOwnerModalProps) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement>(null);

  const { loading, updatePostbox } = usePostbox();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register
  } = useForm<FormControls>({
    defaultValues: {
      name: postboxToUpdate.name,
      note: postboxToUpdate.note,
      notifications: [...postboxToUpdate.notifications],
      owner: { ...postboxToUpdate.owner }
    },
    resolver: joiResolver(
      UpdatePostboxRequestSchema.messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const onUpdate = useCallback(
    async (data: FormControls) => {
      await updatePostbox(postboxToUpdate.id, data);
      onClose();
    },
    [onClose, postboxToUpdate.id, updatePostbox]
  );

  useEffect(() => {
    register('notifications', { value: [...postboxToUpdate.notifications] });
  }, [postboxToUpdate.notifications, register]);

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Postfachinhaber bearbeiten')}
      id="update-postbox-owner-modal"
      loading={loading}
      onClose={() => !loading && onClose()}
      onPrimaryButtonClick={() => submitForm(form.current)}
      onSecondaryButtonClick={() => !loading && onClose()}
      open
      primaryButtonLabel={t('Änderungen speichern')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <form onSubmit={handleSubmit(onUpdate)} ref={form}>
        {(postboxToUpdate.owner.type === PersonType.PUBLIC_ORGANISATION ||
          postboxToUpdate.owner.type === PersonType.PRIVATE_LEGAL_ENTITY) && (
          <>
            <FjdStack spacing="xl">
              <FjdFormControl
                inputId="owner.organizationName"
                label={t('Organisationsname')}
                validationMessage={
                  (errors.owner as DeepMap<PublicOrganization, FieldError>)
                    ?.organizationName?.message
                }
              >
                <Controller
                  name="owner.organizationName"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FjdTextInput
                        defaultValue={field.value}
                        id="owner.organizationName"
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                      />
                    );
                  }}
                />
              </FjdFormControl>

              <FjdColumns>
                <FjdFormControl
                  inputId="owner.organisationalUnit"
                  label={t('Organisationseinheit')}
                  optional
                  validationMessage={
                    (errors.owner as DeepMap<PublicOrganization, FieldError>)
                      ?.organisationalUnit?.message
                  }
                >
                  <Controller
                    name="owner.organisationalUnit"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.organisationalUnit"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                {postboxToUpdate.owner.type ===
                  PersonType.PUBLIC_ORGANISATION && (
                  <FjdFormControl
                    inputId="owner.typeOfPublicOrganization"
                    label={t('Art der öffentlichen Stelle')}
                    optional
                    validationMessage={
                      (errors.owner as DeepMap<PublicOrganization, FieldError>)
                        ?.typeOfPublicOrganization?.message
                    }
                  >
                    <Controller
                      name="owner.typeOfPublicOrganization"
                      control={control}
                      render={({ field }) => {
                        return (
                          <FjdAutocomplete
                            id="owner.typeOfPublicOrganization"
                            label={t('Mitwirkungsaufgabe')}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                            menuContainer={document.body}
                            options={typeOfPublicOrganizationOptions}
                            value={field.value}
                          />
                        );
                      }}
                    />
                  </FjdFormControl>
                )}
              </FjdColumns>
            </FjdStack>
          </>
        )}

        {postboxToUpdate.owner.type === PersonType.NATURAL_PERSON && (
          <>
            <FjdStack spacing="xl">
              <FjdColumns>
                <FjdFormControl
                  inputId="owner.name.doctoralDegrees"
                  label={t('Titel')}
                  optional
                  validationMessage={
                    (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                      ?.doctoralDegrees?.message
                  }
                >
                  <Controller
                    name="owner.name.doctoralDegrees"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.name.doctoralDegrees"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                <FjdFormControl
                  inputId="owner.name.formOfAddress"
                  label={t('Anrede')}
                  validationMessage={
                    (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                      ?.formOfAddress?.message
                  }
                >
                  <Controller
                    name="owner.name.formOfAddress"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdSelect
                          disableSearch
                          id="name.formOfAddress"
                          name={field.name}
                          onChange={field.onChange}
                          options={Object.values(FormOfAddress).map(
                            (formOfAddress) => ({
                              selected: field.value === formOfAddress,
                              text: formOfAddress,
                              value: formOfAddress
                            })
                          )}
                          value={field.value}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </FjdColumns>

              <FjdColumns>
                <FjdFormControl
                  inputId="owner.name.lastName"
                  label={t('Name')}
                  validationMessage={
                    (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                      ?.lastName?.message
                  }
                >
                  <Controller
                    name="owner.name.lastName"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.name.lastName"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>

                <FjdFormControl
                  inputId="owner.name.firstName"
                  label={t('Vorname')}
                  validationMessage={
                    (errors.owner as DeepMap<NaturalPerson, FieldError>)?.name
                      ?.firstName?.message
                  }
                >
                  <Controller
                    name="owner.name.firstName"
                    control={control}
                    render={({ field }) => {
                      return (
                        <FjdTextInput
                          defaultValue={field.value}
                          id="owner.name.firstName"
                          name={field.name}
                          onBlur={field.onBlur}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                </FjdFormControl>
              </FjdColumns>
            </FjdStack>
          </>
        )}

        <input type="submit" hidden />
      </form>
    </FjdModal>
  );
}
