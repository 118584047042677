import { FjdGrid, FjdGridItem } from 'fjd-react-components';
import { useMemo } from 'react';
import useSWR from 'swr';

import {
  NotificationDuration,
  Postbox,
  PostboxNotification
} from '../../../../models/Postbox';
import EscalationLevel from '../EscalationLevel/EscalationLevel';

interface EscalationLevelsProps {
  postboxId: string;
}

export type NotificationsByEscalationLevel = {
  [level: number]: PostboxNotification[];
};

export function EscalationLevels({ postboxId }: EscalationLevelsProps) {
  const { data: postbox } = useSWR<Postbox>(`/postboxes/${postboxId}`);

  const escalationLevels = useMemo<NotificationsByEscalationLevel>(
    () =>
      postbox
        ? postbox.notifications.reduce(
            (levels, notification) => ({
              ...levels,
              [notification.escalationLevel]: [
                ...(levels[notification.escalationLevel] || []),
                notification
              ]
            }),
            {} as NotificationsByEscalationLevel
          )
        : {},
    [postbox]
  );

  return escalationLevels && postbox ? (
    <FjdGrid>
      {Object.keys(NotificationDuration).map((_, index) => (
        <FjdGridItem key={index} span={6}>
          <EscalationLevel
            level={index}
            notifications={escalationLevels[index] || []}
            postbox={postbox}
          />
        </FjdGridItem>
      ))}
    </FjdGrid>
  ) : null;
}

export { EscalationLevels as default } from './EscalationLevels';
