import Joi, { Schema } from 'joi';
import { Except } from 'type-fest';

import {
  NaturalPersonNameSchema,
  OrganisationalUnitSchema,
  OrganizationNameSchema,
  Person,
  PersonType,
  TypeOfPublicOrganizationSchema
} from './Person';
import { Postbox } from './Postbox';
import { User } from './User';

export type Invite = {
  caseId?: string;
  email: string;
  firstName?: string;
  lastName?: string;
  organisationalUnit?: string;
  organizationName?: string;
  postboxId: string;
  publicServiceTypeId?: string;
  text?: string;
  type: PersonType;
  username: string;
};
export type InvitationUserRequest = {
  invitationEmail: string;
  owner: Person;
  postboxIdLeading: string;
  publicServiceTypeId?: string;
  text?: string;
};

export type InvitationResponse = {
  createdUser: User;
  createdPostbox: Postbox;
};

export type CreateInviteRequest = Except<Invite, 'username'>;

export const CreateInvitationUserRequestSchema: Schema = Joi.object({
  publicServiceTypeId: Joi.any().optional(),
  text: Joi.any().optional(),
  postboxIdLeading: Joi.string().required(),
  invitationEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  owner: Joi.object()
    .keys({
      name: NaturalPersonNameSchema.when('type', {
        is: PersonType.NATURAL_PERSON,
        otherwise: Joi.forbidden()
      }),
      organisationalUnit: OrganisationalUnitSchema.when('type', {
        is: PersonType.PRIVATE_LEGAL_ENTITY,
        otherwise: OrganisationalUnitSchema.when('type', {
          is: PersonType.PUBLIC_ORGANISATION,
          otherwise: Joi.forbidden()
        })
      }),
      organizationName: OrganizationNameSchema.when('type', {
        is: PersonType.PRIVATE_LEGAL_ENTITY,
        otherwise: OrganizationNameSchema.when('type', {
          is: PersonType.PUBLIC_ORGANISATION,
          otherwise: Joi.forbidden()
        })
      }),
      type: Joi.string()
        .valid(...Object.values(PersonType))
        .required(),
      typeOfPublicOrganization: TypeOfPublicOrganizationSchema.when('type', {
        is: PersonType.PUBLIC_ORGANISATION,
        otherwise: Joi.forbidden()
      })
    })
    .required()
});
