export type LogEntry = {
  caseId?: string;
  createdAt: string;
  id: string;
  information?: string;
  messageId?: string;
  participationId?: string;
  postboxId?: string;
  status: LogEntryStatus;
  type: LogEntryType;
};

export enum LogEntryStatus {
  CLOSED = 'CLOSED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  FILE_DELETED = 'FILE_DELETED',
  FILE_UPLOAD = 'FILE_UPLOAD',
  FORWARDED = 'FORWARDED',
  OPENED = 'OPENED',
  QUEUED = 'QUEUED',
  RECEIVED = 'RECEIVED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  UPDATED = 'UPDATED'
}

export enum LogEntryType {
  CASE = 'CASE',
  MESSAGE = 'MESSAGE',
  PARTICIPATION = 'PARTICIPATION',
  POSTBOX = 'POSTBOX'
}
