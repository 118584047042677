import {
  FjdSpinner,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { Suspense } from 'react';
import { useParams } from 'react-router';

import { useTable } from '../../../../hooks/useTable';
import { useTableCellRenderer } from '../../../../hooks/useTableCellRenderer';
import { useTreeExpansion } from '../../../../hooks/useTreeExpansion';
import { Case } from '../../../../models/Case';
import { CasesTableParticipationRows } from '../CasesTableParticipationRows/CasesTableParticipationRows';

interface CasesTableCaseRowProps {
  case: Case;
  first: boolean;
  last: boolean;
  onOpenPublicServiceType?: (publicServiceTypeId: string) => void;
  table: ReturnType<typeof useTable>;
}

export function CasesTableCaseRow({
  case: caseData,
  first,
  last,
  onOpenPublicServiceType,
  table
}: CasesTableCaseRowProps) {
  const { postboxId: currentPostboxId } = useParams();
  const { treeExpansion } = useTreeExpansion('cases-table');
  const { isJoinedCell, renderCell } = useTableCellRenderer({
    element: caseData,
    first,
    last,
    onOpenPublicServiceType,
    table,
    treeId: 'cases-table'
  });

  return caseData ? (
    <>
      <FjdVirtualizedTableRow
        bordered={false}
        highlightOnHover
        withTopSeparator
      >
        {table.activeColumns.map((column) => (
          <FjdVirtualizedTableCell
            format={column.format || 'default'}
            horizontalSeparator={!isJoinedCell(column.id)}
            key={`${caseData.id}-${column.id}`}
            maxWidth={column.maxWidth}
            minWidth={column.minWidth}
            verticalSeparator
            width={column.width}
          >
            {renderCell(column.id)}
          </FjdVirtualizedTableCell>
        ))}
      </FjdVirtualizedTableRow>

      {treeExpansion[caseData.id] && (
        <Suspense
          fallback={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                <FjdSpinner size="s" />
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          }
        >
          <CasesTableParticipationRows
            caseId={caseData.id}
            onOpenPublicServiceType={onOpenPublicServiceType}
            postboxId={currentPostboxId}
            table={table}
          />
        </Suspense>
      )}
    </>
  ) : null;
}
