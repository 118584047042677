import {
  FjdLoadingOverlay,
  FjdStack,
  FjdVirtualizedTable,
  FjdVirtualizedTableCell,
  FjdVirtualizedTableCol,
  FjdVirtualizedTableRow
} from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePaginatedSwr } from '../../../../hooks/usePaginatedSwr';
import { useProtocol } from '../../../../hooks/useProtocol';
import { Message } from '../../../../models/Message';
import { Participation } from '../../../../models/Participation';
import { ParticipationSelectionTableRow } from './ParticipationSelectionTableRow/ParticipationSelectionTableRow';

interface ParticipationSelectionTableProps {
  onSelect: (element: Message | Participation) => void;
  postboxId: string;
}

export function ParticipationSelectionTable({
  onSelect,
  postboxId
}: ParticipationSelectionTableProps) {
  const { t } = useTranslation();

  const {
    data: participations,
    isValidating,
    pagination
  } = usePaginatedSwr<Participation>({
    elementLabel: t('Mitwirkungen'),
    key: `/postboxes/${postboxId}/participation-results`,
    pageSize: 10
  });

  const { downloadProtocol, loading } = useProtocol();

  return participations ? (
    <FjdLoadingOverlay loading={loading || isValidating}>
      <FjdStack spacing="s">
        <FjdVirtualizedTable
          disableVirtualization
          header={
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCol>{t('Name')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>{t('ID')}</FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol>
                {t('Beschreibung')}
              </FjdVirtualizedTableCol>
              <FjdVirtualizedTableCol maxWidth="6.5rem"></FjdVirtualizedTableCol>
            </FjdVirtualizedTableRow>
          }
        >
          {participations.length === 0 && (
            <FjdVirtualizedTableRow>
              <FjdVirtualizedTableCell>
                {t('Keine Mitwirkungen gefunden.')}
              </FjdVirtualizedTableCell>
            </FjdVirtualizedTableRow>
          )}

          {participations.map((participation, index) => (
            <ParticipationSelectionTableRow
              key={participation.id}
              onDownloadProtocol={downloadProtocol}
              onSelect={onSelect}
              participation={participation}
            />
          ))}
        </FjdVirtualizedTable>

        {pagination.PaginationComponent}
      </FjdStack>
    </FjdLoadingOverlay>
  ) : null;
}
