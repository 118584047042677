import { useCallback, useEffect } from 'react';
import { useStorageState } from 'react-storage-hooks';
import { atom, useRecoilState } from 'recoil';

export const treeExpansionAtom = atom<TreeExpansionState>({
  default: {},
  key: 'treeExpansion'
});

export const treeExpansionStateLocalStorageKey = 'pdbk-tree-expansion-state';

export interface TreeExpansionState {
  [treeId: string]: { [nodeId: string]: boolean };
}

export function useTreeExpansion(treeId: string) {
  const [, setStorageTreeExpansionState] = useStorageState<TreeExpansionState>(
    localStorage,
    treeExpansionStateLocalStorageKey
  );

  const [treeExpansion, setTreeExpansion] = useRecoilState(treeExpansionAtom);

  const expandNode = useCallback(
    (nodeId: string) => {
      setTreeExpansion((treeExpansion) => ({
        ...treeExpansion,
        [treeId]: { ...treeExpansion[treeId], [nodeId]: true }
      }));
    },
    [setTreeExpansion, treeId]
  );

  const toggleNode = useCallback(
    (nodeId: string) => {
      setTreeExpansion((treeExpansion) => ({
        ...treeExpansion,
        [treeId]: {
          ...treeExpansion[treeId],
          [nodeId]: !treeExpansion[treeId][nodeId]
        }
      }));
    },
    [setTreeExpansion, treeId]
  );

  useEffect(() => {
    setTreeExpansion((treeExpansion) => ({
      ...treeExpansion,
      [treeId]: treeExpansion[treeId] || {}
    }));
  }, [setTreeExpansion, treeId]);

  useEffect(() => {
    setStorageTreeExpansionState(treeExpansion);
  }, [setStorageTreeExpansionState, treeExpansion]);

  return { expandNode, toggleNode, treeExpansion: treeExpansion[treeId] || {} };
}
