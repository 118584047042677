import { FjdStack } from 'fjd-react-components';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import {
  PostboxesOwnerTable,
  PostboxesOwnerTableContext
} from '../../Case/PostboxesOwnerTable/PostboxesOwnerTable';

export function PostboxDirectories() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          {t('Verzeichnisse')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense fallback={<LoadingSpinner label={t('Lade Verzeichnis …')} />}>
        <FjdStack spacing="5xl">
          <PostboxesOwnerTable
            context={PostboxesOwnerTableContext.DIRECTORY}
            pageSize={20}
          />
        </FjdStack>
      </Suspense>
    </>
  );
}

export { PostboxDirectories as default } from './PostboxDirectories';
