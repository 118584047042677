import {
  FjdColumns,
  FjdFormControl,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import Joi from 'joi';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Except } from 'type-fest';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../../hooks/useJoi';
import {
  CreateContactPersonRequest,
  FunctionSchema
} from '../../../../../models/ContactPerson';
import {
  FormOfAddress,
  NaturalPersonNameSchema
} from '../../../../../models/Person';

interface UpsertContactPersonFormStep1Props {
  initialData?: Partial<CreateContactPersonRequest>;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
}

export type FormControls = Except<
  CreateContactPersonRequest,
  'emailAddress' | 'physicalAddress' | 'telephones'
>;

export function UpsertContactPersonFormStep1({
  initialData,
  onChange,
  onInit,
  onNext
}: UpsertContactPersonFormStep1Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      function: initialData?.function,
      name: initialData?.name
    },
    resolver: joiResolver(
      Joi.object({
        function: FunctionSchema,
        name: NaturalPersonNameSchema
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const name = watch('name');

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdStack spacing="xl">
        <FjdColumns>
          <FjdFormControl
            inputId="formOfAddress"
            label={t('Anrede')}
            validationMessage={errors.name?.formOfAddress?.message}
          >
            <Controller
              name="name.formOfAddress"
              control={control}
              render={({ field }) => {
                return (
                  <FjdSelect
                    disableSearch
                    id="formOfAddress"
                    name={field.name}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        name: {
                          ...name,
                          [field.name.split('.')[1]]: value
                        }
                      });
                    }}
                    options={Object.values(FormOfAddress).map(
                      (formOfAddress) => ({
                        selected: field.value === formOfAddress,
                        text: formOfAddress,
                        value: formOfAddress
                      })
                    )}
                    value={field.value}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId="name.doctoralDegrees"
            label={t('Titel')}
            optional
            validationMessage={errors.name?.doctoralDegrees?.message}
          >
            <Controller
              name="name.doctoralDegrees"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="name.doctoralDegrees"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        name: {
                          ...name,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdColumns>

        <FjdColumns>
          <FjdFormControl
            inputId="name.lastName"
            label={t('Name')}
            validationMessage={errors.name?.lastName?.message}
          >
            <Controller
              name="name.lastName"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="name.lastName"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        name: {
                          ...name,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId="name.firstName"
            label={t('Vorname')}
            validationMessage={errors.name?.firstName?.message}
          >
            <Controller
              name="name.firstName"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="name.firstName"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        name: {
                          ...name,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdColumns>

        <FjdFormControl
          inputId="function"
          label={t('Funktion in der Organisationsstruktur')}
          optional
          validationMessage={errors?.function?.message}
        >
          <Controller
            name="function"
            control={control}
            render={({ field }) => {
              return (
                <FjdTextInput
                  defaultValue={field.value}
                  id="function"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(event) => {
                    field.onChange(event);
                    onChange({
                      function: event.target.value
                    });
                  }}
                />
              );
            }}
          />
        </FjdFormControl>
      </FjdStack>

      <input type="submit" hidden />
    </form>
  );
}
