import { ReactNode, useEffect } from 'react';
import { Route } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

interface GuardedRouteProps {
  children?: ReactNode | ReactNode[];
  [prop: string]: any;
}

export function GuardedRoute({ children, ...rest }: GuardedRouteProps) {
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (!keycloak.authenticated) {
      keycloak.login();
    }
  }, [keycloak]);

  return keycloak.authenticated ? <Route {...rest}>{children}</Route> : null;
}

export default GuardedRoute;
