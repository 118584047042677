import {
  FjdButton,
  FjdHeading,
  FjdSectionHeader,
  FjdStack
} from 'fjd-react-components';
import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import useSWR from 'swr';

import { PublicServiceType as PublicServiceTypeModel } from '../../models/PublicServiceType';
import { LoadingSpinner } from '../../shared/LoadingSpinner';
import { RouterLink } from '../../shared/RouterLink';
import { PublicServiceTypeDetails } from './PublicServiceTypeDetails/PublicServiceType';

export function PublicServiceType() {
  const { publicServiceTypeId } = useParams();
  const { t } = useTranslation();

  const { data: publicServiceType } = useSWR<PublicServiceTypeModel>(
    `/public-service-types/${publicServiceTypeId}`
  );

  return (
    <>
      <Helmet>
        <title>
          {t('Verwaltungsleistung: {{publicServiceTypeName}}', {
            publicServiceTypeName: publicServiceType?.name
          })}{' '}
          - {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense
        fallback={<LoadingSpinner label={t('Lade Verwaltungsleistung …')} />}
      >
        <FjdStack spacing="5xl">
          <FjdSectionHeader
            heading={
              <FjdStack orientation="horizontal">
                <RouterLink
                  component={({ href, onClick }) => (
                    <FjdButton
                      appearance="primary-link"
                      hideLabel
                      href={href}
                      iconLeft="arrow-left"
                      label={t('Zurück')}
                      onClick={onClick}
                    />
                  )}
                  to={`/settings`}
                />
                <FjdHeading level={1} text={publicServiceType?.name || ''} />
              </FjdStack>
            }
          />
          <PublicServiceTypeDetails publicServiceTypeId={publicServiceTypeId} />
        </FjdStack>
      </Suspense>
    </>
  );
}

export { PublicServiceType as default } from './PublicServiceType';
