import {
  FjdButton,
  FjdFlexSpacer,
  FjdHeading,
  FjdHorizontalRule,
  FjdSection,
  FjdSectionHeader,
  FjdStack,
  FjdSwitch
} from 'fjd-react-components';
import { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { Postbox } from '../../../models/Postbox';
import { LoadingSpinner } from '../../../shared/LoadingSpinner';
import { PostboxSelect } from '../../../shared/PostboxSelect';
import { AuthorizeUserModal } from './AuthorizeUserModal/AuthorizeUserModal';
import { CreatePostboxModal } from './CreatePostboxModal/CreatePostboxModal';
import { DeletePostboxModal } from './DeletePostboxModal/DeletePostboxModal';
import { PostboxUsersTable } from './PostboxUsersTable/PostboxUsersTable';

export function PostboxesSettings() {
  const { t } = useTranslation();

  const [authorizing, setAuthorizing] = useState(false);
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedPostbox, setSelectedPostbox] = useState<Postbox | null>();
  const [confirmDeletion, setConfirmDeletion] = useState(false);

  useEffect(() => {
    setConfirmDeletion(false);
  }, [selectedPostbox]);

  return (
    <>
      <Helmet>
        <title>
          {t('Postfachverwaltung')} -{' '}
          {t('Plattform für digitale Behördenkommunikation')}
        </title>
      </Helmet>

      <Suspense fallback={<LoadingSpinner label={t('Lade Postfächer …')} />}>
        <FjdStack spacing="5xl">
          <FjdStack orientation="horizontal" spacing="s">
            <PostboxSelect
              onChange={setSelectedPostbox}
              selectedPostbox={selectedPostbox}
            />

            <FjdFlexSpacer />

            <FjdButton
              iconLeft="add-outline"
              label={t('Neues Postfach anlegen')}
              onClick={() => setCreating(true)}
              size="s"
            />
          </FjdStack>

          {selectedPostbox && (
            <>
              <FjdHorizontalRule />

              <FjdSection>
                <FjdStack spacing="xxl">
                  <FjdSectionHeader
                    heading={
                      <FjdHeading
                        level={2}
                        text={t('Postfach-Nutzerverwaltung')}
                      />
                    }
                    tools={
                      <FjdStack orientation="horizontal">
                        <FjdFlexSpacer />
                        <FjdButton
                          appearance="primary-link"
                          iconLeft="add-outline"
                          label={t('Neuen Nutzer autorisieren')}
                          onClick={() => setAuthorizing(true)}
                          size="s"
                        />
                      </FjdStack>
                    }
                  />

                  <PostboxUsersTable postboxId={selectedPostbox.id} />
                </FjdStack>
              </FjdSection>

              <FjdHorizontalRule />

              <FjdSection>
                <FjdStack spacing="xxl">
                  <FjdSectionHeader
                    heading={
                      <FjdHeading
                        level={2}
                        text={t('Ausgewähltes Postfach löschen')}
                      />
                    }
                  />

                  <FjdStack orientation="horizontal" spacing="xl">
                    <FjdSwitch
                      checked={confirmDeletion}
                      id="delete-confirmation"
                      label={t(
                        'Ja, ich möchte „{{postboxName}}“ unwiderruflich löschen!',
                        { postboxName: selectedPostbox.name }
                      )}
                      onChange={setConfirmDeletion}
                    />

                    <FjdButton
                      appearance="default"
                      disabled={!confirmDeletion}
                      iconLeft="trashcan"
                      label={t('Postfach löschen')}
                      onClick={() => setDeleting(true)}
                      size="s"
                    />
                  </FjdStack>
                </FjdStack>
              </FjdSection>
            </>
          )}
        </FjdStack>
      </Suspense>

      {authorizing && selectedPostbox && (
        <AuthorizeUserModal
          onClose={() => setAuthorizing(false)}
          postboxId={selectedPostbox?.id}
        />
      )}

      {creating && <CreatePostboxModal onClose={() => setCreating(false)} />}

      {deleting && selectedPostbox && (
        <DeletePostboxModal
          onClose={() => {
            setDeleting(false);
          }}
          onDelete={() => {
            setDeleting(false);
            setSelectedPostbox(undefined);
            setConfirmDeletion(false);
          }}
          postboxToDelete={selectedPostbox}
        />
      )}
    </>
  );
}

export { PostboxesSettings as default } from './PostboxesSettings';
