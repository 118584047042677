import {
  FjdButton,
  FjdColumns,
  FjdFormControl,
  FjdRichtextEditor,
  FjdSelect,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import Joi from 'joi';
import { useEffect, useRef } from 'react';
import { Controller, Resolver, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Except } from 'type-fest';

import { joiResolver } from '@hookform/resolvers/joi';

import { useJoi } from '../../../../hooks/useJoi';
import { CreateCaseRequest } from '../../../../models/Case';
import { ContactPerson } from '../../../../models/ContactPerson';
import {
  CreateLeadingPartyRequest,
  CreateLeadingPartyRequestSchema
} from '../../../../models/LeadingParty';
import { PublicServiceType } from '../../../../models/PublicServiceType';
import { UpserCaseFormData } from '../UpsertCaseModal';

interface UpsertCaseFormStep2Props {
  initialData?: UpserCaseFormData;
  onBack: () => void;
  onChange: (data: Partial<FormControls>) => void;
  onInit: (form: HTMLFormElement) => void;
  onNext: () => void;
  postboxId: string;
}

type FormControls = {
  case: Except<CreateCaseRequest, 'publicServiceTypeId'>;
  leadingParty: CreateLeadingPartyRequest;
};

export function UpsertCaseFormStep2({
  initialData,
  onBack,
  onChange,
  onInit,
  onNext,
  postboxId
}: UpsertCaseFormStep2Props) {
  const { t } = useTranslation();
  const { validationMessages } = useJoi();

  const form = useRef<HTMLFormElement | null>(null);

  const { data: publicServiceType } = useSWR<PublicServiceType>(
    `/public-service-types/${initialData?.case.publicServiceTypeId}`
  );

  const { data: contactPersons } = useSWR<ContactPerson[]>(
    `/postboxes/${postboxId}/contact-persons`
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm<FormControls>({
    defaultValues: {
      case: {
        description: initialData?.case.description,
        title: initialData?.case.title
      },
      leadingParty: {
        contactPersonId: initialData?.leadingParty.contactPersonId,
        fileReference: initialData?.leadingParty.fileReference,
        postboxId: initialData?.leadingParty.postboxId,
        processReference: initialData?.leadingParty.processReference,
        responsibleOrganisationalUnit:
          initialData?.leadingParty.responsibleOrganisationalUnit
      }
    },
    resolver: joiResolver(
      Joi.object({
        case: Joi.object({
          description: Joi.string().optional().allow(''),
          startDate: Joi.string().optional().allow(''),
          title: Joi.string().required()
        }),
        leadingParty: CreateLeadingPartyRequestSchema
      }).messages(validationMessages)
    ) as Resolver<FormControls, object>
  });

  const caseValue = watch('case');
  const leadingPartyValue = watch('leadingParty');

  useEffect(() => {
    if (form.current) {
      onInit(form.current);
    }
  }, [onInit]);

  return (
    <form onSubmit={handleSubmit(onNext)} ref={form}>
      <FjdStack spacing="xl">
        <FjdFormControl label={t('Verwaltungsleistung')}>
          <FjdStack orientation="horizontal">
            <FjdTextInput
              id="casePublicServiceType"
              name="publicServiceType"
              readonly
              value={publicServiceType?.name || ''}
            />
            <FjdButton
              appearance="primary-link"
              hideLabel
              iconLeft="edit"
              label={t('Ändern')}
              onClick={onBack}
            />
          </FjdStack>
        </FjdFormControl>

        <FjdFormControl
          inputId="caseTitle"
          label={t('Titel')}
          validationMessage={errors.case?.title?.message}
        >
          <Controller
            name="case.title"
            control={control}
            render={({ field }) => {
              return (
                <FjdTextInput
                  autoFocus
                  defaultValue={field.value}
                  id="caseTitle"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={(event) => {
                    field.onChange(event);
                    onChange({
                      case: {
                        ...caseValue,
                        [field.name.split('.')[1]]: event.target.value
                      }
                    });
                  }}
                />
              );
            }}
          />
        </FjdFormControl>

        <FjdFormControl
          inputId="caseDescription"
          label={t('Beschreibung')}
          optional
          validationMessage={errors.case?.description?.message}
        >
          <Controller
            name="case.description"
            control={control}
            render={({ field }) => {
              return (
                <FjdRichtextEditor
                  id="caseDescription"
                  initialValue={field.value}
                  name={field.name}
                  onChange={(value) => {
                    field.onChange(value);
                    onChange({
                      case: {
                        ...caseValue,
                        [field.name.split('.')[1]]: value
                      }
                    });
                  }}
                  output="html"
                  rows={5}
                />
              );
            }}
          />
        </FjdFormControl>

        <FjdColumns>
          <FjdFormControl
            inputId="leadingPartyFileReference"
            label={t('Aktenzeichen')}
            optional
            validationMessage={errors.leadingParty?.fileReference?.message}
          >
            <Controller
              name="leadingParty.fileReference"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="leadingPartyFileReference"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        leadingParty: {
                          ...leadingPartyValue,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId="leadingPartyProcessReference"
            label={t('Geschäftszeichen')}
            optional
            validationMessage={errors.leadingParty?.processReference?.message}
          >
            <Controller
              name="leadingParty.processReference"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="leadingPartyProcessReference"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        leadingParty: {
                          ...leadingPartyValue,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdColumns>

        <FjdColumns>
          <FjdFormControl
            inputId="leadingPartyResponsibleOrganisationalUnit"
            label={t('Organisationseinheit')}
            optional
            validationMessage={
              errors.leadingParty?.responsibleOrganisationalUnit?.message
            }
          >
            <Controller
              name="leadingParty.responsibleOrganisationalUnit"
              control={control}
              render={({ field }) => {
                return (
                  <FjdTextInput
                    defaultValue={field.value}
                    id="leadingPartyResponsibleOrganisationalUnit"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event);
                      onChange({
                        leadingParty: {
                          ...leadingPartyValue,
                          [field.name.split('.')[1]]: event.target.value
                        }
                      });
                    }}
                  />
                );
              }}
            />
          </FjdFormControl>

          <FjdFormControl
            inputId="leadingPartyContactPersonId"
            label={t('Kontaktperson')}
            optional
            validationMessage={errors.leadingParty?.contactPersonId?.message}
          >
            <Controller
              name="leadingParty.contactPersonId"
              control={control}
              render={({ field }) => {
                return (
                  <FjdSelect
                    id="leadingPartyContactPersonId"
                    name={field.name}
                    onChange={(value) => {
                      field.onChange(value);
                      onChange({
                        leadingParty: {
                          ...leadingPartyValue,
                          [field.name.split('.')[1]]: value
                        }
                      });
                    }}
                    options={[
                      {
                        selected:
                          field.value === '' || field.value === undefined,
                        text: t('- keine -'),
                        value: ''
                      },
                      ...(contactPersons || []).map((contactPerson) => ({
                        selected: field.value === contactPerson.id,
                        text: `${
                          contactPerson.name.formOfAddress === 'keine Angabe'
                            ? ''
                            : contactPerson.name.formOfAddress
                        } ${contactPerson.name.firstName} ${
                          contactPerson.name.lastName
                        }`,
                        value: contactPerson.id
                      }))
                    ]}
                  />
                );
              }}
            />
          </FjdFormControl>
        </FjdColumns>
      </FjdStack>

      <input type="submit" hidden />
    </form>
  );
}
