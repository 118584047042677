import { FjdMarkdown, FjdModal, FjdStack } from 'fjd-react-components';
import { useTranslation } from 'react-i18next';

import { usePostbox } from '../../../../hooks/usePostbox';
import { Postbox } from '../../../../models/Postbox';

interface DeletePostboxModalProps {
  onClose: () => void;
  onDelete: () => void;
  postboxToDelete?: Postbox;
}

export function DeletePostboxModal({
  onClose,
  onDelete,
  postboxToDelete
}: DeletePostboxModalProps) {
  const { t } = useTranslation();

  const { deletePostbox, loading } = usePostbox();

  const onConfirmDelete = async () => {
    if (postboxToDelete) {
      await deletePostbox(postboxToDelete.id);
      onDelete();
    }
  };

  return (
    <FjdModal
      appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
      closeOnBackdropClick={false}
      heading={t('Postfach wird unwiderruflich gelöscht!')}
      id="delete-postbox-modal"
      loading={loading}
      onClose={onClose}
      onPrimaryButtonClick={onConfirmDelete}
      onSecondaryButtonClick={onClose}
      open
      primaryButtonLabel={t('Bestätigen und Postfach löschen')}
      secondaryButtonLabel={t('Abbrechen')}
    >
      <FjdStack spacing="xl">
        <FjdMarkdown
          source={`**${t(
            'Sind sie sich sicher, dass sie das Postfach **„{{postboxName}}“** löschen wollen? Sie können diese Aktion nicht rückgängig machen!',
            { postboxName: postboxToDelete?.name }
          )}**`}
        />

        <FjdMarkdown
          source={t(
            `Das Postfach wird für neue Fälle und Mitwirkungen gesperrt. Gegebenenfalls noch nicht abgeschlossene Fälle können abgeschlossen werden.\n\n
Sobald alle im Postfach verwalteten Fälle abgeschlossen und gelöscht sind, werde alle Informationen zum Postfach unwiderruflich gelöscht.`
          )}
          useParagraphs
        />
      </FjdStack>
    </FjdModal>
  );
}
