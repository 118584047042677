import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import useSWR from 'swr';

import { Case } from '../models/Case';
import { Message } from '../models/Message';
import { Participation } from '../models/Participation';
import { RouteKey, routes } from '../utils/router';
import { useParentPostboxId } from './useParentPostboxId';

function useElementByLocation() {
  const casePageMatch = useMatch(routes[RouteKey.CASE]);
  const messagePageMatch = useMatch(routes[RouteKey.MESSAGE]);
  const participationPageMatch = useMatch(routes[RouteKey.PARTICIPATION]);
  const parentPostboxId = useParentPostboxId();

  const elementUrl = useMemo(() => {
    if (casePageMatch) {
      return `/postboxes/${
        parentPostboxId || casePageMatch.params.postboxId
      }/cases/${casePageMatch.params.caseId}`;
    }

    if (messagePageMatch) {
      return `/postboxes/${
        parentPostboxId || messagePageMatch.params.postboxId
      }/participations/${messagePageMatch.params.participationId}/messages/${
        messagePageMatch.params.messageId
      }`;
    }

    if (participationPageMatch) {
      return `/postboxes/${
        parentPostboxId || participationPageMatch.params.postboxId
      }/participations/${participationPageMatch.params.participationId}`;
    }

    return null;
  }, [
    casePageMatch,
    messagePageMatch,
    parentPostboxId,
    participationPageMatch
  ]);

  const element = useSWR<Case | Message | Participation>(elementUrl, {
    suspense: false
  });

  return elementUrl ? element.data : null;
}

export default useElementByLocation;
