import { FjdAlert, FjdAlertContainer } from 'fjd-react-components';
import { FjdAlertIntent } from 'fjd-react-components/build/components/Alert/Alert';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

export interface Alert {
  id?: string;
  intent: FjdAlertIntent;
  timeout?: number;
  title: string;
}

const alertsState = atom<Alert[]>({
  default: [],
  key: 'alerts'
});

function useAlerts() {
  const [alerts, setAlerts] = useRecoilState(alertsState);

  const alert = useCallback(
    (intent: FjdAlertIntent, title: string, duration = 3000) => {
      const id = `${Math.round(Math.random() * 1000000)}`;

      setAlerts((alerts) => [
        ...alerts,
        {
          id,
          intent,
          timeout: window.setTimeout(() => {
            setAlerts((currentAlerts) =>
              currentAlerts.filter((a) => a.id !== id)
            );
          }, duration),
          title
        }
      ]);
    },
    [setAlerts]
  );

  const renderAlertContainer = useCallback(() => {
    return (
      <FjdAlertContainer>
        {[...alerts].reverse().map((alert) => (
          <FjdAlert
            closable={false}
            intent={alert.intent}
            key={alert.id}
            title={alert.title}
          />
        ))}
      </FjdAlertContainer>
    );
  }, [alerts]);

  return { alert, alerts, renderAlertContainer };
}

export default useAlerts;
